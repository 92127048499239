import { Users } from "../models";

export enum ActionTypes {
  SET_USERS_FILTER = "SET_USERS_FILTER",
  USERS_FETCH = "USERS_FETCH",
  USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS",
  USERS_FETCH_FAILURE = "USERS_FETCH_FAILURE"
}

export type UsersAction =
  | { readonly type: ActionTypes.SET_USERS_FILTER; readonly name: string | null }
  | { readonly type: ActionTypes.USERS_FETCH }
  | { readonly type: ActionTypes.USERS_FETCH_SUCCESS; readonly users: Users }
  | { readonly type: ActionTypes.USERS_FETCH_FAILURE; readonly errorMsg: string };

export function setUsersFilter(name: string | null): UsersAction {
  return {
    type: ActionTypes.SET_USERS_FILTER,
    name
  };
}

export function usersFetch(): UsersAction {
  return {
    type: ActionTypes.USERS_FETCH
  };
}

export function usersFetchSuccess(users: Users): UsersAction {
  return {
    type: ActionTypes.USERS_FETCH_SUCCESS,
    users
  };
}

export function usersFetchFailure(errorMsg: string): UsersAction {
  return {
    type: ActionTypes.USERS_FETCH_FAILURE,
    errorMsg
  };
}
