import { Image } from "../models";

export interface CopyImageForm {
  readonly duplicateImageName: string | null;
}

export enum ActionTypes {
  OPEN_COPY_IMAGE_DIALOG = "OPEN_COPY_IMAGE_DIALOG",
  CLOSE_COPY_IMAGE_DIALOG = "CLOSE_COPY_IMAGE_DIALOG",
  SET_COPY_IMAGE_FORM = "SET_COPY_IMAGE_FORM",
  COPY_IMAGE_TO_STUDY = "COPY_IMAGE_TO_STUDY",
  COPY_IMAGE_TO_STUDY_SUCCESS = "COPY_IMAGE_TO_STUDY_SUCCESS",
  COPY_IMAGE_TO_STUDY_FAILURE = "COPY_IMAGE_TO_STUDY_FAILURE"
}

export type CopyImageDialogAction =
  | {
      readonly type: ActionTypes.OPEN_COPY_IMAGE_DIALOG;
      readonly image: Image;
    }
  | { readonly type: ActionTypes.CLOSE_COPY_IMAGE_DIALOG }
  | { readonly type: ActionTypes.SET_COPY_IMAGE_FORM; readonly form: CopyImageForm }
  | { readonly type: ActionTypes.COPY_IMAGE_TO_STUDY }
  | { readonly type: ActionTypes.COPY_IMAGE_TO_STUDY_SUCCESS }
  | { readonly type: ActionTypes.COPY_IMAGE_TO_STUDY_FAILURE; readonly errorMsg: string };

export function openCopyImageDialog(image: Image): CopyImageDialogAction {
  return {
    type: ActionTypes.OPEN_COPY_IMAGE_DIALOG,
    image
  };
}

export function closeCopyImageDialog(): CopyImageDialogAction {
  return {
    type: ActionTypes.CLOSE_COPY_IMAGE_DIALOG
  };
}

export function setCopyImageForm(form: CopyImageForm): CopyImageDialogAction {
  return {
    type: ActionTypes.SET_COPY_IMAGE_FORM,
    form
  };
}

export function copyImage(): CopyImageDialogAction {
  return {
    type: ActionTypes.COPY_IMAGE_TO_STUDY
  };
}

export function copyImageSuccess(): CopyImageDialogAction {
  return {
    type: ActionTypes.COPY_IMAGE_TO_STUDY_SUCCESS
  };
}

export function copyImageFailure(errorMsg: string): CopyImageDialogAction {
  return {
    type: ActionTypes.COPY_IMAGE_TO_STUDY_FAILURE,
    errorMsg
  };
}
