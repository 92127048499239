import { LatLngLiteral } from "leaflet";
import {
  Annotation,
  FreehandAnnotationClass,
  PointAnnotationClass,
  HpfAnnotationClass,
  UUID
} from "../models";

// This form is for annotation types that can be _edited_ (moved around or text added). Several
// types of annotations cannot be edited.
export interface BaseAnnotationForm {
  readonly geometry: LatLngLiteral;
}
export type HPFAnnotationForm = BaseAnnotationForm & {
  readonly radiusX: number;
  readonly text: string | null;
  readonly tempHpf: LatLngLiteral | null;
};
export type EllipseAnnotationForm = BaseAnnotationForm & {
  readonly radiusX: number;
  readonly radiusY: number;
  readonly tilt: number;
  readonly text: string | null;
};
export type TextAnnotationForm = BaseAnnotationForm & {
  readonly text: string;
};

export enum AnnotationStatus {
  Dropping = "DROPPING",
  Placing = "PLACING",
  Editing = "EDITING"
}

export enum AnnotationType {
  Line = "LINE",
  Ellipse = "ELLIPSE",
  HPF = "HPF",
  Text = "TEXT",
  Point = "POINT",
  Freehand = "FREEHAND"
}

export interface LineAnnotationType {
  readonly type: AnnotationType.Line;
}

export interface EllipseAnnotationType {
  readonly type: AnnotationType.Ellipse;
}

export interface TextAnnotationType {
  readonly type: AnnotationType.Text;
}

// NOTE: These annotation types are special in that they have extra info associated with them
// (annotation class, which determines their color).
export interface HpfAnnotationType {
  readonly type: AnnotationType.HPF;
  readonly hpfAnnotationClass: HpfAnnotationClass | null;
}

export interface PointAnnotationType {
  readonly type: AnnotationType.Point;
  readonly pointAnnotationClass: PointAnnotationClass | null;
}

export interface FreehandAnnotationType {
  readonly type: AnnotationType.Freehand;
  readonly freehandAnnotationClass: FreehandAnnotationClass | null;
}

export type SelectedAnnotationType = EditableAnnotationType | UneditableAnnotationType;

export type EditableAnnotationType = EllipseAnnotationType | HpfAnnotationType | TextAnnotationType;

// NOTE: These annotation types cannot be edited; they are placed/drawn and saved directly
export type UneditableAnnotationType =
  | PointAnnotationType
  //| HpfAnnotationType
  | LineAnnotationType
  | FreehandAnnotationType;

export type FormFromEditableAnnotationType<
  T extends EditableAnnotationType
> = T extends EllipseAnnotationType
  ? EllipseAnnotationForm
  : T extends TextAnnotationType
  ? TextAnnotationForm
  : T extends HpfAnnotationType
  ? HPFAnnotationForm
  : never;

export type AnnotationForm<T extends SelectedAnnotationType> =
  | {
      readonly form: null;
      readonly status: AnnotationStatus.Placing;
      readonly selectedAnnotationType: T;
    }
  | {
      readonly form: null;
      readonly status: AnnotationStatus.Dropping;
      readonly tempHpf: LatLngLiteral | null;
      readonly selectedAnnotationType: T;
    }
  | {
      readonly form: FormFromEditableAnnotationType<Exclude<T, UneditableAnnotationType>>;
      readonly status: AnnotationStatus.Editing;
      readonly selectedAnnotationType: Exclude<T, UneditableAnnotationType>;
    };

export enum ActionTypes {
  SET_IMAGE_ANNOTATION_HIGHLIGHT = "SET_IMAGE_ANNOTATION_HIGHLIGHT",
  SET_IMAGE_ANNOTATION_UNHIGHLIGHT = "SET_IMAGE_ANNOTATION_UNHIGHLIGHT",
  SET_IMAGE_ANNOTATION_TEXT = "SET_IMAGE_ANNOTATION_TEXT",
  SET_IMAGE_ANNOTATION_FOR_ELLIPSE_TYPE = "SET_IMAGE_ANNOTATION_FOR_ELLIPSE_TYPE",
  SET_IMAGE_ANNOTATION_FOR_TEXT_TYPE = "SET_IMAGE_ANNOTATION_FOR_TEXT_TYPE",
  SET_IMAGE_ANNOTATION_FOR_HPF_TYPE = "SET_IMAGE_ANNOTATION_FOR_HPF_TYPE",
  CREATE_LINE_IMAGE_ANNOTATION = "CREATE_LINE_IMAGE_ANNOTATION",
  CREATE_POINT_IMAGE_ANNOTATION = "CREATE_POINT_IMAGE_ANNOTATION",
  CREATE_HPF_IMAGE_ANNOTATION = "CREATE_HPF_IMAGE_ANNOTATION",
  CREATE_FREEHAND_IMAGE_ANNOTATION = "CREATE_FREEHAND_IMAGE_ANNOTATION",
  BEGIN_PLACING_ANNOTATION = "BEGIN_PLACING_ANNOTATION",
  BEGIN_DROPPING_ANNOTATION = "BEGIN_DROPPING_ANNOTATION",
  CANCEL_IMAGE_ANNOTATION = "CANCEL_IMAGE_ANNOTATION",
  CREATE_IMAGE_ANNOTATION = "CREATE_IMAGE_ANNOTATION",
  CREATE_IMAGE_ANNOTATION_ELLIPSE = "ELLIPSE_CREATE_IMAGE_ANNOTATION",
  CREATE_IMAGE_ANNOTATION_FAILURE = "CREATE_IMAGE_ANNOTATION_FAILURE",
  CREATE_IMAGE_ANNOTATION_SUCCESS = "CREATE_IMAGE_ANNOTATION_SUCCESS",
  CREATE_HPF_IMAGE_ANNOTATION_SUCCESS = "CREATE_HPF_IMAGE_ANNOTATION_SUCCESS",
  DELETE_IMAGE_ANNOTATION = "DELETE_IMAGE_ANNOTATION",
  DELETE_IMAGE_ANNOTATION_FAILURE = "DELETE_IMAGE_ANNOTATION_FAILURE",
  DELETE_IMAGE_ANNOTATION_SUCCESS = "DELETE_IMAGE_ANNOTATION_SUCCESS"
}

export type AnnotationAction =
  | {
      readonly type: ActionTypes.BEGIN_PLACING_ANNOTATION;
      readonly selectedAnnotationType: SelectedAnnotationType;
      readonly imageId: UUID;
    }
  | {
      readonly type: ActionTypes.BEGIN_DROPPING_ANNOTATION;
      readonly selectedAnnotationType: SelectedAnnotationType;
      readonly imageId: UUID;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_ELLIPSE_TYPE;
      readonly point: LatLngLiteral;
      readonly radiusX: number;
      readonly radiusY: number;
      readonly tilt: number;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_HPF_TYPE;
      readonly point: LatLngLiteral;
      readonly radius: number;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_TEXT_TYPE;
      readonly point: LatLngLiteral;
    }
  | {
      readonly type: ActionTypes.CREATE_LINE_IMAGE_ANNOTATION;
      readonly points: ReadonlyArray<LatLngLiteral>;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_FREEHAND_IMAGE_ANNOTATION;
      readonly positions: ReadonlyArray<LatLngLiteral>;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_POINT_IMAGE_ANNOTATION;
      readonly point: LatLngLiteral;
      readonly hpfRadius: number;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_HPF_IMAGE_ANNOTATION;
      readonly point: LatLngLiteral;
      readonly radius: number;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CANCEL_IMAGE_ANNOTATION;
    }
  | {
      readonly type: ActionTypes.CREATE_IMAGE_ANNOTATION;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_IMAGE_ANNOTATION_ELLIPSE;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_IMAGE_ANNOTATION_FAILURE;
      readonly errorMsg: string;
    }
  | {
      readonly type: ActionTypes.CREATE_IMAGE_ANNOTATION_SUCCESS;
      readonly annotation: Annotation;
      readonly keepAnnotating: boolean;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.CREATE_HPF_IMAGE_ANNOTATION_SUCCESS;
      readonly annotation: Annotation;
      readonly keepAnnotating: boolean;
      readonly cleanupCallback: () => void;
    }
  | {
      readonly type: ActionTypes.DELETE_IMAGE_ANNOTATION;
      readonly annotationId: UUID;
      readonly deleteNested: boolean;
    }
  | {
      readonly type: ActionTypes.DELETE_IMAGE_ANNOTATION_FAILURE;
      readonly errorMsg: string;
    }
  | {
      readonly type: ActionTypes.DELETE_IMAGE_ANNOTATION_SUCCESS;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_TEXT;
      readonly text: string;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_HIGHLIGHT;
      readonly text: string;
    }
  | {
      readonly type: ActionTypes.SET_IMAGE_ANNOTATION_UNHIGHLIGHT;
      readonly text: string;
    };

export function beginAnnotation(
  selectedAnnotationType: SelectedAnnotationType,
  imageId: UUID
): AnnotationAction {
  return {
    type: ActionTypes.BEGIN_PLACING_ANNOTATION,
    selectedAnnotationType,
    imageId
  };
}

export function beginDroppingAnnotation(
  selectedAnnotationType: SelectedAnnotationType,
  imageId: UUID
): AnnotationAction {
  return {
    type: ActionTypes.BEGIN_DROPPING_ANNOTATION,
    selectedAnnotationType,
    imageId
  };
}

export function setImageAnnotationForEllipseType(
  point: LatLngLiteral,
  radiusX: number,
  radiusY: number,
  tilt: number
): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_ELLIPSE_TYPE,
    point,
    radiusX,
    radiusY,
    tilt
  };
}

export function setImageAnnotationForHPFType(
  point: LatLngLiteral,
  radius: number
): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_HPF_TYPE,
    point,
    radius
  };
}

export function setImageAnnotationForTextType(point: LatLngLiteral): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_FOR_TEXT_TYPE,
    point
  };
}

export function createFreehandImageAnnotation(
  positions: ReadonlyArray<LatLngLiteral>,
  cleanupCallback: () => void
): AnnotationAction {
  return {
    type: ActionTypes.CREATE_FREEHAND_IMAGE_ANNOTATION,
    positions,
    cleanupCallback
  };
}

export function createLineImageAnnotation(
  points: ReadonlyArray<LatLngLiteral>,
  cleanupCallback: () => void
): AnnotationAction {
  return {
    type: ActionTypes.CREATE_LINE_IMAGE_ANNOTATION,
    points,
    cleanupCallback
  };
}

export function createPointImageAnnotation(
  point: LatLngLiteral,
  hpfRadius: number,
  cleanupCallback: () => void
): AnnotationAction {
  return {
    type: ActionTypes.CREATE_POINT_IMAGE_ANNOTATION,
    point,
    hpfRadius,
    cleanupCallback
  };
}

export function setImageAnnotationText(text: string): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_TEXT,
    text
  };
}

export function setImageAnnotationHighlight(annotationId: string): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_HIGHLIGHT,
    text: annotationId
  };
}

export function setImageAnnotationUnhighlight(annotationId: string): AnnotationAction {
  return {
    type: ActionTypes.SET_IMAGE_ANNOTATION_UNHIGHLIGHT,
    text: annotationId
  };
}

export function cancelImageAnnotation(): AnnotationAction {
  return {
    type: ActionTypes.CANCEL_IMAGE_ANNOTATION
  };
}

export function createHpfImageAnnotation(
  point: LatLngLiteral,
  radius: number,
  cleanupCallback: () => void
): AnnotationAction {
  return {
    type: ActionTypes.CREATE_HPF_IMAGE_ANNOTATION,
    point,
    radius,
    cleanupCallback
  };
}

export function createAnnotationEllipse(cleanupCallback: () => void): AnnotationAction {
  return {
    type: ActionTypes.CREATE_IMAGE_ANNOTATION_ELLIPSE,
    cleanupCallback
  };
}

export function createAnnotation(cleanupCallback: () => void): AnnotationAction {
  return {
    type: ActionTypes.CREATE_IMAGE_ANNOTATION,
    cleanupCallback
  };
}

export const createAnnotationSuccess = (keepAnnotating: boolean, cleanupCallback: () => void) => (
  annotation: Annotation
): AnnotationAction => {
  return {
    type: ActionTypes.CREATE_IMAGE_ANNOTATION_SUCCESS,
    annotation,
    keepAnnotating,
    cleanupCallback
  };
};

export const createHpfAnnotationSuccess = (
  keepAnnotating: boolean,
  cleanupCallback: () => void
) => (annotation: Annotation): AnnotationAction => {
  return {
    type: ActionTypes.CREATE_HPF_IMAGE_ANNOTATION_SUCCESS,
    annotation,
    keepAnnotating,
    cleanupCallback
  };
};

export function createAnnotationFailure(errorMsg: string): AnnotationAction {
  return {
    type: ActionTypes.CREATE_IMAGE_ANNOTATION_FAILURE,
    errorMsg
  };
}

export function deleteAnnotation(annotationId: UUID, deleteNested: boolean): AnnotationAction {
  return {
    type: ActionTypes.DELETE_IMAGE_ANNOTATION,
    annotationId,
    deleteNested
  };
}

export function deleteAnnotationSuccess(): AnnotationAction {
  return {
    type: ActionTypes.DELETE_IMAGE_ANNOTATION_SUCCESS
  };
}

export function deleteAnnotationFailure(errorMsg: string): AnnotationAction {
  return {
    type: ActionTypes.DELETE_IMAGE_ANNOTATION_FAILURE,
    errorMsg
  };
}
