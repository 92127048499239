import { QueryRecords } from "../models";
import { QueryFilter } from "../reducers/queries";

export enum ActionTypes {
  SET_QUERIES_FILTER = "SET_QUERIES_FILTER",
  QUERIES_FETCH = "QUERIES_FETCH",
  QUERIES_FETCH_SUCCESS = "QUERIES_FETCH_SUCCESS",
  QUERIES_FETCH_FAILURE = "QUERIES_FETCH_FAILURE"
}

export type QueriesAction =
  | { readonly type: ActionTypes.SET_QUERIES_FILTER; readonly queryFilter: QueryFilter }
  | { readonly type: ActionTypes.QUERIES_FETCH; readonly queryFilter: QueryFilter }
  | { readonly type: ActionTypes.QUERIES_FETCH_SUCCESS; readonly queries: QueryRecords }
  | { readonly type: ActionTypes.QUERIES_FETCH_FAILURE; readonly errorMsg: string };

export function setQueriesFilter(queryFilter: QueryFilter): QueriesAction {
  return {
    type: ActionTypes.SET_QUERIES_FILTER,
    queryFilter
  };
}

export function queriesFetch(queryFilter: QueryFilter): QueriesAction {
  return {
    type: ActionTypes.QUERIES_FETCH,
    queryFilter
  };
}

export function queriesFetchSuccess(queries: QueryRecords): QueriesAction {
  return {
    type: ActionTypes.QUERIES_FETCH_SUCCESS,
    queries
  };
}

export function queriesFetchFailure(errorMsg: string): QueriesAction {
  return {
    type: ActionTypes.QUERIES_FETCH_FAILURE,
    errorMsg
  };
}
