import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  CaseFilters,
  casesFetch,
  casesFetchFailure,
  casesFetchSuccess
} from "../actions/cases";
import { ActionTypes as StudyActionTypes } from "../actions/studies";

import { fetchCases } from "../api";
import { CasesAndCounts } from "../models";
import { Resource } from "../types";

export interface CasesState {
  readonly cases: Resource<CasesAndCounts>;
  readonly filters: CaseFilters;
}

export const initialState: CasesState = {
  cases: {
    isPending: false
  },
  filters: {}
};

const casesReducer: LoopReducer<CasesState, Action> = (
  state: CasesState = initialState,
  action: Action
): CasesState | Loop<CasesState, Action> => {
  switch (action.type) {
    case StudyActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      };
    case ActionTypes.SET_CASE_FILTERS:
      return loop(
        {
          ...state,
          filters: action.filters
        },
        Cmd.action(casesFetch(action.studyId))
      );
    case ActionTypes.CASES_FETCH:
      return loop(
        {
          ...state,
          cases: {
            isPending: true
          }
        },
        Cmd.run(fetchCases, {
          successActionCreator: casesFetchSuccess,
          failActionCreator: casesFetchFailure,
          args: [
            action.studyId,
            state.filters.procId,
            state.filters.status,
            state.filters.assignee
          ] as Parameters<typeof fetchCases>
        })
      );
    case ActionTypes.CASES_FETCH_SUCCESS:
      return {
        ...state,
        cases: {
          resource: action.cases
        }
      };
    case ActionTypes.CASES_FETCH_FAILURE:
      return {
        ...state,
        cases: {
          errorMessage: action.errorMsg
        }
      };
    default:
      return state as never;
  }
};

export default casesReducer;
