import { AnnotationAction } from "./actions/annotation";
import { AuditTrailAction } from "./actions/auditTrail";
import { AuthAction } from "./actions/auth";
import { CaseDialogAction } from "./actions/caseDialog";
import { CasesAction } from "./actions/cases";
import { CaseStatusDialogAction } from "./actions/caseStatusDialog";
import { CaseViewerAction } from "./actions/caseViewer";
import { ConfigAction } from "./actions/config";
import { CopyImageDialogAction } from "./actions/copyImageDialog";
import { DeleteImageDialogAction } from "./actions/deleteImageDialog";
import { ImageDialogAction } from "./actions/imageDialog";
import { ImagesAction } from "./actions/images";
import { ImageViewerAction } from "./actions/imageViewer";
import { LocationMapAction } from "./actions/locationMap";
import { MapAction } from "./actions/map";
import { MetadataImagesAction } from "./actions/metadataImages";
import { AnnotationNestAction } from "./actions/annotationNest";
import { MoveImageDialogAction } from "./actions/moveImageDialog";
import { ReportsAction } from "./actions/reports";
import { StudiesAction } from "./actions/studies";
import { StudyConfigurationAction } from "./actions/studyConfiguration";
import { UploadDialogAction } from "./actions/uploadDialog";
import { UserDialogAction } from "./actions/userDialog";
import { UsersAction } from "./actions/users";
import { QueriesAction } from "./actions/queries";

interface RedirectAction {
  readonly type: "REDIRECT";
  readonly path: string;
}

export type Action =
  | AuditTrailAction
  | AuthAction
  | CaseDialogAction
  | CaseViewerAction
  | CasesAction
  | CaseStatusDialogAction
  | ConfigAction
  | DeleteImageDialogAction
  | ImageViewerAction
  | LocationMapAction
  | ImagesAction
  | MapAction
  | MoveImageDialogAction
  | CopyImageDialogAction
  | ImageDialogAction
  | ReportsAction
  | StudiesAction
  | StudyConfigurationAction
  | UploadDialogAction
  | UserDialogAction
  | UsersAction
  | MetadataImagesAction
  | AnnotationNestAction
  | AnnotationAction
  | RedirectAction
  | QueriesAction;

export type DispatchFunction = (action: Action) => void;

export function redirect(path: string): Action {
  return {
    type: "REDIRECT",
    path
  };
}
