import React from "react";
import { Circle, CircleProps } from "react-leaflet";

import AnnotationPopup from "./AnnotationPopup";
import { UUID } from "../models";
import { LatLngLiteral } from "leaflet";

interface Props {
  readonly text: string | null;
  readonly date: string;
  readonly radius: number;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly isClickable: boolean;
  readonly weight: number;
  readonly canDelete: boolean;
  readonly dashArray: string | null;
}

export const HPF_CURSOR = "HPF cursor";

const HPFAnnotation = ({
  position,
  radius,
  text,
  date,
  annotationId,
  color,
  weight,
  canDelete,
  dashArray
}: Props) => {
  const commonProps: Pick<CircleProps, "center" | "radius" | "fillOpacity"> = {
    center: position,
    radius,
    fillOpacity: 0
  };
  return (
    <>
      {/* This is the fully functional HPF with border and popup */}
      {/* console.log("circle weight: " + weight.toString() +
            " radius: " + radius.toString() +
            " lat " + position.lat.toString())*/}
      {text === HPF_CURSOR ? (
        <Circle
          color={color || "#00FF00"}
          dashArray={dashArray || ""}
          weight={weight}
          {...commonProps}
        >
          <>{/*console.log("hpf ___ color in: " + color + " text " + text)*/}</>
        </Circle>
      ) : weight > 5 ? (
        <Circle
          color={color || "#00FF00"}
          weight={weight}
          radius={radius * 1.1}
          {...commonProps}
        ></Circle>
      ) : (
        <>
          <Circle
            color={color || "#00FF00"}
            dashArray={dashArray || ""}
            weight={weight}
            {...commonProps}
          >
            <AnnotationPopup
              text={text}
              date={date}
              annotationId={annotationId}
              canDelete={canDelete}
            />
          </Circle>
          {/* This one is the same size with no border. Clicking it is a no-op so it eats up the clicks
            of the inside of the other circle since it's on top */}
          <Circle weight={0} {...commonProps} />
        </>
      )}
    </>
  );
};

export default HPFAnnotation;
