import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  fetchMetadataImageFailure,
  fetchMetadataImageSuccess
} from "../actions/metadataImages";

import { fetchMetadataImage } from "../api";
import { MetadataImage, MetadataImageType } from "../models";
import { Resource } from "../types";

export type MetadataImagesStateAbstract = {
  readonly [key in MetadataImageType]: Resource<MetadataImage<MetadataImageType>>;
};

export interface MetadataImagesState extends MetadataImagesStateAbstract {
  readonly label: Resource<MetadataImage<"label">>;
  readonly macro: Resource<MetadataImage<"macro">>;
  readonly zoomActive: boolean;
  readonly isMetadataPanelExpanded: boolean;
}

export const initialState: MetadataImagesState = {
  label: {
    isPending: false
  },
  macro: {
    isPending: false
  },
  zoomActive: false,
  isMetadataPanelExpanded: true
};

export const metadataImagesReducer: LoopReducer<MetadataImagesState, Action> = (
  state: MetadataImagesState = initialState,
  action: Action
): MetadataImagesState | Loop<MetadataImagesState, Action> => {
  switch (action.type) {
    case ActionTypes.FETCH_METADATA_IMAGE:
      return loop(
        {
          ...state,
          [action.metadataType]: {
            metadataType: action.metadataType,
            imageData: {
              isPending: true
            }
          }
        },
        Cmd.run(fetchMetadataImage, {
          successActionCreator: fetchMetadataImageSuccess,
          failActionCreator: fetchMetadataImageFailure(action.metadataType),
          args: [action.uri, action.metadataType] as Parameters<typeof fetchMetadataImage>
        })
      );
    case ActionTypes.FETCH_METADATA_IMAGE_SUCCESS:
      return {
        ...state,
        [action.metadataImage.metadataType]: {
          resource: action.metadataImage
        }
      };
    case ActionTypes.FETCH_METADATA_IMAGE_FAILURE:
      return {
        ...state,
        [action.metadataType]: {
          errorMessage: action.errorMessage
        }
      };
    case ActionTypes.TOGGLE_ZOOM:
      return {
        ...state,
        zoomActive: !state.zoomActive
      };
    case ActionTypes.TOGGLE_METADATA_EXPANDED:
      return {
        ...state,
        isMetadataPanelExpanded: !state.isMetadataPanelExpanded
      };
    default:
      return state;
  }
};

export default metadataImagesReducer;
