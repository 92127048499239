import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  ImageMetadataForm,
  updateImageFailure,
  updateImageSuccess
} from "../actions/imageDialog";
import { imagesFetch } from "../actions/images";
import { refreshImage } from "../actions/imageViewer";

import { updateImage } from "../api";
import { Image, onlyUpdates } from "../models";
import { WriteResource } from "../types";

export type ImageDialogState =
  | {
      readonly isOpen: true;
      readonly savedImage: Image;
      readonly image: WriteResource<ImageMetadataForm, Image>;
      readonly refreshSingleImageOnSuccess: boolean;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: ImageDialogState = {
  isOpen: false
};

export const imageDialogReducer: LoopReducer<ImageDialogState, Action> = (
  state: ImageDialogState = initialState,
  action: Action
): ImageDialogState | Loop<ImageDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_IMAGE_DIALOG:
      return {
        isOpen: true,
        savedImage: action.image,
        image: {
          data: {
            accessionNumber: {
              value: action.image.accessionNumber
            },
            biopsyLocation: {
              value: action.image.biopsyLocation
            }
          }
        },
        refreshSingleImageOnSuccess: action.refreshSingleImageOnSuccess
      };
    case ActionTypes.CLOSE_IMAGE_DIALOG:
      return {
        isOpen: false
      };
    case ActionTypes.EDIT_IMAGE:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: action.form
            }
          }
        : state;
    case ActionTypes.UPDATE_IMAGE:
      const updates = state.isOpen
        ? onlyUpdates(
            {
              accessionNumber: {
                value: state.savedImage.accessionNumber
              },
              biopsyLocation: {
                value: state.savedImage.biopsyLocation
              }
            },
            state.image.data
          )
        : null;
      return state.isOpen && updates
        ? loop(
            {
              ...state,
              isSaving: true
            },
            Cmd.run(updateImage, {
              successActionCreator: updateImageSuccess,
              failActionCreator: updateImageFailure,
              args: [
                state.savedImage.id,
                updates.accessionNumber,
                updates.biopsyLocation
              ] as Parameters<typeof updateImage>
            })
          )
        : state;
    case ActionTypes.UPDATE_IMAGE_SUCCESS:
      return "savedImage" in state
        ? loop(
            initialState,
            Cmd.action(
              "refreshSingleImageOnSuccess" in state && state.refreshSingleImageOnSuccess
                ? refreshImage(state.savedImage.id)
                : imagesFetch(state.savedImage.studyId)
            )
          )
        : state;
    case ActionTypes.UPDATE_IMAGE_FAILURE:
      return "image" in state
        ? {
            ...state,
            image: {
              data: state.image.data,
              errorMessage: action.errorMsg
            }
          }
        : state;
    default:
      return state;
  }
};

export default imageDialogReducer;
