import { User } from "../models";

export enum ActionTypes {
  USER_FETCH = "USER_FETCH",
  USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS",
  USER_FETCH_FAILURE = "USER_FETCH_FAILURE",
  CHECK_LOGGED_IN = "CHECK_LOGGED_IN",
  LOGOUT = "LOGOUT"
}

export type AuthAction =
  | { readonly type: ActionTypes.USER_FETCH }
  | { readonly type: ActionTypes.USER_FETCH_SUCCESS; readonly user: User }
  | { readonly type: ActionTypes.USER_FETCH_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.CHECK_LOGGED_IN }
  | { readonly type: ActionTypes.LOGOUT; readonly redirectToPath: string };

export function userFetch(): AuthAction {
  return {
    type: ActionTypes.USER_FETCH
  };
}

export function userFetchSuccess(user: User): AuthAction {
  return {
    type: ActionTypes.USER_FETCH_SUCCESS,
    user
  };
}

export function userFetchFailure(errorMsg: string): AuthAction {
  return {
    type: ActionTypes.USER_FETCH_FAILURE,
    errorMsg
  };
}

export function checkLoggedIn(): AuthAction {
  return {
    type: ActionTypes.CHECK_LOGGED_IN
  };
}

export function logout(redirectToPath: string): AuthAction {
  return {
    type: ActionTypes.LOGOUT,
    redirectToPath
  };
}
