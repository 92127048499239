import { err, JsonDecoder, ok, Result } from "ts.data.json";
import {
  AdminCase,
  AdminCaseWithImagesAndReaders,
  AdminStudyListView,
  Annotation,
  ApiResponse,
  Case,
  CaseAndCountsAdminView,
  CaseAndCountsNonAdminView,
  CaseStatus,
  Config,
  DynamicQueryStatus,
  EllipseAnnotation,
  FixedQueryCategory,
  FixedQueryValue,
  FreehandAnnotation,
  FreehandAnnotationClass,
  FreehandAnnotationClassWithCount,
  HPFAnnotation,
  HpfAnnotationClass,
  HpfAnnotationClassWithCount,
  Image,
  ImageAndQuery,
  ImageListView,
  ImageNoCase,
  ImageWithAnnotations,
  ImageWithCase,
  Indication,
  Line,
  LineAnnotation,
  Modality,
  OtherQueryValue,
  Point,
  PointAnnotation,
  PointAnnotationClass,
  PointAnnotationClassWithCount,
  Polygon,
  ProcessedImage,
  QueryAssessmentType,
  QueryObjectType,
  QueryRecord,
  QueryRecordCategory,
  QueryResolutionType,
  QuerySearchRecord,
  QueryStatus,
  QueryType,
  QueryViewRecord,
  Reader,
  ReaderCase,
  ReaderCaseWithImages,
  ReaderStudyListView,
  ReaderStudyStats,
  ReadonlyUser,
  ResolvedQuery,
  ResolvedQueryType,
  SimpleCase,
  Study,
  StudyAdminView,
  StudyListView,
  StudyNoUsers,
  StudyView,
  TextAnnotation,
  UnprocessedImage,
  UnresolvedQuery,
  UnresolvedQueryType,
  Uploader,
  UploaderStudyListView,
  User,
  UserRole
} from "./models";

export const dateDecoder = JsonDecoder.string.map(s => new Date(Date.parse(s)));

export function tOrNull<T>(decoder: JsonDecoder.Decoder<T>): JsonDecoder.Decoder<T | null> {
  return JsonDecoder.oneOf<T | null>(
    [decoder, JsonDecoder.isNull(null), JsonDecoder.isUndefined(null)],
    `Optional:${typeof decoder}`
  );
}

export const optionalStringDecoder = tOrNull(JsonDecoder.string);
export const optionalNumberDecoder = tOrNull(JsonDecoder.number);

export function decodeRoleString(roleString: string): Result<UserRole> {
  switch (roleString.toUpperCase()) {
    case "ADMIN":
      return ok(UserRole.Admin);
    case "LAB":
      return ok(UserRole.Lab);
    case "READER":
      return ok(UserRole.Reader);
    case "ISC":
      return ok(UserRole.ISC);
    case "READONLY":
      return ok(UserRole.Readonly);
    case "NOTO":
      return ok(UserRole.Noto);
    default:
      return err<UserRole>(`Cannot decode user role from ${roleString}`);
  }
}

export const userRoleDecoder = new JsonDecoder.Decoder<UserRole>((json: any) => {
  return typeof json === "string"
    ? decodeRoleString(json)
    : err<UserRole>(`Cannot decode user role from ${json}`);
});

export const nullableUserRoleDecoder = tOrNull(userRoleDecoder);

export const userDecoder = JsonDecoder.object<User>(
  {
    id: JsonDecoder.string,
    robartsUserId: JsonDecoder.number,
    username: JsonDecoder.string,
    lastLoginDt: tOrNull(dateDecoder),
    role: nullableUserRoleDecoder,
    firstName: optionalStringDecoder,
    lastName: optionalStringDecoder,
    registered: JsonDecoder.boolean
  },
  "User"
);

export const readerDecoder = new JsonDecoder.Decoder<Reader>((json: any) => {
  return typeof json === "object" && json.role === UserRole.Reader
    ? ok({ ...json } as Reader)
    : err<Reader>(`Cannot decode Reader with role ${json.role}`);
});

export const readerStudyStats = JsonDecoder.object<ReaderStudyStats>(
  {
    reader: readerDecoder,
    numReadCases: JsonDecoder.number,
    numTotalCases: JsonDecoder.number
  },
  "ReaderStudyStats"
);

export const readersStudyStatsDecoder = JsonDecoder.array(readerStudyStats, "ReadersStudyStats");

export const uploaderDecoder = new JsonDecoder.Decoder<Uploader>((json: any) => {
  return typeof json === "object" && json.role === UserRole.Lab
    ? ok({ ...json } as Uploader)
    : err<Uploader>(`Cannot decode Uploader with role ${json.role}`);
});

export const readonlyUserDecoder = new JsonDecoder.Decoder<ReadonlyUser>((json: any) => {
  return typeof json === "object" && json.role === UserRole.Readonly
    ? ok({ ...json } as ReadonlyUser)
    : err<ReadonlyUser>(`Cannot decode ReadonlyUser with role ${json.role}`);
});

export const usersDecoder = JsonDecoder.array(userDecoder, "Users");

export const readersDecoder = JsonDecoder.array(readerDecoder, "Readers");

export function decodeIndicationString(indicationString: string): Result<Indication> {
  switch (indicationString.toUpperCase()) {
    case "CROHNS_DISEASE":
      return ok(Indication.CrohnsDisease);
    case "ULCERATIVE_COLITIS":
      return ok(Indication.UlcerativeColitis);
    case "EOSINOPHILIC_ESOPHAGITIS":
      return ok(Indication.EosinophilicEsophagitis);
    case "NONALCOHOLIC_STEATOHEPATITIS":
      return ok(Indication.NonalcoholicSteatohepatitis);
    case "POUCHITIS":
      return ok(Indication.Pouchitis);
    case "CELIAC_DISEASE":
      return ok(Indication.CeliacDisease);
    case "PRIMARY_SCLEROSING_CHOLANGITIS":
      return ok(Indication.PrimarySclerosingCholangitis);
    case "EOSINOPHILIC_DUODENITIS":
      return ok(Indication.EosinophilicDuodenitis);
    case "EOSINOPHILIC_GASTROENTERITIS":
      return ok(Indication.EosinophilicGastroenteritis);
    default:
      return err<Indication>(`Cannot decode indication from ${indicationString}`);
  }
}

export const indicationDecoder = new JsonDecoder.Decoder<Indication>((json: any) => {
  return typeof json === "string"
    ? decodeIndicationString(json)
    : err<Indication>(`Cannot decode indication from ${json}`);
});

export function decodeModalityString(modalityString: string): Result<Modality> {
  switch (modalityString.toUpperCase()) {
    case "HISTOPATHOLOGY":
      return ok(Modality.Histopathology);
    case "ENDOSCOPY":
      return ok(Modality.Endoscopy);
    case "IMMUNOHISTOCHEMISTRY":
      return ok(Modality.Immunohistochemistry);
    default:
      return err<Modality>(`Cannot decode modality from ${modalityString}`);
  }
}

export const modalityDecoder = new JsonDecoder.Decoder<Modality>((json: any) => {
  return typeof json === "string"
    ? decodeModalityString(json)
    : err<Modality>(`Cannot decode modality from ${json}`);
});

export const studyDecoder = JsonDecoder.object<Study>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    indication: indicationDecoder,
    segments: JsonDecoder.number,
    modality: JsonDecoder.array<Modality>(modalityDecoder, "modality"),
    onHold: JsonDecoder.boolean,
    onHoldReason: tOrNull(JsonDecoder.string),
    createdAt: dateDecoder
  },
  "Study"
);

export const studyNoUsersDecoder = JsonDecoder.object<StudyNoUsers>(
  {
    study: studyDecoder
  },
  "StudyNoUsers"
);

export const freehandAnnotationClassDecoder = JsonDecoder.object<FreehandAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("FREEHAND"),
    enabled: JsonDecoder.boolean
  },
  "FreehandAnnotationClass"
);

export const freehandAnnotationClassArrayDecoder = JsonDecoder.array<FreehandAnnotationClass>(
  freehandAnnotationClassDecoder,
  "FreehandAnnotationClasses"
);

export const freehandAnnotationClassWithCountDecoder = JsonDecoder.object<FreehandAnnotationClassWithCount>(
  {
    annotationClass: freehandAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "FreehandAnnotationClassWithCount"
);

export const freehandAnnotationClassWithCountArrayDecoder = JsonDecoder.array<FreehandAnnotationClassWithCount>(
  freehandAnnotationClassWithCountDecoder,
  "FreehandAnnotationClassesWithCount"
);

export const pointAnnotationClassDecoder = JsonDecoder.object<PointAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("POINT"),
    enabled: JsonDecoder.boolean
  },
  "PointAnnotationClass"
);

export const pointAnnotationClassArrayDecoder = JsonDecoder.array<PointAnnotationClass>(
  pointAnnotationClassDecoder,
  "PointAnnotationClasses"
);

export const pointAnnotationClassWithCountDecoder = JsonDecoder.object<PointAnnotationClassWithCount>(
  {
    annotationClass: pointAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "PointAnnotationClassWithCount"
);

export const pointAnnotationClassWithCountArrayDecoder = JsonDecoder.array<PointAnnotationClassWithCount>(
  pointAnnotationClassWithCountDecoder,
  "PointAnnotationClassesWithCount"
);

export const hpfAnnotationClassDecoder = JsonDecoder.object<HpfAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("HPF"),
    enabled: JsonDecoder.boolean
  },
  "HpfAnnotationClass"
);

export const hpfAnnotationClassArrayDecoder = JsonDecoder.array<HpfAnnotationClass>(
  hpfAnnotationClassDecoder,
  "HpfAnnotationClasses"
);

export const hpfAnnotationClassWithCountDecoder = JsonDecoder.object<HpfAnnotationClassWithCount>(
  {
    annotationClass: hpfAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "HpfAnnotationClassWithCount"
);

export const hpfAnnotationClassWithCountArrayDecoder = JsonDecoder.array<HpfAnnotationClassWithCount>(
  hpfAnnotationClassWithCountDecoder,
  "HpfAnnotationClassesWithCount"
);

export const studyAdminViewDecoder = JsonDecoder.object<StudyAdminView>(
  {
    study: studyDecoder,
    uploaders: JsonDecoder.array<Uploader>(uploaderDecoder, "uploaders"),
    readers: JsonDecoder.array<Reader>(readerDecoder, "readers"),
    readonlyUsers: JsonDecoder.array<ReadonlyUser>(readonlyUserDecoder, "readonlyUsers"),
    hpfAnnotationClasses: JsonDecoder.array<HpfAnnotationClassWithCount>(
      hpfAnnotationClassWithCountDecoder,
      "hpfAnnotationClasses"
    ),
    pointAnnotationClasses: JsonDecoder.array<PointAnnotationClassWithCount>(
      pointAnnotationClassWithCountDecoder,
      "pointAnnotationClasses"
    ),
    freehandAnnotationClasses: JsonDecoder.array<FreehandAnnotationClassWithCount>(
      freehandAnnotationClassWithCountDecoder,
      "freehandAnnotationClasses"
    )
  },
  "StudyWithUsers"
);

export const studyViewDecoder = JsonDecoder.oneOf<StudyView>(
  [studyAdminViewDecoder, studyNoUsersDecoder],
  "StudyView"
);

export const readerStudyListView = JsonDecoder.object<ReaderStudyListView>(
  {
    studyView: studyNoUsersDecoder,
    numberOfAssignedReadCases: JsonDecoder.number,
    numberOfAssignedUnreadCases: JsonDecoder.number
  },
  "ReaderStudyListView"
);

export const uploaderStudyListView = JsonDecoder.object<UploaderStudyListView>(
  {
    studyView: studyNoUsersDecoder,
    numberOfQueriesOpen: JsonDecoder.number
  },
  "UploaderStudyListView"
);

export const adminStudyListView = JsonDecoder.object<AdminStudyListView>(
  {
    studyView: studyAdminViewDecoder,
    mostRecentUploadAt: tOrNull(dateDecoder),
    numberOfCasesNeedingQC: JsonDecoder.number,
    numberOfQueriesAnswered: JsonDecoder.number,
    numberOfQueriesOpen: JsonDecoder.number,
    numberOfUnassignedImages: JsonDecoder.number
  },
  "AdminStudyListView"
);

export const studyListViewDecoder = JsonDecoder.oneOf<
  ReaderStudyListView | AdminStudyListView | UploaderStudyListView
>([readerStudyListView, adminStudyListView, uploaderStudyListView], "StudyListView");

export const studyListViewsDecoder = JsonDecoder.array<StudyListView>(
  studyListViewDecoder,
  "StudyListViews"
);

export const configDecoder = JsonDecoder.object<Config>(
  {
    ssoLoginUrl: JsonDecoder.string,
    ssoLogoutUrl: JsonDecoder.string,
    tileServerLocation: JsonDecoder.string
  },
  "Config"
);

const latLngDecoder = JsonDecoder.tuple(
  [JsonDecoder.number, JsonDecoder.number],
  "[number, number]"
);

const lineDecoder = JsonDecoder.object<Line>(
  {
    type: JsonDecoder.isExactly("LineString"),
    coordinates: JsonDecoder.array(latLngDecoder, "Points")
  },
  "Line"
);

const polygonDecoder = JsonDecoder.object<Polygon>(
  {
    type: JsonDecoder.isExactly("Polygon"),
    coordinates: JsonDecoder.array(JsonDecoder.array(latLngDecoder, "Points"), "Coords")
  },
  "Polygon"
);

const pointDecoder = JsonDecoder.object<Point>(
  {
    type: JsonDecoder.isExactly("Point"),
    coordinates: latLngDecoder,
    parent: optionalStringDecoder
  },
  "Point"
);

export const ellipseAnnotationDecoder = JsonDecoder.object<EllipseAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    radiusX: JsonDecoder.number,
    radiusY: JsonDecoder.number,
    tilt: JsonDecoder.number,
    text: optionalStringDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "EllipseAnnotation"
);

export const textAnnotationDecoder = JsonDecoder.object<TextAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    text: JsonDecoder.string,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "TextAnnotation"
);

export const hpfAnnotationDecoder = JsonDecoder.object<HPFAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    radius: JsonDecoder.number,
    weight: optionalNumberDecoder,
    text: optionalStringDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    color: tOrNull(JsonDecoder.string)
  },
  "HPFAnnotation"
);

export const pointAnnotationDecoder = JsonDecoder.object<PointAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    parent: tOrNull(JsonDecoder.string),
    color: tOrNull(JsonDecoder.string),
    weight: optionalNumberDecoder
  },
  "PointAnnotation"
);

export const lineAnnotationDecoder = JsonDecoder.object<LineAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: lineDecoder,
    length: tOrNull(JsonDecoder.number),
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "LineAnnotation"
);

export const freehandAnnotationDecoder = JsonDecoder.object<FreehandAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: polygonDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    color: tOrNull(JsonDecoder.string)
  },
  "FreehandAnnotation"
);

export const annotationDecoder = JsonDecoder.oneOf<
  | TextAnnotation
  | EllipseAnnotation
  | HPFAnnotation
  | PointAnnotation
  | LineAnnotation
  | FreehandAnnotation
>(
  [
    ellipseAnnotationDecoder,
    textAnnotationDecoder,
    hpfAnnotationDecoder,
    pointAnnotationDecoder,
    lineAnnotationDecoder,
    freehandAnnotationDecoder
  ],
  "AnnotationDecoder"
);

export const annotationsDecoder = JsonDecoder.array(annotationDecoder, "Annotation");

export function decodeQueryStatus(queryStatusString: string): Result<QueryStatus> {
  switch (queryStatusString.toUpperCase()) {
    case "OPEN":
      return ok(QueryStatus.Open);
    case "CLOSED":
      return ok(QueryStatus.Closed);
    default:
      return err<QueryStatus>(`Cannot decode query status type from ${queryStatusString}`);
  }
}

export const decodeQueryStatusDecoder = new JsonDecoder.Decoder<QueryStatus>((json: any) => {
  return typeof json === "string"
    ? decodeQueryStatus(json)
    : err<QueryStatus>(`Cannot decode query status type from ${json}`);
});

export function decodeDynamicQueryStatus(
  dynamicQueryStatusString: string
): Result<DynamicQueryStatus> {
  switch (dynamicQueryStatusString.toUpperCase()) {
    case "ANSWERED":
      return ok(DynamicQueryStatus.Answered);
    case "FOLLOWUPREQUIRED":
      return ok(DynamicQueryStatus.FollowUpRequired);
    case "FOLLOWEDUP":
      return ok(DynamicQueryStatus.FollowedUp);
    case "UPCOMINGDUEDATE":
      return ok(DynamicQueryStatus.UpcomingDueDate);
    default:
      return err<DynamicQueryStatus>(
        `Cannot decode query assessment type from ${dynamicQueryStatusString}`
      );
  }
}

export const decodeDynamicQueryStatusDecoder = new JsonDecoder.Decoder<DynamicQueryStatus>(
  (json: any) => {
    return typeof json === "string"
      ? decodeDynamicQueryStatus(json)
      : err<DynamicQueryStatus>(`Cannot decode dynamic query status type from ${json}`);
  }
);

export function decodeQueryAssessmentType(
  assessmentTypeString: string
): Result<QueryAssessmentType> {
  switch (assessmentTypeString.toUpperCase()) {
    case "PRE":
      return ok(QueryAssessmentType.Pre);
    case "POST":
      return ok(QueryAssessmentType.Post);
    default:
      return err<QueryAssessmentType>(
        `Cannot decode query assessment type from ${assessmentTypeString}`
      );
  }
}

export const queryAssessmentTypeDecoder = new JsonDecoder.Decoder<QueryAssessmentType>(
  (json: any) => {
    return typeof json === "string"
      ? decodeQueryAssessmentType(json)
      : err<QueryAssessmentType>(`Cannot decode query assessment type from ${json}`);
  }
);

export function decodeQueryResolutionString(resolutionString: string): Result<QueryResolutionType> {
  switch (resolutionString.toUpperCase()) {
    case "RESOLVED":
      return ok(QueryResolutionType.Resolved);
    case "UNRESOLVABLE":
      return ok(QueryResolutionType.Unresolvable);
    default:
      return err<QueryResolutionType>(`Cannot decode query resolution from ${resolutionString}`);
  }
}

export const queryResolutionDecoder = new JsonDecoder.Decoder<QueryResolutionType>((json: any) => {
  return typeof json === "string"
    ? decodeQueryResolutionString(json)
    : err<QueryResolutionType>(`Cannot decode query resolution from ${json}`);
});

export const nullableQueryResolutionDecoder = tOrNull(queryResolutionDecoder);

export const processedImageDecoder = JsonDecoder.object<ProcessedImage>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    s3Key: JsonDecoder.string,
    uploadedAt: dateDecoder,
    studyId: JsonDecoder.string,
    uploaderId: JsonDecoder.string,
    cogKey: JsonDecoder.string,
    magnifications: JsonDecoder.array<number>(JsonDecoder.number, "magnifications"),
    extent: JsonDecoder.object<Polygon>(
      {
        type: JsonDecoder.isExactly("Polygon"),
        coordinates: JsonDecoder.array(JsonDecoder.array(latLngDecoder, "Points"), "Rings")
      },
      "Polygon"
    ),
    caseId: tOrNull(JsonDecoder.string),
    processingFailed: JsonDecoder.boolean,
    pixelSize: JsonDecoder.number,
    accessionNumber: tOrNull(JsonDecoder.string),
    biopsyLocation: tOrNull(JsonDecoder.string),
    physicalResolution: JsonDecoder.number,
    maxTmsZoom: JsonDecoder.number,
    zStackSize: tOrNull(JsonDecoder.number),
    lastImageComment: tOrNull(JsonDecoder.string),
    lastLabQueryId: tOrNull(JsonDecoder.string),
    ignored: JsonDecoder.boolean
  },
  "ProcessedImage"
);

export const unprocessedImageDecoder = JsonDecoder.object<UnprocessedImage>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    s3Key: JsonDecoder.string,
    uploadedAt: dateDecoder,
    studyId: JsonDecoder.string,
    uploaderId: JsonDecoder.string,
    caseId: tOrNull(JsonDecoder.string),
    processingFailed: tOrNull(JsonDecoder.boolean),
    accessionNumber: tOrNull(JsonDecoder.string),
    biopsyLocation: tOrNull(JsonDecoder.string),
    zStackSize: JsonDecoder.isNull(null),
    lastImageComment: tOrNull(JsonDecoder.string),
    lastLabQueryId: tOrNull(JsonDecoder.string),
    ignored: JsonDecoder.boolean
  },
  "UnprocessedImage"
);

export const imageDecoder = JsonDecoder.oneOf<ProcessedImage | UnprocessedImage>(
  [processedImageDecoder, unprocessedImageDecoder],
  "Image"
);

export function decodeUnresolvedQueryType(queryType: string): Result<UnresolvedQueryType> {
  switch (queryType.toUpperCase()) {
    case "OPEN":
      return ok(QueryType.Open);
    case "PENDING_REVIEW":
      return ok(QueryType.PendingReview);
    default:
      return err<UnresolvedQueryType>(`Cannot decode query type from ${queryType}`);
  }
}

export const unresolvedQueryTypeDecoder = new JsonDecoder.Decoder<UnresolvedQueryType>(
  (json: any) => {
    return typeof json === "string"
      ? decodeUnresolvedQueryType(json)
      : err<UnresolvedQueryType>(`Cannot decode unresolved query type from ${json}`);
  }
);

export function decodeResolvedQueryType(queryType: string): Result<ResolvedQueryType> {
  switch (queryType.toUpperCase()) {
    case "RESOLVED":
      return ok(QueryType.Resolved);
    default:
      return err<ResolvedQueryType>(`Cannot decode query type from ${queryType}`);
  }
}

export const resolvedQueryTypeDecoder = new JsonDecoder.Decoder<ResolvedQueryType>((json: any) => {
  return typeof json === "string"
    ? decodeResolvedQueryType(json)
    : err<ResolvedQueryType>(`Cannot decode resolved query type from ${json}`);
});

export function decodeFixedQueryCategory(queryType: string): Result<FixedQueryCategory> {
  switch (queryType.toUpperCase()) {
    case "BLURRY":
      return ok(FixedQueryCategory.Blurry);
    case "INCOMPLETE":
      return ok(FixedQueryCategory.Incomplete);
    case "BAD_FILENAME":
      return ok(FixedQueryCategory.BadFilename);
    case "INTERNAL_QUERY_WITH_DM":
      return ok(FixedQueryCategory.InternalWithDM);
    case "INTERNAL_QUERY_WITH_PM":
      return ok(FixedQueryCategory.InternalWithPM);
    case "INTERNAL_QUERY_WITH_CR":
      return ok(FixedQueryCategory.InternalWithCR);
    default:
      return err<FixedQueryCategory>(`Cannot decode query type from ${queryType}`);
  }
}

export const queryCategoryDecoder = new JsonDecoder.Decoder<FixedQueryCategory>((json: any) => {
  return typeof json === "string"
    ? decodeFixedQueryCategory(json)
    : err<FixedQueryCategory>(`Cannot decode unresolved query type from ${json}`);
});

export const fixedQueryValueDecoder = JsonDecoder.object<FixedQueryValue>(
  {
    category: queryCategoryDecoder
  },
  "FixedQueryValue"
);

export const otherQueryValueDecoder = JsonDecoder.object<OtherQueryValue>(
  {
    text: JsonDecoder.string
  },
  "OtherQueryValue"
);

export const queryValueDecoder = JsonDecoder.oneOf<FixedQueryValue | OtherQueryValue>(
  [fixedQueryValueDecoder, otherQueryValueDecoder],
  "QueryDecoder"
);

export const unresolvedQueryDecoder = JsonDecoder.object<UnresolvedQuery>(
  {
    value: queryValueDecoder,
    queryType: unresolvedQueryTypeDecoder
  },
  "UnresolvedQuery"
);

export const resolvedQueryDecoder = JsonDecoder.object<ResolvedQuery>(
  {
    value: queryValueDecoder,
    queryType: resolvedQueryTypeDecoder,
    queryResolution: queryResolutionDecoder
  },
  "ResolvedQuery"
);

export const queryDecoder = JsonDecoder.oneOf<ResolvedQuery | UnresolvedQuery>(
  [resolvedQueryDecoder, unresolvedQueryDecoder],
  "QueryDecoder"
);

export function decodeQueryRecordCategory(queryType: string): Result<QueryRecordCategory> {
  switch (queryType.toUpperCase()) {
    case "BLURRY":
      return ok(QueryRecordCategory.Blurry);
    case "INCOMPLETE":
      return ok(QueryRecordCategory.Incomplete);
    case "BAD_FILENAME":
      return ok(QueryRecordCategory.BadFilename);
    case "OTHER":
      return ok(QueryRecordCategory.Other);
    case "INTERNAL_QUERY_WITH_DM":
      return ok(QueryRecordCategory.InternalWithDM);
    case "INTERNAL_QUERY_WITH_PM":
      return ok(QueryRecordCategory.InternalWithPM);
    case "INTERNAL_QUERY_WITH_CR":
      return ok(QueryRecordCategory.InternalWithCR);
    case "INTERNAL_QUERY_OTHER":
      return ok(QueryRecordCategory.InternalOther);
    default:
      return err<QueryRecordCategory>(`Cannot decode query type from ${queryType}`);
  }
}

export const queryRecordCategoryDecoder = new JsonDecoder.Decoder<QueryRecordCategory>(
  (json: any) => {
    return typeof json === "string"
      ? decodeQueryRecordCategory(json)
      : err<QueryRecordCategory>(`Cannot decode query record category type from ${json}`);
  }
);

export function decodeQueryObjectTypeString(
  queryObjectTypeString: string
): Result<QueryObjectType> {
  switch (queryObjectTypeString.toUpperCase()) {
    case "CASE":
      return ok(QueryObjectType.Case);
    case "IMAGE":
      return ok(QueryObjectType.Image);
    default:
      return err<QueryObjectType>(`Cannot decode query object type from ${queryObjectTypeString}`);
  }
}

export const queryObjectTypeDecoder = new JsonDecoder.Decoder<QueryObjectType>((json: any) => {
  return typeof json === "string"
    ? decodeQueryObjectTypeString(json)
    : err<QueryObjectType>(`Cannot decode case status from ${json}`);
});

export const queryViewRecordDecoder = JsonDecoder.object<QueryViewRecord>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: JsonDecoder.string,
    caseId: JsonDecoder.string,
    organizationId: tOrNull(JsonDecoder.string),
    assessmentType: queryAssessmentTypeDecoder,
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: queryRecordCategoryDecoder,
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    openedAt: dateDecoder,
    openedBy: JsonDecoder.string,
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string)
  },
  "QueryViewRecord"
);

export const querySearchRecordDecoder = JsonDecoder.object<QuerySearchRecord>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: JsonDecoder.string,
    caseId: JsonDecoder.string,
    organizationId: tOrNull(JsonDecoder.string),
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: queryRecordCategoryDecoder,
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    openedAt: dateDecoder,
    openedBy: JsonDecoder.string,
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string),
    studyName: JsonDecoder.string,
    caseName: JsonDecoder.string,
    imageName: tOrNull(JsonDecoder.string)
  },
  "QuerySearchRecord"
);

export const queryRecordDecoder = JsonDecoder.oneOf<QueryRecord>(
  [queryViewRecordDecoder, querySearchRecordDecoder],
  "QueryRecord"
);

export const queriesDecoder = JsonDecoder.array(queryRecordDecoder, "QueryRecords");
export const querySearchDecoder = JsonDecoder.array(querySearchRecordDecoder, "QuerySearchRecords");

export const imageAndQueryDecoder = JsonDecoder.object<ImageAndQuery>(
  {
    image: imageDecoder,
    query: tOrNull(queryDecoder),
    lastLabQuery: tOrNull(queryRecordDecoder),
    isShownToReader: JsonDecoder.boolean
  },
  "ImageWithQuery"
);

export const simpleCaseDecoder = JsonDecoder.object<SimpleCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string
  },
  "SimpleCase"
);

export const imageNoCaseDecoder = JsonDecoder.object<ImageNoCase>(
  {
    imageAndQuery: imageAndQueryDecoder
  },
  "ImageNoCase"
);

export const imageWithCaseDecoder = JsonDecoder.object<ImageWithCase>(
  {
    imageAndQuery: imageAndQueryDecoder,
    simpleCase: tOrNull(simpleCaseDecoder)
  },
  "ImageWithCase"
);

export const imageListViewDecoder = JsonDecoder.oneOf<ImageWithCase | ImageNoCase>(
  [imageWithCaseDecoder, imageNoCaseDecoder],
  "ImageListView"
);

export const imageListViewArrayDecoder = JsonDecoder.array<ImageListView>(
  imageListViewDecoder,
  "ImageListViews"
);

export const imageWithAnnotationsDecoder = JsonDecoder.object<ImageWithAnnotations>(
  {
    imageAndQuery: imageAndQueryDecoder,
    study: studyDecoder,
    annotations: JsonDecoder.array<Annotation>(annotationDecoder, "Annotations"),
    hpfAnnotationCount: JsonDecoder.number,
    hpfAnnotationClassesWithCount: hpfAnnotationClassWithCountArrayDecoder,
    pointAnnotationCount: JsonDecoder.number,
    pointAnnotationClassesWithCount: pointAnnotationClassWithCountArrayDecoder,
    freehandAnnotationCount: JsonDecoder.number,
    freehandAnnotationClassesWithCount: freehandAnnotationClassWithCountArrayDecoder
  },
  "ImageWithAnnotations"
);

export const imageArrayDecoder = JsonDecoder.array<Image>(imageDecoder, "Images");

export const imageAndQueryArrayDecoder = JsonDecoder.array<ImageAndQuery>(
  imageAndQueryDecoder,
  "ImageAndQueries"
);

export const imageQueriesDecoder = JsonDecoder.array<ReadonlyArray<ImageAndQuery>>(
  imageAndQueryArrayDecoder,
  "ImageQueries"
);

export function decodeCaseStatusString(caseStatusString: string): Result<CaseStatus> {
  switch (caseStatusString.toUpperCase()) {
    case "PENDING_QC":
      return ok(CaseStatus.PendingQC);
    case "PROCESSED":
      return ok(CaseStatus.Processed);
    case "COMPLETED":
      return ok(CaseStatus.Completed);
    case "INVALID":
      return ok(CaseStatus.Invalid);
    default:
      return err<CaseStatus>(`Cannot decode case status from ${caseStatusString}`);
  }
}

export const caseStatusDecoder = new JsonDecoder.Decoder<CaseStatus>((json: any) => {
  return typeof json === "string"
    ? decodeCaseStatusString(json)
    : err<CaseStatus>(`Cannot decode case status from ${json}`);
});

export const readerCaseDecoder = JsonDecoder.object<ReaderCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string,
    createdAt: dateDecoder,
    studyId: JsonDecoder.string,
    status: caseStatusDecoder
  },
  "ReaderCase"
);

export const adminCaseDecoder = JsonDecoder.object<AdminCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string,
    createdAt: dateDecoder,
    studyId: JsonDecoder.string,
    subjectId: JsonDecoder.string,
    visitId: JsonDecoder.string,
    qc1By: tOrNull(JsonDecoder.string),
    qc2By: tOrNull(JsonDecoder.string),
    readBy: tOrNull(JsonDecoder.string),
    status: caseStatusDecoder,
    prevWorkflowStatuses: JsonDecoder.array<CaseStatus>(caseStatusDecoder, "PrevStatuses"),
    lastCaseComment: tOrNull(JsonDecoder.string),
    lastInternalQuery: tOrNull(queryRecordDecoder),
    openQueries: JsonDecoder.number
  },
  "AdminCase"
);

export const caseDecoder = JsonDecoder.oneOf<AdminCase | ReaderCase>(
  [adminCaseDecoder, readerCaseDecoder],
  "Case"
);

export const caseArrayDecoder = JsonDecoder.array<Case>(caseDecoder, "Cases");

export const readerCaseWithImagesDecoder = JsonDecoder.object<ReaderCaseWithImages>(
  {
    caseWithStatus: readerCaseDecoder,
    images: imageArrayDecoder
  },
  "ReaderCaseWithImages"
);

export const adminCaseWithImagesAndReadersDecoder = JsonDecoder.object<AdminCaseWithImagesAndReaders>(
  {
    caseWithStatus: adminCaseDecoder,
    images: imageArrayDecoder,
    imageQueries: imageQueriesDecoder,
    readers: readersStudyStatsDecoder
  },
  "AdminCaseWithImagesAndReaders"
);

export const caseWithImagesDecoder = JsonDecoder.oneOf<
  AdminCaseWithImagesAndReaders | ReaderCaseWithImages
>([adminCaseWithImagesAndReadersDecoder, readerCaseWithImagesDecoder], "CaseWithImages");

export const caseAndCountsNonAdminViewDecoder = JsonDecoder.object<CaseAndCountsNonAdminView>(
  {
    caseWithStatus: readerCaseDecoder,
    numberOfImages: JsonDecoder.number
  },
  "CaseAndCountsNonAdminView"
);

export const caseAndCountsAdminViewDecoder = JsonDecoder.object<CaseAndCountsAdminView>(
  {
    caseWithStatus: adminCaseDecoder,
    numberOfImages: JsonDecoder.number,
    numberOfQueries: JsonDecoder.number,
    qc1User: tOrNull(userDecoder),
    qc2User: tOrNull(userDecoder),
    assignedReaders: readersDecoder
  },
  "CaseAndCountsAdminView"
);

export const caseAndCountsDecoder = JsonDecoder.oneOf<
  CaseAndCountsAdminView | CaseAndCountsNonAdminView
>([caseAndCountsAdminViewDecoder, caseAndCountsNonAdminViewDecoder], "CaseAndCounts");

export const caseAndCountsArrayDecoder = JsonDecoder.array<
  CaseAndCountsAdminView | CaseAndCountsNonAdminView
>(caseAndCountsDecoder, "CaseAndCountsArray");

export const apiResponseDecoder = JsonDecoder.object<ApiResponse>(
  {
    success: JsonDecoder.boolean,
    message: JsonDecoder.string
  },
  "ApiResponse"
);
