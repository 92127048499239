import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Blaster } from "@blasterjs/core";
import { ConfigProvider } from "react-avatar";
import { RouteProps } from "react-router";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";

import Case from "./Case";
import Content from "../components/Content";
import "../css/App.css";
import Image from "./Image";
import Login from "./Login";
import Queries from "./Queries";
import Reports from "./Reports";
import Studies from "./Studies";
import Study from "./Study";
import StudyConfiguration from "./StudyConfiguration";
import theme from "../theme";
import Users from "./Users";

import { User, UserRole } from "../models";
import { Resource } from "../types";

interface StateProps {
  readonly user: Resource<User>;
}

type Props = StateProps;

interface UserRouteProps {
  readonly user: Resource<User>;
  readonly permittedRoles?: ReadonlyArray<UserRole>;
  readonly element: any;
}

/*
 * User must be authenticated and registered to view this route.
 */
function RoleBasedRoute({ user, permittedRoles, element }: UserRouteProps & RouteProps) {
  const location = useLocation();
  if (!("resource" in user)) {
    if (location.pathname === "/login") {
      return <Login />;
    } else {
      return <Navigate replace to="/login" />;
    }
  } else if (!user.resource.registered) {
    return <Navigate replace to="/welcome" />;
  } else if (
    permittedRoles &&
    permittedRoles.length &&
    user.resource.role !== null &&
    !permittedRoles.includes(user.resource.role)
  ) {
    return <Navigate to="/" />;
  } else {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });
    return typeof queryParams.redirect === "string" ? (
      <Navigate replace to={queryParams.redirect} />
    ) : (
      element
    );
  }
}

const App = ({ user }: Props) => {
  return (
    <BrowserRouter>
      <Blaster theme={theme}>
        <ConfigProvider colors={theme.colors.avatars}>
          <div className="App">
            {"isPending" in user ? (
              <Content isLoading={true} />
            ) : (
              <>
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <RoleBasedRoute user={user} element={<Navigate replace to="/welcome" />} />
                    }
                  />
                  <Route
                    path="/welcome"
                    element={
                      <RoleBasedRoute user={user} element={<Navigate replace to="/studies" />} />
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <RoleBasedRoute
                        user={user}
                        permittedRoles={[UserRole.Admin]}
                        element={<Users />}
                      />
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <RoleBasedRoute
                        user={user}
                        permittedRoles={[UserRole.Admin, UserRole.Readonly, UserRole.ISC]}
                        element={<Reports />}
                      />
                    }
                  />
                  <Route
                    path="/queries/"
                    element={
                      <RoleBasedRoute
                        user={user}
                        permittedRoles={[
                          UserRole.Admin,
                          UserRole.ISC,
                          UserRole.Lab,
                          UserRole.Readonly
                        ]}
                        element={<Queries />}
                      />
                    }
                  />
                  <Route
                    path="/studies/:id/configure"
                    element={<RoleBasedRoute user={user} element={<StudyConfiguration />} />}
                  />
                  <Route
                    path="/studies/:id/:tab"
                    element={<RoleBasedRoute user={user} element={<Study />} />}
                  />
                  <Route
                    path="/studies/:id/"
                    element={<RoleBasedRoute user={user} element={<Study />} />}
                  />
                  <Route
                    path="/studies/:studyId/images/:id"
                    element={<RoleBasedRoute user={user} element={<Image />} />}
                  />
                  <Route
                    path="/studies/:studyId/cases/:id"
                    element={<RoleBasedRoute user={user} element={<Case />} />}
                  />
                  <Route
                    path="/studies"
                    element={<RoleBasedRoute user={user} element={<Studies />} />}
                  />
                  <Route path="/" element={<Navigate replace to="/studies" />} />
                </Routes>
              </>
            )}
          </div>
        </ConfigProvider>
      </Blaster>
    </BrowserRouter>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth
  };
}

export default connect(mapStateToProps)(App);
