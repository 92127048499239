import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  copyImageFailure,
  CopyImageForm,
  copyImageSuccess
} from "../actions/copyImageDialog";
import { imagesFetch } from "../actions/images";

import { copyImage } from "../api";
import { Image } from "../models";
import { WriteResource } from "../types";
import { duplicateImageName } from "../utils";

export type CopyImageDialogState =
  | {
      readonly isOpen: true;
      readonly savedImage: Image;
      readonly image: WriteResource<CopyImageForm, Image>;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: CopyImageDialogState = {
  isOpen: false
};

export const copyImageDialogReducer: LoopReducer<CopyImageDialogState, Action> = (
  state: CopyImageDialogState = initialState,
  action: Action
): CopyImageDialogState | Loop<CopyImageDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_COPY_IMAGE_DIALOG:
      return {
        isOpen: true,
        savedImage: action.image,
        image: {
          data: {
            duplicateImageName: duplicateImageName(action.image.name)
          }
        }
      };
    case ActionTypes.CLOSE_COPY_IMAGE_DIALOG:
      return {
        isOpen: false
      };
    case ActionTypes.SET_COPY_IMAGE_FORM:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: action.form
            }
          }
        : state;
    case ActionTypes.COPY_IMAGE_TO_STUDY:
      return state.isOpen
        ? loop(
            state,
            Cmd.run(copyImage, {
              successActionCreator: copyImageSuccess,
              failActionCreator: copyImageFailure,
              args: [state.savedImage.id, state.image.data.duplicateImageName] as Parameters<
                typeof copyImage
              >
            })
          )
        : state;
    case ActionTypes.COPY_IMAGE_TO_STUDY_SUCCESS:
      return state.isOpen
        ? loop(initialState, Cmd.action(imagesFetch(state.savedImage.studyId)))
        : state;
    case ActionTypes.COPY_IMAGE_TO_STUDY_FAILURE:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: state.image.data,
              errorMessage: action.errorMsg
            }
          }
        : state;
    default:
      return state;
  }
};

export default copyImageDialogReducer;
