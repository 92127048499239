export enum ActionTypes {
  TOGGLE_LOCATOR_MAP_PANEL_EXPANDED = "TOGGLE_LOCATOR_MAP_PANEL_EXPANDED"
}

export type LocationMapAction = {
  readonly type: ActionTypes.TOGGLE_LOCATOR_MAP_PANEL_EXPANDED;
};
export function toggleLocatorMapPanelExpanded(): LocationMapAction {
  return {
    type: ActionTypes.TOGGLE_LOCATOR_MAP_PANEL_EXPANDED
  };
}
