import { Button, Select, Icon, Label, Text, Box, TextArea } from "@blasterjs/core";
import { useEffect, useState } from "react";
import React from "react";
import store from "../store";

import {
  FixedQueryCategory,
  formatQueryCategory,
  formatQueryRecordCategory,
  formatQueryResolutionType,
  ImageAndQuery,
  OtherQueryCategory,
  QueryObjectType,
  QueryRecord,
  QueryResolutionType,
  QueryStatus,
  QueryWindowLocation,
  User,
  userIsAdminOrIsc,
  UserRole,
  UUID
} from "../models";

import { addDays } from "date-fns";
import { closeQuery, newQueryReminder, openQuery, unresolvableQuery } from "../actions/caseViewer";
import { formatDate } from "../utils";
import ReplaceImage from "./ReplaceImage";
import styled from "styled-components";
import { hideImageForReader } from "../actions/imageViewer";

export const QueryBox = styled(Box)`
  background: #f0f2f5;
  padding: 1.6rem;
  border: 1px solid #d2d5df;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

const SelectionBox = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

interface QueryWindowProps {
  readonly studyId: UUID;
  readonly caseId: UUID;
  readonly queryObjectType: QueryObjectType;
  readonly queryRecord: QueryRecord | null;
  readonly imageAndQuery: ImageAndQuery;
  readonly displayShowToReader: boolean;
  readonly user: User;
  readonly queryWindowLocation: QueryWindowLocation;
}

const QueryWindow = ({
  studyId,
  caseId,
  queryObjectType,
  queryRecord,
  imageAndQuery,
  displayShowToReader,
  user,
  queryWindowLocation
}: QueryWindowProps) => {
  const userIsAdminLike = userIsAdminOrIsc(user.role);
  const userIsLab = user.role === null ? false : [UserRole.Lab].includes(user.role);

  return (
    <>
      {userIsAdminLike || userIsLab ? (
        <SingleQuery
          studyId={studyId}
          caseId={caseId}
          queryObjectType={queryObjectType}
          queryRecord={queryRecord}
          imageAndQuery={imageAndQuery}
          displayShowToReader={displayShowToReader}
          userIsAdminLike={userIsAdminLike}
          userIsLab={userIsLab}
          queryWindowLocation={queryWindowLocation}
        />
      ) : null}
    </>
  );
};

interface SingleQueryState {
  isEditing: boolean;
  hasQuery: boolean;
  query: QueryRecord | null;
  isClosing: boolean;
  detailsOfResolution: string;
  category: string;
  categoryOtherText: string | null;
  displayCategoryOtherText: boolean;
  followUpInDays: number;
  isFollowingUp: boolean;
  isMarkingUnresolvable: boolean;
  unresolvableReason: string;
}
const initialState: SingleQueryState = {
  isEditing: false,
  hasQuery: false,
  query: null,
  isClosing: false,
  detailsOfResolution: "",
  category: "",
  categoryOtherText: null,
  displayCategoryOtherText: false,
  followUpInDays: 0,
  isFollowingUp: false,
  isMarkingUnresolvable: false,
  unresolvableReason: ""
};

interface SingleQueryProps {
  readonly studyId: UUID;
  readonly caseId: UUID;
  readonly queryObjectType: QueryObjectType;
  readonly queryRecord: QueryRecord | null;
  readonly imageAndQuery: ImageAndQuery;
  readonly displayShowToReader: boolean;
  readonly userIsAdminLike: boolean;
  readonly userIsLab: boolean;
  readonly queryWindowLocation: QueryWindowLocation;
}
const SingleQuery = ({
  studyId,
  caseId,
  queryObjectType,
  queryRecord,
  imageAndQuery,
  displayShowToReader,
  userIsAdminLike,
  userIsLab,
  queryWindowLocation
}: SingleQueryProps) => {
  const [state, setState] = useState<SingleQueryState>(initialState);

  useEffect(() => {
    setState({
      ...state,
      isEditing: false,
      hasQuery: queryRecord ? true : false,
      query: queryRecord
    });
  }, [studyId, caseId, queryRecord, imageAndQuery]);

  const openInternalQueryButton = userIsAdminLike ? (
    <Button
      mt={1}
      block={true}
      onClick={() =>
        setState({
          ...state,
          isEditing: true
        })
      }
    >
      Open Internal Query
    </Button>
  ) : null;

  const openLabQueryButton = userIsAdminLike ? (
    <Button
      mt={1}
      block={true}
      onClick={() =>
        setState({
          ...state,
          isEditing: true
        })
      }
    >
      Open Lab Query
    </Button>
  ) : null;

  const markQueryUnresolvableReasonOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      unresolvableReason: e.target.value
    });
  };

  const saveCloseUnresolvableQueryOnClick = () => {
    if (state.query?.id) {
      if (queryWindowLocation == QueryWindowLocation.Image) {
        store.dispatch(
          unresolvableQuery(state.query.id, state.unresolvableReason, imageAndQuery.image.id)
        );
      } else {
        store.dispatch(unresolvableQuery(state.query.id, state.unresolvableReason, null));
      }
    }
    setState({
      ...state,
      isClosing: false,
      isMarkingUnresolvable: false
    });
  };

  const unresolvableButton = state.isMarkingUnresolvable ? (
    <div>
      <Box>
        <Box>
          <Label>
            Unresolvable details:
            <TextArea
              placeholder=""
              mb={2}
              maxLength={255}
              defaultValue={""}
              onChange={markQueryUnresolvableReasonOnChange}
            />
          </Label>
        </Box>
        <Button
          // isLoading={isSubmitting}
          intent="primary"
          appearance="prominent"
          // disabled={configValidations.errorsExist}
          onClick={saveCloseUnresolvableQueryOnClick}
        >
          Save
        </Button>
        <Button
          style={{
            marginLeft: "15px"
          }}
          // isLoading={isSubmitting}
          onClick={() =>
            setState({
              ...state,
              isMarkingUnresolvable: false
            })
          }
        >
          Cancel
        </Button>
      </Box>
    </div>
  ) : (
    <Button
      mt={1}
      block={true}
      onClick={() =>
        setState({
          ...state,
          isMarkingUnresolvable: true
        })
      }
    >
      Mark Query Unresolvable
    </Button>
  );

  const followUpButton = (
    <Button
      mt={1}
      block={true}
      onClick={() =>
        setState({
          ...state,
          isFollowingUp: true
        })
      }
    >
      Send Follow-Up
    </Button>
  );

  const labQueryCategories: ReadonlyArray<any> = [
    FixedQueryCategory.Blurry,
    FixedQueryCategory.Incomplete,
    FixedQueryCategory.BadFilename,
    OtherQueryCategory.Other
  ];

  const internalQueryCategories: ReadonlyArray<any> = [
    FixedQueryCategory.InternalWithDM,
    FixedQueryCategory.InternalWithPM,
    FixedQueryCategory.InternalWithCR,
    OtherQueryCategory.Other
  ];

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCategory = e.target.value;
    setState({
      ...state,
      category: selectedCategory,
      categoryOtherText: null,
      displayCategoryOtherText: selectedCategory == OtherQueryCategory.Other
    });
  };

  const categoryOtherTextOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      categoryOtherText: e.target.value
    });
  };

  const onFollowUpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      followUpInDays: parseInt(e.target.value)
    });
  };

  const saveQueryOnClick = () => {
    const objectId = queryObjectType == QueryObjectType.Case ? caseId : imageAndQuery.image.id;
    store.dispatch(
      openQuery(
        queryObjectType,
        objectId,
        studyId,
        caseId,
        state.category,
        state.categoryOtherText,
        state.followUpInDays
      )
    );

    setState({
      ...state,
      categoryOtherText: null
    });
  };

  const saveFollowUpDate = () => {
    if (state.query && state.query.lastQueryReminderId) {
      store.dispatch(
        newQueryReminder(state.query.id, state.query.lastQueryReminderId, state.followUpInDays)
      );
      setState({
        ...state,
        isFollowingUp: false
      });
    }
  };

  const cancelOnClick = () => {
    setState({
      ...state,
      isEditing: false,
      categoryOtherText: null
    });
  };

  const queryCategorySelect = (
    <Label>
      New query
      <Select defaultValue={""} onChange={onCategoryChange}>
        <option />
        {(queryObjectType == QueryObjectType.Case
          ? internalQueryCategories
          : labQueryCategories
        ).map(category => (
          <option key={category} value={category}>
            {formatQueryCategory(category)}
          </option>
        ))}
      </Select>
    </Label>
  );

  const followUpSelect = (
    <Label>
      Flag for follow-up on
      <Select defaultValue={""} onChange={onFollowUpChange}>
        <option />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(day => (
          <option key={day} value={day}>
            {day} {day == 1 ? "day" : "days"} - {addDays(new Date(), day).toDateString()}
          </option>
        ))}
      </Select>
    </Label>
  );

  const categoryOtherTextForm = state.displayCategoryOtherText ? (
    <div>
      <Box>
        <Box>
          <Label>
            Other:
            <TextArea
              placeholder=""
              mb={2}
              maxLength={255}
              defaultValue={state.categoryOtherText || ""}
              onChange={categoryOtherTextOnChange}
            />
          </Label>
        </Box>
      </Box>
    </div>
  ) : null;

  const queryForm = (
    <QueryBox>
      {queryCategorySelect}
      {categoryOtherTextForm}
      {followUpSelect}
      <Box>
        <br />
        <Button
          // isLoading={isSubmitting}
          intent="primary"
          appearance="prominent"
          // disabled={configValidations.errorsExist}
          onClick={saveQueryOnClick}
        >
          Save
        </Button>
        <Button
          style={{
            marginLeft: "15px"
          }}
          // isLoading={isSubmitting}
          onClick={cancelOnClick}
        >
          Cancel
        </Button>
      </Box>
    </QueryBox>
  );

  const closeQueryDetailedReasonOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      detailsOfResolution: e.target.value
    });
  };

  const saveCloseQueryOnClick = () => {
    if (state.query?.id) {
      store.dispatch(closeQuery(state.query.id, state.detailsOfResolution));
    }
    setState({
      ...state,
      isClosing: false
    });
  };

  const showImageToReaderOnClick = () => {
    store.dispatch(hideImageForReader());
  };

  const hideImageForReaderOnClick = () => {
    store.dispatch(hideImageForReader());
  };

  const highlightDateIfPast = (latestFollowUpOnDt: Date | null) => {
    const isRed = latestFollowUpOnDt && new Date() > latestFollowUpOnDt;
    const formattedDateString = formatDate(latestFollowUpOnDt);
    return <span style={isRed ? { color: "red" } : undefined}>{formattedDateString}</span>;
  };

  // is query open
  // if closed, display "Details of Resolution"
  const showQueryNonAdminOrIscView =
    state.query && state.query.queryStatus == QueryStatus.Open ? (
      <div>
        {state.query.resolution == null ? (
          <>
            <div>
              {state.query.category ? formatQueryRecordCategory(state.query.category) : null}
            </div>
            <div>{state.query.categoryOtherText}</div>

            <QueryBox>
              {queryObjectType == QueryObjectType.Image ? (
                <>
                  <ReplaceImage imageAndQuery={imageAndQuery} refreshCase={true} />
                  {unresolvableButton}
                </>
              ) : null}
            </QueryBox>
          </>
        ) : (
          <>
            <div>
              <h3>Answered Query, Pending Review</h3>
            </div>
            <div>
              {state.query.category ? formatQueryRecordCategory(state.query.category) : null}
            </div>
            <div>{state.query.categoryOtherText}</div>
            <div>{formatQueryResolutionType(state.query.resolution)}</div>
            <div>{state.query.resolutionText}</div>
          </>
        )}
      </div>
    ) : null;

  const showQueryUserIsAdminOrIsc = (
    <div>
      {state.query !== null ? (
        state.query.queryStatus == QueryStatus.Open ? (
          <QueryBox>
            {state.query.resolution ? (
              <>
                <h3>Answered Query, Pending Review</h3>
                <div>
                  {state.query.category ? formatQueryRecordCategory(state.query.category) : null}
                </div>
                <div>{state.query.categoryOtherText}</div>
                <div>{formatQueryResolutionType(state.query.resolution)}</div>
                <div>{state.query.resolutionText}</div>
              </>
            ) : (
              <>
                <div>
                  {queryObjectType == QueryObjectType.Case
                    ? "Open Internal Query"
                    : "Open Lab Query"}{" "}
                </div>
                <div>
                  {state.query.category ? formatQueryRecordCategory(state.query.category) : null}
                </div>
                <div>{state.query.categoryOtherText}</div>

                {/* <div>{followUpHistory}</div> */}
                {queryObjectType == QueryObjectType.Image ? (
                  <>
                    <ReplaceImage imageAndQuery={imageAndQuery} refreshCase={true} />
                    {unresolvableButton}
                  </>
                ) : null}

                <div>Follow-up on {highlightDateIfPast(state.query.latestFollowUpOnDt)}</div>
                {state.isFollowingUp ? (
                  <>
                    {followUpSelect}
                    <Box>
                      <br />
                      <Button
                        // isLoading={isSubmitting}
                        intent="primary"
                        appearance="prominent"
                        // disabled={configValidations.errorsExist}
                        onClick={saveFollowUpDate}
                      >
                        Save
                      </Button>
                      <Button
                        style={{
                          marginLeft: "15px"
                        }}
                        // isLoading={isSubmitting}
                        onClick={() =>
                          setState({
                            ...state,
                            isFollowingUp: false
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>{followUpButton}</>
                )}
              </>
            )}

            <div>
              {state.isClosing ? (
                <div>
                  <Box>
                    <Box>
                      <Label>
                        Details of Resolution:
                        <TextArea
                          placeholder=""
                          mb={2}
                          maxLength={255}
                          defaultValue={""}
                          onChange={closeQueryDetailedReasonOnChange}
                        />
                      </Label>
                    </Box>
                    <Button
                      // isLoading={isSubmitting}
                      intent="primary"
                      appearance="prominent"
                      // disabled={configValidations.errorsExist}
                      onClick={saveCloseQueryOnClick}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        marginLeft: "15px"
                      }}
                      // isLoading={isSubmitting}
                      onClick={() =>
                        setState({
                          ...state,
                          isClosing: false
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </Box>
                </div>
              ) : (
                <Button
                  mt={1}
                  block={true}
                  onClick={() =>
                    setState({
                      ...state,
                      isClosing: true
                    })
                  }
                >
                  Close Query
                </Button>
              )}
            </div>
          </QueryBox>
        ) : (
          <>
            <QueryBox>
              <div>
                <h3>Closed Query</h3>
                <div>{formatQueryRecordCategory(state.query.category)}</div>
                <div>{state.query.categoryOtherText}</div>
                <div>
                  {state.query.resolution &&
                  state.query.resolution == QueryResolutionType.Unresolvable
                    ? "Unresolvable"
                    : "Resolved"}{" "}
                  Query
                </div>
              </div>

              <div>Details of Resolution</div>
              <div>{state.query.resolutionText}</div>
            </QueryBox>
            {queryObjectType == QueryObjectType.Case ? openInternalQueryButton : openLabQueryButton}
          </>
        )
      ) : null}
    </div>
  );

  const renderQueryByRole = userIsAdminLike
    ? showQueryUserIsAdminOrIsc
    : userIsLab
    ? showQueryNonAdminOrIscView
    : null;

  const queryWidget = <div>{state.isEditing ? queryForm : renderQueryByRole}</div>;

  const showToReaderButton = userIsAdminLike ? (
    <Box>
      <SelectionBox>
        <Icon name="image" color="gray" />
        <Text as="b" ml={1} fontSize={1} mt="-0.3rem">
          Show this image to reader? {imageAndQuery.image.ignored}
        </Text>
      </SelectionBox>
      <Text>
        {imageAndQuery.query === null
          ? "This image was uploaded as a replacement for an image with a query"
          : "This image has a query but can still be shown to readers"}
      </Text>
      {imageAndQuery.image.ignored ? (
        <Button
          mt={1}
          block={true}
          onClick={showImageToReaderOnClick}
          title={"Using this image will make it visible to readers and will close any open queries"}
          appearance="prominent"
          intent="primary"
        >
          Show to Reader
        </Button>
      ) : (
        <Button
          mt={1}
          block={true}
          onClick={hideImageForReaderOnClick}
          title={"Using this image will make it visible to readers and will close any open queries"}
          appearance="prominent"
          intent="primary"
        >
          Hide from Reader
        </Button>
      )}
    </Box>
  ) : null;

  /*
  Element visibility by role:
    1. Closed query details    admin, isc
    2. Open query button       admin, isc
    3. Replace Image           admin, isc, lab
    4. unresolvable button     lab
    5. send follow up          admin, isc
    5. close                   admin, isc
    6. show to reader          admin, isc
    3. comment                 admin, isc; read-only view only no edit
  */

  return (
    <>
      {state.hasQuery || state.isEditing
        ? queryWidget
        : queryObjectType == QueryObjectType.Case
        ? openInternalQueryButton
        : openLabQueryButton}
      {displayShowToReader ? showToReaderButton : null}
    </>
  );
};

export default QueryWindow;
