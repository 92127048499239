import { CasesAndCounts, UUID } from "../models";

export interface CaseFilters {
  readonly procId?: string;
  readonly status?: string;
  readonly assignee?: string;
}

export enum ActionTypes {
  SET_CASE_FILTERS = "SET_CASE_FILTERS",
  CASES_FETCH = "CASES_FETCH",
  CASES_FETCH_SUCCESS = "CASES_FETCH_SUCCESS",
  CASES_FETCH_FAILURE = "CASES_FETCH_FAILURE"
}

export type CasesAction =
  | {
      readonly type: ActionTypes.SET_CASE_FILTERS;
      readonly studyId: UUID;
      readonly filters: CaseFilters;
    }
  | {
      readonly type: ActionTypes.CASES_FETCH;
      readonly studyId: UUID;
    }
  | { readonly type: ActionTypes.CASES_FETCH_SUCCESS; readonly cases: CasesAndCounts }
  | { readonly type: ActionTypes.CASES_FETCH_FAILURE; readonly errorMsg: string };

export function setCaseFilters(studyId: UUID, filters: CaseFilters): CasesAction {
  return {
    type: ActionTypes.SET_CASE_FILTERS,
    studyId,
    filters
  };
}

export function casesFetch(studyId: UUID): CasesAction {
  return {
    type: ActionTypes.CASES_FETCH,
    studyId
  };
}

export function casesFetchSuccess(cases: CasesAndCounts): CasesAction {
  return {
    type: ActionTypes.CASES_FETCH_SUCCESS,
    cases
  };
}

export function casesFetchFailure(errorMsg: string): CasesAction {
  return {
    type: ActionTypes.CASES_FETCH_FAILURE,
    errorMsg
  };
}
