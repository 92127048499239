import { User, UserRole } from "../models";

export enum ActionTypes {
  TOGGLE_DIALOG = "TOGGLE_DIALOG",
  CHANGE_USER_FORM = "CHANGE_USER_FORM",
  USER_UPDATE = "USER_UPDATE",
  USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE"
}

export interface UserFormI {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string;
  readonly role: UserRole | null;
}

export type UserDialogAction =
  | {
      readonly type: ActionTypes.TOGGLE_DIALOG;
      readonly isOpen: boolean;
      readonly user: User | undefined;
    }
  | { readonly type: ActionTypes.CHANGE_USER_FORM; readonly userForm: UserFormI }
  | { readonly type: ActionTypes.USER_UPDATE }
  | { readonly type: ActionTypes.USER_UPDATE_SUCCESS; readonly user: User }
  | { readonly type: ActionTypes.USER_UPDATE_FAILURE; readonly errorMsg: string };

export function toggleDialog(isOpen: boolean, user: User | undefined): UserDialogAction {
  return {
    type: ActionTypes.TOGGLE_DIALOG,
    isOpen,
    user
  };
}

export function changeUserForm(userForm: UserFormI): UserDialogAction {
  return {
    type: ActionTypes.CHANGE_USER_FORM,
    userForm
  };
}

export function userUpdate(): UserDialogAction {
  return {
    type: ActionTypes.USER_UPDATE
  };
}

export function userUpdateSuccess(user: User): UserDialogAction {
  return {
    type: ActionTypes.USER_UPDATE_SUCCESS,
    user
  };
}

export function userUpdateFailure(errorMsg: string): UserDialogAction {
  return {
    type: ActionTypes.USER_UPDATE_FAILURE,
    errorMsg
  };
}
