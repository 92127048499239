import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore, Middleware, StoreEnhancer } from "redux";
import { install, StoreCreator } from "redux-loop";

import { Action } from "./actions";
import { logger, redirect, setOriginCookie } from "./middleware";
import createRootReducer, { initialState } from "./reducers";
import { State } from "./reducers";

export const history = createBrowserHistory();

declare global {
  interface Window {
    readonly __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const enhancedCreateStore = createStore as StoreCreator;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const commonMiddlewares: ReadonlyArray<Middleware> = [setOriginCookie(), redirect(history)];
const appliedMiddlewares = process.env.REACT_APP_DEBUG
  ? applyMiddleware(logger(), ...commonMiddlewares)
  : applyMiddleware(...commonMiddlewares);

type StateWithRouter = State;

const enhancer = composeEnhancers(install(), appliedMiddlewares) as StoreEnhancer<
  StateWithRouter,
  Action
>;

export default enhancedCreateStore(createRootReducer(), initialState, enhancer);
