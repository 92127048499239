import { LatLngLiteral } from "leaflet";
import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action, redirect } from "../actions";
import {
  ActionTypes as AnnotationActionTypes,
  AnnotationForm,
  AnnotationStatus,
  AnnotationType,
  createAnnotationFailure,
  createAnnotationSuccess,
  createHpfAnnotationSuccess,
  deleteAnnotationFailure,
  deleteAnnotationSuccess,
  EllipseAnnotationType,
  HpfAnnotationType,
  SelectedAnnotationType,
  TextAnnotationType
} from "../actions/annotation";
import {
  ActionTypes as CaseViewerActionTypes,
  caseFetchFailure,
  caseFetchSuccess,
  closeQueryFailure,
  closeQuerySuccess,
  newQueryReminderFailure,
  newQueryReminderSuccess,
  openQueryFailure,
  openQuerySuccess,
  refreshCase,
  refreshCaseFailure,
  refreshCaseSuccess,
  transitionCaseStatusFailure,
  transitionCaseStatusSuccess,
  unresolvableQueryFailure,
  unresolvableQuerySuccess,
  updateCaseCommentFailure,
  updateCaseCommentSuccess,
  updateImageCommentFailure,
  updateImageCommentSuccess
} from "../actions/caseViewer";
import {
  ActionTypes as ImageViewerActionTypes,
  hideImageForReaderFailure,
  hideImageForReaderSuccess,
  imageFetch,
  imageFetchFailure,
  imageFetchSuccess,
  QueryEditorForm,
  refreshImage,
  refreshImageFailure,
  refreshImageSuccess,
  selectImage
} from "../actions/imageViewer";

import {
  addComment,
  closeQuery,
  createAnnotation,
  createQuery,
  deleteAnnotation,
  fetchCase,
  fetchImage,
  toggleImageVisibility,
  markQueryUnresolvable,
  newQueryFollowUpReminder,
  transitionCaseStatus
} from "../api";
import { Annotation, CaseStatus, CaseWithImages, ImageWithAnnotations } from "../models";
import { Resource, WriteResource } from "../types";
import { lineToGeoJSON, pointToGeoJSON, polygonToGeoJSON } from "../utils";

export interface CaseImageViewerState {
  readonly histoCase: Resource<CaseWithImages> | null;
  readonly imageWithAnnotations: Resource<ImageWithAnnotations> | null;
  readonly hideAnnotations: boolean;
  readonly allHighlightedAnnotations: ReadonlyArray<Annotation>;
  readonly highlightedAnnotations: ReadonlyArray<string>;
  readonly brightnessAdjust: number;
  readonly queryEditor: QueryEditorForm | null;
  readonly annotation: WriteResource<AnnotationForm<SelectedAnnotationType> | null, Annotation>;
  readonly tempHpf: LatLngLiteral | null;
  readonly hpfCursorColor: string | null;
  readonly isSidebarExpanded: boolean;
  readonly isMicroscopeActive: boolean;
}

export const initialState: CaseImageViewerState = {
  histoCase: {
    isPending: false
  },
  imageWithAnnotations: {
    isPending: false
  },
  hideAnnotations: false,
  allHighlightedAnnotations: [],
  highlightedAnnotations: [],
  brightnessAdjust: 0,
  queryEditor: null,
  annotation: {
    data: null
  },
  tempHpf: null,
  hpfCursorColor: null,
  isSidebarExpanded: true,
  isMicroscopeActive: false
};

function highlightAllImage(
  iwa: ImageWithAnnotations,
  ids: readonly string[]
): readonly Annotation[] {
  return iwa.annotations.map((anno: Annotation) => {
    return anno !== null
      ? ids.includes(anno.id)
        ? { ...anno, weight: 10 }
        : { ...anno, weight: 5 }
      : anno;
  });
}

function highlightAll(
  iwa: Resource<ImageWithAnnotations> | null,
  ids: readonly string[]
): readonly Annotation[] {
  return iwa && "resource" in iwa ? highlightAllImage(iwa.resource, ids) : [];
}

function addId(annos: readonly string[], id: string): readonly string[] {
  if (annos.find(e => e === id)) {
    return annos;
  } else {
    return [...annos, id];
  }
}

function removeId(annos: readonly string[], id: string): readonly string[] {
  return annos.filter(anno => anno !== id);
}

/*
 * Reducer containing the state for viewing cases and images.
 *
 * The two resources are highly interrelated and need to share state. For
 * example:
 * - Editing an image query can affect a case's status
 * - Selecting images within a case changes the selected image in the viewer
 *
 * Note that cases can have no images and images can be viewed individually
 * outside of a case.
 */
const caseViewerReducer: LoopReducer<CaseImageViewerState, Action> = (
  state: CaseImageViewerState = initialState,
  action: Action
): CaseImageViewerState | Loop<CaseImageViewerState, Action> => {
  const actType = action.type;
  switch (actType) {
    case CaseViewerActionTypes.CASE_FETCH:
      return loop(
        {
          ...state,
          histoCase: {
            isPending: true
          },
          imageWithAnnotations: null
        },
        Cmd.run(fetchCase, {
          successActionCreator: caseFetchSuccess,
          failActionCreator: caseFetchFailure,
          args: [action.caseId] as Parameters<typeof fetchCase>
        })
      );
    case CaseViewerActionTypes.CASE_FETCH_SUCCESS:
      // Re-fetch first image in case if there is one
      const imageToFetchId =
        "images" in action.histoCase && action.histoCase.images.length > 0
          ? action.histoCase.images[0]?.id
          : "imageQueries" in action.histoCase &&
            action.histoCase.imageQueries[0] &&
            action.histoCase.imageQueries[0][0]
          ? action.histoCase.imageQueries[0][0].image.id
          : null;
      const newState = {
        ...state,
        histoCase: {
          resource: action.histoCase
        }
      };
      return imageToFetchId ? loop(newState, Cmd.action(imageFetch(imageToFetchId))) : newState;
    case CaseViewerActionTypes.CASE_FETCH_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.REFRESH_CASE:
      return state.histoCase !== null && "resource" in state.histoCase
        ? loop(
            // Avoid updating state to indicate fetch is in progress so as to avoid flicker
            // We want to update the case data seamlessly without a page refresh
            state,
            Cmd.run(fetchCase, {
              successActionCreator: refreshCaseSuccess,
              failActionCreator: refreshCaseFailure,
              args: [state.histoCase.resource.caseWithStatus.id] as Parameters<typeof fetchCase>
            })
          )
        : (state as never);
    case CaseViewerActionTypes.REFRESH_CASE_SUCCESS:
      const selectedImageId =
        state.imageWithAnnotations && "resource" in state.imageWithAnnotations
          ? state.imageWithAnnotations.resource.imageAndQuery.image.id
          : null;
      const isSelectedImageInCase =
        selectedImageId !== null &&
        action.histoCase.images.map(image => image.id).includes(selectedImageId);
      const newStateAfterRefresh = {
        ...state,
        histoCase: {
          resource: action.histoCase
        },
        imageWithAnnotations: isSelectedImageInCase ? state.imageWithAnnotations : null,
        allHighlightedAnnotations: highlightAll(state.imageWithAnnotations, [])
      };
      return selectedImageId && isSelectedImageInCase
        ? // Refresh currently selected image after successful case refresh
          loop(newStateAfterRefresh, Cmd.action(refreshImage(selectedImageId)))
        : action.histoCase.images[0]
        ? // Refresh the first image as long as there is at least one
          loop(newStateAfterRefresh, Cmd.action(selectImage(action.histoCase.images[0].id)))
        : newStateAfterRefresh;
    case CaseViewerActionTypes.REFRESH_CASE_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.TRANSITION_CASE_STATUS:
      return state.histoCase !== null && "resource" in state.histoCase
        ? loop(
            state,
            Cmd.run(transitionCaseStatus, {
              successActionCreator: transitionCaseStatusSuccess,
              failActionCreator: transitionCaseStatusFailure,
              args: [state.histoCase.resource.caseWithStatus.id] as Parameters<
                typeof transitionCaseStatus
              >
            })
          )
        : state;
    case CaseViewerActionTypes.TRANSITION_CASE_STATUS_SUCCESS:
      return loop(
        {
          ...state,
          histoCase: {
            resource: action.histoCase
          }
        },
        // Redirect to study page once the case has been read. This is also important because
        // readers will no longer be able to see this case if it's re-fetched since the case is no
        // longer ready for reading.
        action.histoCase.caseWithStatus.status === CaseStatus.Completed
          ? Cmd.action(redirect(`/studies/${action.histoCase.caseWithStatus.studyId}`))
          : Cmd.none
      );
    case CaseViewerActionTypes.TRANSITION_CASE_STATUS_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case ImageViewerActionTypes.IMAGE_FETCH:
      return loop(
        {
          ...state,
          imageWithAnnotations: {
            isPending: true
          }
        },
        Cmd.run(fetchImage, {
          successActionCreator: imageFetchSuccess,
          failActionCreator: imageFetchFailure,
          args: [action.imageId] as Parameters<typeof fetchImage>
        })
      );
    case ImageViewerActionTypes.IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        imageWithAnnotations: {
          resource: action.image
        },
        allHighlightedAnnotations: highlightAllImage(action.image, [])
      };
    case ImageViewerActionTypes.IMAGE_FETCH_FAILURE:
      return {
        ...state,
        imageWithAnnotations: {
          errorMessage: action.errorMsg
        }
      };
    case ImageViewerActionTypes.SET_QUERY_EDITOR_VALUE:
      return {
        ...state,
        queryEditor: action.queryValue
      };
    case ImageViewerActionTypes.CANCEL_QUERY_EDIT:
      return {
        ...state,
        queryEditor: null
      };
    case ImageViewerActionTypes.SELECT_IMAGE:
      return loop(
        {
          ...state,
          // Clear out form state when image is selected
          queryEditor: initialState.queryEditor
        },
        Cmd.action(refreshImage(action.imageId))
      );
    case ImageViewerActionTypes.REFRESH_IMAGE:
      return loop(
        // Avoid updating state to indicate fetch is in progress so as to avoid flicker
        // We want to update the image data seamlessly without a page refresh
        state,
        Cmd.run(fetchImage, {
          successActionCreator: refreshImageSuccess(action.refreshCaseOnSuccess || false),
          failActionCreator: refreshImageFailure,
          args: [action.imageId] as Parameters<typeof fetchImage>
        })
      );
    case ImageViewerActionTypes.REFRESH_IMAGE_SUCCESS:
      return loop(
        {
          ...state,
          imageWithAnnotations: {
            resource: action.image
          },
          allHighlightedAnnotations: highlightAllImage(action.image, [])
        },
        action.refreshCaseOnSuccess ? Cmd.action(refreshCase()) : Cmd.none
      );
    case ImageViewerActionTypes.REFRESH_IMAGE_FAILURE:
      return {
        ...state,
        imageWithAnnotations: {
          errorMessage: action.errorMsg
        }
      };
    case ImageViewerActionTypes.TOGGLE_IMAGE_VISIBILITY:
      return state &&
        state.imageWithAnnotations !== null &&
        "resource" in state.imageWithAnnotations
        ? loop(
            state,
            Cmd.run(toggleImageVisibility, {
              successActionCreator: hideImageForReaderSuccess,
              failActionCreator: hideImageForReaderFailure,
              args: [state.imageWithAnnotations.resource.imageAndQuery.image.id] as Parameters<
                typeof toggleImageVisibility
              >
            })
          )
        : (state as never);
    case ImageViewerActionTypes.TOGGLE_IMAGE_VISIBILITY_SUCCESS:
      return loop(state, Cmd.action(refreshCase()));
    case ImageViewerActionTypes.TOGGLE_IMAGE_VISIBILITY_FAILURE:
      return {
        ...state,
        imageWithAnnotations: {
          errorMessage: action.errorMsg
        }
      };

    case ImageViewerActionTypes.ADJUST_BRIGHTNESS:
      return {
        ...state,
        brightnessAdjust: action.adjustment
      };
    case ImageViewerActionTypes.TOGGLE_ANNOTATIONS:
      return {
        ...state,
        hideAnnotations: !state.hideAnnotations
      };
    case AnnotationActionTypes.BEGIN_PLACING_ANNOTATION:
      return {
        ...state,
        annotation: {
          data: {
            form: null,
            status: AnnotationStatus.Placing,
            selectedAnnotationType: action.selectedAnnotationType
          } as AnnotationForm<typeof action.selectedAnnotationType>
        }
      };
    case AnnotationActionTypes.BEGIN_DROPPING_ANNOTATION:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            {
              ...state,
              imageWithAnnotations: { ...state.imageWithAnnotations },
              annotation: {
                data: {
                  form: null,
                  status: AnnotationStatus.Dropping,
                  selectedAnnotationType: {
                    ...action.selectedAnnotationType
                  }
                } as AnnotationForm<typeof action.selectedAnnotationType>
              },
              hpfCursorColor: (action.selectedAnnotationType as any).hpfAnnotationClass.color
            },
            Cmd.list(
              [
                Cmd.action(refreshImage(state.imageWithAnnotations.resource.imageAndQuery.image.id))
                //Cmd.run(action.cleanupCallback)
              ],
              { sequence: true }
            )
          )
        : (state as never);
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_FOR_ELLIPSE_TYPE:
      return state.annotation.data
        ? {
            ...state,
            annotation: {
              data: {
                form: {
                  geometry: action.point,
                  radiusX: action.radiusX,
                  radiusY: action.radiusY,
                  tilt: action.tilt,
                  text:
                    state.annotation.data.form && "text" in state.annotation.data.form
                      ? state.annotation.data.form.text
                      : null
                },
                status: AnnotationStatus.Editing,
                selectedAnnotationType: {
                  type: AnnotationType.Ellipse
                }
              } as AnnotationForm<EllipseAnnotationType>
            }
          }
        : (state as never);
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_FOR_HPF_TYPE:
      return state.annotation.data
        ? {
            ...state,
            annotation: {
              data: {
                form: {
                  geometry: action.point,
                  radiusX: action.radius,
                  text:
                    state.annotation.data.form && "text" in state.annotation.data.form
                      ? state.annotation.data.form.text
                      : null
                },
                status: AnnotationStatus.Editing,
                selectedAnnotationType: {
                  type: AnnotationType.HPF,
                  hpfAnnotationClass: (state.annotation.data
                    .selectedAnnotationType as HpfAnnotationType).hpfAnnotationClass
                }
              } as AnnotationForm<HpfAnnotationType>
            }
          }
        : (state as never);
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_FOR_TEXT_TYPE:
      return state.annotation.data
        ? {
            ...state,
            annotation: {
              data: {
                form: {
                  geometry: action.point,
                  text:
                    state.annotation.data.form && "text" in state.annotation.data.form
                      ? state.annotation.data.form.text
                      : null
                },
                status: AnnotationStatus.Editing,
                selectedAnnotationType: {
                  type: AnnotationType.Text
                }
              } as AnnotationForm<TextAnnotationType>
            }
          }
        : (state as never);
    case AnnotationActionTypes.CREATE_FREEHAND_IMAGE_ANNOTATION:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            state,
            Cmd.run(createAnnotation, {
              successActionCreator: createAnnotationSuccess(true, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                polygonToGeoJSON(action.positions),
                null,
                null,
                null,
                null,
                state.annotation.data &&
                "freehandAnnotationClass" in state.annotation.data.selectedAnnotationType &&
                state.annotation.data.selectedAnnotationType.freehandAnnotationClass
                  ? state.annotation.data.selectedAnnotationType.freehandAnnotationClass.id
                  : null,
                "FREEHAND",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_LINE_IMAGE_ANNOTATION:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            state,
            Cmd.run(createAnnotation, {
              successActionCreator: createAnnotationSuccess(true, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                lineToGeoJSON(action.points),
                null,
                null,
                null,
                null,
                null,
                "LINE",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_POINT_IMAGE_ANNOTATION:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            state,
            Cmd.run(createAnnotation, {
              successActionCreator: createAnnotationSuccess(true, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                pointToGeoJSON(action.point),
                action.hpfRadius,
                null,
                null,
                null,
                state.annotation.data &&
                "pointAnnotationClass" in state.annotation.data.selectedAnnotationType &&
                state.annotation.data.selectedAnnotationType.pointAnnotationClass
                  ? state.annotation.data.selectedAnnotationType.pointAnnotationClass.id
                  : null,
                "POINT",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_HPF_IMAGE_ANNOTATION:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            {
              ...state,
              tempHpf: action.point
            },
            Cmd.run(createAnnotation, {
              successActionCreator: createHpfAnnotationSuccess(true, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                pointToGeoJSON(action.point),
                action.radius,
                null,
                null,
                null,
                state.annotation.data &&
                "hpfAnnotationClass" in state.annotation.data.selectedAnnotationType &&
                state.annotation.data.selectedAnnotationType.hpfAnnotationClass
                  ? state.annotation.data.selectedAnnotationType.hpfAnnotationClass.id
                  : null,
                "HPF",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_TEXT:
      return state.annotation.data && state.annotation.data.form
        ? {
            ...state,
            annotation: {
              data: {
                ...state.annotation.data,
                form: {
                  ...state.annotation.data.form,
                  text: action.text
                }
              }
            }
          }
        : (state as never);
    case AnnotationActionTypes.CANCEL_IMAGE_ANNOTATION:
      return {
        ...state,
        annotation: initialState.annotation
      };
    case AnnotationActionTypes.CREATE_IMAGE_ANNOTATION_ELLIPSE:
      return state.imageWithAnnotations &&
        "resource" in state.imageWithAnnotations &&
        state.annotation.data &&
        state.annotation.data.form
        ? loop(
            state,
            Cmd.run(createAnnotation, {
              successActionCreator: createAnnotationSuccess(false, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                pointToGeoJSON(state.annotation.data.form.geometry),
                "radiusX" in state.annotation.data.form ? state.annotation.data.form.radiusX : null,
                "radiusY" in state.annotation.data.form ? state.annotation.data.form.radiusY : null,
                "tilt" in state.annotation.data.form ? state.annotation.data.form.tilt : null,
                "text" in state.annotation.data.form ? state.annotation.data.form.text : null,
                null,
                "ELLIPSE",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_IMAGE_ANNOTATION:
      return state.imageWithAnnotations &&
        "resource" in state.imageWithAnnotations &&
        state.annotation.data &&
        state.annotation.data.form
        ? loop(
            state,
            Cmd.run(createAnnotation, {
              successActionCreator: createAnnotationSuccess(false, action.cleanupCallback),
              failActionCreator: createAnnotationFailure,
              args: [
                pointToGeoJSON(state.annotation.data.form.geometry),
                "radiusX" in state.annotation.data.form ? state.annotation.data.form.radiusX : null,
                "radiusY" in state.annotation.data.form ? state.annotation.data.form.radiusY : null,
                "tilt" in state.annotation.data.form ? state.annotation.data.form.tilt : null,
                "text" in state.annotation.data.form ? state.annotation.data.form.text : null,
                null,
                "TEXT",
                state.imageWithAnnotations.resource.imageAndQuery.image.id
              ] as Parameters<typeof createAnnotation>
            })
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_IMAGE_ANNOTATION_SUCCESS:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            action.keepAnnotating
              ? // NOTE: Placing of annotations continues until the user elects to stop for certain
                // annotation types like point, freehand, line
                state
              : {
                  ...state,
                  annotation: initialState.annotation
                },
            Cmd.list(
              [
                Cmd.action(
                  refreshImage(state.imageWithAnnotations.resource.imageAndQuery.image.id)
                ),
                Cmd.run(action.cleanupCallback)
              ],
              { sequence: true }
            )
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_HPF_IMAGE_ANNOTATION_SUCCESS:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            {
              ...state,
              tempHpf: null
            },
            Cmd.list(
              [
                Cmd.action(
                  refreshImage(state.imageWithAnnotations.resource.imageAndQuery.image.id)
                ),
                Cmd.run(action.cleanupCallback)
              ],
              { sequence: true }
            )
          )
        : (state as never);
    case AnnotationActionTypes.CREATE_IMAGE_ANNOTATION_FAILURE:
      return {
        ...state,
        annotation: {
          data: state.annotation.data,
          errorMessage: action.errorMsg
        }
      };
    case AnnotationActionTypes.DELETE_IMAGE_ANNOTATION:
      return loop(
        state,
        Cmd.run(deleteAnnotation, {
          successActionCreator: deleteAnnotationSuccess,
          failActionCreator: deleteAnnotationFailure,
          args: [action.annotationId, action.deleteNested] as Parameters<typeof deleteAnnotation>
        })
      );
    case AnnotationActionTypes.DELETE_IMAGE_ANNOTATION_SUCCESS:
      return state.imageWithAnnotations && "resource" in state.imageWithAnnotations
        ? loop(
            {
              ...state,
              annotation: initialState.annotation
            },
            Cmd.action(refreshImage(state.imageWithAnnotations.resource.imageAndQuery.image.id))
          )
        : (state as never);
    case AnnotationActionTypes.DELETE_IMAGE_ANNOTATION_FAILURE:
      return {
        ...state,
        annotation: {
          data: null,
          errorMessage: action.errorMsg
        }
      };
    case ImageViewerActionTypes.TOGGLE_SIDEBAR_EXPANDED:
      return {
        ...state,
        isSidebarExpanded: !state.isSidebarExpanded
      };
    case ImageViewerActionTypes.TOGGLE_MICROSCOPE_ACTIVE:
      return {
        ...state,
        isMicroscopeActive: !state.isMicroscopeActive
      };
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_HIGHLIGHT:
      return {
        ...state,
        //allAnnotations: highlightAll(state.allAnnotations),
        allHighlightedAnnotations: highlightAll(state.imageWithAnnotations, [
          ...state.highlightedAnnotations,
          action.text
        ]),
        highlightedAnnotations: addId(state.highlightedAnnotations, action.text)
      };
    case AnnotationActionTypes.SET_IMAGE_ANNOTATION_UNHIGHLIGHT:
      return {
        ...state,
        allHighlightedAnnotations: highlightAll(
          state.imageWithAnnotations,
          removeId(state.highlightedAnnotations, action.text)
        ),
        highlightedAnnotations: removeId(state.highlightedAnnotations, action.text)
      };
    case CaseViewerActionTypes.UPDATE_CASE_COMMENT:
      return loop(
        {
          ...state
        },
        Cmd.run(addComment, {
          successActionCreator: updateCaseCommentSuccess,
          failActionCreator: updateCaseCommentFailure,
          args: [action.caseId, null, action.comment] as Parameters<typeof addComment>
        })
      );
    case CaseViewerActionTypes.UPDATE_CASE_COMMENT_SUCCESS:
      return {
        ...state
      };
    case CaseViewerActionTypes.UPDATE_CASE_COMMENT_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.UPDATE_IMAGE_COMMENT:
      return loop(
        {
          ...state
        },
        Cmd.run(addComment, {
          successActionCreator: updateImageCommentSuccess,
          failActionCreator: updateImageCommentFailure,
          args: [action.caseId, action.imageId, action.comment] as Parameters<typeof addComment>
        })
      );
    case CaseViewerActionTypes.UPDATE_IMAGE_COMMENT_SUCCESS:
      return {
        ...state
      };
    case CaseViewerActionTypes.UPDATE_IMAGE_COMMENT_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.OPEN_QUERY:
      return loop(
        {
          ...state
        },
        Cmd.run(createQuery, {
          successActionCreator: openQuerySuccess,
          failActionCreator: openQueryFailure,
          args: [
            action.queryObjectType,
            action.objectId,
            action.studyId,
            action.caseId,
            action.category,
            action.categoryOtherText,
            action.followUpInDays
          ] as Parameters<typeof createQuery>
        })
      );
    case CaseViewerActionTypes.OPEN_QUERY_SUCCESS:
      return loop(
        {
          ...state
        },
        Cmd.action(refreshCase())
      );
    case CaseViewerActionTypes.OPEN_QUERY_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.CLOSE_QUERY:
      return loop(
        {
          ...state
        },
        Cmd.run(closeQuery, {
          successActionCreator: closeQuerySuccess,
          failActionCreator: closeQueryFailure,
          args: [action.queryId, action.detailedReasonText] as Parameters<typeof closeQuery>
        })
      );
    case CaseViewerActionTypes.CLOSE_QUERY_SUCCESS:
      return loop(
        {
          ...state
        },
        Cmd.action(refreshCase())
      );
    case CaseViewerActionTypes.CLOSE_QUERY_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.UNRESOLVABLE_QUERY:
      return loop(
        {
          ...state
        },
        Cmd.run(markQueryUnresolvable, {
          successActionCreator: unresolvableQuerySuccess(action.refreshImageOnSuccess),
          failActionCreator: unresolvableQueryFailure,
          args: [action.queryId, action.unresolvableReasonText] as Parameters<
            typeof markQueryUnresolvable
          >
        })
      );
    case CaseViewerActionTypes.UNRESOLVABLE_QUERY_SUCCESS:
      return loop(
        {
          ...state
        },
        action.refreshImageOnSuccess
          ? Cmd.action(refreshImage(action.refreshImageOnSuccess))
          : Cmd.action(refreshCase())
      );
    case CaseViewerActionTypes.UNRESOLVABLE_QUERY_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    case CaseViewerActionTypes.NEW_QUERY_REMINDER:
      return loop(
        {
          ...state
        },
        Cmd.run(newQueryFollowUpReminder, {
          successActionCreator: newQueryReminderSuccess,
          failActionCreator: newQueryReminderFailure,
          args: [action.queryId, action.queryReminderId, action.followUpInDays] as Parameters<
            typeof newQueryFollowUpReminder
          >
        })
      );
    case CaseViewerActionTypes.NEW_QUERY_REMINDER_SUCCESS:
      return loop(
        {
          ...state
        },
        Cmd.action(refreshCase())
      );
    case CaseViewerActionTypes.NEW_QUERY_REMINDER_FAILURE:
      return {
        ...state,
        histoCase: {
          errorMessage: action.errorMsg
        }
      };
    default:
      //console.log("unknown action: " + actType);
      return state as never;
  }
};

export default caseViewerReducer;
