import { CancelTokenSource } from "axios";
import { Image, SignedURLs, StudyId } from "../models";

export enum ActionTypes {
  SIGNED_URLS_CREATE = "SIGNED_URLS_CREATE",
  SIGNED_URLS_CREATE_SUCCESS = "SIGNED_URLS_CREATE_SUCCESS",
  SIGNED_URLS_CREATE_FAILURE = "SIGNED_URLS_CREATE_FAILURE",
  OPEN_UPLOAD_DIALOG = "OPEN_UPLOAD_DIALOG",
  CLOSE_UPLOAD_DIALOG = "CLOSE_UPLOAD_DIALOG",
  CHANGE_FILES = "CHANGE_FILES",
  UPLOAD_IMAGES = "UPLOAD_IMAGES",
  UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS",
  UPLOAD_IMAGES_FAILURE = "UPLOAD_IMAGES_FAILURE",
  UPLOAD_IMAGES_PROGRESS = "UPLOAD_IMAGES_PROGRESS",
  UPLOAD_IMAGES_NOTIFY = "UPLOAD_IMAGES_NOTIFY",
  UPLOAD_IMAGES_NOTIFY_SUCCESS = "UPLOAD_IMAGES_NOTIFY_SUCCESS",
  UPLOAD_IMAGES_NOTIFY_FAILURE = "UPLOAD_IMAGES_NOTIFY_FAILURE"
}

export type UploadDialogAction =
  | {
      readonly type: ActionTypes.OPEN_UPLOAD_DIALOG;
      readonly studyId: StudyId;
      readonly imageToReplace?: Image;
      readonly refreshImageOnSuccessfulReplace?: true;
      readonly refreshCaseOnSuccessfulReplace?: true;
    }
  | { readonly type: ActionTypes.CLOSE_UPLOAD_DIALOG }
  | { readonly type: ActionTypes.CHANGE_FILES; readonly files: FileList | null }
  | { readonly type: ActionTypes.SIGNED_URLS_CREATE }
  | { readonly type: ActionTypes.SIGNED_URLS_CREATE_SUCCESS; readonly urls: SignedURLs }
  | { readonly type: ActionTypes.SIGNED_URLS_CREATE_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.UPLOAD_IMAGES; readonly cancelTokenSource: CancelTokenSource }
  | { readonly type: ActionTypes.UPLOAD_IMAGES_SUCCESS }
  | { readonly type: ActionTypes.UPLOAD_IMAGES_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.UPLOAD_IMAGES_PROGRESS;
      readonly file: File;
      readonly progress: number;
    }
  | { readonly type: ActionTypes.UPLOAD_IMAGES_NOTIFY }
  | { readonly type: ActionTypes.UPLOAD_IMAGES_NOTIFY_SUCCESS }
  | { readonly type: ActionTypes.UPLOAD_IMAGES_NOTIFY_FAILURE; readonly errorMsg: string };

export function openUploadDialog(
  studyId: StudyId,
  imageToReplace?: Image,
  refreshImageOnSuccessfulReplace?: true,
  refreshCaseOnSuccessfulReplace?: true
): UploadDialogAction {
  return {
    type: ActionTypes.OPEN_UPLOAD_DIALOG,
    studyId,
    imageToReplace,
    refreshImageOnSuccessfulReplace,
    refreshCaseOnSuccessfulReplace
  };
}

export function closeUploadDialog(): UploadDialogAction {
  return {
    type: ActionTypes.CLOSE_UPLOAD_DIALOG
  };
}

export function changeFiles(files: FileList | null): UploadDialogAction {
  return {
    type: ActionTypes.CHANGE_FILES,
    files
  };
}

export function signedURLsCreate(): UploadDialogAction {
  return {
    type: ActionTypes.SIGNED_URLS_CREATE
  };
}

export function signedURLsCreateSuccess(urls: SignedURLs): UploadDialogAction {
  return {
    type: ActionTypes.SIGNED_URLS_CREATE_SUCCESS,
    urls
  };
}

export function signedURLsCreateFailure(errorMsg: string): UploadDialogAction {
  return {
    type: ActionTypes.SIGNED_URLS_CREATE_FAILURE,
    errorMsg
  };
}

export function uploadImages(cancelTokenSource: CancelTokenSource): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES,
    cancelTokenSource
  };
}

export function uploadImagesSuccess(): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_SUCCESS
  };
}

export function uploadImagesFailure(errorMsg: string): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_FAILURE,
    errorMsg
  };
}

export function uploadImagesProgress(file: File, progress: number): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_PROGRESS,
    file,
    progress
  };
}

export function uploadImagesNotify(): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_NOTIFY
  };
}

export function uploadImagesNotifySuccess(): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_NOTIFY_SUCCESS
  };
}

export function uploadImagesNotifyFailure(errorMsg: string): UploadDialogAction {
  return {
    type: ActionTypes.UPLOAD_IMAGES_NOTIFY_FAILURE,
    errorMsg
  };
}
