import { Appearance, Box, Button, Heading, Icon } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";

const DialogHeaderStyled = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray200};
  padding: 0 1.6rem 1rem;
  margin: -1rem -1.6rem 0;
`;

const DialogBodyStyled = styled.div`
  padding: 1.2rem 1.6rem;
  margin: 0 -1.6rem;
  overflow: auto;
`;

const DialogFooterStyled = styled.div`
  border-top: 1px solid ${props => props.theme.colors.gray200};
  padding: 1rem 1.6rem 1rem;
  margin: 0 -1.6rem -2rem;
  display: flex;
  > div {
    margin-right: 1rem;
  }
`;

interface StateProps {
  readonly title: string;
  readonly closeDialog?: () => void;
  readonly style?: object;
}

const DialogHeader = ({ title, closeDialog, style }: StateProps) => {
  return (
    <DialogHeaderStyled style={style}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Heading as="h5" textAlign="left" style={{ marginTop: "0.5rem" }}>
            {title}
          </Heading>
        </Box>
        {closeDialog && (
          <Box>
            <Button appearance={Appearance.MINIMAL} onClick={closeDialog} mr="-1.6rem" py="1">
              <Icon name="cross" />
            </Button>
          </Box>
        )}
      </Box>
    </DialogHeaderStyled>
  );
};

const DialogFooter = ({ children }: { readonly children: React.ReactNode }) => {
  return <DialogFooterStyled>{children}</DialogFooterStyled>;
};

const DialogBody = ({ children }: { readonly children: React.ReactNode }) => {
  return <DialogBodyStyled>{children}</DialogBodyStyled>;
};

export { DialogHeader, DialogFooter, DialogBody };
