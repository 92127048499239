import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Callout, Icon, Intent, Text, Select, Label, Checkbox } from "@blasterjs/core";
import {
  DynamicQueryStatus,
  formatDynamicQueryStatus,
  formatQueryRecordCategory,
  formatQueryStatus,
  QueryObjectType,
  QueryRecordCategory,
  QueryRecords,
  QuerySearchRecord,
  QueryStatus,
  StudyListViews
} from "../models";
import { useNavigate } from "react-router-dom";
import { setQueriesFilter, queriesFetch } from "../actions/queries";
import Page, { PageHeader, PageHeading } from "../components/Page";
import { QueryFilter } from "../reducers/queries";
import store from "../store";
import UserDialog from "../components/UserDialog";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { Resource } from "../types";
import { studiesFetch } from "../actions/studies";

export interface QueryState {
  readonly queries: Resource<QueryRecords>;
  readonly queriesFilter: QueryFilter;
  readonly studies: Resource<StudyListViews>;
}
type StateProps = QueryState;

const initialQueryFilter: QueryFilter = {
  searchText: "",
  studyFilter: null,
  caseFilter: null,
  statusFilter: null,
  typeFilter: null,
  includeClosedQueries: false
};

const Queries = ({ queries, queriesFilter, studies }: StateProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(queriesFetch(queriesFilter));
    store.dispatch(studiesFetch());
    return () => {
      store.dispatch(setQueriesFilter(initialQueryFilter));
    };
  }, []);
  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);

  const error =
    "errorMessage" in queries ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{queries.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const onRowClick = (query: QuerySearchRecord) => {
    navigate(`/studies/${query.studyId}/cases/${query.caseId}`);
  };

  function formatQueryObjectType(qot: QueryObjectType): string {
    switch (qot) {
      case QueryObjectType.Case:
        return "Internal";
      case QueryObjectType.Image:
        return "Lab";
      default:
        return "";
    }
  }

  function getStatusLabelColor(dynamicStatus: DynamicQueryStatus) {
    switch (dynamicStatus) {
      case DynamicQueryStatus.Answered:
        return "green";
      case DynamicQueryStatus.FollowUpRequired:
        return "red";
      default:
        return "";
    }
  }

  function truncate(str: String, n: number) {
    return str.length > n ? str.slice(0, n - 1) : str;
  }

  const columns: ReadonlyArray<object> = [
    {
      name: "Opened",
      selector: (query: QuerySearchRecord) => query.openedAt,
      sortable: true,
      format: (query: QuerySearchRecord) => <Timestamp date={query.openedAt} />
    },
    {
      name: "Query Status",
      selector: (query: QuerySearchRecord) => query.queryStatus,
      sortable: true,
      format: (query: QuerySearchRecord) => {
        /*eslint-disable */
        let statusLabel = formatQueryStatus(query.queryStatus);
        let labelColor = "";
        /*eslint-enable */
        if (query.queryStatus == QueryStatus.Open && query.dynamicStatus) {
          statusLabel = formatDynamicQueryStatus(query.dynamicStatus);
          labelColor = getStatusLabelColor(query.dynamicStatus);
        } else {
          if (query.dynamicStatus == DynamicQueryStatus.Answered) {
            statusLabel = formatDynamicQueryStatus(query.dynamicStatus);
            labelColor = getStatusLabelColor(query.dynamicStatus);
          } else {
            statusLabel = query.queryStatus;
          }
        }
        return <span style={{ color: labelColor }}>{statusLabel}</span>;
      }
    },
    {
      name: "Type",
      selector: (query: QuerySearchRecord) => query.objectType,
      sortable: true,
      format: (query: QuerySearchRecord) => formatQueryObjectType(query.objectType)
    },
    {
      name: "Reason",
      selector: (query: QuerySearchRecord) => query.categoryOtherText,
      sortable: true,
      format: (query: QuerySearchRecord) => {
        return query.category == QueryRecordCategory.Other
          ? `${truncate(query.categoryOtherText || "", 15)}...`
          : formatQueryRecordCategory(query.category);
      }
    },
    {
      name: "Study",
      selector: (query: QuerySearchRecord) => query.studyName,
      sortable: true
    },
    {
      name: "Case",
      selector: (query: QuerySearchRecord) => query.caseName,
      sortable: true
    },
    {
      name: "Image",
      selector: (query: QuerySearchRecord) => query.imageName,
      sortable: true
    },
    {
      name: "Follow Up Due On",
      selector: (query: QuerySearchRecord) => query.latestFollowUpOnDt,
      sortable: true,
      format: (query: QuerySearchRecord) => {
        const isRed = query.latestFollowUpOnDt && new Date() > query.latestFollowUpOnDt;
        /*eslint-disable */
        let output = (
          <span style={isRed ? { color: "red" } : undefined}>
            <Timestamp date={query.latestFollowUpOnDt} />
          </span>
        );
        /*eslint-enable */

        if (query.queryStatus == QueryStatus.Closed) {
          output = <></>;
        }

        return <>{output}</>;
      }
    }
  ];
  const onQuerySearchChange = (searchTerms: string) => {
    store.dispatch(
      setQueriesFilter({
        ...queriesFilter,
        searchText: searchTerms
      })
    );
  };

  const onStudySelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const studyFilter = e.target.value;

    store.dispatch(
      setQueriesFilter({
        ...queriesFilter,
        studyFilter: studyFilter.length == 0 ? null : studyFilter
      })
    );
  };

  const onStatusSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const statusFilter = e.target.value;
    store.dispatch(
      setQueriesFilter({
        ...queriesFilter,
        statusFilter: statusFilter.length == 0 ? null : statusFilter
      })
    );
  };

  const onTypeSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const typeFilter = e.target.value;

    store.dispatch(
      setQueriesFilter({
        ...queriesFilter,
        typeFilter: typeFilter.length == 0 ? null : typeFilter
      })
    );
  };

  const onIncludeClosedQueriesChange = () => {
    store.dispatch(
      setQueriesFilter({
        ...queriesFilter,
        includeClosedQueries: !queriesFilter.includeClosedQueries
      })
    );
  };

  const qcStudySelect =
    "resource" in studies ? (
      <Select onChange={onStudySelectChange} defaultValue={""}>
        <option key={""} value={""}>
          Any study
        </option>
        {studies.resource.map(study => {
          return (
            <option key={study.studyView.study.id} value={study.studyView.study.id}>
              {study.studyView.study.name}
            </option>
          );
        })}
      </Select>
    ) : null;

  const qcStatusSelect = (
    <Select onChange={onStatusSelectChange} defaultValue={""}>
      <option key="" value="">
        Any status
      </option>
      {Object.values(QueryStatus).map(status => {
        return (
          <option key={status} value={status}>
            {formatQueryStatus(status)}
          </option>
        );
      })}
      {Object.values(DynamicQueryStatus).map(status => {
        return (
          <option key={status} value={status}>
            {formatDynamicQueryStatus(status)}
          </option>
        );
      })}
    </Select>
  );

  const qcTypeSelect = (
    <Select onChange={onTypeSelectChange} defaultValue={""}>
      <option key="" value="">
        Query type
      </option>
      {Object.values(QueryObjectType).map(status => {
        return (
          <option key={formatQueryObjectType(status)} value={status}>
            {formatQueryObjectType(status)}
          </option>
        );
      })}
    </Select>
  );

  const qcIncludeClosedQueries = (
    <Label>
      <Checkbox
        checked={queriesFilter.includeClosedQueries}
        onChange={onIncludeClosedQueriesChange}
        ml={1}
        mr={1}
      />
      <Text color="gray600" style={{ top: "2px", position: "relative", fontWeight: 400 }}>
        Include Closed Queries
      </Text>
    </Label>
  );

  const content = error ? (
    error
  ) : (
    <TableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="queries-search"
            width="auto"
            defaultValue={queriesFilter?.searchText || ""}
            placeholder={"Search by name"}
            onValueChange={onQuerySearchChange}
          />
          <Box paddingLeft="10px">{qcStudySelect}</Box>
          <Box paddingLeft="10px">{qcStatusSelect}</Box>
          <Box paddingLeft="10px">{qcTypeSelect}</Box>
          {queriesFilter.statusFilter != null ? null : (
            <Box paddingLeft="10px">{qcIncludeClosedQueries}</Box>
          )}
        </Box>
      </TableFilters>
      {"resource" in queries ? (
        <StyledDataTable
          columns={columns}
          data={queries.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          onRowClicked={onRowClick}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
    </TableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Queries</PageHeading>
        </PageHeader>
        {content}
        <UserDialog />
      </Box>
    </Page>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    queries: state.queries.queries,
    queriesFilter: state.queries.queriesFilter,
    studies: state.studies.studies
  };
}

export default connect(mapStateToProps)(Queries);
