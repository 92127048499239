import { Icon } from "@blasterjs/core";
import { Popup } from "react-leaflet";
import React from "react";
import { UUID } from "../models";
import { deleteAnnotation } from "../actions/annotation";
import store from "../store";

interface Props {
  readonly text?: string | null;
  readonly date: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
}

export const deleteImageAnnotation = (annotationId: UUID) => () =>
  store.dispatch(deleteAnnotation(annotationId, false));

/*
 * Popup shown when annotations are clicked.
 */
const AnnotationPopup = ({ canDelete, text, date, annotationId }: Props) => (
  <Popup>
    <div style={{ fontSize: "16px" }}>{text}</div>
    <div>{date}</div>
    {canDelete ? (
      <Icon
        name="trash"
        onClick={deleteImageAnnotation(annotationId)}
        style={{ cursor: "pointer" }}
      />
    ) : null}
  </Popup>
);

export default AnnotationPopup;
