import { ReportStudyData, ReportUserData } from "../models";

export enum ActionTypes {
  SET_AUDIT_TRAIL_STUDY_DATA = "SET_AUDIT_TRAIL_STUDY_DATA",
  SET_AUDIT_TRAIL_USER_DATA = "SET_AUDIT_TRAIL_USER_DATA",
  REQUEST_CSV = "REQUEST_CSV",
  REQUEST_CSV_SUCCESS = "REQUEST_CSV_SUCCESS",
  REQUEST_CSV_FAILURE = "REQUEST_CSV_FAILURE"
}

export type AuditTrailAction =
  | { readonly type: ActionTypes.SET_AUDIT_TRAIL_STUDY_DATA; readonly studyData?: ReportStudyData }
  | { readonly type: ActionTypes.SET_AUDIT_TRAIL_USER_DATA; readonly userData?: ReportUserData }
  | { readonly type: ActionTypes.REQUEST_CSV }
  | { readonly type: ActionTypes.REQUEST_CSV_SUCCESS; readonly auditCsv: string }
  | { readonly type: ActionTypes.REQUEST_CSV_FAILURE; readonly errorMsg: string };

export function setAuditTrailStudyData(studyData?: ReportStudyData): AuditTrailAction {
  return {
    type: ActionTypes.SET_AUDIT_TRAIL_STUDY_DATA,
    studyData
  };
}

export function setAuditTrailUserData(userData?: ReportUserData): AuditTrailAction {
  return {
    type: ActionTypes.SET_AUDIT_TRAIL_USER_DATA,
    userData
  };
}

export function requestCsv(): AuditTrailAction {
  return {
    type: ActionTypes.REQUEST_CSV
  };
}

export function requestCsvSuccess(auditCsv: string): AuditTrailAction {
  return {
    type: ActionTypes.REQUEST_CSV_SUCCESS,
    auditCsv
  };
}

export function requestCsvFailure(errorMsg: string): AuditTrailAction {
  return {
    type: ActionTypes.REQUEST_CSV_FAILURE,
    errorMsg
  };
}
