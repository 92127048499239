import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { imagesFetch } from "../actions/images";
import {
  ActionTypes,
  moveImageFailure,
  MoveImageForm,
  moveImageSuccess
} from "../actions/moveImageDialog";

import { moveImageToStudy } from "../api";
import { Image } from "../models";
import { WriteResource } from "../types";

export type MoveImageDialogState =
  | {
      readonly isOpen: true;
      readonly savedImage: Image;
      readonly image: WriteResource<MoveImageForm, Image>;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: MoveImageDialogState = {
  isOpen: false
};

export const moveImageDialogReducer: LoopReducer<MoveImageDialogState, Action> = (
  state: MoveImageDialogState = initialState,
  action: Action
): MoveImageDialogState | Loop<MoveImageDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_MOVE_IMAGE_DIALOG:
      return {
        isOpen: true,
        savedImage: action.image,
        image: {
          data: {
            studyId: null,
            reasonForChange: null
          }
        }
      };
    case ActionTypes.CLOSE_MOVE_IMAGE_DIALOG:
      return {
        isOpen: false
      };
    case ActionTypes.SET_MOVE_IMAGE_FORM:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: action.form
            }
          }
        : state;
    case ActionTypes.MOVE_IMAGE_TO_STUDY:
      return state.isOpen
        ? loop(
            state,
            Cmd.run(moveImageToStudy, {
              successActionCreator: moveImageSuccess,
              failActionCreator: moveImageFailure,
              args: [
                state.savedImage.id,
                {
                  value: state.image.data.studyId,
                  reasonForChange: state.image.data.reasonForChange || undefined
                }
              ] as Parameters<typeof moveImageToStudy>
            })
          )
        : state;
    case ActionTypes.MOVE_IMAGE_TO_STUDY_SUCCESS:
      return state.isOpen
        ? loop(initialState, Cmd.action(imagesFetch(state.savedImage.studyId)))
        : state;
    case ActionTypes.MOVE_IMAGE_TO_STUDY_FAILURE:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: state.image.data,
              errorMessage: action.errorMsg
            }
          }
        : state;
    default:
      return state;
  }
};

export default moveImageDialogReducer;
