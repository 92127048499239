import { Loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { ActionTypes } from "../actions/map";

export interface MapState {
  readonly zoom: number;
  readonly selectedImage: number;
  readonly imageMenuOverflow: boolean;
}

export const initialState: MapState = {
  zoom: 0,
  selectedImage: 0,
  imageMenuOverflow: false
};

const mapReducer: LoopReducer<MapState, Action> = (
  state: MapState = initialState,
  action: Action
): MapState | Loop<MapState, Action> => {
  switch (action.type) {
    case ActionTypes.ZOOM_CHANGE:
      return {
        ...state,
        zoom: action.zoom
      };
    case ActionTypes.SET_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: action.selectedImage
      };
    case ActionTypes.SET_IMAGE_MENU_OVERFLOW:
      return {
        ...state,
        imageMenuOverflow: action.imageMenuOverflow
      };
    default:
      return state;
  }
};

export default mapReducer;
