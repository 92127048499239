import { union, without } from "ramda";
import { Cmd, Loop, loop, LoopReducer } from "redux-loop";
import { equals } from "ramda";

import { Action, redirect } from "../actions";
import {
  ActionTypes,
  AnnotationClassForm,
  AssignableUserRoles,
  ReadersResource,
  ReadonlyUsersResource,
  searchReadersRequestFailure,
  searchReadersRequestSuccess,
  searchReadonlyUsersRequestFailure,
  searchReadonlyUsersRequestSuccess,
  searchUploadersRequestFailure,
  searchUploadersRequestSuccess,
  studyFetchRequestFailure,
  studyFetchRequestSuccess,
  StudyForm,
  StudyFormFields,
  studyRequestFailure,
  studyRequestSuccess,
  UpdateStudyForm,
  UploadersResource
} from "../actions/studyConfiguration";

import { createStudy, fetchStudy, fetchUsers, updateStudy } from "../api";
import { AnnotationClassType, onlyUpdates, StudyAdminView, UserRole } from "../models";
import { convAnno, convAnnoOld } from "../pages/StudyConfiguration";
import { WriteResource } from "../types";
import { areDifferent, idsOnly } from "../utils";

function roleToProperty(
  role: AssignableUserRoles
): Extract<keyof StudyForm, "readers" | "uploaders" | "readonlyUsers"> {
  switch (role) {
    case UserRole.Reader:
      return "readers";
    case UserRole.Lab:
      return "uploaders";
    case UserRole.Readonly:
      return "readonlyUsers";
    default: {
      const impossible: never = role;
      return impossible;
    }
  }
}

function adminStudyViewToForm(studyView: StudyAdminView): UpdateStudyForm {
  return {
    name: {
      value: studyView.study.name
    },
    indication: {
      value: studyView.study.indication
    },
    segments: {
      value: studyView.study.segments
    },
    modality: {
      value: studyView.study.modality
    },
    uploaders: {
      value: studyView.uploaders
    },
    readers: {
      value: studyView.readers
    },
    readonlyUsers: {
      value: studyView.readonlyUsers
    },
    onHold: {
      value: studyView.study.onHold
    },
    onHoldReason: {
      value: studyView.study.onHoldReason
    },
    hpfAnnotationClasses: {
      value: studyView.hpfAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    },
    pointAnnotationClasses: {
      value: studyView.pointAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    },
    freehandAnnotationClasses: {
      value: studyView.freehandAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    }
  };
}

export interface StudyConfigurationState {
  readonly savedStudy: StudyAdminView | null;
  readonly study: WriteResource<StudyForm, StudyAdminView>;
  readonly uploadersSearchText: string;
  readonly readersSearchText: string;
  readonly readonlyUsersSearchText: string;
  readonly uploaderSearchResults: UploadersResource;
  readonly readerSearchResults: ReadersResource;
  readonly readonlyUserSearchResults: ReadonlyUsersResource;
}

export const initialAnnotationClassState: AnnotationClassForm<AnnotationClassType> = {
  name: "",
  color: "",
  sortOrder: null,
  type: "FREEFORM",
  enabled: true,
  deleted: false
};

function isAnnotationClassFormBlank(form: AnnotationClassForm<AnnotationClassType>): boolean {
  return (
    (form.name === "" && form.color === "" && form.sortOrder === null) || form.deleted === true
  );
}

export const initialState: StudyConfigurationState = {
  savedStudy: null,
  study: {
    data: {
      name: { value: "" },
      indication: { value: null },
      segments: { value: null },
      modality: { value: [] },
      uploaders: { value: [] },
      readers: { value: [] },
      readonlyUsers: { value: [] },
      onHold: { value: false },
      onHoldReason: { value: null },
      hpfAnnotationClasses: { value: [] },
      pointAnnotationClasses: { value: [] },
      freehandAnnotationClasses: { value: [] }
    }
  },
  uploadersSearchText: "",
  readersSearchText: "",
  readonlyUsersSearchText: "",
  uploaderSearchResults: {
    isPending: false
  },
  readerSearchResults: {
    isPending: false
  },
  readonlyUserSearchResults: {
    isPending: false
  }
};

export const studyConfigurationReducer: LoopReducer<StudyConfigurationState, Action> = (
  state: StudyConfigurationState = initialState,
  action: Action
): StudyConfigurationState | Loop<StudyConfigurationState, Action> => {
  switch (action.type) {
    case ActionTypes.STUDY_RESET:
      return initialState;
    case ActionTypes.CHANGE_STUDY:
      return {
        ...state,
        study: {
          data: action.study
        }
      };
    case ActionTypes.STUDY_FETCH_REQUEST:
      return loop(
        {
          ...state,
          study: {
            ...state.study,
            isPending: true
          }
        },
        Cmd.run(fetchStudy, {
          successActionCreator: studyFetchRequestSuccess,
          failActionCreator: studyFetchRequestFailure,
          args: [[action.studyId] as Parameters<typeof fetchStudy>]
        })
      );
    case ActionTypes.SEARCH_UPLOADERS_REQUEST:
      return loop(
        {
          ...state,
          uploadersSearchText: action.name,
          uploaderSearchResults: {
            isPending: true
          }
        },
        Cmd.run(fetchUsers, {
          successActionCreator: searchUploadersRequestSuccess,
          failActionCreator: searchUploadersRequestFailure,
          args: [action.name, UserRole.Lab, true, state.study.data.uploaders.value.map(r => r.id)]
        })
      );
    case ActionTypes.SEARCH_READERS_REQUEST:
      return loop(
        {
          ...state,
          readersSearchText: action.name,
          readerSearchResults: {
            isPending: true
          }
        },
        Cmd.run(fetchUsers, {
          successActionCreator: searchReadersRequestSuccess,
          failActionCreator: searchReadersRequestFailure,
          args: [action.name, UserRole.Reader, true, state.study.data.readers.value.map(r => r.id)]
        })
      );
    case ActionTypes.SEARCH_READONLY_USERS_REQUEST:
      return loop(
        {
          ...state,
          readonlyUsersSearchText: action.name,
          readonlyUserSearchResults: {
            isPending: true
          }
        },
        Cmd.run(fetchUsers, {
          successActionCreator: searchReadonlyUsersRequestSuccess,
          failActionCreator: searchReadonlyUsersRequestFailure,
          args: [
            action.name,
            UserRole.Readonly,
            true,
            state.study.data.readonlyUsers.value.map(r => r.id)
          ]
        })
      );
    case ActionTypes.STUDY_FETCH_REQUEST_SUCCESS:
      return "readers" in action.studyView
        ? {
            ...state,
            savedStudy: action.studyView,
            study: {
              data: adminStudyViewToForm(action.studyView)
            }
          }
        : state;
    case ActionTypes.SEARCH_UPLOADERS_REQUEST_SUCCESS:
      return {
        ...state,
        uploaderSearchResults: {
          resource: action.uploaders
        }
      };
    case ActionTypes.SEARCH_READERS_REQUEST_SUCCESS:
      return {
        ...state,
        readerSearchResults: {
          resource: action.readers
        }
      };
    case ActionTypes.SEARCH_READONLY_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        readonlyUserSearchResults: {
          resource: action.readonlyUsers
        }
      };
    case ActionTypes.STUDY_FETCH_REQUEST_FAILURE:
      return {
        ...state,
        study: {
          ...state.study,
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.SEARCH_UPLOADERS_REQUEST_FAILURE:
      return {
        ...state,
        uploaderSearchResults: {
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.SEARCH_READERS_REQUEST_FAILURE:
      return {
        ...state,
        readerSearchResults: {
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.SEARCH_READONLY_USERS_REQUEST_FAILURE:
      return {
        ...state,
        readonlyUserSearchResults: {
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.ASSIGN_USER_TO_STUDY: {
      const roleProperty = roleToProperty(action.role);
      return "data" in state.study
        ? {
            ...state,
            study: {
              data: {
                ...state.study.data,
                [roleProperty]: {
                  ...state.study.data[roleProperty],
                  value: union(state.study.data[roleProperty].value, [action.user])
                }
              }
            }
          }
        : state;
    }
    case ActionTypes.UNASSIGN_USER_FROM_STUDY: {
      const roleProperty = roleToProperty(action.role);
      return "data" in state.study
        ? {
            ...state,
            study: {
              data: {
                ...state.study.data,
                [roleProperty]: {
                  ...state.study.data[roleProperty],
                  value: without([action.user], state.study.data[roleProperty].value)
                }
              }
            }
          }
        : state;
    }
    case ActionTypes.STUDY_REQUEST: {
      // Filter out completely blank point annotation class values since the form adds those by
      // default
      const newStudy = {
        ...state.study.data,
        hpfAnnotationClasses: {
          ...state.study.data.hpfAnnotationClasses,
          value: state.study.data.hpfAnnotationClasses.value.filter(
            hpfAnnotationClass => !isAnnotationClassFormBlank(hpfAnnotationClass)
          )
        },
        pointAnnotationClasses: {
          ...state.study.data.pointAnnotationClasses,
          value: state.study.data.pointAnnotationClasses.value.filter(
            pointAnnotationClass => !isAnnotationClassFormBlank(pointAnnotationClass)
          )
        },
        freehandAnnotationClasses: {
          ...state.study.data.freehandAnnotationClasses,
          value: state.study.data.freehandAnnotationClasses.value.filter(
            freehandAnnotationClass => !isAnnotationClassFormBlank(freehandAnnotationClass)
          )
        }
      };
      const cleanedStudyData: StudyForm = {
        ...state.study.data,
        hpfAnnotationClasses: {
          value: state.study.data.hpfAnnotationClasses.value.filter(
            hpfAnnotationClass => !isAnnotationClassFormBlank(hpfAnnotationClass)
          )
        },
        pointAnnotationClasses: {
          value: state.study.data.pointAnnotationClasses.value.filter(
            pointAnnotationClass => !isAnnotationClassFormBlank(pointAnnotationClass)
          )
        },
        freehandAnnotationClasses: {
          value: state.study.data.freehandAnnotationClasses.value.filter(
            freehandAnnotationClass => !isAnnotationClassFormBlank(freehandAnnotationClass)
          )
        }
      };
      type UserFields = keyof Pick<StudyFormFields, "uploaders" | "readers" | "readonlyUsers">;
      const noChangeStudyUsersField = (field: UserFields, update: any) => {
        const diff =
          state?.savedStudy &&
          update?.value &&
          areDifferent(idsOnly(state?.savedStudy[field]), idsOnly(update.value));
        const rfc: string = update?.reasonForChange || "";
        return !(diff && rfc.length > 0);
      };
      type AnnotationClassFields = keyof Pick<
        StudyFormFields,
        "hpfAnnotationClasses" | "pointAnnotationClasses" | "freehandAnnotationClasses"
      >;
      const noChangeStudyAnnotationField = (field: AnnotationClassFields, update: any) => {
        //const update = studyConfiguration.study.data[field];
        const newVal = update?.value.map(convAnno);
        const oldVal = state?.savedStudy && state?.savedStudy[field].map(convAnnoOld);
        const diff = oldVal && !equals(oldVal, newVal);
        const rfc: string = update?.reasonForChange || "";
        return !(diff && rfc.length > 0);
      };
      const updates =
        state.savedStudy && onlyUpdates(adminStudyViewToForm(state.savedStudy), newStudy);
      return loop(
        {
          ...state,
          study: {
            data: state.study.data,
            isPending: true
          }
        },
        updates
          ? Cmd.run(updateStudy, {
              successActionCreator: studyRequestSuccess,
              failActionCreator: studyRequestFailure,
              args: [
                state?.savedStudy?.study.id,
                state?.savedStudy?.study.name !== updates.name?.value ? updates.name : null,
                state?.savedStudy?.study.indication !== updates.indication?.value
                  ? updates.indication
                  : null,
                state?.savedStudy?.study.segments !== updates.segments?.value
                  ? updates.segments
                  : null,
                state?.savedStudy?.study.modality !== updates.modality?.value
                  ? updates.modality
                  : null,
                noChangeStudyUsersField("uploaders", updates.uploaders) ? null : updates.uploaders,
                noChangeStudyUsersField("readers", updates.readers) ? null : updates.readers,
                noChangeStudyUsersField("readonlyUsers", updates.readonlyUsers)
                  ? null
                  : updates.readonlyUsers,
                state?.savedStudy?.study.onHold !== updates.onHold?.value ? updates.onHold : null,
                state?.savedStudy?.study.onHoldReason !== updates.onHoldReason?.value
                  ? updates.onHoldReason
                  : null,
                noChangeStudyAnnotationField("hpfAnnotationClasses", updates.hpfAnnotationClasses)
                  ? null
                  : updates.hpfAnnotationClasses,
                noChangeStudyAnnotationField(
                  "pointAnnotationClasses",
                  updates.pointAnnotationClasses
                )
                  ? null
                  : updates.pointAnnotationClasses,
                noChangeStudyAnnotationField(
                  "freehandAnnotationClasses",
                  updates.freehandAnnotationClasses
                )
                  ? null
                  : updates.freehandAnnotationClasses
              ] as Parameters<typeof updateStudy>
            })
          : Cmd.run(createStudy, {
              successActionCreator: studyRequestSuccess,
              failActionCreator: studyRequestFailure,
              args: [
                cleanedStudyData.name.value,
                cleanedStudyData.indication.value,
                cleanedStudyData.segments.value,
                cleanedStudyData.modality.value,
                cleanedStudyData.uploaders.value,
                cleanedStudyData.readers.value,
                cleanedStudyData.readonlyUsers.value,
                cleanedStudyData.onHold.value,
                cleanedStudyData.onHoldReason.value,
                cleanedStudyData.hpfAnnotationClasses.value,
                cleanedStudyData.pointAnnotationClasses.value,
                cleanedStudyData.freehandAnnotationClasses.value
              ] as Parameters<typeof createStudy>
            })
      );
    }
    case ActionTypes.STUDY_REQUEST_SUCCESS:
      return loop(
        {
          ...state,
          study: {
            data: state.study.data,
            resource: action.study
          }
        },
        Cmd.action(redirect(`/studies/${action.study.id}`))
      );
    case ActionTypes.STUDY_REQUEST_FAILURE:
      return "data" in state.study && "readers" in state.study.data
        ? {
            ...state,
            study: {
              data: state.study.data,
              errorMessage: action.errorMsg
            }
          }
        : state;
    case ActionTypes.STUDY_CANCEL_EDIT:
      return loop(
        {
          ...state
        },
        Cmd.action(redirect(`/studies/${action.studyId}`))
      );
    default:
      return state;
  }
};

export default studyConfigurationReducer;
