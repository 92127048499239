import { Checkbox, Label } from "@blasterjs/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { toggleAnnotations } from "../actions/imageViewer";
import { State } from "../reducers";
import { CaseImageViewerState } from "../reducers/caseImageViewer";
import store from "../store";

type StateProps = Pick<CaseImageViewerState, "hideAnnotations">;

const ToggleContainer = styled(Label)`
  border-radius: 0;
  border-bottom: 0;
  height: 3.6rem;
  padding: 6px 10px 0;
  margin-left: -1px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  margin-right: 5px;
  background-color: #fff;
  color: ${props => props.theme.colors.gray900};
  line-height: 0;
  font-size: ${props => props.theme.fontSizes[1]};
`;

const ToggleText = styled.span`
  position: relative;
  top: 1px;
  margin-left: 4px;
`;

const toggle = () => store.dispatch(toggleAnnotations());

const ToggleAnnotations = ({ hideAnnotations }: StateProps) => {
  return (
    <ToggleContainer>
      <Checkbox checked={!hideAnnotations} onChange={toggle} />
      <ToggleText>Annotations</ToggleText>
    </ToggleContainer>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    hideAnnotations: state.caseImageViewer.hideAnnotations
  };
}

export default connect(mapStateToProps)(ToggleAnnotations);
