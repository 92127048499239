import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  queriesFetch,
  queriesFetchFailure,
  queriesFetchSuccess
} from "../actions/queries";

import { fetchQueries } from "../api";
import { QueryRecords, UUID } from "../models";
import { Resource } from "../types";

export interface QueryFilter {
  readonly searchText: string | null;
  readonly studyFilter: UUID | null;
  readonly caseFilter: UUID | null;
  readonly statusFilter: string | null;
  readonly typeFilter: string | null;
  readonly includeClosedQueries: boolean | null;
}

export interface QueryState {
  readonly queries: Resource<QueryRecords>;
  readonly queriesFilter: QueryFilter;
}

export const initialState: QueryState = {
  queries: {
    isPending: false
  },
  queriesFilter: {
    searchText: "",
    studyFilter: null,
    caseFilter: null,
    statusFilter: null,
    typeFilter: null,
    includeClosedQueries: false
  }
};

const queriesReducer: LoopReducer<QueryState, Action> = (
  state: QueryState = initialState,
  action: Action
): QueryState | Loop<QueryState, Action> => {
  switch (action.type) {
    case ActionTypes.SET_QUERIES_FILTER:
      return loop(
        {
          ...state,
          queriesFilter: action.queryFilter
        },
        Cmd.action(queriesFetch(action.queryFilter))
      );
    case ActionTypes.QUERIES_FETCH:
      return loop(
        {
          ...state,
          users: {
            isPending: true
          }
        },
        Cmd.run(fetchQueries, {
          successActionCreator: queriesFetchSuccess,
          failActionCreator: queriesFetchFailure,
          args: [state.queriesFilter] as Parameters<typeof fetchQueries>
        })
      );
    case ActionTypes.QUERIES_FETCH_SUCCESS:
      return {
        ...state,
        queries: {
          resource: action.queries
        }
      };
    case ActionTypes.QUERIES_FETCH_FAILURE:
      return {
        ...state,
        queries: {
          errorMessage: action.errorMsg
        }
      };
    default:
      return state;
  }
};

export default queriesReducer;
