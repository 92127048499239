import { Appearance, Box, Button, Heading, Icon, Table } from "@blasterjs/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Image, User, userIsAdminOrIsc } from "../models";
import { State } from "../reducers";
import { Resource } from "../types";

import { openImageDialog } from "../actions/imageDialog";
import store from "../store";

import ImageDialog from "../components/ImageDialog";
import { copyText } from "../components/AnnotationNest";
import Timestamp from "./Timestamp";

const CompactTable = styled(Table)`
  td:first-child {
    font-weight: 600;
    padding-right: 1rem;
    width: 12rem;
  }
`;

interface StateProps {
  readonly user: Resource<User>;
}

interface Props {
  readonly image: Image;
  readonly collapseIcon?: React.ReactNode;
}

const ImageInfo = ({ image, collapseIcon, user }: Props & StateProps) => {
  const isAdminOrIsc = userIsAdminOrIsc(("resource" in user && user.resource.role) || null);
  const editIcon = isAdminOrIsc ? (
    <Button
      value={image.id}
      onClick={() => store.dispatch(openImageDialog(image, true))}
      appearance={Appearance.MINIMAL}
      title="Edit Image"
    >
      <Icon name="edit" />
    </Button>
  ) : null;
  const imageDialog = isAdminOrIsc ? <ImageDialog /> : null;
  return (
    <>
      <Box display="flex" width="100%">
        <Heading textAlign="left" as="h6" mb={1}>
          Image
        </Heading>
        <Box ml={"auto"}>
          {editIcon}
          {collapseIcon}
        </Box>
      </Box>
      <CompactTable fontSize={1}>
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <Box lineHeight="1.3">{image.name}</Box>
            </td>
          </tr>
          <tr>
            <td>Uploaded</td>
            <td>
              <Timestamp date={image.uploadedAt} />
            </td>
          </tr>
          <tr>
            <td>Accession #</td>
            <td>
              <Box
                className="copyableBox"
                title="click to copy"
                lineHeight="1.3"
                onClick={(e: MouseEvent) =>
                  e && e.target && copyText((e.target as HTMLSpanElement).innerText)
                }
              >
                {image.accessionNumber}
              </Box>
            </td>
          </tr>
          <tr>
            <td>Biopsy Location</td>
            <td>
              <Box lineHeight="1.3">{image.biopsyLocation}</Box>
            </td>
          </tr>
        </tbody>
      </CompactTable>
      {imageDialog}
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth
  };
}

export default connect(mapStateToProps)(ImageInfo);
