import { Appearance, Button, Icon } from "@blasterjs/core";
import { useEffect, useState } from "react";
import React from "react";
import store from "../store";
import { User, userIsAdminOrIsc, UserRole, UUID } from "../models";

import { Box, Label, TextArea } from "@blasterjs/core";
import { updateCaseComment } from "../actions/caseViewer";
import { updateImageComment } from "../actions/caseViewer";
import { QueryBox } from "./QueryWindow";

export enum CommentBoxType {
  Case,
  Image
}
interface CommentBoxState {
  isEditing: boolean;
  comment: string | null;
}
const initialState: CommentBoxState = {
  isEditing: false,
  comment: null
};

interface CommentBoxProps {
  readonly type: CommentBoxType;
  readonly caseId: UUID;
  readonly imageId: UUID | null;
  readonly commentText: string | null;
  readonly user: User;
}

const CommentBox = ({ type, caseId, imageId, commentText, user }: CommentBoxProps) => {
  const [state, setState] = useState<CommentBoxState>(initialState);
  const userIsAdminLike = userIsAdminOrIsc(user.role);
  const userIsReadOnly = user.role === null ? false : [UserRole.Readonly].includes(user.role);

  useEffect(() => {
    if (commentText) {
      setState({
        ...state,
        comment: commentText
      });
    }
  }, [commentText]);

  const cancelOnClick = () => {
    setState({
      ...state,
      isEditing: false
    });
  };

  const editOnClick = () => {
    setState({
      ...state,
      isEditing: true
    });
  };

  const addCommentButton = userIsAdminLike ? (
    <Button mt={1} block={true} onClick={editOnClick}>
      Add Comment
    </Button>
  ) : null;

  const commentOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      comment: e.target.value
    });
  };

  const saveOnClick = () => {
    if (state.comment) {
      if (type == CommentBoxType.Case) {
        store.dispatch(updateCaseComment(caseId, state.comment));
      } else {
        if (imageId) {
          store.dispatch(updateImageComment(caseId, imageId, state.comment));
        }
      }

      setState({
        ...state,
        isEditing: false
      });
    }
  };

  const showComment =
    userIsAdminLike || userIsReadOnly ? (
      <QueryBox>
        <h3>Comments</h3>
        {state.comment}
        <Button value={user.id} onClick={editOnClick} appearance={Appearance.MINIMAL}>
          <Icon name="edit" />
        </Button>
      </QueryBox>
    ) : null;

  const commentForm = userIsAdminLike ? (
    <div>
      <Box>
        <Box>
          <Label>
            Comment:
            <TextArea
              placeholder=""
              mb={2}
              maxLength={255}
              defaultValue={state.comment || ""}
              onChange={commentOnChange}
            />
          </Label>
        </Box>
        <Button
          // isLoading={isSubmitting}
          intent="primary"
          appearance="prominent"
          // disabled={configValidations.errorsExist}
          onClick={saveOnClick}
        >
          Save
        </Button>
        <Button
          style={{
            marginLeft: "15px"
          }}
          // isLoading={isSubmitting}
          onClick={cancelOnClick}
        >
          Cancel
        </Button>
      </Box>
    </div>
  ) : null;

  return <>{state.isEditing ? commentForm : state.comment ? showComment : addCommentButton}</>;
};

export default CommentBox;
