import { Image, StudyId } from "../models";

export interface MoveImageForm {
  readonly studyId: StudyId | null;
  readonly reasonForChange: string | null;
}

export enum ActionTypes {
  OPEN_MOVE_IMAGE_DIALOG = "OPEN_MOVE_IMAGE_DIALOG",
  CLOSE_MOVE_IMAGE_DIALOG = "CLOSE_MOVE_IMAGE_DIALOG",
  SET_MOVE_IMAGE_FORM = "SET_MOVE_IMAGE_FORM",
  MOVE_IMAGE_TO_STUDY = "MOVE_IMAGE_TO_STUDY",
  MOVE_IMAGE_TO_STUDY_SUCCESS = "MOVE_IMAGE_TO_STUDY_SUCCESS",
  MOVE_IMAGE_TO_STUDY_FAILURE = "MOVE_IMAGE_TO_STUDY_FAILURE"
}

export type MoveImageDialogAction =
  | {
      readonly type: ActionTypes.OPEN_MOVE_IMAGE_DIALOG;
      readonly image: Image;
    }
  | { readonly type: ActionTypes.CLOSE_MOVE_IMAGE_DIALOG }
  | { readonly type: ActionTypes.SET_MOVE_IMAGE_FORM; readonly form: MoveImageForm }
  | { readonly type: ActionTypes.MOVE_IMAGE_TO_STUDY }
  | { readonly type: ActionTypes.MOVE_IMAGE_TO_STUDY_SUCCESS }
  | { readonly type: ActionTypes.MOVE_IMAGE_TO_STUDY_FAILURE; readonly errorMsg: string };

export function openMoveImageDialog(image: Image): MoveImageDialogAction {
  return {
    type: ActionTypes.OPEN_MOVE_IMAGE_DIALOG,
    image
  };
}

export function closeMoveImageDialog(): MoveImageDialogAction {
  return {
    type: ActionTypes.CLOSE_MOVE_IMAGE_DIALOG
  };
}

export function setMoveImageForm(form: MoveImageForm): MoveImageDialogAction {
  return {
    type: ActionTypes.SET_MOVE_IMAGE_FORM,
    form
  };
}

export function moveImage(): MoveImageDialogAction {
  return {
    type: ActionTypes.MOVE_IMAGE_TO_STUDY
  };
}

export function moveImageSuccess(): MoveImageDialogAction {
  return {
    type: ActionTypes.MOVE_IMAGE_TO_STUDY_SUCCESS
  };
}

export function moveImageFailure(errorMsg: string): MoveImageDialogAction {
  return {
    type: ActionTypes.MOVE_IMAGE_TO_STUDY_FAILURE,
    errorMsg
  };
}
