import {
  Appearance,
  Box,
  Button,
  Callout,
  Heading,
  Icon,
  Label,
  Radio,
  Table,
  Text
} from "@blasterjs/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Avatar from "./Avatar";

import { openEditCaseDialog } from "../actions/caseDialog";
import { transitionCaseStatus } from "../actions/caseViewer";
import { toggleSidebarExpanded } from "../actions/imageViewer";
import CaseDialog from "./CaseDialog";
import Content from "./Content";
import ConfirmationDialog from "./ConfirmationDialog";
import { CollapseIcon } from "./Expandable";
import ImageInfo from "./ImageInfo";
import InfoSidebar from "./InfoSidebar";
import AnnotationNestPanel from "./AnnotationNest";
import Timestamp from "./Timestamp";

import { cancelImageAnnotation } from "../actions/annotation";
import { selectImage } from "../actions/imageViewer";
import {
  CaseStatus,
  CaseWithImages,
  formatCaseStatus,
  Image,
  ImageAndQueries,
  ImageAndQuery,
  QueryObjectType,
  QueryResolutionType,
  QueryStatus,
  QueryWindowLocation,
  User,
  userIsAdminOrIsc,
  UserRole
} from "../models";
import { State } from "../reducers";
import store from "../store";
import { Resource } from "../types";
import QueryWindow from "./QueryWindow";
import CommentBox, { CommentBoxType } from "./CommentBox";

const RegularImage = styled.li`
  border-left: 2px solid transparent;
  padding-left: 3px;
  margin-left: -1rem;
  padding-top: 0.3rem;
  &:not(:last-child) {
    padding-bottom: 3px;
  }
`;

// Workaround since upgrading to styled-components v5 causes unwanted style changes and isn't being
// purused for now. See https://github.com/microsoft/TypeScript/issues/37597
const ResolvedImageQueryImage = styled(RegularImage as any)`
  border-color: gray;
`;

// Workaround since upgrading to styled-components v5 causes unwanted style changes and isn't being
// purused for now. See https://github.com/microsoft/TypeScript/issues/37597
const UnresolvedImageQueryImage = styled(RegularImage as any)`
  border-color: #dc3831;
`;

const ImageHiddenFromReaderLabel = styled.label`
  color: gray;
`;

const CompactTable = styled(Table)`
  width: 100%;
  td:first-child {
    font-weight: 600;
    padding-right: 1rem;
    width: 12rem;
  }
`;

const QueryChain = styled.ul`
  li:last-child {
    margin-bottom: 3px;
  }
`;

interface StateProps {
  readonly user: Resource<User>;
}

interface Props {
  readonly histoCase: CaseWithImages;
  readonly selectedImage: ImageAndQuery | null;
  readonly userCanDeleteAnnotations: boolean;
}

function imageQueriesWereClosed(imageQueries: ImageAndQueries): boolean {
  return imageQueries
    .map(imageAndQuery => imageAndQuery.lastLabQuery)
    .some(query => query && query.queryStatus == QueryStatus.Closed);
}

function imageQueriesNeedResolution(imageQueries: ImageAndQueries): boolean {
  return imageQueries
    .map(imageAndQuery => imageAndQuery.lastLabQuery)
    .some(query => query && query.queryStatus == QueryStatus.Open);
}

// function imageQueriesWereResolved(imageQueries: ImageAndQueries): boolean {
//   return imageQueries.map(imageAndQuery => imageAndQuery.query).some(isResolvedQuery);
// }

// function imageQueriesNeedResolution(imageQueries: ImageAndQueries): boolean {
//   return imageQueries.map(imageAndQuery => imageAndQuery.query).some(isUnresolvedQuery);
// }

function findSelectedQueryChain(
  imageQueries: ReadonlyArray<ImageAndQueries>,
  selectedImage: ImageAndQuery
): ImageAndQueries | undefined {
  return imageQueries.filter(imageQuery =>
    imageQuery.map(q => q.image.id).includes(selectedImage.image.id)
  )[0];
}

function displayShowToReaderToggle(
  imageQueries: ReadonlyArray<ImageAndQueries>,
  selectedImage: ImageAndQuery
): boolean {
  const selectedQueryChain = findSelectedQueryChain(imageQueries, selectedImage);
  const selectedImageQueryIsUnresolveable =
    (selectedImage.lastLabQuery &&
      selectedImage.lastLabQuery.resolution &&
      selectedImage.lastLabQuery.resolution == QueryResolutionType.Unresolvable) ||
    false;
  const isInChain = selectedQueryChain ? selectedQueryChain.length > 1 : false;
  return isInChain || selectedImageQueryIsUnresolveable;
}

const onSelectImage = (image: Image) => () => {
  // NOTE: It's important to dispatch this action to cancel any in-progress annotation to ensure the
  // annotation code cleans up after itself properly.
  store.dispatch(cancelImageAnnotation());
  store.dispatch(selectImage(image.id));
};

const InfoSidebarCase = ({
  histoCase,
  selectedImage,
  user,
  userCanDeleteAnnotations
}: Props & StateProps) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const onTransition = () => setConfirmationDialogOpen(true);
  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    store.dispatch(transitionCaseStatus(histoCase.caseWithStatus));
  };
  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };
  const confirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Case Status Change"
      message="Are you sure you want to change this case's status?"
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
  const button =
    "resource" in user ? (
      userIsAdminOrIsc(user.resource.role) &&
      histoCase.caseWithStatus.status === CaseStatus.PendingQC ? (
        <Button iconAfter="check" block={true} onClick={onTransition}>
          Pass QC
        </Button>
      ) : user.resource.role === UserRole.Reader &&
        histoCase.caseWithStatus.status === CaseStatus.Processed ? (
        <Button appearance="prominent" iconAfter="check" block={true} onClick={onTransition}>
          Mark as Scored
        </Button>
      ) : "errorMessage" in histoCase ? (
        <Callout intent="danger">
          <Text as="p">Unable to transition case status</Text>
        </Callout>
      ) : null
    ) : null;
  const editIcon =
    "resource" in user && userIsAdminOrIsc(user.resource.role) ? (
      <Button
        value={histoCase.caseWithStatus.id}
        onClick={() =>
          store.dispatch(
            openEditCaseDialog(histoCase.caseWithStatus.id, histoCase.caseWithStatus.studyId, true)
          )
        }
        appearance={Appearance.MINIMAL}
        title="Edit Case"
      >
        <Icon name="edit" />
      </Button>
    ) : null;
  const content =
    "resource" in user ? (
      <>
        <Box display="flex" width="100%">
          <Heading textAlign="left" as="h6" mb={1}>
            Case
          </Heading>
          <Box ml={"auto"}>
            {editIcon}
            <CollapseIcon onToggle={() => store.dispatch(toggleSidebarExpanded())} />
          </Box>
        </Box>
        <CompactTable fontSize={1} compact={true}>
          <tbody>
            <tr>
              <td>Procedure&nbsp;ID</td>
              <td>{histoCase.caseWithStatus.procId}</td>
            </tr>
            {"subjectId" in histoCase.caseWithStatus && user.resource.role !== UserRole.Reader ? (
              <tr>
                <td>Subject&nbsp;ID</td>
                <td>{histoCase.caseWithStatus.subjectId}</td>
              </tr>
            ) : null}
            {"visitId" in histoCase.caseWithStatus && user.resource.role !== UserRole.Reader ? (
              <tr>
                <td>Visit&nbsp;ID</td>
                <td>{histoCase.caseWithStatus.visitId}</td>
              </tr>
            ) : null}
            <tr>
              <td>Created</td>
              <td>
                <Timestamp date={histoCase.caseWithStatus.createdAt} />
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{formatCaseStatus(histoCase.caseWithStatus.status)}</td>
            </tr>
            {"readers" in histoCase ? (
              <tr>
                <td>Assigned</td>
                <td>
                  {histoCase.readers.length ? (
                    <span>
                      {histoCase.readers.slice(0, 5).map(readerStats => (
                        <Box key={readerStats.reader.id} ml="1px" mr="1px" display="inline">
                          <Avatar isSmall={true} user={readerStats.reader} />
                        </Box>
                      ))}
                      {histoCase.readers.length > 5 ? "…" : null}
                    </span>
                  ) : (
                    "—"
                  )}
                </td>
              </tr>
            ) : null}
            {"resource" in user && selectedImage !== null ? (
              <tr>
                <td colSpan={3}>
                  <QueryWindow
                    studyId={histoCase.caseWithStatus.studyId}
                    caseId={histoCase.caseWithStatus.id}
                    queryObjectType={QueryObjectType.Case}
                    queryRecord={
                      "lastInternalQuery" in histoCase.caseWithStatus
                        ? histoCase.caseWithStatus.lastInternalQuery
                        : null
                    }
                    imageAndQuery={selectedImage}
                    displayShowToReader={false}
                    user={user.resource}
                    queryWindowLocation={QueryWindowLocation.Case}
                  />
                  <CommentBox
                    type={CommentBoxType.Case}
                    caseId={histoCase.caseWithStatus.id}
                    imageId={null}
                    commentText={
                      "lastCaseComment" in histoCase.caseWithStatus
                        ? histoCase.caseWithStatus.lastCaseComment
                        : ""
                    }
                    user={user.resource}
                  />
                </td>
              </tr>
            ) : null}
            {user.resource.role === UserRole.Reader &&
            "images" in histoCase &&
            histoCase.images.length ? (
              <>
                <tr>
                  <td>Images</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      {histoCase.images.map(image => {
                        const isSelected =
                          selectedImage !== null && image.id === selectedImage.image.id;
                        return (
                          <li key={image.id}>
                            <Label style={{ display: "flex", cursor: "pointer", marginTop: "5px" }}>
                              <Radio
                                name="caseImages"
                                value={image.id}
                                key={image.id}
                                checked={isSelected}
                                onChange={onSelectImage(image)}
                                style={{
                                  pointerEvents: "none",
                                  marginTop: "-3px",
                                  marginRight: "2px",
                                  flexShrink: 0
                                }}
                              />
                              <Text
                                fontSize={1}
                                lineHeight="1.3"
                                flex="1"
                                style={{ pointerEvents: "none", wordBreak: "break-all" }}
                              >
                                {image.name}
                              </Text>
                            </Label>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </>
            ) : "imageQueries" in histoCase && histoCase.imageQueries.length ? (
              <>
                <tr>
                  <td>Images</td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "10px" }} colSpan={2}>
                    <ul style={{ marginTop: "5px" }}>
                      {histoCase.imageQueries.map((imageQueries, index) => {
                        const queryChain = imageQueries.map(imageAndQuery => {
                          const isSelected =
                            selectedImage !== null &&
                            imageAndQuery.image.id === selectedImage.image.id;
                          const label = imageAndQuery.isShownToReader ? (
                            <label>{imageAndQuery.image.name}</label>
                          ) : (
                            <ImageHiddenFromReaderLabel>
                              {imageAndQuery.image.name}
                            </ImageHiddenFromReaderLabel>
                          );
                          const content = (
                            <>
                              <Label style={{ display: "flex", cursor: "pointer" }}>
                                <Radio
                                  name="caseImages"
                                  value={imageAndQuery.image.id}
                                  key={imageAndQuery.image.id}
                                  checked={isSelected}
                                  onChange={onSelectImage(imageAndQuery.image)}
                                  style={{
                                    pointerEvents: "none",
                                    marginTop: "-3px",
                                    marginRight: "2px",
                                    flexShrink: 0
                                  }}
                                />
                                <Text
                                  fontSize={1}
                                  lineHeight="1.3"
                                  flex="1"
                                  style={{ pointerEvents: "none", wordBreak: "break-all" }}
                                >
                                  {label}
                                </Text>
                              </Label>
                            </>
                          );
                          return imageQueriesNeedResolution(imageQueries) ? (
                            <UnresolvedImageQueryImage key={imageAndQuery.image.id}>
                              {content}
                            </UnresolvedImageQueryImage>
                          ) : imageQueriesWereClosed(imageQueries) ? (
                            <ResolvedImageQueryImage key={imageAndQuery.image.id}>
                              {content}
                            </ResolvedImageQueryImage>
                          ) : (
                            <RegularImage key={imageAndQuery.image.id}>{content}</RegularImage>
                          );
                        });
                        return (
                          <li key={index}>
                            <QueryChain key={index}>{queryChain}</QueryChain>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </CompactTable>
        <Box mt={2} mb={3}>
          {button}
        </Box>
        <Box mt={1} mb={2}>
          <hr />
        </Box>
        {"resource" in user && selectedImage !== null ? (
          <>
            <ImageInfo image={selectedImage.image} />
            <QueryWindow
              studyId={histoCase.caseWithStatus.studyId}
              caseId={histoCase.caseWithStatus.id}
              queryObjectType={QueryObjectType.Image}
              queryRecord={selectedImage.lastLabQuery}
              imageAndQuery={selectedImage}
              displayShowToReader={
                "imageQueries" in histoCase
                  ? displayShowToReaderToggle(histoCase.imageQueries, selectedImage)
                  : false
              }
              user={user.resource}
              queryWindowLocation={QueryWindowLocation.Case}
            />
            <CommentBox
              type={CommentBoxType.Image}
              caseId={histoCase.caseWithStatus.id}
              imageId={selectedImage.image.id}
              commentText={selectedImage.image.lastImageComment}
              user={user.resource}
            />
            <AnnotationNestPanel
              userCanDeleteAnnotations={userCanDeleteAnnotations}
              imageId={selectedImage.image.id}
            />
          </>
        ) : null}
        {confirmTransitionDialog}
      </>
    ) : null;
  return (
    <>
      <InfoSidebar>
        <Content isLoading={"isPending" in user || "isPending" in histoCase}>{content}</Content>
      </InfoSidebar>
      <CaseDialog />
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth
  };
}

export default connect(mapStateToProps)(InfoSidebarCase);
