import { CancelTokenSource } from "axios";
import { curry } from "ramda";
import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action, DispatchFunction } from "../actions";
import { imagesFetch } from "../actions/images";
import { refreshImage } from "../actions/imageViewer";
import {
  ActionTypes,
  signedURLsCreateFailure,
  signedURLsCreateSuccess,
  uploadImages,
  uploadImagesFailure,
  uploadImagesNotify,
  uploadImagesNotifyFailure,
  uploadImagesNotifySuccess,
  uploadImagesProgress,
  uploadImagesSuccess
} from "../actions/uploadDialog";

import {
  createSignedURLs,
  generateCancelTokenSource,
  sendNewFilesNotification,
  uploadFiles
} from "../api";
import { Image, SignedURLs, StudyId } from "../models";
import { WriteResource } from "../types";

interface FileUploadProgress {
  readonly file: File;
  readonly progress: number;
}

interface AllFileUploadProgress {
  // NOTE: key is the filename
  readonly [key: string]: FileUploadProgress;
}

interface InProgressUpload {
  readonly uploadProgress: AllFileUploadProgress;
  readonly cancelTokenSource: CancelTokenSource;
}

export interface UploadDialogState {
  readonly isOpen: boolean;
  readonly acceptButtonDisabled: boolean;
  readonly fileSignedURLs: WriteResource<FileList | null, SignedURLs>;
  readonly studyId: StudyId | null;
  readonly imageToReplace: Image | null;
  readonly refreshImageOnSuccessfulReplace: boolean;
  readonly refreshCaseOnSuccessfulReplace: boolean;
  readonly currentUpload: InProgressUpload | null;
}

export const initialState: UploadDialogState = {
  isOpen: false,
  acceptButtonDisabled: false,
  fileSignedURLs: {
    data: null,
    resource: []
  },
  studyId: null,
  imageToReplace: null,
  refreshImageOnSuccessfulReplace: false,
  refreshCaseOnSuccessfulReplace: false,
  currentUpload: null
};

export const uploadImagesProgressDispatchFile = (dispatch: DispatchFunction, file: File) => {
  return curry(dispatchUploadImagesProgress)(dispatch, file);
};
export const dispatchUploadImagesProgress = (
  dispatch: DispatchFunction,
  file: File,
  progressEvent: ProgressEvent
) => {
  dispatch(uploadImagesProgress(file, progressEvent.loaded));
};

export const uploadDialogReducer: LoopReducer<UploadDialogState, Action> = (
  state: UploadDialogState = initialState,
  action: Action
): UploadDialogState | Loop<UploadDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_UPLOAD_DIALOG:
      return {
        ...initialState,
        isOpen: true,
        studyId: action.studyId,
        imageToReplace: action.imageToReplace || null,
        refreshImageOnSuccessfulReplace: action.refreshImageOnSuccessfulReplace || false,
        refreshCaseOnSuccessfulReplace: action.refreshCaseOnSuccessfulReplace || false
      };
    case ActionTypes.CLOSE_UPLOAD_DIALOG:
      return {
        ...initialState,
        isOpen: false
      };
    case ActionTypes.CHANGE_FILES:
      return {
        ...state,
        fileSignedURLs: {
          data: action.files
        }
      };
    case ActionTypes.SIGNED_URLS_CREATE:
      return "data" in state.fileSignedURLs &&
        state.fileSignedURLs.data !== null &&
        state.studyId !== null
        ? loop(
            {
              ...state,
              fileSignedURLs: {
                data: state.fileSignedURLs.data,
                isPending: true
              }
            },
            Cmd.run(createSignedURLs, {
              successActionCreator: signedURLsCreateSuccess,
              failActionCreator: signedURLsCreateFailure,
              args: [state.studyId, state.fileSignedURLs.data] as Parameters<
                typeof createSignedURLs
              >
            })
          )
        : state;
    case ActionTypes.SIGNED_URLS_CREATE_SUCCESS:
      return loop(
        {
          ...state,
          fileSignedURLs: {
            data: state.fileSignedURLs.data,
            resource: action.urls
          }
        },
        Cmd.action(uploadImages(generateCancelTokenSource()))
      );
    case ActionTypes.SIGNED_URLS_CREATE_FAILURE:
      return {
        ...state,
        fileSignedURLs: {
          data: state.fileSignedURLs.data,
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.UPLOAD_IMAGES:
      return "data" in state.fileSignedURLs &&
        state.fileSignedURLs.data !== null &&
        "resource" in state.fileSignedURLs &&
        state.studyId !== null
        ? loop(
            {
              ...state,
              fileSignedURLs: {
                data: state.fileSignedURLs.data,
                resource: state.fileSignedURLs.resource,
                isPending: true
              },
              currentUpload: {
                uploadProgress: Array.from(state.fileSignedURLs.data).reduce(
                  (acc: AllFileUploadProgress, file: File) => ({
                    ...acc,
                    [file.name]: {
                      file,
                      progress: 0
                    }
                  }),
                  {}
                ),
                cancelTokenSource: action.cancelTokenSource
              }
            },
            Cmd.run(uploadFiles, {
              successActionCreator: uploadImagesSuccess,
              failActionCreator: uploadImagesFailure,
              args: [
                // NOTE: The type of Cmd.dispatch is `symbol`
                (Cmd.dispatch as unknown) as DispatchFunction,
                state.studyId,
                state.fileSignedURLs.data,
                state.fileSignedURLs.resource,
                uploadImagesProgressDispatchFile,
                state.imageToReplace,
                action.cancelTokenSource
              ] as Parameters<typeof uploadFiles>
            })
          )
        : state;
    case ActionTypes.UPLOAD_IMAGES_SUCCESS:
      return loop(state, Cmd.action(uploadImagesNotify()));
    case ActionTypes.UPLOAD_IMAGES_FAILURE:
      return loop(
        {
          ...state,
          fileSignedURLs: {
            data: state.fileSignedURLs.data,
            errorMessage: action.errorMsg
          }
        },
        Cmd.action(uploadImagesNotify())
      );
    case ActionTypes.UPLOAD_IMAGES_PROGRESS:
      return state.currentUpload !== null
        ? {
            ...state,
            currentUpload: {
              uploadProgress: {
                ...state.currentUpload.uploadProgress,
                [action.file.name]: {
                  file: action.file,
                  progress: action.progress
                }
              },
              cancelTokenSource: state.currentUpload.cancelTokenSource
            }
          }
        : (state as never);
    case ActionTypes.UPLOAD_IMAGES_NOTIFY:
      return state.studyId && state.fileSignedURLs.data
        ? loop(
            state,
            Cmd.run(sendNewFilesNotification, {
              successActionCreator: uploadImagesNotifySuccess,
              failActionCreator: uploadImagesNotifyFailure,
              args: [
                state.studyId,
                state.fileSignedURLs.data.length,
                "errorMessage" in state.fileSignedURLs
              ] as Parameters<typeof sendNewFilesNotification>
            })
          )
        : state;
    case ActionTypes.UPLOAD_IMAGES_NOTIFY_SUCCESS:
      return state.studyId
        ? loop(
            {
              ...state,
              isOpen: "errorMessage" in state.fileSignedURLs
            },
            Cmd.action(
              state.imageToReplace && state.refreshImageOnSuccessfulReplace
                ? refreshImage(state.imageToReplace.id, state.refreshCaseOnSuccessfulReplace)
                : imagesFetch(state.studyId)
            )
          )
        : state;
    case ActionTypes.UPLOAD_IMAGES_NOTIFY_FAILURE:
      return state.studyId
        ? loop(
            {
              ...state,
              fileSignedURLs: {
                data: state.fileSignedURLs.data,
                errorMessage:
                  "errorMessage" in state.fileSignedURLs
                    ? state.fileSignedURLs.errorMessage
                    : "Warning: failed to send email notification of upload(s)"
              }
            },
            Cmd.action(imagesFetch(state.studyId))
          )
        : state;
    default:
      return state;
  }
};

export default uploadDialogReducer;
