import { History } from "history";
import Cookies from "js-cookie";
import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { Action } from "./actions";

/**
 * Log the action and new state to the console.
 */
export const logger = () => {
  const loggerMiddleware: Middleware = ({ getState }: MiddlewareAPI) => (
    next: Dispatch
  ) => action => {
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    // eslint-disable-next-line no-console
    console.log(getState());

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };

  return loggerMiddleware;
};

/**
 * Set cookie for `sso-origin` based on referrer.
 *
 * This is used by Alimentiv SSO to redirect back to that page automatically,
 * skipping the RCT hub.
 */
export const setOriginCookie = () => {
  const setCookieMiddleware: Middleware = () => (next: Dispatch) => action => {
    Cookies.set("sso-origin", window.location.href, {
      // Set cookie domain.
      // NOTE: This is needed because the subdomains for the Lucidity app and
      // SSO are different.
      // For example, in staging:
      // histo.tst-robartsinc.com VS sso.tst-robartsinc.com
      // In this case, the cookie domain needs to
      // be set to tst-robartsinc.com to persist across subdomains.
      domain: new URL(window.location.href).hostname.split(".").slice(-2).join(".")
    });
    return next(action);
  };

  return setCookieMiddleware;
};

export const redirect = (history: History) => {
  const redirectMiddleware: Middleware = () => (next: Dispatch) => (action: Action) => {
    if (action.type === "REDIRECT") {
      history.replace(action.path);
      window.location.reload();
    }
    return next(action);
  };

  return redirectMiddleware;
};
