import { Callout, Intent } from "@blasterjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { caseFetch } from "../actions/caseViewer";
import { CaseWithImages, Config, ImageWithAnnotations, User, UserRole } from "../models";
import { State } from "../reducers";
import store from "../store";
import { Resource } from "../types";

import ImageViewer from "../components/ImageViewer";
import InfoSidebarCase from "../components/InfoSidebarCase";
import TopBarCase from "../components/TopBarCase";
import Content from "../components/Content";
import { studyFetchRequest } from "../actions/studyConfiguration";

interface Props {
  readonly histoCase: Resource<CaseWithImages> | null;
  readonly selectedImage: Resource<ImageWithAnnotations> | null;
  readonly config: Resource<Config>;
  readonly user: Resource<User>;
}

const Case = ({ histoCase, selectedImage, config, user }: Props) => {
  const params = useParams();
  const id = params.id || "no-id";
  const studyId = params.studyId || "no-study-id";
  useEffect(() => {
    store.dispatch(caseFetch(id));
    store.dispatch(studyFetchRequest(studyId));
  }, [id]);
  const isUserAdminOrReader =
    "resource" in user && user.resource.role
      ? [UserRole.Admin, UserRole.Reader].includes(user.resource.role)
      : false;

  const userCanDeleteAnnotations = isUserAdminOrReader;

  const errorText =
    "errorMessage" in config
      ? config.errorMessage
      : histoCase && "errorMessage" in histoCase
      ? histoCase.errorMessage
      : selectedImage && "errorMessage" in selectedImage
      ? selectedImage.errorMessage
      : null;
  const content =
    "resource" in config &&
    histoCase !== null &&
    "resource" in histoCase &&
    (selectedImage === null || "resource" in selectedImage) ? (
      <ImageViewer
        imageWithAnnotations={selectedImage === null ? selectedImage : selectedImage.resource}
        tileServerLocation={config.resource.tileServerLocation}
        topBar={<TopBarCase histoCase={histoCase.resource.caseWithStatus} />}
        sidebar={
          <InfoSidebarCase
            histoCase={histoCase.resource}
            selectedImage={selectedImage === null ? null : selectedImage.resource.imageAndQuery}
            userCanDeleteAnnotations={userCanDeleteAnnotations}
          />
        }
      />
    ) : errorText ? (
      <Callout intent={Intent.DANGER}>{errorText}</Callout>
    ) : null;
  return (
    <Content
      isLoading={
        (histoCase !== null && "isPending" in histoCase) ||
        "isPending" in config ||
        (selectedImage !== null && "isPending" in selectedImage)
      }
    >
      {content}
    </Content>
  );
};

function mapStateToProps(state: State): Props {
  return {
    histoCase: state.caseImageViewer.histoCase,
    selectedImage: state.caseImageViewer.imageWithAnnotations,
    config: state.config,
    user: state.auth
  };
}

export default connect(mapStateToProps)(Case);
