import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  UserFormI,
  userUpdateFailure,
  userUpdateSuccess
} from "../actions/userDialog";
import { usersFetch } from "../actions/users";

import { updateUser } from "../api";
import { User } from "../models";

export interface UserDialogState {
  readonly isOpen: boolean;
  readonly user: User | undefined;
  readonly userForm: UserFormI;
  readonly acceptButtonDisabled: boolean;
  readonly returnedUser: User | null;
  readonly errorMessage: string | null;
}

const initialState: UserDialogState = {
  isOpen: false,
  user: undefined,
  userForm: {
    firstName: "",
    lastName: "",
    email: "",
    role: null
  },
  acceptButtonDisabled: true,
  returnedUser: null,
  errorMessage: null
};

function enableAcceptButton(uf: UserFormI, user: User | undefined): boolean {
  return (
    user !== undefined &&
    (uf.firstName !== user.firstName || uf.lastName !== user.lastName || uf.role !== user.role)
  );
}

export const userDialogReducer: LoopReducer<UserDialogState, Action> = (
  state: UserDialogState = initialState,
  action: Action
): UserDialogState | Loop<UserDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.TOGGLE_DIALOG:
      return action.isOpen && action.user
        ? {
            ...state,
            isOpen: true,
            user: action.user,
            userForm: {
              firstName: action.user.firstName ? action.user.firstName : "",
              lastName: action.user.lastName ? action.user.lastName : "",
              email: action.user.username,
              role: action.user.role
            },
            acceptButtonDisabled: true
          }
        : {
            ...state,
            isOpen: false,
            user: undefined,
            userForm: {
              firstName: "",
              lastName: "",
              email: "",
              role: null
            },
            acceptButtonDisabled: true
          };
    case ActionTypes.CHANGE_USER_FORM:
      return {
        ...state,
        userForm: action.userForm,
        acceptButtonDisabled: !enableAcceptButton(action.userForm, state.user)
      };
    case ActionTypes.USER_UPDATE:
      return state.user
        ? loop(
            {
              ...state
            },
            Cmd.run(updateUser, {
              successActionCreator: userUpdateSuccess,
              failActionCreator: userUpdateFailure,
              args: [
                state.user.id,
                state.userForm.firstName,
                state.userForm.lastName,
                state.userForm.role
              ]
            })
          )
        : loop(
            {
              ...state
            },
            Cmd.action(userUpdateFailure("Invalid state."))
          );
    case ActionTypes.USER_UPDATE_SUCCESS:
      return loop(
        {
          ...state,
          returnedUser: action.user,
          errorMessage: null,
          isOpen: false,
          userForm: {
            firstName: "",
            lastName: "",
            email: "",
            role: null
          },
          acceptButtonDisabled: true
        },
        Cmd.action(usersFetch())
      );
    case ActionTypes.USER_UPDATE_FAILURE:
      return {
        ...state,
        returnedUser: null,
        errorMessage: action.errorMsg
      };
    default:
      return state;
  }
};
