import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  fetchMetadataImageFailure,
  fetchMetadataImageSuccess
} from "../actions/annotationNest";

import { fetchMetadataImage } from "../api";
import { MetadataImage, MetadataImageType, Fragment } from "../models";
import { Resource } from "../types";

export type AnnotationNestStateAbstract = {
  readonly [key in MetadataImageType]: Resource<MetadataImage<MetadataImageType>>;
};

export interface AnnotationNestState extends AnnotationNestStateAbstract {
  readonly label: Resource<MetadataImage<"label">>;
  readonly macro: Resource<MetadataImage<"macro">>;
  readonly isAnnotationNestPanelExpanded: boolean;
  readonly topLevelAnnotations: ReadonlyArray<Fragment>;
}

export const initialState: AnnotationNestState = {
  label: {
    isPending: false
  },
  macro: {
    isPending: false
  },
  isAnnotationNestPanelExpanded: true,
  topLevelAnnotations: []
};

export const annotationNestReducer: LoopReducer<AnnotationNestState, Action> = (
  state: AnnotationNestState = initialState,
  action: Action
): AnnotationNestState | Loop<AnnotationNestState, Action> => {
  switch (action.type) {
    case ActionTypes.FETCH_METADATA_IMAGE:
      return loop(
        {
          ...state,
          [action.metadataType]: {
            metadataType: action.metadataType,
            imageData: {
              isPending: true
            }
          }
        },
        Cmd.run(fetchMetadataImage, {
          successActionCreator: fetchMetadataImageSuccess,
          failActionCreator: fetchMetadataImageFailure(action.metadataType),
          args: [action.uri, action.metadataType] as Parameters<typeof fetchMetadataImage>
        })
      );
    case ActionTypes.FETCH_METADATA_IMAGE_SUCCESS:
      return {
        ...state,
        [action.metadataImage.metadataType]: {
          resource: action.metadataImage
        }
      };
    case ActionTypes.FETCH_METADATA_IMAGE_FAILURE:
      return {
        ...state,
        [action.metadataType]: {
          errorMessage: action.errorMessage
        }
      };
    case ActionTypes.TOGGLE_ZOOM:
      return {
        ...state
        //zoomActive: !state.zoomActive
      };
    case ActionTypes.TOGGLE_ANNOTATION_NEST_EXPANDED:
      return {
        ...state,
        isAnnotationNestPanelExpanded: !state.isAnnotationNestPanelExpanded
      };
    default:
      return state;
  }
};

export default annotationNestReducer;
