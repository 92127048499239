import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { ActionTypes, requestCsvFailure, requestCsvSuccess } from "../actions/auditTrail";
import { downloadAuditCsv } from "../api";
import { ReportStudyData, ReportUserData } from "../models";

export interface AuditTrailState {
  readonly studyData?: ReportStudyData;
  readonly userData?: ReportUserData;
  readonly requestingCsv: boolean;
  readonly errorMessage?: string;
}

export const initialState: AuditTrailState = {
  studyData: undefined,
  userData: undefined,
  requestingCsv: false,
  errorMessage: undefined
};

export const auditTrailReducer: LoopReducer<AuditTrailState, Action> = (
  state: AuditTrailState = initialState,
  action: Action
): AuditTrailState | Loop<AuditTrailState, Action> => {
  switch (action.type) {
    case ActionTypes.SET_AUDIT_TRAIL_STUDY_DATA:
      return {
        ...state,
        studyData: action.studyData
      };
    case ActionTypes.SET_AUDIT_TRAIL_USER_DATA:
      return {
        ...state,
        userData: action.userData
      };
    case ActionTypes.REQUEST_CSV:
      return loop(
        {
          ...state,
          requestingCsv: true,
          errorMessage: undefined
        },
        Cmd.run(downloadAuditCsv, {
          successActionCreator: requestCsvSuccess,
          failActionCreator: requestCsvFailure,
          args: [state.studyData, state.userData] as Parameters<typeof downloadAuditCsv>
        })
      );
    case ActionTypes.REQUEST_CSV_SUCCESS:
      return {
        ...state,
        requestingCsv: false
      };
    case ActionTypes.REQUEST_CSV_FAILURE:
      return {
        ...state,
        requestingCsv: false,
        errorMessage: action.errorMsg
      };
    default:
      return state;
  }
};

export default auditTrailReducer;
