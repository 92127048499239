import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { configFetch } from "./actions/config";
import App from "./pages/App";
import store from "./store";

import "@fontsource/inter/variable-full.css";
import "./css/index.css";

store.dispatch(configFetch());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
