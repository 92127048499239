import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  ReportType,
  requestReportCsvFailure,
  requestReportCsvSuccess
} from "../actions/reports";
import { downloadReportCsv } from "../api";
import { DateFilter, ReportStudyData } from "../models";
import { WriteResource } from "../types";

export type ReportState = {
  [reportType in ReportType]: WriteResource<
    {
      readonly studyData?: ReportStudyData;
      readonly dateFilter?: DateFilter;
    } | null,
    void
  >;
};

export const initialState: ReportState = {
  "image-details": { data: null },
  "hpf-annotations": { data: null }
};

export const reportsReducer: LoopReducer<ReportState, Action> = (
  state: ReportState = initialState,
  action: Action
): ReportState | Loop<ReportState, Action> => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_STUDY_DATA:
      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          data: {
            ...state[action.reportType].data,
            studyData: action.studyData
          }
        }
      };
    case ActionTypes.SET_REPORT_DATE_FILTER:
      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          data: {
            ...state[action.reportType].data,
            dateFilter: action.dateFilter
          }
        }
      };
    case ActionTypes.REQUEST_REPORT_CSV:
      return loop(
        {
          ...state,
          [action.reportType]: {
            ...state[action.reportType],
            isPending: true
          }
        },
        Cmd.run(downloadReportCsv, {
          successActionCreator: requestReportCsvSuccess(action.reportType),
          failActionCreator: requestReportCsvFailure(action.reportType),
          args: [
            action.reportType,
            state[action.reportType].data?.studyData,
            state[action.reportType].data?.dateFilter
          ] as Parameters<typeof downloadReportCsv>
        })
      );
    case ActionTypes.REQUEST_REPORT_CSV_SUCCESS:
      return state[action.reportType]
        ? {
            ...state,
            [action.reportType]: {
              ...state[action.reportType],
              resource: void 0
            }
          }
        : state;
    case ActionTypes.REQUEST_REPORT_CSV_FAILURE:
      return state[action.reportType]
        ? {
            ...state,
            [action.reportType]: {
              ...state[action.reportType],
              errorMessage: action.errorMessage
            }
          }
        : state;
    default:
      return state;
  }
};

export default reportsReducer;
