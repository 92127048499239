import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  ImageFilters,
  imagesFetch,
  imagesFetchFailure,
  imagesFetchSuccess
} from "../actions/images";
import { ActionTypes as StudyActionTypes } from "../actions/studies";

import { fetchImages } from "../api";
import { ImageListViews } from "../models";
import { Resource } from "../types";

export interface ImagesState {
  readonly images: Resource<ImageListViews>;
  readonly filters: ImageFilters;
}

export const initialState: ImagesState = {
  images: {
    isPending: false
  },
  filters: {}
};

const imagesReducer: LoopReducer<ImagesState, Action> = (
  state: ImagesState = initialState,
  action: Action
): ImagesState | Loop<ImagesState, Action> => {
  switch (action.type) {
    case StudyActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      };
    case ActionTypes.SET_IMAGE_FILTERS:
      return loop(
        {
          ...state,
          filters: action.filters
        },
        Cmd.action(imagesFetch(action.studyId))
      );
    case ActionTypes.IMAGES_FETCH:
      return loop(
        {
          ...state,
          images: {
            isPending: true
          }
        },
        Cmd.run(fetchImages, {
          successActionCreator: imagesFetchSuccess,
          failActionCreator: imagesFetchFailure,
          args: [
            action.studyId,
            state.filters.name,
            state.filters.hasQueries,
            state.filters.isUnassigned
          ] as Parameters<typeof fetchImages>
        })
      );
    case ActionTypes.IMAGES_FETCH_SUCCESS:
      return {
        ...state,
        images: {
          resource: action.images
        }
      };
    case ActionTypes.IMAGES_FETCH_FAILURE:
      return {
        ...state,
        images: {
          errorMessage: action.errorMsg
        }
      };
    default:
      return state as never;
  }
};

export default imagesReducer;
