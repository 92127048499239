import parse from "date-fns/parse";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Button, Callout, Heading, Intent, Label, Select, Text } from "@blasterjs/core";
import {
  requestCsv as requestAuditTrailCsv,
  setAuditTrailStudyData,
  setAuditTrailUserData
} from "../actions/auditTrail";
import {
  ReportType,
  requestReportCsv,
  setReportDateFilter,
  setReportStudyData
} from "../actions/reports";
import { studiesFetch } from "../actions/studies";
import { usersFetch } from "../actions/users";
import DebouncedTextInput from "../components/DebouncedTextInput";
import Page, { PageBody, PageHeader, PageHeading } from "../components/Page";
import { DateFilter, StudyListViews, User, userLabel, UserRole, Users } from "../models";
import { AuditTrailState } from "../reducers/auditTrail";
import { ReportState } from "../reducers/reports";
import store from "../store";
import { Resource } from "../types";

interface StateProps {
  readonly user: Resource<User>;
  readonly studies: Resource<StudyListViews>;
  readonly users: Resource<Users>;
  readonly auditTrailState: AuditTrailState;
  readonly reportState: ReportState;
}

type Props = StateProps;

interface ReportConfig {
  readonly key: ReportType;
  readonly label: string;
  readonly global: boolean;
}

const reportKeysLabels: ReadonlyArray<ReportConfig> = [
  {
    key: "image-details",
    label: "Image Details Report",
    global: true
  },
  {
    key: "hpf-annotations",
    label: "HPF Annotations Report",
    global: false
  }
];

const dateFilterLabels: ReadonlyArray<{
  readonly key: keyof DateFilter;
  readonly label: string;
}> = [
  {
    key: "start",
    label: "Start Date"
  },
  {
    key: "end",
    label: "End Date"
  }
];

function parseDateString(dateString: string): Date | "invalid" {
  const parsedDate = new Date(parse(dateString, "dd-MMM-yyyy", new Date()));
  // See also: https://stackoverflow.com/a/1353711/1464098
  const isValidDate = parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  return isValidDate ? parsedDate : "invalid";
}

const Reports = ({ user, studies, users, auditTrailState, reportState }: Props) => {
  const studiesFetched = "resource" in studies;
  const usersFetched = "resource" in users;
  const userIsAdmin = "resource" in user && user.resource.role === UserRole.Admin;
  useEffect(() => {
    !studiesFetched && store.dispatch(studiesFetch());
    // NOTE: Only admins can filter audit trail by user
    !usersFetched && userIsAdmin && store.dispatch(usersFetch());
  }, [studiesFetched, usersFetched, userIsAdmin]);

  const displayErrorMessage = (errorMessage: string | undefined) =>
    errorMessage !== undefined ? (
      <Box width="58em" m={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const emptyOption = <option key="" value="" />;
  const requestingCsv = auditTrailState.requestingCsv || "isPending" in Object.values(reportState);

  const studySelect = (onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void) =>
    "resource" in studies ? (
      <Label>
        Study
        <Select onChange={onChange} disabled={requestingCsv}>
          {emptyOption}
          {studies.resource.map(selectedStudy => {
            return (
              <option
                key={selectedStudy.studyView.study.id}
                value={selectedStudy.studyView.study.id}
              >
                {selectedStudy.studyView.study.name}
              </option>
            );
          })}
        </Select>
      </Label>
    ) : null;

  const selectStudyForReport = (reportType: ReportType) => (
    <Box display="flex" key={reportType}>
      {studySelect((e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.selectedOptions[0];
        selectedOption &&
          store.dispatch(
            setReportStudyData(reportType, {
              id: selectedOption.value,
              name: selectedOption.text
            })
          );
      })}
      {reportType === "hpf-annotations" &&
        dateFilterLabels.map(({ key, label }) => (
          <Label key={key} ml={2}>
            {label}
            <DebouncedTextInput
              placeholder="DD-MMM-YYYY"
              defaultValue=""
              onValueChange={(dateString: string) => {
                store.dispatch(
                  setReportDateFilter(reportType, {
                    ...reportState[reportType].data?.dateFilter,
                    [key]: dateString.trim() === "" ? undefined : parseDateString(dateString)
                  })
                );
              }}
            />
          </Label>
        ))}
    </Box>
  );

  const userSelect = (onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void) =>
    "resource" in users ? (
      <Label>
        User
        <Select onChange={onChange} disabled={requestingCsv}>
          {emptyOption}
          {users.resource.map(user => {
            return (
              <option key={user.id} value={user.id}>
                {userLabel(user)}
              </option>
            );
          })}
        </Select>
      </Label>
    ) : null;

  const downloadAuditTrailButton =
    "resource" in studies &&
    // Readonly users don't fetch all users so that condition isn't required
    ("resource" in users ||
      ("resource" in user && user.resource.role === UserRole.Readonly) ||
      ("resource" in user && user.resource.role === UserRole.ISC)) ? (
      <Button
        iconBefore="document"
        onClick={() => store.dispatch(requestAuditTrailCsv())}
        disabled={
          requestingCsv || (!auditTrailState.studyData?.id && !auditTrailState.userData?.id)
        }
        isLoading={requestingCsv}
        appearance="prominent"
        intent="primary"
      >
        Download CSV
      </Button>
    ) : null;

  const downloadReportButton = (reportType: ReportType, global: boolean) =>
    "resource" in studies ? (
      <Button
        key={reportType}
        iconBefore="document"
        onClick={() => store.dispatch(requestReportCsv(reportType))}
        disabled={
          !(global && userIsAdmin) &&
          (requestingCsv ||
            !reportState[reportType]?.data?.studyData?.id ||
            reportState[reportType]?.data?.dateFilter?.start === "invalid" ||
            reportState[reportType]?.data?.dateFilter?.end === "invalid")
        }
        isLoading={requestingCsv}
        appearance="prominent"
        intent="primary"
      >
        Download Report
      </Button>
    ) : null;

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Reports</PageHeading>
        </PageHeader>
        <PageBody>
          <Box>
            <Heading as="h4">Audit Trail</Heading>
            <Box display="flex">{displayErrorMessage(auditTrailState.errorMessage)}</Box>
            <Box display="flex">
              <Box width="auto" mr={2}>
                {studySelect((e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedOption = e.target.selectedOptions[0];
                  store.dispatch(
                    setAuditTrailStudyData(
                      selectedOption
                        ? {
                            id: selectedOption.value,
                            name: selectedOption.text
                          }
                        : undefined
                    )
                  );
                })}
              </Box>
              {/* Only admin users need extra margin to space out the dropdowns; readonly users don't see the user select dropdown */}
              <Box
                width="auto"
                mr={"resource" in user && user.resource.role === UserRole.Admin ? 2 : 0}
              >
                {userSelect((e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedOption = e.target.selectedOptions[0];
                  store.dispatch(
                    setAuditTrailUserData(
                      selectedOption
                        ? {
                            id: selectedOption.value,
                            name: selectedOption.text
                          }
                        : undefined
                    )
                  );
                })}
              </Box>
              <Box width="5rem" style={{ alignSelf: "flex-end" }}>
                {downloadAuditTrailButton}
              </Box>
            </Box>
          </Box>
          {reportKeysLabels.map(({ key, label, global }) => {
            const report = reportState[key];
            return (
              <Box mt={5} key={key}>
                <Heading as="h4">{label}</Heading>
                <Box display="flex">
                  {displayErrorMessage("errorMessage" in report ? report.errorMessage : undefined)}
                </Box>
                <Box display="flex">
                  <Box width="auto" mr={2}>
                    {selectStudyForReport(key)}
                  </Box>
                  <Box width="5rem" style={{ alignSelf: "flex-end" }}>
                    {downloadReportButton(key, global)}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </PageBody>
      </Box>
    </Page>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth,
    studies: state.studies.studies,
    users: state.users.users,
    auditTrailState: state.auditTrail,
    reportState: state.reports
  };
}

export default connect(mapStateToProps)(Reports);
