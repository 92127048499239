import { Box, Button, Callout, Dialog, Intent, Text } from "@blasterjs/core";
import React from "react";
import { connect } from "react-redux";

import {
  closeDeleteImageDialog,
  imageDelete,
  setDeleteImageForm
} from "../actions/deleteImageDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { State } from "../reducers";
import { DeleteImageDialogState } from "../reducers/deleteImageDialog";
import store from "../store";

interface Props {
  readonly deleteImageDialog: DeleteImageDialogState;
}

const deleteImageDialog = ({ deleteImageDialog }: Props) => {
  const closeDialog = () => {
    store.dispatch(closeDeleteImageDialog());
  };

  const onConfirm = () => {
    store.dispatch(imageDelete());
  };

  const onChangeReasonForChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    deleteImageDialog.isOpen &&
      store.dispatch(
        setDeleteImageForm({
          ...deleteImageDialog.image.data,
          reasonForChange: e.target.value
        })
      );
  };

  const errorText =
    deleteImageDialog.isOpen && "errorMessage" in deleteImageDialog.image ? (
      <Box mb="2">
        <Callout intent={Intent.DANGER}>
          <Text>{deleteImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return deleteImageDialog.isOpen ? (
    <Dialog isOpen={deleteImageDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader title="Archive image" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>Are you sure you want to archive {deleteImageDialog.image.data.image.name}?</Box>
        <Box style={{ marginTop: 12 }}>
          <ReasonForChange
            rkey="deleteImage_reason"
            value={(deleteImageDialog.isOpen && deleteImageDialog.image.data.reasonForChange) || ""}
            onChange={onChangeReasonForChange}
          />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button onClick={onConfirm} appearance="prominent" intent="danger">
            Confirm
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  ) : null;
};

function mapStateToProps(state: State): Props {
  return {
    deleteImageDialog: state.deleteImageDialog
  };
}

export default connect(mapStateToProps)(deleteImageDialog);
