import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Button, Callout, Dialog, Intent, Label, Text, TextInput } from "@blasterjs/core";

import {
  closeImageDialog,
  editImage,
  ImageMetadataForm,
  updateImage
} from "../actions/imageDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { ImageDialogState } from "../reducers/imageDialog";
import store from "../store";

interface Props {
  readonly imageDialog: ImageDialogState;
}

interface EditableMetadataFields {
  readonly key: Extract<keyof ImageMetadataForm, "accessionNumber" | "biopsyLocation">;
  readonly label: string;
}

const editableFields: readonly EditableMetadataFields[] = [
  { key: "accessionNumber", label: "Accession #" },
  { key: "biopsyLocation", label: "Biopsy Location" }
];

const ImageDialog = ({ imageDialog }: Props) => {
  const updateField = <
    Key extends keyof ImageMetadataForm,
    PartialUpdate extends Partial<ImageMetadataForm[Key]>
  >(
    key: Key,
    value: PartialUpdate
  ) => {
    imageDialog.isOpen &&
      store.dispatch(
        editImage({
          ...imageDialog.image.data,
          [key]: {
            ...imageDialog.image.data[key],
            ...value
          }
        })
      );
  };
  const updateFieldWithValue = (key: keyof ImageMetadataForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateField(key, { value: e.currentTarget.value });
  const updateFieldWithReasonForChange = (key: keyof ImageMetadataForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateField(key, { reasonForChange: e.currentTarget.value });
  const closeDialog = () => {
    store.dispatch(closeImageDialog());
  };
  const onSave = () => {
    store.dispatch(updateImage());
  };
  const errorText =
    "image" in imageDialog && "errorMessage" in imageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{imageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return imageDialog.isOpen ? (
    <Dialog
      isOpen={
        // Avoid flicker by waiting to open dialog until image has loaded
        imageDialog.isOpen && !("isPending" in imageDialog.image)
      }
      onRequestClose={closeDialog}
      appElementId="root"
    >
      <DialogHeader title="Edit Image" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>
          <Label>
            Name
            <TextInput
              placeholder="Image Name"
              mb={2}
              value={imageDialog.savedImage.name}
              disabled={true}
            />
          </Label>
        </Box>
        {editableFields.map(({ key, label }) => (
          <>
            <hr />
            <Box>
              <Label>
                {label}
                <TextInput
                  placeholder={label}
                  mb={2}
                  value={imageDialog.image.data[key].value}
                  onChange={updateFieldWithValue(key)}
                />
              </Label>
            </Box>
            {imageDialog.isOpen &&
            imageDialog.savedImage[key] !== null &&
            imageDialog.savedImage[key] !== imageDialog.image.data[key].value ? (
              <Box>
                <ReasonForChange
                  rkey="imageData_reason"
                  mb={2}
                  value={
                    "reasonForChange" in imageDialog.image.data[key]
                      ? imageDialog.image.data[key].reasonForChange
                      : ""
                  }
                  onChange={updateFieldWithReasonForChange(key)}
                />
              </Box>
            ) : null}
          </>
        ))}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            appearance="prominent"
            intent="primary"
            onClick={onSave}
            isLoading={"isSaving" in imageDialog.image}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  ) : null;
};

function mapStateToProps(state: State): Props {
  return {
    imageDialog: state.imageDialog
  };
}

export default connect(mapStateToProps)(ImageDialog);
