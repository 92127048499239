import { Cmd, Loop, loop, LoopReducer } from "redux-loop";
import { Action } from "../actions";
import {
  ActionTypes,
  deleteImageFailure,
  DeleteImageForm,
  deleteImageSuccess
} from "../actions/deleteImageDialog";
import { imagesFetch } from "../actions/images";
import { deleteImage } from "../api";
import { Image } from "../models";
import { WriteResource } from "../types";

export type DeleteImageDialogState =
  | {
      readonly isOpen: true;
      readonly image: WriteResource<DeleteImageForm, Image>;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: DeleteImageDialogState = {
  isOpen: false
};

export const deleteImageDialogReducer: LoopReducer<DeleteImageDialogState, Action> = (
  state: DeleteImageDialogState = initialState,
  action: Action
): DeleteImageDialogState | Loop<DeleteImageDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_DELETE_IMAGE_DIALOG:
      return {
        ...state,
        isOpen: true,
        image: {
          data: {
            image: action.image,
            reasonForChange: null
          }
        }
      };
    case ActionTypes.CLOSE_DELETE_IMAGE_DIALOG:
      return {
        isOpen: false
      };
    case ActionTypes.SET_DELETE_IMAGE_FORM:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: action.form
            }
          }
        : state;
    case ActionTypes.IMAGE_DELETE:
      return state.isOpen
        ? loop(
            state,
            Cmd.run(deleteImage, {
              successActionCreator: deleteImageSuccess,
              failActionCreator: deleteImageFailure,
              args: [
                {
                  value: state.image.data.image.id,
                  reasonForChange: state.image.data.reasonForChange || undefined
                }
              ] as Parameters<typeof deleteImage>
            })
          )
        : state;
    case ActionTypes.DELETE_IMAGE_SUCCESS:
      return state.isOpen
        ? loop(initialState, Cmd.action(imagesFetch(state.image.data.image.studyId)))
        : state;
    case ActionTypes.DELETE_IMAGE_FAILURE:
      return state.isOpen
        ? {
            ...state,
            image: {
              data: state.image.data,
              errorMessage: action.errorMessage
            }
          }
        : state;
    default:
      return state;
  }
};
