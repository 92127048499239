import {
  AddReasonForChange,
  AnnotationClassType,
  Indication,
  Modality,
  NestValues,
  Reader,
  Readers,
  ReadonlyUser,
  ReadonlyUsers,
  Study,
  StudyView,
  Uploader,
  Uploaders,
  UserRole,
  UUID
} from "../models";
import { Resource } from "../types";

export interface StudyFormFields {
  readonly name: string;
  readonly indication: Indication | null;
  readonly segments: number | null;
  readonly modality: ReadonlyArray<Modality>;
  readonly uploaders: Uploaders;
  readonly readers: Readers;
  readonly readonlyUsers: ReadonlyUsers;
  readonly onHold: Boolean;
  readonly onHoldReason: string | null;
  readonly hpfAnnotationClasses: ReadonlyArray<AnnotationClassForm<"HPF">>;
  readonly pointAnnotationClasses: ReadonlyArray<AnnotationClassForm<"POINT">>;
  readonly freehandAnnotationClasses: ReadonlyArray<AnnotationClassForm<"FREEHAND">>;
}

type Form = NestValues<StudyFormFields>;

export type UpdateStudyForm = AddReasonForChange<Form>;

export type CreateStudyForm = Form;

export type StudyForm = UpdateStudyForm | CreateStudyForm;

// eslint-disable-next-line
export interface AnnotationClassForm<T extends AnnotationClassType> {
  readonly id?: string;
  readonly name: string;
  readonly color: string;
  readonly sortOrder: number | null;
  readonly type: string; // T;
  readonly enabled: boolean;
  readonly count?: number;
  readonly deleted: boolean;
}

export type EditableAnnotationClassFields = Extract<
  keyof AnnotationClassForm<AnnotationClassType>,
  "name" | "color" | "sortOrder"
>;

export enum ActionTypes {
  CHANGE_STUDY = "CHANGE_STUDY",
  STUDY_FETCH_REQUEST = "STUDY_FETCH_REQUEST",
  STUDY_FETCH_REQUEST_SUCCESS = "STUDY_FETCH_REQUEST_SUCCESS",
  STUDY_FETCH_REQUEST_FAILURE = "STUDY_FETCH_REQUEST_FAILURE",
  SEARCH_UPLOADERS_REQUEST = "SEARCH_UPLOADERS_REQUEST",
  SEARCH_UPLOADERS_REQUEST_SUCCESS = "SEARCH_UPLOADERS_REQUEST_SUCCESS",
  SEARCH_UPLOADERS_REQUEST_FAILURE = "SEARCH_UPLOADERS_REQUEST_FAILURE",
  ASSIGN_USER_TO_STUDY = "ASSIGN_USER_TO_STUDY",
  UNASSIGN_USER_FROM_STUDY = "UNASSIGN_USER_FROM_STUDY",
  SEARCH_READERS_REQUEST = "SEARCH_READERS_REQUEST",
  SEARCH_READERS_REQUEST_SUCCESS = "SEARCH_READERS_REQUEST_SUCCESS",
  SEARCH_READERS_REQUEST_FAILURE = "SEARCH_READERS_REQUEST_FAILURE",
  SEARCH_READONLY_USERS_REQUEST = "SEARCH_READONLY_USERS_REQUEST",
  SEARCH_READONLY_USERS_REQUEST_SUCCESS = "SEARCH_READONLY_USERS_REQUEST_SUCCESS",
  SEARCH_READONLY_USERS_REQUEST_FAILURE = "SEARCH_READONLY_USERS_REQUEST_FAILURE",
  STUDY_RESET = "STUDY_RESET",
  STUDY_REQUEST = "STUDY_REQUEST",
  STUDY_REQUEST_SUCCESS = "STUDY_REQUEST_SUCCESS",
  STUDY_REQUEST_FAILURE = "STUDY_REQUEST_FAILURE",
  STUDY_CANCEL_EDIT = "STUDY_CANCEL_EDIT"
}

export type UploadersResource = Resource<Uploaders>;
export type ReadersResource = Resource<Readers>;
export type ReadonlyUsersResource = Resource<ReadonlyUsers>;

export type AssignableUserRoles = UserRole.Reader | UserRole.Lab | UserRole.Readonly;
export type UserFromRole<T extends AssignableUserRoles> = T extends UserRole.Reader
  ? Reader
  : T extends UserRole.Lab
  ? Uploader
  : T extends UserRole.Readonly
  ? ReadonlyUser
  : never;

export type StudyConfigurationAction =
  | { readonly type: ActionTypes.CHANGE_STUDY; readonly study: UpdateStudyForm }
  | { readonly type: ActionTypes.STUDY_FETCH_REQUEST; readonly studyId: UUID }
  | { readonly type: ActionTypes.STUDY_FETCH_REQUEST_SUCCESS; readonly studyView: StudyView }
  | { readonly type: ActionTypes.STUDY_FETCH_REQUEST_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.SEARCH_UPLOADERS_REQUEST; readonly name: string }
  | {
      readonly type: ActionTypes.SEARCH_UPLOADERS_REQUEST_SUCCESS;
      readonly uploaders: ReadonlyArray<Uploader>;
    }
  | { readonly type: ActionTypes.SEARCH_UPLOADERS_REQUEST_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.ASSIGN_USER_TO_STUDY;
      readonly role: AssignableUserRoles;
      readonly user: UserFromRole<AssignableUserRoles>;
    }
  | {
      readonly type: ActionTypes.UNASSIGN_USER_FROM_STUDY;
      readonly role: AssignableUserRoles;
      readonly user: UserFromRole<AssignableUserRoles>;
    }
  | { readonly type: ActionTypes.SEARCH_READERS_REQUEST; readonly name: string }
  | {
      readonly type: ActionTypes.SEARCH_READERS_REQUEST_SUCCESS;
      readonly readers: ReadonlyArray<Reader>;
    }
  | { readonly type: ActionTypes.SEARCH_READERS_REQUEST_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.SEARCH_READONLY_USERS_REQUEST; readonly name: string }
  | {
      readonly type: ActionTypes.SEARCH_READONLY_USERS_REQUEST_SUCCESS;
      readonly readonlyUsers: ReadonlyArray<ReadonlyUser>;
    }
  | { readonly type: ActionTypes.SEARCH_READONLY_USERS_REQUEST_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.STUDY_RESET }
  | { readonly type: ActionTypes.STUDY_CANCEL_EDIT; readonly studyId: string }
  | { readonly type: ActionTypes.STUDY_REQUEST }
  | { readonly type: ActionTypes.STUDY_REQUEST_SUCCESS; readonly study: Study }
  | { readonly type: ActionTypes.STUDY_REQUEST_FAILURE; readonly errorMsg: string };

export function changeStudy(study: UpdateStudyForm): StudyConfigurationAction {
  return {
    type: ActionTypes.CHANGE_STUDY,
    study
  };
}

export function studyFetchRequest(studyId: UUID): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_FETCH_REQUEST,
    studyId
  };
}

export function searchUploadersRequest(name: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_UPLOADERS_REQUEST,
    name
  };
}

export function searchReadersRequest(name: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST,
    name
  };
}

export function searchReadonlyUsersRequest(name: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READONLY_USERS_REQUEST,
    name
  };
}

export function studyFetchRequestSuccess(studyView: StudyView): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_FETCH_REQUEST_SUCCESS,
    studyView
  };
}

export function searchUploadersRequestSuccess(uploaders: Uploaders): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_UPLOADERS_REQUEST_SUCCESS,
    uploaders
  };
}

export function searchReadersRequestSuccess(readers: Readers): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST_SUCCESS,
    readers
  };
}

export function searchReadonlyUsersRequestSuccess(
  readonlyUsers: ReadonlyUsers
): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READONLY_USERS_REQUEST_SUCCESS,
    readonlyUsers
  };
}

export function studyFetchRequestFailure(errorMsg: string): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_FETCH_REQUEST_FAILURE,
    errorMsg
  };
}

export function searchUploadersRequestFailure(errorMsg: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_UPLOADERS_REQUEST_FAILURE,
    errorMsg
  };
}

export function searchReadersRequestFailure(errorMsg: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST_FAILURE,
    errorMsg
  };
}

export function searchReadonlyUsersRequestFailure(errorMsg: string): StudyConfigurationAction {
  return {
    type: ActionTypes.SEARCH_READONLY_USERS_REQUEST_FAILURE,
    errorMsg
  };
}

export function assignUserToStudy(
  role: AssignableUserRoles,
  user: UserFromRole<AssignableUserRoles>
): StudyConfigurationAction {
  return {
    type: ActionTypes.ASSIGN_USER_TO_STUDY,
    role,
    user
  };
}

export function unassignUserFromStudy(
  role: AssignableUserRoles,
  user: UserFromRole<AssignableUserRoles>
): StudyConfigurationAction {
  return {
    type: ActionTypes.UNASSIGN_USER_FROM_STUDY,
    role,
    user
  };
}

export function studyReset(): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_RESET
  };
}

export function studyRequest(): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_REQUEST
  };
}

export function studyRequestSuccess(study: Study): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_REQUEST_SUCCESS,
    study
  };
}

export function studyRequestFailure(errorMsg: string): StudyConfigurationAction {
  return {
    type: ActionTypes.STUDY_REQUEST_FAILURE,
    errorMsg
  };
}
