import { Callout, Intent } from "@blasterjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { imageFetch } from "../actions/imageViewer";
import { Config, ImageWithAnnotations, User } from "../models";
import { State } from "../reducers";
import store from "../store";
import { Resource } from "../types";

import ImageViewer from "../components/ImageViewer";
import InfoSidebarImage from "../components/InfoSidebarImage";
import TopBarImage from "../components/TopBarImage";
import Content from "../components/Content";

interface Props {
  readonly imageWithAnnotations: Resource<ImageWithAnnotations> | null;
  readonly config: Resource<Config>;
  readonly user: Resource<User>;
}

const Image = ({ imageWithAnnotations, config, user }: Props) => {
  const params = useParams();
  const id = params.id || "no-id";
  useEffect(() => {
    store.dispatch(imageFetch(id));
  }, [id]);

  const errorText =
    "errorMessage" in config
      ? config.errorMessage
      : imageWithAnnotations && "errorMessage" in imageWithAnnotations
      ? imageWithAnnotations.errorMessage
      : null;
  const content =
    "resource" in config &&
    (imageWithAnnotations === null || "resource" in imageWithAnnotations) ? (
      <ImageViewer
        imageWithAnnotations={imageWithAnnotations === null ? null : imageWithAnnotations.resource}
        tileServerLocation={config.resource.tileServerLocation}
        topBar={
          <TopBarImage
            image={
              imageWithAnnotations === null
                ? null
                : imageWithAnnotations.resource.imageAndQuery.image
            }
          />
        }
        sidebar={
          imageWithAnnotations === null ? null : (
            <InfoSidebarImage
              imageAndQuery={imageWithAnnotations.resource.imageAndQuery}
              user={user}
            />
          )
        }
      />
    ) : errorText ? (
      <Callout intent={Intent.DANGER}>{errorText}</Callout>
    ) : null;
  return (
    <Content
      isLoading={
        imageWithAnnotations === null ||
        "isPending" in imageWithAnnotations ||
        "isPending" in config
      }
    >
      {content}
    </Content>
  );
};

function mapStateToProps(state: State): Props {
  return {
    imageWithAnnotations: state.caseImageViewer.imageWithAnnotations,
    config: state.config,
    user: state.auth
  };
}

export default connect(mapStateToProps)(Image);
