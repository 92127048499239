import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action, redirect } from "../actions";
import { ActionTypes, userFetchFailure, userFetchSuccess } from "../actions/auth";

import { fetchUser } from "../api";
import { User } from "../models";
import { Resource } from "../types";

const initialState = {
  isPending: false
};

const authReducer: LoopReducer<Resource<User>, Action> = (
  state: Resource<User> = initialState,
  action: Action
): Resource<User> | Loop<Resource<User>, Action> => {
  switch (action.type) {
    case ActionTypes.USER_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchUser, {
          successActionCreator: userFetchSuccess,
          failActionCreator: userFetchFailure,
          args: []
        })
      );
    case ActionTypes.USER_FETCH_SUCCESS:
      return {
        resource: action.user
      };
    case ActionTypes.USER_FETCH_FAILURE:
      return {
        errorMessage: action.errorMsg
      };
    case ActionTypes.CHECK_LOGGED_IN:
      // NOTE: This only serves to fire off an API call to potentially trigger a redirect (no state
      // update, no subsequent actions wired up to result of promise).
      return loop(state, Cmd.run(fetchUser));
    case ActionTypes.LOGOUT:
      return loop(
        {
          errorMessage: "Please log in"
        },
        Cmd.action(redirect(action.redirectToPath))
      );
    default:
      return state;
  }
};

export default authReducer;
