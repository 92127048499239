import { Polyline, PolylineProps, Tooltip } from "react-leaflet";

import AnnotationPopup from "./AnnotationPopup";
import React from "react";
import { State } from "../reducers";
import { UUID } from "../models";
import { connect } from "react-redux";

interface Props extends PolylineProps {
  readonly text: string;
  readonly date: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  readonly showLabel?: boolean;
  readonly lineLength: number | null;
  readonly maxZoom: number;
}

interface StateProps {
  readonly zoom: number;
}

const LineAnnotation = ({
  positions,
  text,
  date,
  annotationId,
  canDelete,
  showLabel
}: Props & StateProps) => {
  return (
    <Polyline positions={positions} color={"#00FF00"} weight={5}>
      <AnnotationPopup text={text} date={date} annotationId={annotationId} canDelete={canDelete} />
      {showLabel === true ? (
        <Tooltip
          key={"tt" + annotationId.toString()}
          className="line-annotation-label"
          direction="center"
          offset={[0, 0]}
          permanent={true}
        >
          {text}
        </Tooltip>
      ) : (
        <></>
      )}
    </Polyline>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    zoom: state.map.zoom
  };
}

export default connect(mapStateToProps)(LineAnnotation);
