import { prop } from "ramda";
import React from "react";
import { connect } from "react-redux";

import { State } from "../reducers";

import {
  Box,
  Button,
  Callout,
  Dialog,
  Grouper,
  Intent,
  Label,
  Text,
  TextInput
} from "@blasterjs/core";

import {
  addReader,
  assignImage,
  CaseForm,
  caseSearchReadersRequest,
  caseSuggestReadersRequest,
  changeCase,
  closeCaseDialog,
  createCaseRequest,
  dropReader,
  editCaseRequest,
  imagesSearch,
  unassignImage
} from "../actions/caseDialog";
import DebouncedTextInput from "../components/DebouncedTextInput";
import ReasonForChange from "../components/ReasonForChange";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import Select, { SelectOfType } from "../components/Select";

import { CaseDialogMode } from "../actions/caseDialog";
import { CaseStatus, formatCaseStatus, Image, ReaderStudyStats, userLabel } from "../models";
import { CaseDialogState } from "../reducers/caseDialog";
import store from "../store";
import { areDifferent } from "../utils";

function formatReaderStats(stats: ReaderStudyStats): string {
  return `${userLabel(stats.reader)} (${stats.numReadCases}/${stats.numTotalCases} cases read)`;
}

interface Props {
  readonly caseDialog: CaseDialogState;
}

const SelectReaders = Select as SelectOfType<ReaderStudyStats>;
const SelectImages = Select as SelectOfType<Image>;

const CaseDialog = ({ caseDialog }: Props) => {
  const updateCaseField = <
    Key extends keyof CaseForm<CaseDialogMode>,
    PartialUpdate extends Partial<CaseForm<CaseDialogMode>[Key]>
  >(
    caseKey: Key,
    value: PartialUpdate
  ) => {
    store.dispatch(
      changeCase({
        ...caseDialog.histoCase.data,
        [caseKey]: {
          ...caseDialog.histoCase.data[caseKey],
          ...value
        }
      })
    );
  };
  const updateCaseFieldWithValue = (key: keyof CaseForm<CaseDialogMode>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateCaseField(key, { value: e.currentTarget.value });
  const updateCaseFieldWithReasonForChange = (key: keyof CaseForm<CaseDialogMode>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateCaseField(key, { reasonForChange: e.currentTarget.value });
  const onSubjectIdChange = (subjectId: string) => {
    store.dispatch(caseSuggestReadersRequest(subjectId));
    updateCaseField("subjectId", { value: subjectId });
  };
  const onReaderNameChange = (value: string) => {
    store.dispatch(caseSearchReadersRequest(value, caseDialog.studyId));
  };
  const onClickSuggestedReader = (reader: ReaderStudyStats) => () => onSelectReader(reader);
  const onSelectReader = (reader: ReaderStudyStats) => {
    store.dispatch(addReader(reader));
  };
  const onUnassignReader = (reader: ReaderStudyStats) => {
    store.dispatch(dropReader(reader));
  };
  const onImageNameChange = (value: string) => {
    store.dispatch(imagesSearch(value));
  };
  const onSelectImage = (image: Image) => {
    store.dispatch(assignImage(image));
  };
  const onUnassignImage = (image: Image) => {
    store.dispatch(unassignImage(image));
  };
  const closeDialog = () => {
    store.dispatch(closeCaseDialog());
  };
  const onSave = () => {
    store.dispatch(
      caseDialog.mode === CaseDialogMode.OpenForCreate ? createCaseRequest() : editCaseRequest()
    );
  };
  const imageAssignmentDisabled = caseDialog.savedHistoCase
    ? caseDialog.savedHistoCase.caseWithStatus.status !== CaseStatus.PendingQC
    : false;
  const disabledText = imageAssignmentDisabled
    ? `Images can only be assigned to cases in ${formatCaseStatus(CaseStatus.PendingQC)}`
    : undefined;
  const errorText =
    "errorMessage" in caseDialog.histoCase ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{caseDialog.histoCase.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const readerSuggestionText =
    "resource" in caseDialog.readerSuggestions && caseDialog.readerSuggestions.resource.length ? (
      <Text>
        <Text as="i">
          Suggested reader(s) for subject {caseDialog.histoCase.data.subjectId.value}
        </Text>
        :
        <Grouper>
          {caseDialog.readerSuggestions.resource.map(readerStats => (
            <Button key={readerStats.reader.id} onClick={onClickSuggestedReader(readerStats)}>
              {userLabel(readerStats.reader)}
            </Button>
          ))}
        </Grouper>
      </Text>
    ) : null;
  return (
    <Dialog
      isOpen={
        caseDialog.mode !== CaseDialogMode.Closed &&
        // Avoid flicker by waiting to open dialog until case has loaded
        !("isPending" in caseDialog.histoCase)
      }
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      <DialogHeader
        title={(caseDialog.mode === CaseDialogMode.OpenForCreate ? "Create" : "Edit") + " Case"}
        closeDialog={closeDialog}
      />
      <DialogBody>
        {errorText}
        <Box>
          <Label>
            Procedure ID
            <TextInput
              placeholder="Procedure ID"
              mb={2}
              value={caseDialog.histoCase.data.procId.value}
              onChange={updateCaseFieldWithValue("procId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.procId !==
          caseDialog.histoCase.data.procId.value ? (
          <Box>
            <ReasonForChange
              rkey="procId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.procId
                  ? caseDialog.histoCase.data.procId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("procId")}
            />
          </Box>
        ) : null}
        <Box>
          <Label>
            Subject ID
            <DebouncedTextInput
              placeholder="Subject ID"
              mb={2}
              defaultValue={caseDialog.histoCase.data.subjectId.value}
              onValueChange={onSubjectIdChange}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.subjectId !==
          caseDialog.histoCase.data.subjectId.value ? (
          <Box>
            <ReasonForChange
              rkey="subjectId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.subjectId
                  ? caseDialog.histoCase.data.subjectId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("subjectId")}
            />
          </Box>
        ) : null}
        <Box>
          <Label>
            Visit ID
            <TextInput
              placeholder="Visit ID"
              mb={2}
              value={caseDialog.histoCase.data.visitId.value}
              onChange={updateCaseFieldWithValue("visitId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.visitId !==
          caseDialog.histoCase.data.visitId.value ? (
          <Box>
            <ReasonForChange
              rkey="visitId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.visitId
                  ? caseDialog.histoCase.data.visitId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("visitId")}
            />
          </Box>
        ) : null}
        <hr />
        <Box mb={2}>{readerSuggestionText}</Box>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>
              Assign Readers
              <SelectReaders
                placeholder={"Enter user’s name"}
                searchText={caseDialog.readersSearchText}
                searchResults={
                  "resource" in caseDialog.readersSearchResults
                    ? caseDialog.readersSearchResults.resource
                    : []
                }
                onSelect={onSelectReader}
                onChangeSearchText={onReaderNameChange}
                onDeselect={onUnassignReader}
                selectedItems={
                  caseDialog.histoCase.data ? caseDialog.histoCase.data.readers.value : []
                }
                isLoading={
                  "isPending" in caseDialog.readersSearchResults
                    ? caseDialog.readersSearchResults.isPending
                    : false
                }
                format={formatReaderStats}
              />
            </Label>
          </Box>
          {caseDialog.mode === CaseDialogMode.OpenForEdit &&
          caseDialog.savedHistoCase &&
          areDifferent(
            caseDialog.savedHistoCase.readers.map(readerStats => readerStats.reader.id),
            caseDialog.histoCase.data.readers.value.map(readerStats => readerStats.reader.id)
          ) ? (
            <Box>
              <ReasonForChange
                rkey="readers_reason"
                mb={2}
                value={
                  "reasonForChange" in caseDialog.histoCase.data.readers
                    ? caseDialog.histoCase.data.readers.reasonForChange
                    : ""
                }
                onChange={updateCaseFieldWithReasonForChange("readers")}
              />
            </Box>
          ) : null}
        </Box>
        <hr />
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box width="100%">
            <Label>
              Assign Images
              <SelectImages
                placeholder={"Enter image name"}
                searchText={caseDialog.imagesSearchText}
                searchResults={
                  "resource" in caseDialog.imagesSearchResults
                    ? caseDialog.imagesSearchResults.resource
                    : []
                }
                onSelect={onSelectImage}
                onChangeSearchText={onImageNameChange}
                onDeselect={onUnassignImage}
                selectedItems={
                  caseDialog.histoCase.data ? caseDialog.histoCase.data.images.value : []
                }
                isLoading={
                  "isPending" in caseDialog.imagesSearchResults &&
                  caseDialog.imagesSearchResults.isPending
                }
                format={prop("name")}
                disabled={imageAssignmentDisabled}
                disabledText={disabledText}
              />
            </Label>
          </Box>
          {caseDialog.mode === CaseDialogMode.OpenForEdit &&
          caseDialog.savedHistoCase &&
          areDifferent(
            caseDialog.savedHistoCase.images.map(image => image.id),
            caseDialog.histoCase.data.images.value.map(image => image.id)
          ) ? (
            <Box>
              <ReasonForChange
                rkey="images_reason"
                mb={2}
                value={
                  "reasonForChange" in caseDialog.histoCase.data.images
                    ? caseDialog.histoCase.data.images.reasonForChange
                    : ""
                }
                onChange={updateCaseFieldWithReasonForChange("images")}
              />
            </Box>
          ) : null}
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            intent="primary"
            appearance="prominent"
            onClick={onSave}
            isLoading={"isSaving" in caseDialog.histoCase}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

function mapStateToProps(state: State): Props {
  return {
    caseDialog: state.caseDialog
  };
}

export default connect(mapStateToProps)(CaseDialog);
