export enum ActionTypes {
  ZOOM_CHANGE = "ZOOM_CHANGE",
  SET_SELECTED_IMAGE = "SET_SELECTED_IMAGE",
  SET_IMAGE_MENU_OVERFLOW = "SET_IMAGE_MENU_OVERFLOW"
}

export type MapAction =
  | { readonly type: ActionTypes.ZOOM_CHANGE; readonly zoom: number }
  | { readonly type: ActionTypes.SET_SELECTED_IMAGE; readonly selectedImage: number }
  | { readonly type: ActionTypes.SET_IMAGE_MENU_OVERFLOW; readonly imageMenuOverflow: boolean };

export function setZoom(zoom: number): MapAction {
  return {
    type: ActionTypes.ZOOM_CHANGE,
    zoom
  };
}

export function setSelectedImage(selectedImage: number): MapAction {
  return {
    type: ActionTypes.SET_SELECTED_IMAGE,
    selectedImage
  };
}

export function setImageMenuOverflow(imageMenuOverflow: boolean): MapAction {
  return {
    type: ActionTypes.SET_IMAGE_MENU_OVERFLOW,
    imageMenuOverflow
  };
}
