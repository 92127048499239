import { ImageListViews, UUID } from "../models";

export interface ImageFilters {
  readonly name?: string;
  readonly hasQueries?: boolean | null;
  readonly isUnassigned?: boolean | null;
}

export enum ActionTypes {
  SET_IMAGE_FILTERS = "SET_IMAGE_FILTERS",
  IMAGES_FETCH = "IMAGES_FETCH",
  IMAGES_FETCH_SUCCESS = "IMAGES_FETCH_SUCCESS",
  IMAGES_FETCH_FAILURE = "IMAGES_FETCH_FAILURE"
}

export type ImagesAction =
  | {
      readonly type: ActionTypes.SET_IMAGE_FILTERS;
      readonly studyId: UUID;
      readonly filters: ImageFilters;
    }
  | {
      readonly type: ActionTypes.IMAGES_FETCH;
      readonly studyId: UUID;
    }
  | { readonly type: ActionTypes.IMAGES_FETCH_SUCCESS; readonly images: ImageListViews }
  | { readonly type: ActionTypes.IMAGES_FETCH_FAILURE; readonly errorMsg: string };

export function setImageFilters(studyId: UUID, filters: ImageFilters): ImagesAction {
  return {
    type: ActionTypes.SET_IMAGE_FILTERS,
    studyId,
    filters
  };
}

export function imagesFetch(studyId: UUID): ImagesAction {
  return {
    type: ActionTypes.IMAGES_FETCH,
    studyId
  };
}

export function imagesFetchSuccess(images: ImageListViews): ImagesAction {
  return {
    type: ActionTypes.IMAGES_FETCH_SUCCESS,
    images
  };
}

export function imagesFetchFailure(errorMsg: string): ImagesAction {
  return {
    type: ActionTypes.IMAGES_FETCH_FAILURE,
    errorMsg
  };
}
