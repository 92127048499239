import axios, { AxiosError, AxiosPromise, CancelTokenSource } from "axios";
import { saveAs } from "file-saver";
import { Feature } from "geojson";
import qs from "qs";
import { identity, isNil, pathOr, reject, zip } from "ramda";
import { JsonDecoder } from "ts.data.json";

import { DispatchFunction } from "./actions";
import { logout } from "./actions/auth";
import { ReportType } from "./actions/reports";
import { StudyFormFields } from "./actions/studyConfiguration";
import {
  adminCaseWithImagesAndReadersDecoder,
  annotationDecoder,
  apiResponseDecoder,
  caseAndCountsArrayDecoder,
  caseWithImagesDecoder,
  configDecoder,
  imageArrayDecoder,
  imageDecoder,
  imageListViewArrayDecoder,
  imageWithAnnotationsDecoder,
  querySearchDecoder,
  readersStudyStatsDecoder,
  studyDecoder,
  studyListViewsDecoder,
  studyViewDecoder,
  userDecoder,
  usersDecoder
} from "./decoders";
import {
  AdminCaseWithImagesAndReaders,
  Annotation,
  ApiResponse,
  CasesAndCounts,
  CaseStatus,
  CaseWithImages,
  Config,
  DateFilter,
  formatCaseStatus,
  Image,
  ImageListViews,
  Images,
  ImageWithAnnotations,
  MetadataImage,
  MetadataImageType,
  QueryObjectType,
  QuerySearchRecords,
  ReadersStudyStats,
  ReportStudyData,
  ReportUserData,
  SignedURLs,
  Study,
  StudyId,
  StudyListViews,
  StudyView,
  UpdateValueWithMaybeReasonForChange,
  UpdateValueWithReasonForChange,
  User,
  UserRole,
  Users,
  UUID
} from "./models";
import { QueryFilter } from "./reducers/queries";
import store from "./store";
import { idsOnly, LOCALE, updateWithIdsOnly } from "./utils";

const customAxios = axios.create({
  // This custom serializer makes our query params adhere to the format the backend expects for repeated parameters: <origin>?id=1&id=2&id=3
  paramsSerializer: params => qs.stringify(reject(isNil, params), { arrayFormat: "repeat" })
});

customAxios.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401 && window.location.pathname !== "/login") {
    store.dispatch(logout("/login?redirect=" + window.location.pathname));
  } else {
    return Promise.reject(error);
  }
});

// Use with pathOr to drill down into an error message when present
const errorMsgPath: ReadonlyArray<string> = ["response", "data", "message"];

async function decodeResponse<T>(decoder: JsonDecoder.Decoder<T>, responseData: any): Promise<T> {
  return decoder.decodePromise(responseData);
}

export function fetchHisto(path: string, params: object = {}): AxiosPromise {
  return customAxios.get(path, {
    params
  });
}

export function putHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.put(path, data);
}

export function postHisto(path: string, data: object): AxiosPromise {
  return customAxios.post(path, data);
}

export function patchHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.patch(path, data);
}

export function deleteHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.delete(path, data);
}

export async function fetchUser(): Promise<User> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users/info")
      .then(response =>
        decodeResponse<User>(userDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Please log in", errorMsgPath, error)));
  });
}

export async function fetchStudies(name?: string): Promise<StudyListViews> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/studies", { name })
      .then(response =>
        decodeResponse(studyListViewsDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch studies", errorMsgPath, error)));
  });
}

export async function createStudy(
  name: StudyFormFields["name"],
  indication: StudyFormFields["indication"],
  segments: StudyFormFields["segments"],
  modality: StudyFormFields["modality"],
  uploaders: StudyFormFields["uploaders"],
  readers: StudyFormFields["readers"],
  readonlyUsers: StudyFormFields["readonlyUsers"],
  onHold: StudyFormFields["onHold"],
  onHoldReason: StudyFormFields["onHoldReason"],
  hpfAnnotationClasses: StudyFormFields["hpfAnnotationClasses"],
  pointAnnotationClasses: StudyFormFields["pointAnnotationClasses"],
  freehandAnnotationClasses: StudyFormFields["freehandAnnotationClasses"]
): Promise<Study> {
  return new Promise((resolve, reject) => {
    postHisto("/api/studies", {
      name,
      indication,
      segments,
      modality,
      uploaders: idsOnly(uploaders),
      readers: idsOnly(readers),
      readonlyUsers: idsOnly(readonlyUsers),
      onHold,
      onHoldReason,
      hpfAnnotationClasses,
      pointAnnotationClasses,
      freehandAnnotationClasses
    })
      .then(response => decodeResponse(studyDecoder, response.data).then(resolve).catch(reject))
      .catch(error => {
        return reject(pathOr("Unable to create study", errorMsgPath, error));
      });
  });
}

export async function createSignedURLs(studyId: string, files: FileList): Promise<SignedURLs> {
  return new Promise((resolve, reject) => {
    postHisto("/api/signed-urls", {
      studyId: studyId,
      fileNames: files !== null ? Array.from(files).map(file => file.name) : []
    })
      .then(response => {
        return resolve(response.data.urls);
      })
      .catch(error => {
        return reject(pathOr("Unable to create signed URLs", errorMsgPath, error));
      });
  });
}

export async function createCase(
  studyId: UUID,
  procId: string,
  subjectId: string,
  visitId: string,
  readers: ReadonlyArray<UUID>,
  images: ReadonlyArray<UUID>
): Promise<AdminCaseWithImagesAndReaders> {
  return new Promise((resolve, reject) => {
    postHisto("/api/cases", {
      studyId,
      procId,
      subjectId,
      visitId,
      readers,
      images
    })
      .then(response =>
        decodeResponse(adminCaseWithImagesAndReadersDecoder, response.data)
          .then(resolve)
          .catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create case", errorMsgPath, error));
      });
  });
}

function urlToPathname(url: string): string {
  return new URL(url).pathname.slice(1, url.length); // Remove leading slash from pathname
}

export function sendNewFilesNotification(
  studyId: StudyId,
  numFiles: number,
  failures: boolean
): Promise<void> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images/notify-upload`, {
      studyId,
      numFiles,
      failures
    })
      .then(_ => resolve())
      .catch(error => {
        return reject(pathOr("Unable to send new files notification", errorMsgPath, error));
      });
  });
}

export function createImage(
  studyId: StudyId,
  file: File,
  url: string,
  imageToReplace: Image | null
): Promise<Image> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images`, {
      name: file.name,
      s3Key: urlToPathname(url),
      studyId,
      imageToReplaceId: imageToReplace ? imageToReplace.id : null
    })
      .then(response =>
        decodeResponse(imageDecoder, response.data.image).then(resolve).catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create image", errorMsgPath, error));
      });
  });
}

export function createAnnotation(
  geometry: Feature,
  radiusX: number | null,
  radiusY: number | null,
  tilt: number | null,
  text: string | null,
  annotationClassId: UUID | null,
  annotationType: string | null,
  imageId: UUID
): Promise<Annotation> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/annotations`, {
      geometry,
      radiusX,
      radiusY,
      // Adjust tilt so that ellipses display correctly after saving. It's
      // unclear why during editing the tilt is off by 90 degrees, but this
      // seems to fix it.
      tilt: tilt ? tilt + 90 : null,
      text,
      annotationClassId,
      annotationType,
      imageId
    })
      .then(response =>
        decodeResponse(annotationDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create annotation", errorMsgPath, error));
      });
  });
}

export function deleteAnnotation(annotationId: UUID, deleteNested: boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    deleteHisto(`/api/annotations/${annotationId}?deleteNested=${deleteNested}`, {})
      .then(_ => resolve())
      .catch(error => {
        return reject(pathOr("Unable to delete annotation", errorMsgPath, error));
      });
  });
}

export const generateCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source();

function makeUploadPromises(
  dispatch: DispatchFunction,
  studyId: StudyId,
  files: FileList,
  urls: SignedURLs,
  progressCallback: (
    dispatch: DispatchFunction,
    file: File
  ) => (progressEvent: ProgressEvent) => void,
  imageToReplace: Image | null,
  cancelTokenSource: CancelTokenSource
): ReadonlyArray<Promise<Image>> {
  return zip(Array.from(files), urls).map(([file, url]) => {
    return new Promise((resolve, reject) => {
      customAxios
        .put(url, file, {
          cancelToken: cancelTokenSource.token,
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: progressCallback(dispatch, file)
        })
        .then(() => createImage(studyId, file, url, imageToReplace))
        .then(resolve)
        .catch((thrown: AxiosError) => {
          axios.isCancel(thrown)
            ? reject(`Cancelled upload of ${file.name} to ${url}`)
            : reject(`Error uploading ${file.name} to ${url}`);
        });
    });
  });
}

const confirmPromise = (promise: Promise<any>) => {
  // Wrap a promise so the user will be asked to confirm if they navigate away while it's pending
  const confirmNavigate = (event: BeforeUnloadEvent) => {
    // Old style, used by e.g. Chrome
    // eslint-disable-next-line functional/immutable-data
    event.returnValue = true;
    // New style, used by e.g. Firefox
    event.preventDefault();
    return true;
  };
  window.addEventListener("beforeunload", confirmNavigate);
  return promise.finally(() => {
    window.removeEventListener("beforeunload", confirmNavigate);
  });
};

export async function uploadFiles(
  dispatch: DispatchFunction,
  studyId: StudyId,
  files: FileList,
  urls: SignedURLs,
  progressCallback: (
    dispatch: DispatchFunction,
    file: File
  ) => (progressEvent: ProgressEvent) => void,
  imageToReplace: Image | null,
  cancelTokenSource: CancelTokenSource
): Promise<string> {
  return confirmPromise(
    new Promise((resolve, reject) =>
      Promise.all(
        makeUploadPromises(
          dispatch,
          studyId,
          files,
          urls,
          progressCallback,
          imageToReplace,
          cancelTokenSource
        )
      )
        .then(() => resolve("All files uploaded successfully!"))
        .catch(error => {
          return reject(pathOr("File upload failed", errorMsgPath, error));
        })
    )
  );
}

export async function updateStudy(
  studyId: StudyId,
  name?: UpdateValueWithReasonForChange<StudyFormFields["name"]>,
  indication?: UpdateValueWithReasonForChange<StudyFormFields["indication"]>,
  segments?: UpdateValueWithReasonForChange<StudyFormFields["segments"]>,
  modality?: UpdateValueWithReasonForChange<StudyFormFields["modality"]>,
  uploaders?: UpdateValueWithReasonForChange<StudyFormFields["uploaders"]>,
  readers?: UpdateValueWithReasonForChange<StudyFormFields["readers"]>,
  readonlyUsers?: UpdateValueWithReasonForChange<StudyFormFields["readonlyUsers"]>,
  onHold?: UpdateValueWithReasonForChange<StudyFormFields["onHold"]>,
  onHoldReason?: UpdateValueWithReasonForChange<StudyFormFields["onHoldReason"]>,
  hpfAnnotationClasses?: UpdateValueWithReasonForChange<StudyFormFields["hpfAnnotationClasses"]>,
  pointAnnotationClasses?: UpdateValueWithReasonForChange<
    StudyFormFields["pointAnnotationClasses"]
  >,
  freehandAnnotationClasses?: UpdateValueWithReasonForChange<
    StudyFormFields["freehandAnnotationClasses"]
  >
): Promise<Study> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/studies/${studyId}`, {
      name,
      indication,
      segments,
      modality,
      uploaders: uploaders ? updateWithIdsOnly(uploaders) : null,
      readers: readers ? updateWithIdsOnly(readers) : null,
      readonlyUsers: readonlyUsers ? updateWithIdsOnly(readonlyUsers) : null,
      onHold: onHold,
      onHoldReason: onHoldReason,
      hpfAnnotationClasses,
      pointAnnotationClasses,
      freehandAnnotationClasses
    })
      .then(response => {
        decodeResponse(studyDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        return reject(pathOr("Unable to update study", errorMsgPath, error));
      });
  });
}

export async function fetchUsers(
  name?: string,
  role?: string,
  registered?: boolean,
  exclude?: ReadonlyArray<UUID>
): Promise<Users> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users", {
      role,
      name,
      registered,
      exclude
    })
      .then(response =>
        decodeResponse(usersDecoder, response.data.users).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch users", errorMsgPath, error)));
  });
}

export async function fetchConfig(): Promise<Config> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/config")
      .then(response => decodeResponse(configDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to connect to server", errorMsgPath, error)));
  });
}

export async function fetchCase(caseId: UUID): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/cases/${caseId}`)
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch case", errorMsgPath, error)));
  });
}

export async function fetchImage(imageId: UUID): Promise<ImageWithAnnotations> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/images/${imageId}`)
      .then(response =>
        decodeResponse(imageWithAnnotationsDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch image", errorMsgPath, error)));
  });
}

export async function fetchImages(
  studyId: UUID,
  name: string | null,
  hasQueries: boolean | null,
  isUnassigned: boolean | null
): Promise<ImageListViews> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/images`, {
      name,
      hasQueries,
      isUnassigned
    })
      .then(response =>
        decodeResponse(imageListViewArrayDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch images for study", errorMsgPath, error)));
  });
}

export async function searchStudyImages(
  studyId: UUID,
  caseId: UUID | null,
  name: string | null,
  exclude: ReadonlyArray<UUID>
): Promise<Images> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/cases/images`, {
      id: caseId,
      name,
      exclude
    })
      .then(response =>
        decodeResponse(imageArrayDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch images for case", errorMsgPath, error)));
  });
}

export async function fetchCases(
  studyId: UUID,
  procId?: string,
  status?: CaseStatus,
  assignee?: string
): Promise<CasesAndCounts> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/cases`, {
      procId,
      status,
      assignee
    })
      .then(response =>
        decodeResponse(caseAndCountsArrayDecoder, response.data.cases).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch cases for study", errorMsgPath, error)));
  });
}

export async function fetchStudy(studyId: UUID): Promise<StudyView> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}`)
      .then(response => decodeResponse(studyViewDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr(`Unable to fetch study ${studyId}`, errorMsgPath, error)));
  });
}

export async function updateUser(
  id: string,
  firstName: string,
  lastName: string,
  role: UserRole
): Promise<User> {
  return new Promise((resolve, reject) => {
    putHisto(`/api/users/${id}`, {
      firstName: firstName || null,
      lastName: lastName || null,
      role
    })
      .then(response => decodeResponse(userDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to update user", errorMsgPath, error)));
  });
}

export async function moveImageToStudy(
  imageId: UUID,
  studyId: UpdateValueWithMaybeReasonForChange<UUID>
): Promise<Image> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/study`, {
      ...studyId
    })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error =>
        reject(
          pathOr(
            `Unable to move image to new study. Must include reason for change.`,
            errorMsgPath,
            error
          )
        )
      );
  });
}

export async function copyImage(imageId: UUID, duplicateImageName: string): Promise<Image> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images/${imageId}/copy`, {
      name: duplicateImageName
    })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to copy image", errorMsgPath, error)));
  });
}

export async function updateImage(
  imageId: UUID,
  accessionNumber?: UpdateValueWithReasonForChange<string | null>,
  biopsyLocation?: UpdateValueWithReasonForChange<string | null>
): Promise<Image> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}`, { accessionNumber, biopsyLocation })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error =>
        reject(
          pathOr("Unable to update image. Must include reason for change.", errorMsgPath, error)
        )
      );
  });
}

export async function revertCaseStatus(
  histoCaseId: UUID,
  status: UpdateValueWithMaybeReasonForChange<CaseStatus>
): Promise<AdminCaseWithImagesAndReaders> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}/status/back`, { ...status })
      .then(response =>
        decodeResponse(adminCaseWithImagesAndReadersDecoder, response.data)
          .then(resolve)
          .catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(
            `Unable to set status of case back to ${formatCaseStatus(
              status.value
            )}. Must set new status and include reason for change.`,
            errorMsgPath,
            error
          )
        )
      );
  });
}

export async function transitionCaseStatus(histoCaseId: UUID): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}/status/forward`)
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to change status of ${histoCaseId}`, errorMsgPath, error))
      );
  });
}

export async function editCase(
  histoCaseId: UUID,
  procId?: UpdateValueWithReasonForChange<string>,
  subjectId?: UpdateValueWithReasonForChange<string>,
  visitId?: UpdateValueWithReasonForChange<string>,
  readers?: UpdateValueWithReasonForChange<ReadonlyArray<UUID>>,
  images?: UpdateValueWithReasonForChange<ReadonlyArray<UUID>>
): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}`, {
      procId,
      subjectId,
      visitId,
      readers,
      images
    })
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to edit case. Reason for change is required.`, errorMsgPath, error))
      );
  });
}

export async function toggleImageVisibility(imageId: UUID): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/toggle-image-visibility`)
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to hide image for reader for image ${imageId}`, errorMsgPath, error))
      );
  });
}

export async function downloadAuditCsv(
  studyData?: ReportStudyData,
  userData?: ReportUserData
): Promise<void> {
  const studyId = studyData && studyData.id ? studyData.id : undefined;
  const studySlug = studyData ? `${studyData.name.replace(/\s/g, "")}_` : "";

  const userId = userData && userData.id ? userData.id : undefined;
  const userSlug = userData ? `${userData.name.replace(/\s/g, "")}_` : "";

  const now = new Date();
  const dateSlug = now.toLocaleDateString(LOCALE);
  const timeSlug = now.toLocaleTimeString(LOCALE, { hour12: false }).replace(/:/g, "_");
  const tzSlug = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/\//g, "_");

  return new Promise((resolve, reject) => {
    fetchHisto(`/api/audit-trail`, { studyId, userId })
      .then(response => {
        return resolve(
          saveAs(
            new Blob([response.data], { type: "text/csv;charset=utf-8" }),
            `${studySlug}${userSlug}AuditTrailReport_${dateSlug}_${timeSlug}_${tzSlug}.csv`
          )
        );
      })
      .catch(error => reject(pathOr(`Unable to fetch audit trail CSV`, errorMsgPath, error)));
  });
}

export async function downloadReportCsv(
  reportType: ReportType,
  rsd: ReportStudyData | null,
  dateFilter?: DateFilter
): Promise<void> {
  const reportUrl = rsd?.id
    ? `/api/report/${rsd.id}/${reportType}?${qs.stringify(dateFilter || {})}`
    : `/api/report/${reportType}?${qs.stringify(dateFilter || {})}`;
  return new Promise((resolve, reject) => {
    fetchHisto(reportUrl)
      .then(response => {
        const reportBaseName: string = rsd ? rsd.name.replace(/\s/g, "") : "Global";
        return resolve(
          saveAs(
            new Blob([response.data], { type: "text/csv;charset=utf-8" }),
            `${reportBaseName}_${
              reportType === "image-details" ? "ImageDetailsReport" : "HPFAnnotationsReport"
            }_${new Date().toLocaleDateString(LOCALE)}.csv`
          )
        );
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch image details report CSV`, errorMsgPath, error))
      );
  });
}

export async function deleteImage(
  imageId: UpdateValueWithMaybeReasonForChange<UUID>
): Promise<void> {
  return new Promise((resolve, reject) => {
    // NOTE: A payload in a DELETE request has no defined semantics and
    // axios.delete does not support a request body so POST is used here
    postHisto(`/api/images/${imageId.value}`, { ...imageId })
      .then(response => resolve(response.data))
      .catch(error =>
        reject(
          pathOr("Could not archive image. Must include reason for change.", errorMsgPath, error)
        )
      );
  });
}

export async function suggestReaders(studyId: UUID, subjectId: string): Promise<ReadersStudyStats> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/users/suggest-readers`, { studyId, subjectId })
      .then(response => {
        decodeResponse(readersStudyStatsDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch suggested readers", errorMsgPath, error)));
  });
}

export async function searchStudyReaders(studyId: UUID, name: string): Promise<ReadersStudyStats> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/users`, { name })
      .then(response =>
        decodeResponse(readersStudyStatsDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to search study readers", errorMsgPath, error)));
  });
}

/*
 * Fetch API image data (eg. "metadata" images such as label and macro).
 */
export function fetchMetadataImage<T extends MetadataImageType>(
  imageUri: string,
  metadataType: T
): Promise<MetadataImage<T>> {
  return new Promise((resolve, reject) => {
    axios
      .get(imageUri, { responseType: "arraybuffer" })
      .then(response => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        resolve({
          metadataType,
          imageData: "data:;base64," + base64
        });
      })
      .catch(() => reject("Error fetching metadata image data"));
  });
}

export async function addComment(
  caseId: UUID,
  imageId: UUID | null,
  comment: string
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/comment`, { caseId, imageId, comment })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to add case comment for case ${caseId}`, errorMsgPath, error))
      );
  });
}

// queries

export async function fetchQueries(queriesFilter: QueryFilter | null): Promise<QuerySearchRecords> {
  return new Promise((resolve, reject) => {
    postHisto("/api/queries", {
      ...queriesFilter
    })
      .then(response =>
        decodeResponse(querySearchDecoder, response.data.queries).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch queries", errorMsgPath, error)));
  });
}

export async function closeQuery(
  queryId: UUID,
  detailedResolutionText: String
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/queries/${queryId}/close`, { queryId, detailedResolutionText })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to close query for queryId ${queryId}`, errorMsgPath, error))
      );
  });
}

export async function markQueryUnresolvable(
  queryId: UUID,
  unresolvableReasonText: String
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/queries/${queryId}/unresolvable`, { queryId, unresolvableReasonText })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(`Unable to mark query unresolvable for queryId ${queryId}`, errorMsgPath, error)
        )
      );
  });
}

export async function createQuery(
  queryObjectType: QueryObjectType,
  objectId: UUID,
  studyId: UUID,
  caseId: UUID,
  category: String,
  categoryOtherText: String | null,
  followUpInDays: number
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/queries/new`, {
      queryObjectType,
      objectId,
      studyId,
      caseId,
      category,
      categoryOtherText,
      followUpInDays
    })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(
            `Unable to create query for object type ${queryObjectType} object id ${objectId}`,
            errorMsgPath,
            error
          )
        )
      );
  });
}

export async function newQueryFollowUpReminder(
  queryId: UUID,
  queryReminderId: UUID,
  followUpInDays: number
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/queries/${queryId}/reminder`, { queryId, queryReminderId, followUpInDays })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(`Unable to create query reminder for queryId ${queryId}`, errorMsgPath, error)
        )
      );
  });
}
