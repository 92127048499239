import { StudyListViews, StudyView, UUID } from "../models";

export enum ActionTypes {
  SET_STUDIES_FILTER = "SET_STUDIES_FILTER",
  STUDY_FETCH = "STUDY_FETCH",
  STUDY_FETCH_SUCCESS = "STUDY_FETCH_SUCCESS",
  STUDY_FETCH_FAILURE = "STUDY_FETCH_FAILURE",
  STUDIES_FETCH = "STUDIES_FETCH",
  STUDIES_FETCH_SUCCESS = "STUDIES_FETCH_SUCCESS",
  STUDIES_FETCH_FAILURE = "STUDIES_FETCH_FAILURE",
  CLEAR_FILTERS = "CLEAR_FILTERS"
}

export type StudiesAction =
  | { readonly type: ActionTypes.SET_STUDIES_FILTER; readonly name: string | null }
  | { readonly type: ActionTypes.STUDY_FETCH; readonly studyId: UUID }
  | { readonly type: ActionTypes.STUDY_FETCH_SUCCESS; readonly study: StudyView }
  | { readonly type: ActionTypes.STUDY_FETCH_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.STUDIES_FETCH }
  | { readonly type: ActionTypes.STUDIES_FETCH_SUCCESS; readonly studies: StudyListViews }
  | { readonly type: ActionTypes.STUDIES_FETCH_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.CLEAR_FILTERS };

export function setStudiesFilter(name: string | null): StudiesAction {
  return {
    type: ActionTypes.SET_STUDIES_FILTER,
    name
  };
}

export function studyFetch(studyId: UUID): StudiesAction {
  return {
    type: ActionTypes.STUDY_FETCH,
    studyId
  };
}

export function studyFetchSuccess(study: StudyView): StudiesAction {
  return {
    type: ActionTypes.STUDY_FETCH_SUCCESS,
    study
  };
}

export function studyFetchFailure(errorMsg: string): StudiesAction {
  return {
    type: ActionTypes.STUDY_FETCH_FAILURE,
    errorMsg
  };
}

export function studiesFetch(): StudiesAction {
  return {
    type: ActionTypes.STUDIES_FETCH
  };
}

export function studiesFetchSuccess(studies: StudyListViews): StudiesAction {
  return {
    type: ActionTypes.STUDIES_FETCH_SUCCESS,
    studies
  };
}

export function studiesFetchFailure(errorMsg: string): StudiesAction {
  return {
    type: ActionTypes.STUDIES_FETCH_FAILURE,
    errorMsg
  };
}

export function clearFilters(): StudiesAction {
  return {
    type: ActionTypes.CLEAR_FILTERS
  };
}
