import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { State } from "../reducers";

import { Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import TooltipText from "../components/TooltipText";
import Content from "../components/Content";
import {
  AdminStudyListView,
  ReaderStudyListView,
  StudyListView,
  StudyListViews,
  UploaderStudyListView,
  User,
  userCanViewStudyHold,
  userIsAdminOrIsc,
  UserRole
} from "../models";
import { Resource } from "../types";

import { setStudiesFilter, studiesFetch } from "../actions/studies";
import store from "../store";

import DebouncedTextInput from "../components/DebouncedTextInput";
import IndicationText from "../components/IndicationText";
import Modalities from "../components/Modalities";
import Page, { PageHeader, PageHeading } from "../components/Page";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import { dataTableTheme } from "../theme";
import { clearSessionStorage, STUDY_IMAGE_NAME, useLocalIntStorage } from "../storage";
import {
  STUDY_CASE_ASSIGNEE,
  STUDY_CASE_PROCID,
  STUDY_CASE_STATUS,
  STUDY_IMAGE_HAS_QUERIES,
  STUDY_IMAGE_IS_UNASSIGNED
} from "../storage";

interface StateProps {
  readonly studies: Resource<StudyListViews>;
  readonly studiesFilter: string | null;
  readonly user: Resource<User>;
}

function sortMostRecentUpload(rowA: AdminStudyListView, rowB: AdminStudyListView): number {
  if (rowA.mostRecentUploadAt === rowB.mostRecentUploadAt) {
    return 0;
  }
  if (rowA.mostRecentUploadAt === null) {
    return 1;
  }
  if (rowB.mostRecentUploadAt === null) {
    return -1;
  }
  return rowB.mostRecentUploadAt.getTime() - rowA.mostRecentUploadAt.getTime();
}

const defineColumns = (
  isAdminIsc: boolean,
  isReader: boolean,
  canSeeOnHold: boolean
): ReadonlyArray<object> => {
  const nameCol = {
    name: "Name",
    selector: (row: StudyListView) => row.studyView.study.name,
    sortable: true,
    grow: 2
  };
  const statusCol = {
    name: "Status",
    selector: (row: StudyListView) => (row.studyView.study.onHold ? "On hold" : "Active"),
    sortable: true,
    grow: 1,
    cell: (row: StudyListView) =>
      row.studyView.study.onHold ? (
        <TooltipText
          text={row.studyView.study.onHoldReason ? row.studyView.study.onHoldReason : ""}
        >
          On Hold
        </TooltipText>
      ) : (
        <TooltipText text="Active">Active</TooltipText>
      )
  };
  const dateColForAdminIsc = {
    name: "Most Recent Upload",
    selector: (row: AdminStudyListView) => row.mostRecentUploadAt,
    sortable: true,
    sortFunction: sortMostRecentUpload,
    grow: 1,
    format: (s: AdminStudyListView) => <Timestamp date={s.mostRecentUploadAt} />
  };
  const dateColForNonAdminIsc = {
    name: "Created",
    selector: (row: StudyListView) => row.studyView.study.createdAt,
    sortable: true,
    grow: 1,
    format: (s: StudyListView) => <Timestamp date={s.studyView.study.createdAt} />
  };

  const commonCols = [
    {
      name: "Indication",
      selector: (row: StudyListView) => row.studyView.study.indication,
      sortable: true,
      grow: 2,
      format: (s: StudyListView) => <IndicationText indication={s.studyView.study.indication} />
    },
    {
      name: "Locations",
      selector: (row: StudyListView) => row.studyView.study.segments,
      sortable: true,
      grow: 0
    },
    {
      name: "Modality",
      selector: (row: StudyListView) => row.studyView.study.modality,
      grow: 2,
      sortable: true,
      format: (s: StudyListView) => <Modalities modalities={s.studyView.study.modality} />
    }
  ];

  const adminIscQcCol = [
    {
      name: "Unassigned Images",
      selector: (row: AdminStudyListView) => row.numberOfUnassignedImages,
      sortable: true
    },
    {
      name: "Cases to QC",
      selector: (row: AdminStudyListView) => row.numberOfCasesNeedingQC,
      sortable: true
    },
    {
      name: "Open Queries",
      selector: (row: AdminStudyListView) => row.numberOfQueriesOpen,
      sortable: true
    },
    {
      name: "Answered Queries",
      selector: (row: AdminStudyListView) => row.numberOfQueriesAnswered,
      sortable: true
    }
  ];

  const readerQcCol = [
    {
      name: "Read Cases",
      selector: (row: ReaderStudyListView) => row.numberOfAssignedReadCases,
      sortable: true
    },
    {
      name: "Unread Cases",
      selector: (row: ReaderStudyListView) => row.numberOfAssignedUnreadCases,
      sortable: true
    }
  ];

  const baseQcCol = [
    {
      name: "Open Queries",
      selector: (row: UploaderStudyListView) => row.numberOfQueriesOpen,
      sortable: true
    }
  ];

  return [
    nameCol,
    canSeeOnHold ? statusCol : [],
    isAdminIsc ? dateColForAdminIsc : dateColForNonAdminIsc,
    commonCols,
    ...(isAdminIsc ? adminIscQcCol : isReader ? readerQcCol : baseQcCol)
  ];
};

const Studies = ({ studies, studiesFilter, user }: StateProps) => {
  const navigate = useNavigate();
  const [isUserAdminIsc, isUserAdmin, isUserReader] = [
    "resource" in user && userIsAdminOrIsc(user.resource.role),
    "resource" in user && user.resource.role === UserRole.Admin,
    "resource" in user && user.resource.role === UserRole.Reader
  ];

  const canSeeOnHold = "resource" in user && userCanViewStudyHold(user.resource.role);

  useEffect(() => {
    store.dispatch(studiesFetch());
    return () => {
      store.dispatch(setStudiesFilter(null));
    };
  }, []);

  const [studyRowsPerPage, setStudyRowsPerPage] = useLocalIntStorage("studyRowsPerPage", 20);

  const data: StudyListViews =
    "resource" in studies
      ? isUserAdminIsc
        ? // eslint-disable-next-line functional/immutable-data
          ([...studies.resource] as AdminStudyListView[]).sort(sortMostRecentUpload)
        : studies.resource
      : [];

  const sortProps = isUserAdminIsc
    ? {}
    : { defaultSortField: "studyView.study.createdAt", defaultSortAsc: false };

  const onRowClick = (s: StudyListView) => {
    // clear session variables when transitioning to study for the first time
    clearSessionStorage(STUDY_CASE_PROCID);
    clearSessionStorage(STUDY_CASE_STATUS);
    clearSessionStorage(STUDY_CASE_ASSIGNEE);

    clearSessionStorage(STUDY_IMAGE_NAME);
    clearSessionStorage(STUDY_IMAGE_HAS_QUERIES);
    clearSessionStorage(STUDY_IMAGE_IS_UNASSIGNED);

    navigate(`/studies/${s.studyView.study.id}/cases`);
  };

  const createStudyButton = isUserAdmin ? (
    <Link to={`/studies/new/configure/`} style={{ textDecoration: "none" }}>
      <Button iconBefore="plus" appearance="prominent" intent="primary">
        Create Study
      </Button>
    </Link>
  ) : null;

  const content =
    "errorMessage" in studies ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{studies.errorMessage}</Text>
        </Callout>
      </Box>
    ) : (
      <TableContainer>
        <TableFilters>
          <Box display="flex" width="100%">
            <DebouncedTextInput
              key="studies-search"
              width="auto"
              defaultValue={studiesFilter || ""}
              placeholder={"Search by name"}
              onValueChange={name => store.dispatch(setStudiesFilter(name))}
            />
            <Box ml="auto">{createStudyButton}</Box>
          </Box>
        </TableFilters>
        {"resource" in studies ? (
          <StyledDataTable
            columns={defineColumns(isUserAdminIsc, isUserReader, canSeeOnHold)}
            data={data}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={onRowClick}
            sortIcon={<Icon name="caretUp" />}
            className="data-table"
            noHeader={true}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationPerPage={studyRowsPerPage}
            onChangeRowsPerPage={setStudyRowsPerPage}
            customTheme={dataTableTheme}
            {...sortProps}
          />
        ) : null}
      </TableContainer>
    );

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Studies</PageHeading>
        </PageHeader>
        <Content isLoading={"isPending" in user}>{content}</Content>
      </Box>
    </Page>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    studies: state.studies.studies,
    studiesFilter: state.studies.studiesFilter,
    user: state.auth
  };
}

export default connect(mapStateToProps)(Studies);
