import { Box } from "@blasterjs/core";
import React from "react";

import { toggleSidebarExpanded } from "../actions/imageViewer";
import { ImageAndQuery, QueryObjectType, QueryWindowLocation, User } from "../models";
import store from "../store";
import { Resource } from "../types";
import { CollapseIcon } from "./Expandable";
import ImageInfo from "./ImageInfo";
import InfoSidebar from "./InfoSidebar";
import { QueryBox } from "./QueryWindow";
import QueryIcon from "./QueryIcon";
import QueryText from "./QueryText";
import QueryWindow from "./QueryWindow";
import ReplaceImage from "./ReplaceImage";

interface Props {
  readonly imageAndQuery: ImageAndQuery;
  readonly user: Resource<User>;
}

const collapseIcon = <CollapseIcon onToggle={() => store.dispatch(toggleSidebarExpanded())} />;

const InfoSidebarImage = ({ imageAndQuery, user }: Props) => {
  return (
    <InfoSidebar>
      <ImageInfo image={imageAndQuery.image} collapseIcon={collapseIcon} />
      <QueryBox>
        <QueryIcon imageAndQuery={imageAndQuery} includeLabel={true} />
        {"resource" in user && imageAndQuery.image.caseId ? (
          <QueryWindow
            studyId={imageAndQuery.image.studyId}
            caseId={imageAndQuery.image.caseId}
            queryObjectType={QueryObjectType.Image}
            imageAndQuery={imageAndQuery}
            displayShowToReader={false}
            user={user.resource}
            queryRecord={imageAndQuery.lastLabQuery}
            queryWindowLocation={QueryWindowLocation.Image}
          />
        ) : null}
      </QueryBox>
      {imageAndQuery.query && (
        <QueryBox>
          <QueryIcon imageAndQuery={imageAndQuery} includeLabel={true} />
          <Box>
            <QueryText imageAndQuery={imageAndQuery} />
          </Box>
          <ReplaceImage imageAndQuery={imageAndQuery} />
        </QueryBox>
      )}
    </InfoSidebar>
  );
};

export default InfoSidebarImage;
