import {
  Annotation,
  EllipseAnnotation as EllipseAnnotationType,
  FreehandAnnotation as FreehandAnnotationType,
  HPFAnnotation as HPFAnnotationType,
  LineAnnotation as LineAnnotationType,
  PointAnnotation as PointAnnotationType,
  ProcessedImageWithAnnotations,
  TextAnnotation as TextAnnotationType,
  User,
  ZoomDefinition
} from "../models";
import { SelectedAnnotationType } from "../actions/annotation";
import React, { useEffect, useState } from "react";
import { TileLayer, useMapEvents } from "react-leaflet";
import { formatDate, formatMicrometers, toMicrometers } from "../utils";
import { createHpfImageAnnotation } from "../actions/annotation";

import EllipseAnnotationWithPopup from "../components/EllipseAnnotation";
import FreehandAnnotation from "../components/FreehandAnnotation";
import HPFAnnotation, { HPF_CURSOR } from "../components/HPFAnnotation";
import LineAnnotation from "../components/LineAnnotation";
import PointAnnotation from "../components/PointAnnotation";
import { Resource } from "../types";
import TextMarker from "../components/TextMarker";
import { checkLoggedIn } from "../actions/auth";
import store from "../store";
import { throttle } from "lodash";
import { range } from "ramda";
import { LatLngLiteral, LeafletEvent, LeafletEventHandlerFnMap } from "leaflet";

interface ImageMapProps {
  readonly minZoom: number;
  readonly maxZoom: number;
  readonly allAnnotations: ReadonlyArray<Annotation>;
  readonly userCanDeleteAnnotations: boolean;
  readonly scaleFactor: number;
  readonly isAnnotationInProgress: boolean;
  readonly imageWithAnnotations: ProcessedImageWithAnnotations;
  readonly user: Resource<User>;
  readonly selectedAnnotation: SelectedAnnotationType | null;
  readonly tempHpf: LatLngLiteral | null;
  readonly hpfRadius: number;
  readonly zoom: number;
  readonly magnifications: ReadonlyArray<ZoomDefinition>;
  readonly isMicroscopeActive: boolean;
  readonly isSidebarExpanded: boolean;
  readonly resolutionInMeters: number;
  readonly tileUrl: string;
  readonly hpfCursorVisible: boolean;
  readonly hpfCursorColor: string | null;
  readonly showLineLabels: boolean;
  readonly spacebarDown: boolean;
}

const ImageMap = ({
  minZoom,
  maxZoom,
  allAnnotations,
  userCanDeleteAnnotations,
  scaleFactor,
  isAnnotationInProgress,
  imageWithAnnotations,
  tempHpf,
  hpfRadius,
  isSidebarExpanded,
  resolutionInMeters,
  tileUrl,
  hpfCursorVisible,
  hpfCursorColor,
  showLineLabels,
  spacebarDown
}: ImageMapProps) => {
  const [hpfCursor, setHpfCursor] = useState({ latlng: null, hold: tempHpf });

  if (tempHpf === null && hpfCursor.hold !== null) {
    setTimeout(() => setHpfCursor({ ...hpfCursor, hold: null }), 170);
  }

  const eventHandlers: LeafletEventHandlerFnMap = {
    click: (event: LeafletEvent) => {
      const e = event as any;
      if (hpfCursorVisible && !spacebarDown) {
        const noop = () => 0;
        setHpfCursor({ ...hpfCursor, hold: hpfCursor.latlng });
        store.dispatch(createHpfImageAnnotation(e.latlng, hpfRadius, noop));
      }
    },
    mousemove: (event: LeafletEvent) => {
      const e = event as any;
      if (hpfCursorVisible) {
        setHpfCursor({ ...hpfCursor, latlng: e.latlng });
      }
    }
  };

  const mapElement = useMapEvents(eventHandlers);

  useEffect(() => {
    // Collapsing/expanding the sidebar changes the map size. When this happens, we need to tell
    // Leaflet to update the map so tiles load properly.
    //
    mapElement.invalidateSize();
  }, [isSidebarExpanded]);

  const { pixelSize, zStackSize } = imageWithAnnotations.imageAndQuery.image;

  const zStackTileUrls =
    zStackSize && zStackSize > 1
      ? range(1, zStackSize).map(
          (index: number) => [index, tileUrl + `?zStackIndex=${index}`] as readonly [number, string]
        )
      : [];

  //const getMinLabelWidth = () => {
  //  const LINE_LABEL_WIDTH = 65; // Usually ~55px, going slightly higher for safety
  //  return mapDistanceToMicrometers(mapElement, LINE_LABEL_WIDTH, pixelSize, resolutionInMeters);
  //};

  // ANNOTATIONS
  const freehandAnnotations: ReadonlyArray<FreehandAnnotationType> = allAnnotations
    ? allAnnotations.filter(
        (annotation): annotation is FreehandAnnotationType => annotation.geometry.type === "Polygon"
      )
    : [];
  const lineAnnotations: ReadonlyArray<LineAnnotationType> = allAnnotations
    ? allAnnotations.filter(
        (annotation): annotation is LineAnnotationType => annotation.geometry.type === "LineString"
      )
    : [];

  const textAnnotations: ReadonlyArray<TextAnnotationType> = allAnnotations
    ? allAnnotations.filter(
        (annotation): annotation is TextAnnotationType =>
          "text" in annotation && !("tilt" in annotation) && !("radius" in annotation)
      )
    : [];
  const ellipseAnnotations: ReadonlyArray<EllipseAnnotationType> = allAnnotations
    ? allAnnotations.filter(
        (annotation): annotation is EllipseAnnotationType => "tilt" in annotation
      )
    : [];
  const hpfAnnotations: ReadonlyArray<HPFAnnotationType> = allAnnotations
    ? allAnnotations.filter((annotation): annotation is HPFAnnotationType => "radius" in annotation)
    : [];
  function getHpfAnnos(
    hpfAnnotations: ReadonlyArray<HPFAnnotationType>
  ): ReadonlyArray<HPFAnnotationType> {
    return hpfAnnotations;
  }
  const pointAnnotations: ReadonlyArray<PointAnnotationType> = allAnnotations
    ? allAnnotations.filter(
        (annotation): annotation is PointAnnotationType =>
          annotation.geometry.type === "Point" && !("text" in annotation)
      )
    : [];

  function isHpfCursorVisible(): boolean {
    return hpfCursorVisible === true;
  }

  return (
    <>
      {/* This tile layer is to avoid empty tiles while tiles for z-stacked images or other zoom levels are loading (since there is this tile layer fixed to the min zoom tiles, this tile layer is shown as the "background" tiles instead of empty tiles which are gray boxes) */}
      <TileLayer
        url={tileUrl}
        zIndex={100}
        noWrap={true}
        minZoom={minZoom}
        maxNativeZoom={minZoom}
        eventHandlers={eventHandlers}
      />
      <TileLayer
        url={tileUrl}
        zIndex={101}
        noWrap={true}
        maxZoom={maxZoom}
        eventHandlers={{
          error: throttle(() => {
            // A tile has errored. Unfortunately, the HTTP response is not available at this stage
            // (see https://github.com/Leaflet/Leaflet/issues/5437#issuecomment-291863493). That
            // said, this _should_ only happen if the user's session has timed out. However, we
            // don't want to redirect if there's a different problem with the tile server. Thus, we
            // re-fetch the user to trigger a redirect if appropriate.
            store.dispatch(checkLoggedIn());
          }, 2000)
        }}
      />
      {/* These TileLayers are for preloading z-stack images. We switch between them when scrolling between z-stacked images. */}
      {zStackTileUrls.map(([index, url]) => (
        <TileLayer
          key={url}
          url={url}
          eventHandlers={eventHandlers}
          zIndex={index}
          // The Leaflet types are off here, this `zStackIndex` goes into the options on the resulting layer
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          zStackIndex={index}
          noWrap={true}
          minZoom={maxZoom}
          maxZoom={maxZoom}
        />
      ))}
      {/* NOTE: Order of annotation types matters here. The annotation types that should show up
          on the "bottom" -- the ones that other annotations, eg. points, can be drawn on top of --
          should be added to the map first. */}
      {freehandAnnotations.map(freehand => (
        <FreehandAnnotation
          key={freehand.id}
          date={formatDate(freehand.createdAt)}
          positions={freehand.geometry.coordinates}
          annotationId={freehand.id}
          color={freehand.color}
          isClickable={!isAnnotationInProgress}
          canDelete={userCanDeleteAnnotations}
        />
      ))}
      {getHpfAnnos(hpfAnnotations).map(hpf => (
        <HPFAnnotation
          key={hpf.id}
          text={hpf.text}
          date={formatDate(hpf.createdAt)}
          position={{ lat: hpf.geometry.coordinates[0], lng: hpf.geometry.coordinates[1] }}
          radius={hpf.radius}
          annotationId={hpf.id}
          color={hpf.color}
          isClickable={!isAnnotationInProgress}
          weight={hpf.weight || 5}
          dashArray=""
          canDelete={userCanDeleteAnnotations}
        />
      ))}
      {ellipseAnnotations.map(ellipse => (
        <EllipseAnnotationWithPopup
          key={ellipse.id}
          text={ellipse.text}
          date={formatDate(ellipse.createdAt)}
          position={{
            lat: ellipse.geometry.coordinates[0],
            lng: ellipse.geometry.coordinates[1]
          }}
          radii={[ellipse.radiusX, ellipse.radiusY]}
          tilt={ellipse.tilt}
          annotationId={ellipse.id}
          isClickable={!isAnnotationInProgress}
          canDelete={userCanDeleteAnnotations}
        />
      ))}
      {lineAnnotations.map(line => {
        const micrometers =
          pixelSize && line.length ? toMicrometers(line.length, pixelSize, resolutionInMeters) : 0;
        return (
          <LineAnnotation
            key={line.id}
            date={formatDate(line.createdAt)}
            positions={line.geometry.coordinates.map(p => ({ lat: p[0], lng: p[1] }))}
            text={micrometers ? formatMicrometers(micrometers) : ""}
            showLabel={showLineLabels}
            lineLength={micrometers}
            annotationId={line.id}
            canDelete={userCanDeleteAnnotations}
            maxZoom={maxZoom}
          />
        );
      })}
      {textAnnotations.map(marker => (
        <TextMarker
          key={marker.id}
          text={marker.text}
          date={formatDate(marker.createdAt)}
          position={{ lat: marker.geometry.coordinates[0], lng: marker.geometry.coordinates[1] }}
          annotationId={marker.id}
          canDelete={userCanDeleteAnnotations}
        />
      ))}
      {pointAnnotations.map((point: PointAnnotationType) => (
        <PointAnnotation
          key={point.id}
          date={formatDate(point.createdAt)}
          position={{ lat: point.geometry.coordinates[0], lng: point.geometry.coordinates[1] }}
          annotationId={point.id}
          color={point.color}
          weight={point.weight || 1}
          canDelete={userCanDeleteAnnotations}
          scaleFactor={scaleFactor}
        />
      ))}
      {isHpfCursorVisible() ? (
        hpfCursor.hold != null && typeof hpfCursor.hold !== "undefined" ? (
          <>
            <HPFAnnotation
              key={"hpf-temp"}
              text={"just a temp hpf"}
              date={"no-data"}
              position={hpfCursor.hold || { lat: 0, lng: 0 }}
              radius={hpfRadius}
              annotationId={"hpf-cursor-id"}
              color={hpfCursorColor}
              isClickable={false}
              weight={5}
              dashArray={""}
              canDelete={false}
            />
          </>
        ) : (
          <>
            <HPFAnnotation
              key={"hpf-cursor: " + (hpfCursorColor || "")}
              text={HPF_CURSOR}
              date={"no-data"}
              position={hpfCursor.latlng || { lat: 0, lng: 0 }}
              radius={hpfRadius}
              annotationId={"hpf-cursor-id"}
              color={hpfCursorColor}
              isClickable={false}
              weight={4}
              dashArray={"5, 10"}
              canDelete={false}
            />
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default ImageMap;
