import { Appearance, Box, Button, Icon } from "@blasterjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { casesFetch } from "../actions/cases";
import Avatar from "../components/Avatar";
import { StyledDataTable } from "../components/Table";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import Content from "./Content";
import {
  AdminCase,
  CaseAndCounts,
  CaseAndCountsAdminView,
  CasesAndCounts,
  formatCaseStatus,
  User,
  userIsAdminOrIsc,
  UserRole,
  UUID
} from "../models";
import { State } from "../reducers";
import store from "../store";
import { dataTableTheme } from "../theme";
import { Resource } from "../types";

import { openEditCaseDialog } from "../actions/caseDialog";
import CaseDialog from "./CaseDialog";
import Timestamp from "../components/Timestamp";
import { openCaseStatusDialog } from "../actions/caseStatusDialog";

import CaseStatusDialog from "./CaseStatusDialog";
import { useLocalIntStorage } from "../storage";

interface Props {
  readonly studyId: UUID;
  readonly user: Resource<User>;
}

interface StateProps {
  readonly cases: Resource<CasesAndCounts>;
}

const editCase = (histoCase: AdminCase) => () =>
  store.dispatch(openEditCaseDialog(histoCase.id, histoCase.studyId, false));

const editCaseStatus = (histoCase: AdminCase) => () =>
  store.dispatch(openCaseStatusDialog(histoCase));

const CasesTable = ({ studyId, user, cases }: Props & StateProps) => {
  useEffect(() => {
    store.dispatch(casesFetch(studyId));
  }, [studyId]);
  const navigate = useNavigate();
  const userIsReader = "resource" in user && user.resource.role === UserRole.Reader;
  const userCanEditCaseStatus =
    "resource" in user &&
    (user.resource.role === UserRole.ISC || user.resource.role === UserRole.Admin);
  const columns: ReadonlyArray<object> = [
    ...[
      {
        name: "Procedure ID",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.procId,
        sortable: true,
        grow: 1
      },
      {
        name: "Created",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.createdAt,
        sortable: true,
        grow: 1,
        format: (caseAndCounts: CaseAndCounts) => (
          <Timestamp date={caseAndCounts.caseWithStatus.createdAt} />
        )
      }
    ],
    ...(userIsReader
      ? []
      : [
          {
            name: "Subject ID",
            selector: (caseAndCounts: CaseAndCountsAdminView) =>
              caseAndCounts.caseWithStatus.subjectId,
            sortable: true,
            grow: 1
          },
          {
            name: "Visit ID",
            selector: (caseAndCounts: CaseAndCountsAdminView) =>
              caseAndCounts.caseWithStatus.visitId,
            sortable: true,
            grow: 1
          }
        ]),
    ...[
      {
        name: "Status",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.status,
        sortable: true,
        grow: 1,
        format: (caseAndCounts: CaseAndCounts) =>
          formatCaseStatus(caseAndCounts.caseWithStatus.status)
      },
      ...(userIsReader
        ? []
        : [
            {
              name: "QC",
              selector: (caseInfo: CaseAndCountsAdminView) => caseInfo.qc1User,
              sortable: true,
              width: "64px",
              format: (caseInfo: CaseAndCountsAdminView) =>
                caseInfo.qc1User ? <Avatar user={caseInfo.qc1User} /> : "—"
            },
            {
              name: "Assigned",
              selector: (caseInfo: CaseAndCountsAdminView) => caseInfo.assignedReaders,
              sortable: true,
              width: "128px",
              format: (caseInfo: CaseAndCountsAdminView) => {
                const firstAssignedReader = caseInfo.assignedReaders[0];
                const secondAssignedReader = caseInfo.assignedReaders[1];
                return caseInfo.assignedReaders.length ? (
                  <span>
                    {firstAssignedReader && <Avatar user={firstAssignedReader} />}
                    {secondAssignedReader && (
                      <Box ml="3px" mr="3px" display="inline">
                        <Avatar user={secondAssignedReader} />
                      </Box>
                    )}
                    {caseInfo.assignedReaders.length > 2 ? "…" : null}
                  </span>
                ) : (
                  "—"
                );
              }
            }
          ]),
      {
        name: "Images",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.numberOfImages,
        sortable: true,
        grow: 0
      }
    ],
    ...(userIsReader
      ? []
      : [
          {
            name: "Queries",
            selector: (caseAndCountsAdminView: CaseAndCountsAdminView) =>
              caseAndCountsAdminView.caseWithStatus.openQueries,
            sortable: true,
            grow: 0
          }
        ]),
    ...[
      {
        right: true,
        grow: 0,
        cell: (caseAndCounts: CaseAndCounts) =>
          "subjectId" in caseAndCounts.caseWithStatus &&
          "resource" in user &&
          userIsAdminOrIsc(user.resource.role) ? (
            <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
              <Icon name="menu" />
              <TooltipFlyout placement={"left-start"}>
                <TooltipLink onClick={editCase(caseAndCounts.caseWithStatus)}>Edit</TooltipLink>
                {caseAndCounts.caseWithStatus.prevWorkflowStatuses.length ? (
                  <TooltipLink onClick={editCaseStatus(caseAndCounts.caseWithStatus)}>
                    Revert status
                  </TooltipLink>
                ) : null}
              </TooltipFlyout>
            </Button>
          ) : null
      }
    ]
  ];
  const viewCase = (caseAndCounts: CaseAndCounts) =>
    navigate(
      `/studies/${caseAndCounts.caseWithStatus.studyId}/cases/${caseAndCounts.caseWithStatus.id}`
    );

  const [caseRowsPerPage, setCaseRowsPerPage] = useLocalIntStorage("caseRowsPerPage", 20);

  const caseStatusDialog = userCanEditCaseStatus ? <CaseStatusDialog /> : null;
  return (
    <Content isLoading={"isPending" in cases || "isPending" in user}>
      {"resource" in cases && "resource" in user ? (
        <StyledDataTable
          columns={columns}
          data={cases.resource}
          highlightOnHover={true}
          pointerOnHover={true}
          defaultSortField="caseWithStatus.createdAt"
          defaultSortAsc={false}
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          onRowClicked={viewCase}
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={caseRowsPerPage}
          onChangeRowsPerPage={setCaseRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
      <CaseDialog />
      {caseStatusDialog}
    </Content>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    cases: state.cases.cases
  };
}

export default connect(mapStateToProps)(CasesTable);
