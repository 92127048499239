import {
  FixedQueryCategory,
  ImageAndQueries,
  ImageWithAnnotations,
  OtherQueryCategory,
  UUID
} from "../models";
import { CaseViewerAction } from "./caseViewer";

// NOTE: Query categories can be null because a category may not yet have been chosen
export type QueryEditorForm =
  | {
      readonly category: FixedQueryCategory | null;
    }
  | {
      readonly category: OtherQueryCategory;
      readonly text: string;
    };

export enum ActionTypes {
  IMAGE_FETCH = "IMAGE_FETCH",
  IMAGE_FETCH_SUCCESS = "IMAGE_FETCH_SUCCESS",
  IMAGE_FETCH_FAILURE = "IMAGE_FETCH_FAILURE",
  SELECT_IMAGE = "SELECT_IMAGE",
  REFRESH_IMAGE = "REFRESH_IMAGE",
  REFRESH_IMAGE_SUCCESS = "REFRESH_IMAGE_SUCCESS",
  REFRESH_IMAGE_FAILURE = "REFRESH_IMAGE_FAILURE",
  SET_QUERY = "SET_QUERY",
  SET_QUERY_SUCCESS = "SET_QUERY_SUCCESS",
  SET_QUERY_FAILURE = "SET_QUERY_FAILURE",
  TOGGLE_IMAGE_VISIBILITY = "TOGGLE_IMAGE_VISIBILITY",
  TOGGLE_IMAGE_VISIBILITY_SUCCESS = "TOGGLE_IMAGE_VISIBILITY_SUCCESS",
  TOGGLE_IMAGE_VISIBILITY_FAILURE = "TOGGLE_IMAGE_VISIBILITY_FAILURE",
  REOPEN_QUERY = "REOPEN_QUERY",
  REOPEN_QUERY_SUCCESS = "REOPEN_QUERY_SUCCESS",
  REOPEN_QUERY_FAILURE = "REOPEN_QUERY_FAILURE",
  ADJUST_BRIGHTNESS = "ADJUST_BRIGHTNESS",
  TOGGLE_ANNOTATIONS = "TOGGLE_ANNOTATIONS",
  SET_QUERY_EDITOR_VALUE = "SET_QUERY_EDITOR_VALUE",
  CANCEL_QUERY_EDIT = "CANCEL_QUERY_EDIT",
  DELETE_IMAGE_QUERY = "DELETE_IMAGE_QUERY",
  DELETE_IMAGE_QUERY_SUCCESS = "DELETE_IMAGE_QUERY_SUCCESS",
  DELETE_IMAGE_QUERY_FAILURE = "DELETE_IMAGE_QUERY_FAILURE",
  TOGGLE_SIDEBAR_EXPANDED = "TOGGLE_SIDEBAR_EXPANDED",
  TOGGLE_MICROSCOPE_ACTIVE = "TOGGLE_MICROSCOPE_ACTIVE"
}

export type ImageViewerAction =
  | {
      readonly type: ActionTypes.IMAGE_FETCH;
      readonly imageId: UUID;
    }
  | { readonly type: ActionTypes.IMAGE_FETCH_SUCCESS; readonly image: ImageWithAnnotations }
  | { readonly type: ActionTypes.IMAGE_FETCH_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.SELECT_IMAGE;
      readonly imageId: UUID;
    }
  | {
      readonly type: ActionTypes.REFRESH_IMAGE;
      readonly imageId: UUID;
      readonly refreshCaseOnSuccess?: boolean;
    }
  | {
      readonly type: ActionTypes.REFRESH_IMAGE_SUCCESS;
      readonly image: ImageWithAnnotations;
      readonly refreshCaseOnSuccess: boolean;
    }
  | {
      readonly type: ActionTypes.REFRESH_IMAGE_FAILURE;
      readonly errorMsg: string;
    }
  | {
      readonly type: ActionTypes.DELETE_IMAGE_QUERY;
    }
  | { readonly type: ActionTypes.DELETE_IMAGE_QUERY_SUCCESS; readonly image: ImageWithAnnotations }
  | { readonly type: ActionTypes.DELETE_IMAGE_QUERY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.SET_QUERY;
    }
  | { readonly type: ActionTypes.SET_QUERY_SUCCESS; readonly image: ImageWithAnnotations }
  | { readonly type: ActionTypes.SET_QUERY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.TOGGLE_IMAGE_VISIBILITY;
    }
  | {
      readonly type: ActionTypes.TOGGLE_IMAGE_VISIBILITY_SUCCESS;
      readonly images: ImageAndQueries;
    }
  | { readonly type: ActionTypes.TOGGLE_IMAGE_VISIBILITY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.REOPEN_QUERY;
    }
  | {
      readonly type: ActionTypes.REOPEN_QUERY_SUCCESS;
      readonly images: ImageAndQueries;
    }
  | { readonly type: ActionTypes.REOPEN_QUERY_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.ADJUST_BRIGHTNESS; readonly adjustment: number }
  | { readonly type: ActionTypes.TOGGLE_ANNOTATIONS }
  | {
      readonly type: ActionTypes.SET_QUERY_EDITOR_VALUE;
      readonly queryValue: QueryEditorForm | null;
    }
  | { readonly type: ActionTypes.CANCEL_QUERY_EDIT }
  | { readonly type: ActionTypes.TOGGLE_SIDEBAR_EXPANDED }
  | { readonly type: ActionTypes.TOGGLE_MICROSCOPE_ACTIVE };

export function imageFetch(imageId: UUID): ImageViewerAction {
  return {
    type: ActionTypes.IMAGE_FETCH,
    imageId
  };
}

export function imageFetchSuccess(image: ImageWithAnnotations): ImageViewerAction {
  return {
    type: ActionTypes.IMAGE_FETCH_SUCCESS,
    image
  };
}

export function imageFetchFailure(errorMsg: string): ImageViewerAction {
  return {
    type: ActionTypes.IMAGE_FETCH_FAILURE,
    errorMsg
  };
}

export function selectImage(imageId: UUID): ImageViewerAction {
  return {
    type: ActionTypes.SELECT_IMAGE,
    imageId
  };
}

export function refreshImage(
  imageId: UUID,
  refreshCaseOnSuccess?: boolean
): ImageViewerAction | CaseViewerAction {
  return {
    type: ActionTypes.REFRESH_IMAGE,
    imageId,
    refreshCaseOnSuccess
  };
}

export const refreshImageSuccess = (refreshCaseOnSuccess: boolean) => (
  image: ImageWithAnnotations
): ImageViewerAction => ({
  type: ActionTypes.REFRESH_IMAGE_SUCCESS,
  image,
  refreshCaseOnSuccess
});

export function refreshImageFailure(errorMsg: string): ImageViewerAction {
  return {
    type: ActionTypes.REFRESH_IMAGE_FAILURE,
    errorMsg
  };
}

export function hideImageForReader(): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_IMAGE_VISIBILITY
  };
}

export function hideImageForReaderSuccess(images: ImageAndQueries): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_IMAGE_VISIBILITY_SUCCESS,
    images
  };
}

export function hideImageForReaderFailure(errorMsg: string): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_IMAGE_VISIBILITY_FAILURE,
    errorMsg
  };
}

export function adjustBrightness(adjustment: number): ImageViewerAction {
  return {
    type: ActionTypes.ADJUST_BRIGHTNESS,
    adjustment
  };
}

export function toggleAnnotations(): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_ANNOTATIONS
  };
}

export function setQueryEditorValue(queryValue: QueryEditorForm | null): ImageViewerAction {
  return {
    type: ActionTypes.SET_QUERY_EDITOR_VALUE,
    queryValue
  };
}

export function cancelQueryEdit(): ImageViewerAction {
  return {
    type: ActionTypes.CANCEL_QUERY_EDIT
  };
}

export function toggleSidebarExpanded(): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_SIDEBAR_EXPANDED
  };
}

export function toggleMicroscropeActive(): ImageViewerAction {
  return {
    type: ActionTypes.TOGGLE_MICROSCOPE_ACTIVE
  };
}
