import { Heading } from "@blasterjs/core";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import { Nav, NavLink } from "./Nav";

interface PageProps {
  readonly children: React.ReactNode;
}

/*
 * Simple wrapper component for pages with a header.
 */
const Page = ({ children }: PageProps) => (
  <>
    <Header />
    <main>{children}</main>
  </>
);

const StyledTabs = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 144rem;
  margin-top: -2rem;
`;

interface PageHeadingProps {
  readonly children: React.ReactNode;
}

const PageHeading = ({ children }: PageHeadingProps) => <Heading as="h1">{children}</Heading>;
const PageBannerHeadingDiv = styled.div`
  margin-left: 8px;
`;
const PageHeader = styled.div`
  display: flex;
  max-width: 100%;
  width: 144rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.theme.space[4]};
  padding-bottom: ${props => props.theme.space[3]};
  align-items: center;
`;

const PageBannerHeading = ({ children }: PageHeadingProps) => (
  <PageBannerHeadingDiv>
    <Heading as="h3">{children}</Heading>
  </PageBannerHeadingDiv>
);

const PageBanner = styled.div`
  display: flex;
  max-width: 100%;
  width: 144rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.theme.space[4]};
  padding-bottom: ${props => props.theme.space[3]};
  align-items: center;
  background-color: #ff9a40;
`;

const PageBody = styled.div`
  max-width: 100%;
  width: 144rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dde0e6;
  padding: 1.2rem;
  background-color: #fff;
`;

const PageActions = styled.div`
  margin-left: auto;
`;

interface TabLink {
  readonly to: string;
  readonly label: string;
}

interface PageTabsProps {
  readonly links: ReadonlyArray<TabLink>;
}

const PageTabs = ({ links }: PageTabsProps) => {
  const location = useLocation();
  return (
    <StyledTabs>
      <Nav type="tabs">
        {links.map(({ label, to }) => (
          <NavLink type="tabs" key={label} to={to} path={location.pathname}>
            {label}
          </NavLink>
        ))}
      </Nav>
    </StyledTabs>
  );
};

export { PageBody, PageHeading, PageHeader, PageBanner, PageBannerHeading, PageActions, PageTabs };
export default Page;
