import { Button } from "@blasterjs/core";
import React from "react";
import { connect } from "react-redux";

import { openUploadDialog } from "../actions/uploadDialog";
import { State } from "../reducers";

import { ImageAndQuery, QueryStatus, User, userCanUpload } from "../models";
import store from "../store";
import { Resource } from "../types";
import UploadDialog from "../components/UploadDialog";

interface StateProps {
  readonly user: Resource<User>;
}

interface Props {
  readonly imageAndQuery: ImageAndQuery;
  readonly refreshCase?: true;
}

const ReplaceImage = ({ user, imageAndQuery, refreshCase }: Props & StateProps) => {
  const canUpload = userCanUpload(("resource" in user && user.resource.role) || null);
  const replaceImageButton =
    canUpload &&
    imageAndQuery.lastLabQuery &&
    imageAndQuery.lastLabQuery.queryStatus === QueryStatus.Open ? (
      <Button
        mt={1}
        block={true}
        onClick={() =>
          store.dispatch(
            openUploadDialog(imageAndQuery.image.studyId, imageAndQuery.image, true, refreshCase)
          )
        }
      >
        Replace Image
      </Button>
    ) : null;
  const uploadDialog = canUpload ? <UploadDialog /> : null;
  return imageAndQuery.lastLabQuery && canUpload ? (
    <>
      {replaceImageButton}
      {uploadDialog}
    </>
  ) : null;
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth
  };
}

export default connect(mapStateToProps)(ReplaceImage);
