import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Callout, Intent, Text } from "@blasterjs/core";
import Page from "../components/Page";
import Content from "../components/Content";

import { Config, User } from "../models";
import { Resource } from "../types";

interface StateProps {
  readonly user: Resource<User>;
  readonly config: Resource<Config>;
}

type Props = StateProps;

const Login = ({ user, config }: Props) => {
  const configErrorText =
    "errorMessage" in config ? (
      <Callout intent={Intent.DANGER}>
        <Text>{config.errorMessage}</Text>
      </Callout>
    ) : null;
  const loginErrorText =
    "errorMessage" in user ? (
      <Callout intent={Intent.WARNING}>
        <Text>{user.errorMessage}</Text>
      </Callout>
    ) : null;
  const errorText = configErrorText || loginErrorText;
  const isLoading =
    ("isPending" in config && config.isPending) || ("isPending" in user && user.isPending);
  const content = errorText ? errorText : null;
  return (
    <Page>
      <Content isLoading={isLoading}>{content}</Content>
    </Page>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth,
    config: state.config
  };
}

export default connect(mapStateToProps)(Login);
