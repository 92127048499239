import { AddReasonForChange, Image, NestValues } from "../models";

export type ImageMetadataForm = AddReasonForChange<
  NestValues<{
    readonly accessionNumber: string | null;
    readonly biopsyLocation: string | null;
  }>
>;

export enum ActionTypes {
  OPEN_IMAGE_DIALOG = "OPEN_IMAGE_DIALOG",
  CLOSE_IMAGE_DIALOG = "CLOSE_IMAGE_DIALOG",
  EDIT_IMAGE = "EDIT_IMAGE",
  UPDATE_IMAGE = "UPDATE_IMAGE",
  UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS",
  UPDATE_IMAGE_FAILURE = "UPDATE_IMAGE_FAILURE"
}

export type ImageDialogAction =
  | {
      readonly type: ActionTypes.OPEN_IMAGE_DIALOG;
      readonly image: Image;
      readonly refreshSingleImageOnSuccess: boolean;
    }
  | { readonly type: ActionTypes.CLOSE_IMAGE_DIALOG }
  | { readonly type: ActionTypes.EDIT_IMAGE; readonly form: ImageMetadataForm }
  | { readonly type: ActionTypes.UPDATE_IMAGE }
  | { readonly type: ActionTypes.UPDATE_IMAGE_SUCCESS }
  | { readonly type: ActionTypes.UPDATE_IMAGE_FAILURE; readonly errorMsg: string };

export function openImageDialog(
  image: Image,
  refreshSingleImageOnSuccess: boolean
): ImageDialogAction {
  return {
    type: ActionTypes.OPEN_IMAGE_DIALOG,
    image,
    refreshSingleImageOnSuccess
  };
}

export function closeImageDialog(): ImageDialogAction {
  return {
    type: ActionTypes.CLOSE_IMAGE_DIALOG
  };
}

export function editImage(form: ImageMetadataForm): ImageDialogAction {
  return {
    type: ActionTypes.EDIT_IMAGE,
    form
  };
}

export function updateImage(): ImageDialogAction {
  return {
    type: ActionTypes.UPDATE_IMAGE
  };
}

export function updateImageSuccess(): ImageDialogAction {
  return {
    type: ActionTypes.UPDATE_IMAGE_SUCCESS
  };
}

export function updateImageFailure(errorMsg: string): ImageDialogAction {
  return {
    type: ActionTypes.UPDATE_IMAGE_FAILURE,
    errorMsg
  };
}
