import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { userFetch } from "../actions/auth";
import { ActionTypes, configFetchFailure, configFetchSuccess } from "../actions/config";

import { fetchConfig } from "../api";
import { Config } from "../models";
import { Resource } from "../types";

const initialState = {
  isPending: false
};

const configReducer: LoopReducer<Resource<Config>, Action> = (
  state: Resource<Config> = initialState,
  action: Action
): Resource<Config> | Loop<Resource<Config>, Action> => {
  switch (action.type) {
    case ActionTypes.CONFIG_FETCH:
      return loop(
        {
          isPending: true
        },
        Cmd.run(fetchConfig, {
          successActionCreator: configFetchSuccess,
          failActionCreator: configFetchFailure,
          args: []
        })
      );
    case ActionTypes.CONFIG_FETCH_SUCCESS:
      return loop(
        {
          resource: action.config
        },
        Cmd.action(userFetch())
      );
    case ActionTypes.CONFIG_FETCH_FAILURE:
      return {
        errorMessage: action.errorMsg
      };
    default:
      return state;
  }
};

export default configReducer;
