import React, { useState } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Button, Callout, Dialog, Intent, Text, TextInput } from "@blasterjs/core";

import { closeCopyImageDialog, copyImage, setCopyImageForm } from "../actions/copyImageDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { CopyImageDialogState } from "../reducers/copyImageDialog";
import store from "../store";

interface Props {
  readonly copyImageDialog: CopyImageDialogState;
}

const CopyImageDialog = ({ copyImageDialog }: Props) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const closeDialog = () => {
    store.dispatch(closeCopyImageDialog());
  };

  const onConfirm = (_: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationDialogOpen(false);
    store.dispatch(copyImage());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    copyImageDialog.isOpen &&
      store.dispatch(
        setCopyImageForm({
          duplicateImageName: e.target.value
        })
      );
  };

  const errorText =
    copyImageDialog.isOpen && "errorMessage" in copyImageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{copyImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return (
    <>
      <Dialog
        isOpen={copyImageDialog.isOpen && !isConfirmationDialogOpen}
        onRequestClose={closeDialog}
        appElementId="root"
      >
        <DialogHeader title="Copy Image" closeDialog={closeDialog} />
        <DialogBody>
          {errorText}
          <Box style={{ marginTop: 12 }}>
            <TextInput
              value={
                (copyImageDialog.isOpen && copyImageDialog.image.data.duplicateImageName) || ""
              }
              onChange={onChange}
            />
          </Box>
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button
              onClick={onConfirm}
              appearance="prominent"
              intent="primary"
              isLoading={
                copyImageDialog.isOpen &&
                "isPending" in copyImageDialog.image &&
                copyImageDialog.image.isPending
              }
            >
              Copy image
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};

function mapStateToProps(state: State): Props {
  return {
    copyImageDialog: state.copyImageDialog
  };
}

export default connect(mapStateToProps)(CopyImageDialog);
