import { MetadataImage, MetadataImageType } from "../models";

export enum ActionTypes {
  FETCH_METADATA_IMAGE = "FETCH_METADATA_IMAGE",
  FETCH_METADATA_IMAGE_SUCCESS = "FETCH_METADATA_IMAGE_SUCCESS",
  FETCH_METADATA_IMAGE_FAILURE = "FETCH_METADATA_IMAGE_FAILURE",
  TOGGLE_ZOOM = "TOGGLE_ZOOM",
  TOGGLE_ANNOTATION_NEST_EXPANDED = "TOGGLE_ANNOTATION_NEST_EXPANDED"
}

export type AnnotationNestAction =
  | {
      readonly type: ActionTypes.FETCH_METADATA_IMAGE;
      readonly uri: string;
      readonly metadataType: MetadataImageType;
    }
  | {
      readonly type: ActionTypes.FETCH_METADATA_IMAGE_SUCCESS;
      readonly metadataImage: MetadataImage<MetadataImageType>;
    }
  | {
      readonly type: ActionTypes.FETCH_METADATA_IMAGE_FAILURE;
      readonly metadataType: MetadataImageType;
      readonly errorMessage: string;
    }
  | {
      readonly type: ActionTypes.TOGGLE_ZOOM;
    }
  | {
      readonly type: ActionTypes.TOGGLE_ANNOTATION_NEST_EXPANDED;
    };

export function fetchMetadataImage(
  uri: string,
  metadataType: MetadataImageType
): AnnotationNestAction {
  return {
    type: ActionTypes.FETCH_METADATA_IMAGE,
    uri,
    metadataType
  };
}

export function fetchMetadataImageSuccess(
  metadataImage: MetadataImage<MetadataImageType>
): AnnotationNestAction {
  return {
    type: ActionTypes.FETCH_METADATA_IMAGE_SUCCESS,
    metadataImage
  };
}

export const fetchMetadataImageFailure = (metadataType: MetadataImageType) => (
  errorMessage: string
): AnnotationNestAction => ({
  type: ActionTypes.FETCH_METADATA_IMAGE_FAILURE,
  metadataType,
  errorMessage
});

export function toggleZoom(): AnnotationNestAction {
  return {
    type: ActionTypes.TOGGLE_ZOOM
  };
}

export function toggleAnnotationNestExpanded(): AnnotationNestAction {
  return {
    type: ActionTypes.TOGGLE_ANNOTATION_NEST_EXPANDED
  };
}
