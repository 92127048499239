import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import {
  Box,
  Button,
  Callout,
  Dialog,
  FileInput,
  Intent,
  ProgressBar,
  Text
} from "@blasterjs/core";

import { changeFiles, closeUploadDialog, signedURLsCreate } from "../actions/uploadDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { UploadDialogState } from "../reducers/uploadDialog";
import store from "../store";

interface StateProps {
  readonly uploadDialog: UploadDialogState;
}

type Props = StateProps;

const UploadDialog = ({ uploadDialog }: Props) => {
  const closeDialog = () => {
    store.dispatch(closeUploadDialog());
  };
  const cancelUpload = () => {
    uploadDialog.currentUpload && uploadDialog.currentUpload.cancelTokenSource.cancel();
  };
  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.dispatch(changeFiles(e.target.files));
  };
  const uploadFiles = () => {
    store.dispatch(signedURLsCreate());
  };
  const errorText =
    "errorMessage" in uploadDialog.fileSignedURLs ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{uploadDialog.fileSignedURLs.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const isUploadInitiated =
    "isPending" in uploadDialog.fileSignedURLs && uploadDialog.fileSignedURLs.isPending;
  const isReplacement = uploadDialog.imageToReplace !== null;
  const otherFileInputArgs = isReplacement
    ? {} // Only allow user to select a single image when replacing
    : {
        multiple: "multiple"
      };

  return isUploadInitiated && uploadDialog.currentUpload !== null ? (
    <Dialog isOpen={uploadDialog.isOpen} appElementId="root">
      <DialogHeader title={isReplacement ? "Uploading Image..." : "Uploading Images..."} />
      <DialogBody>
        {Object.values(uploadDialog.currentUpload.uploadProgress).map((upload, idx) => {
          return (
            <Box key={idx}>
              <h3>{upload.file.name}</h3>
              <ProgressBar mt="2" value={upload.progress} max={upload.file.size} />
            </Box>
          );
        })}
      </DialogBody>
      <DialogFooter>
        <Button onClick={cancelUpload}>Stop</Button>
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog isOpen={uploadDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader
        title={isReplacement ? "Select Replacement Image" : "Select Images"}
        closeDialog={closeDialog}
      />
      <DialogBody>
        {errorText}
        <Box>
          <FileInput onChange={onChangeFiles} accept=".tif,.tiff,.svs" {...otherFileInputArgs} />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            disabled={uploadDialog.acceptButtonDisabled}
            onClick={uploadFiles}
            isLoading={isUploadInitiated}
            appearance="prominent"
            intent="primary"
          >
            Upload
          </Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    uploadDialog: state.uploadDialog
  };
}

export default connect(mapStateToProps)(UploadDialog);
