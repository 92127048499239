import L, { LatLngLiteral } from "leaflet";

import AnnotationPopup from "./AnnotationPopup";
import { GeoJSON } from "react-leaflet";
import React from "react";
import { UUID } from "../models";

interface EllipseProps {
  readonly text: string | null;
  readonly date: string;
  readonly radii: readonly [number, number];
  readonly tilt: number;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
}

type EllipseWithPopupProps = EllipseProps & {
  readonly isClickable: boolean;
  readonly canDelete: boolean;
};

const EllipseAnnotationWithPopup = ({
  text,
  date,
  annotationId,
  position,
  radii,
  tilt,
  canDelete
}: EllipseProps & EllipseWithPopupProps) => {
  const ellipse = new L.Ellipse({
    center: position,
    semiMinor: radii[0],
    semiMajor: radii[1],
    bearing: tilt
  });

  // Need to convert the ellipse to a React Leaflet geojson
  return (
    <GeoJSON
      key={annotationId}
      data={ellipse.toGeoJSON()}
      style={{ color: "black", fillOpacity: 0, weight: 3 }}
    >
      <AnnotationPopup text={text} date={date} annotationId={annotationId} canDelete={canDelete} />
    </GeoJSON>
  );
};

export default EllipseAnnotationWithPopup;
