import { Text } from "@blasterjs/core";
import React from "react";

import { formatQueryCategory, ImageAndQuery } from "../models";

interface Props {
  readonly imageAndQuery: ImageAndQuery;
}

const QueryText = ({ imageAndQuery }: Props) =>
  imageAndQuery.query ? (
    <Text ml={1} fontSize={1} mt="0.2rem">
      “
      {"text" in imageAndQuery.query.value
        ? imageAndQuery.query.value.text
        : formatQueryCategory(imageAndQuery.query.value.category)}
      ”
    </Text>
  ) : null;

export default QueryText;
