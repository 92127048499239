import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { casesFetch } from "../actions/cases";
import {
  ActionTypes,
  CaseStatusForm,
  revertCaseStatusFailure,
  revertCaseStatusSuccess
} from "../actions/caseStatusDialog";

import { revertCaseStatus } from "../api";
import { AdminCase } from "../models";
import { WriteResource } from "../types";

export type CaseStatusDialogState =
  | {
      readonly isOpen: true;
      readonly savedCase: AdminCase;
      readonly histoCase: WriteResource<CaseStatusForm, AdminCase>;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: CaseStatusDialogState = {
  isOpen: false
};

export const caseStatusDialogReducer: LoopReducer<CaseStatusDialogState, Action> = (
  state: CaseStatusDialogState = initialState,
  action: Action
): CaseStatusDialogState | Loop<CaseStatusDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_CASE_STATUS_DIALOG:
      return {
        isOpen: true,
        savedCase: action.histoCase,
        histoCase: {
          data: {
            status: null,
            reasonForChange: null
          }
        }
      };
    case ActionTypes.CLOSE_CASE_STATUS_DIALOG:
      return {
        isOpen: false
      };
    case ActionTypes.SET_CASE_STATUS_FORM:
      return state.isOpen
        ? {
            ...state,
            histoCase: {
              data: action.form
            }
          }
        : state;
    case ActionTypes.REVERT_CASE_STATUS:
      return state.isOpen
        ? loop(
            state,
            Cmd.run(revertCaseStatus, {
              successActionCreator: revertCaseStatusSuccess,
              failActionCreator: revertCaseStatusFailure,
              args: [
                state.savedCase.id,
                {
                  value: state.histoCase.data.status,
                  reasonForChange: state.histoCase.data.reasonForChange || undefined
                }
              ] as Parameters<typeof revertCaseStatus>
            })
          )
        : state;
    case ActionTypes.REVERT_CASE_STATUS_SUCCESS:
      return state.isOpen
        ? loop(initialState, Cmd.action(casesFetch(state.savedCase.studyId)))
        : state;
    case ActionTypes.REVERT_CASE_STATUS_FAILURE:
      return state.isOpen
        ? {
            ...state,
            histoCase: {
              data: state.histoCase.data,
              errorMessage: action.errorMsg
            }
          }
        : state;
    default:
      return state;
  }
};

export default caseStatusDialogReducer;
