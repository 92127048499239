import { Config } from "../models";

export enum ActionTypes {
  CONFIG_FETCH = "CONFIG_FETCH",
  CONFIG_FETCH_SUCCESS = "CONFIG_FETCH_SUCCESS",
  CONFIG_FETCH_FAILURE = "CONFIG_FETCH_FAILURE"
}

export type ConfigAction =
  | { readonly type: ActionTypes.CONFIG_FETCH }
  | { readonly type: ActionTypes.CONFIG_FETCH_SUCCESS; readonly config: Config }
  | { readonly type: ActionTypes.CONFIG_FETCH_FAILURE; readonly errorMsg: string };

export function configFetch(): ConfigAction {
  return {
    type: ActionTypes.CONFIG_FETCH
  };
}

export function configFetchSuccess(config: Config): ConfigAction {
  return {
    type: ActionTypes.CONFIG_FETCH_SUCCESS,
    config
  };
}

export function configFetchFailure(errorMsg: string): ConfigAction {
  return {
    type: ActionTypes.CONFIG_FETCH_FAILURE,
    errorMsg
  };
}
