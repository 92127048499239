import { Box, Callout, Intent, Text } from "@blasterjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { fetchMetadataImage, toggleMetadataExpanded, toggleZoom } from "../actions/metadataImages";
import { MetadataImage, MetadataImageType, UUID } from "../models";
import { State } from "../reducers";
import { MetadataImagesState } from "../reducers/metadataImages";
import store from "../store";
import { Resource } from "../types";
import Expandable, { CollapseIcon } from "./Expandable";

type StateProps = Pick<
  MetadataImagesState,
  "label" | "macro" | "zoomActive" | "isMetadataPanelExpanded"
>;

interface Props {
  readonly imageId: UUID | null;
}

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ZoomContainer = styled.figure`
  .active &:hover img {
    opacity: 0;
  }
`;

const ZoomImages = styled.div`
  &.active {
    cursor: zoom-out;
  }
  &.inactive {
    cursor: zoom-in;
  }
  @supports (-moz-appearance: none) {
    &.active {
      cursor: pointer;
    }
    &.inactive {
      cursor: pointer;
    }
  }
  > :last-child {
    border-top: 1px solid #ddd;
  }
`;

const CollapseIconContainer = styled.div`
  background: #fff;
  transform: scaleX(-1);
`;

interface ZoomImageProps {
  readonly width: number;
  readonly src: string;
  readonly alt: string;
}

/*
 * Image which zooms on mouse over.
 *
 * Based on: https://codepen.io/robertkirsz/pen/ZvorjB
 */
const ZoomImage = ({ width, src, alt }: ZoomImageProps) => {
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  return (
    <ZoomContainer
      onMouseMove={handleMouseMove}
      style={{
        width,
        backgroundImage: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          display: "block",
          width: "100%",
          pointerEvents: "none"
        }}
      />
    </ZoomContainer>
  );
};

const showImage = (metadataImage: Resource<MetadataImage<MetadataImageType>>) =>
  "resource" in metadataImage ? (
    <ZoomImage
      width={300}
      src={metadataImage.resource.imageData}
      alt={metadataImage.resource.metadataType === "label" ? "Label image" : "Macro image"}
    />
  ) : "errorMessage" in metadataImage ? (
    <Callout intent={Intent.DANGER}>
      <Text>{metadataImage.errorMessage}</Text>
    </Callout>
  ) : (
    <Box className="loader" />
  );

const MetadataDialog = ({
  imageId,
  label,
  macro,
  zoomActive,
  isMetadataPanelExpanded
}: StateProps & Props) => {
  useEffect(() => {
    store.dispatch(fetchMetadataImage(`/api/images/${imageId}/label`, "label"));
    store.dispatch(fetchMetadataImage(`/api/images/${imageId}/macro`, "macro"));
  }, [imageId]);
  return (
    <ImagesContainer>
      <Expandable
        label={"Macro/Label"}
        isExpanded={isMetadataPanelExpanded}
        onToggle={() => store.dispatch(toggleMetadataExpanded())}
      >
        <CollapseIconContainer>
          <CollapseIcon
            onToggle={(e: React.MouseEvent) => {
              e.stopPropagation();
              store.dispatch(toggleMetadataExpanded());
            }}
          />
        </CollapseIconContainer>
        <ZoomImages
          className={zoomActive ? "active" : "inactive"}
          onClick={() => store.dispatch(toggleZoom())}
        >
          {showImage(macro)}
          {showImage(label)}
        </ZoomImages>
      </Expandable>
    </ImagesContainer>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    label: state.metadataImages.label,
    macro: state.metadataImages.macro,
    zoomActive: state.metadataImages.zoomActive,
    isMetadataPanelExpanded: state.metadataImages.isMetadataPanelExpanded
  };
}

export default connect(mapStateToProps)(MetadataDialog);
