import { DateFilter, ReportStudyData } from "../models";

export type ReportType = "image-details" | "hpf-annotations";

export enum ActionTypes {
  SET_REPORT_STUDY_DATA = "SET_REPORT_STUDY_DATA",
  SET_REPORT_DATE_FILTER = "SET_REPORT_DATE_FILTER",
  REQUEST_REPORT_CSV = "REQUEST_REPORT_CSV",
  REQUEST_REPORT_CSV_SUCCESS = "REQUEST_REPORT_CSV_SUCCESS",
  REQUEST_REPORT_CSV_FAILURE = "REQUEST_REPORT_CSV_FAILURE"
}

export type ReportsAction =
  | {
      readonly type: ActionTypes.SET_REPORT_STUDY_DATA;
      readonly reportType: ReportType;
      readonly studyData: ReportStudyData;
    }
  | {
      readonly type: ActionTypes.SET_REPORT_DATE_FILTER;
      readonly reportType: ReportType;
      readonly dateFilter: DateFilter;
    }
  | { readonly type: ActionTypes.REQUEST_REPORT_CSV; readonly reportType: ReportType }
  | { readonly type: ActionTypes.REQUEST_REPORT_CSV_SUCCESS; readonly reportType: ReportType }
  | {
      readonly type: ActionTypes.REQUEST_REPORT_CSV_FAILURE;
      readonly reportType: ReportType;
      readonly errorMessage: string;
    };

export function setReportStudyData(
  reportType: ReportType,
  studyData: ReportStudyData
): ReportsAction {
  return {
    type: ActionTypes.SET_REPORT_STUDY_DATA,
    reportType,
    studyData
  };
}

export function setReportDateFilter(reportType: ReportType, dateFilter: DateFilter): ReportsAction {
  return {
    type: ActionTypes.SET_REPORT_DATE_FILTER,
    reportType,
    dateFilter
  };
}

export function requestReportCsv(reportType: ReportType): ReportsAction {
  return {
    type: ActionTypes.REQUEST_REPORT_CSV,
    reportType
  };
}

export const requestReportCsvSuccess = (reportType: ReportType) => (): ReportsAction => {
  return {
    type: ActionTypes.REQUEST_REPORT_CSV_SUCCESS,
    reportType
  };
};

export const requestReportCsvFailure = (reportType: ReportType) => (
  errorMessage: string
): ReportsAction => {
  return {
    type: ActionTypes.REQUEST_REPORT_CSV_FAILURE,
    reportType,
    errorMessage
  };
};
