import { Loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import { ActionTypes } from "../actions/locationMap";

export interface LocatorMapState {
  readonly isLocatorMapPanelExpanded: boolean;
}

export const initialState: LocatorMapState = {
  isLocatorMapPanelExpanded: true
};

export const locatorMapReducer: LoopReducer<LocatorMapState, Action> = (
  state: LocatorMapState = initialState,
  action: Action
): LocatorMapState | Loop<LocatorMapState, Action> => {
  switch (action.type) {
    case ActionTypes.TOGGLE_LOCATOR_MAP_PANEL_EXPANDED:
      return {
        ...state,
        isLocatorMapPanelExpanded: !state.isLocatorMapPanelExpanded
      };
    default:
      return state;
  }
};

export default locatorMapReducer;
