import { A, Appearance, Button, Image } from "@blasterjs/core";
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Avatar from "./Avatar";
import { Nav, NavLink } from "./Nav";

import { State } from "../reducers";

import { Config, User, UserRole } from "../models";
import { Resource } from "../types";
import TooltipFlyout, { TooltipLink } from "./Tooltip";

const HeaderContainer = styled.div`
  display: flex;
  height: ${props => props.theme.heights.appHeader.height};
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: 1;
  z-index: 10000;
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.4rem;
  margin-left: -1rem;
`;

const HeaderActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoStyle = styled(Image)`
  height: 3.1rem;
  width: 8.5rem;
  max-width: none;
`;

interface StateProps {
  readonly user: Resource<User>;
  readonly config: Resource<Config>;
}

type UserButtonProps = StateProps;
type HeaderProps = StateProps;

const UserButton = ({ user, config }: UserButtonProps) => {
  return "resource" in user ? (
    <Button
      data-tooltip={true}
      iconAfter="caretDown"
      isLoading={"isPending" in user}
      appearance={Appearance.MINIMAL}
    >
      <Avatar user={user.resource} />
      <TooltipFlyout placement={"bottom-end"}>
        <TooltipLink href={"resource" in config ? config.resource.ssoLoginUrl : "#"}>
          RCT Hub
        </TooltipLink>
        <TooltipLink href={"resource" in config ? config.resource.ssoLogoutUrl : "#"}>
          Logout
        </TooltipLink>
      </TooltipFlyout>
    </Button>
  ) : (
    <A
      href={"resource" in config ? config.resource.ssoLoginUrl : "#"}
      style={{ textDecoration: "none" }}
    >
      <Button isLoading={"isPending" in user} appearance={Appearance.MINIMAL}>
        Log in
      </Button>
    </A>
  );
};

const Header = ({ user, config }: HeaderProps) => {
  const location = useLocation();
  const usersLink = (
    <NavLink to="/users" path={location.pathname}>
      Users
    </NavLink>
  );
  const studiesLink = (
    <NavLink to="/studies" path={location.pathname}>
      Studies
    </NavLink>
  );
  const reportsLink = (
    <NavLink to="/reports" path={location.pathname}>
      Reports
    </NavLink>
  );
  const queriesLink = (
    <NavLink to="/queries" path={location.pathname}>
      Queries
    </NavLink>
  );
  const links =
    "resource" in user && user.resource.role === UserRole.Admin ? (
      <>
        {studiesLink}
        {usersLink}
        {reportsLink}
        {queriesLink}
      </>
    ) : "resource" in user && user.resource.role === UserRole.Readonly ? (
      <>
        {studiesLink}
        {reportsLink}
        {queriesLink}
      </>
    ) : "resource" in user && user.resource.role === UserRole.ISC ? (
      <>
        {studiesLink}
        {reportsLink}
        {queriesLink}
      </>
    ) : "resource" in user && user.resource.role === UserRole.Lab ? (
      <>
        {studiesLink}
        {queriesLink}
      </>
    ) : "resource" in user && user.resource.registered ? (
      studiesLink
    ) : null;
  return (
    <header>
      <HeaderContainer>
        <HeaderLogo>
          <NavLink logo={true} to="/" path={location.pathname} active={false}>
            <LogoStyle src="/images/lucidity-logo.png" alt="Lucidity" />
          </NavLink>
        </HeaderLogo>
        <Nav>{links}</Nav>
        <HeaderActions>
          <UserButton config={config} user={user} />
        </HeaderActions>
      </HeaderContainer>
    </header>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    user: state.auth,
    config: state.config
  };
}

export default connect(mapStateToProps)(Header);
