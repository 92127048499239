import { Box, Button, Callout, Checkbox, Intent, Label, Select, Text } from "@blasterjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { Ok } from "ts.data.json";
import { openCreateCaseDialog } from "../actions/caseDialog";
import { setCaseFilters } from "../actions/cases";
import { setImageFilters } from "../actions/images";
import { clearFilters, studyFetch } from "../actions/studies";
import { studyFetchRequest } from "../actions/studyConfiguration";
import { openUploadDialog } from "../actions/uploadDialog";
import { caseStatusDecoder } from "../decoders";
import {
  CaseStatus,
  formatCaseStatus,
  StudyAdminView,
  StudyView,
  User,
  userCanSeeCases,
  userCanSeeImages,
  userCanUpload,
  userCanViewStudyHold,
  userLabel,
  UserRole
} from "../models";
import { State } from "../reducers";
import { CasesState } from "../reducers/cases";
import { ImagesState } from "../reducers/images";
import store from "../store";
import { Resource } from "../types";

import CaseDialog from "../components/CaseDialog";
import CasesTable from "../components/CasesTable";
import DebouncedTextInput from "../components/DebouncedTextInput";
import Page, {
  PageActions,
  PageHeader,
  PageHeading,
  PageTabs,
  PageBanner,
  PageBannerHeading
} from "../components/Page";
import { TableContainer, TableFilters } from "../components/Table";
import Content from "../components/Content";
import ImagesTable from "../components/ImagesTable";
import UploadDialog from "../components/UploadDialog";
import { clearSessionStorage, useSessionStorage } from "../storage";
import {
  STUDY_CASE_ASSIGNEE,
  STUDY_CASE_PROCID,
  STUDY_CASE_STATUS,
  STUDY_IMAGE_HAS_QUERIES,
  STUDY_IMAGE_IS_UNASSIGNED,
  STUDY_IMAGE_NAME
} from "../storage";

interface StudyProps {
  readonly studyView: Resource<StudyView>;
  readonly user: Resource<User>;
  readonly images: ImagesState;
  readonly cases: CasesState;
}

enum Routes {
  Cases = "cases",
  Images = "images"
}

const Study = ({ studyView, user, images, cases }: StudyProps) => {
  const params = useParams();
  const id: string = params.id || "no-id";
  const tab: string = params.tab || Routes.Cases;

  const [studyCaseProcId, setStudyCaseProcId] = useSessionStorage(STUDY_CASE_PROCID, "");
  const [studyCaseStatus, setStudyCaseStatus] = useSessionStorage(STUDY_CASE_STATUS, "");
  const [studyCaseAssignee, setStudyCaseAssignee] = useSessionStorage(STUDY_CASE_ASSIGNEE, "");

  const [studyImageName, setStudyImageName] = useSessionStorage(STUDY_IMAGE_NAME, "");
  const [studyImageHasQueries, setStudyImageHasQueries] = useSessionStorage(
    STUDY_IMAGE_HAS_QUERIES,
    ""
  );
  const [studyImageIsUnassigned, setStudyImageIsUnassigned] = useSessionStorage(
    STUDY_IMAGE_IS_UNASSIGNED,
    ""
  );

  useEffect(() => {
    store.dispatch(studyFetch(id));
    store.dispatch(studyFetchRequest(id));
    if (studyCaseProcId !== "" || studyCaseStatus !== "" || studyCaseAssignee !== "") {
      store.dispatch(
        setCaseFilters(id, {
          procId: studyCaseProcId === "" ? undefined : studyCaseProcId,
          status: studyCaseStatus === "" ? undefined : studyCaseStatus,
          assignee: studyCaseAssignee === "" ? undefined : studyCaseAssignee
        })
      );
    }
    if (studyImageName !== "" || studyImageHasQueries !== "" || studyImageIsUnassigned !== "") {
      store.dispatch(
        setImageFilters(id, {
          ...images.filters,
          name: studyImageName !== "" ? studyImageName : undefined,
          hasQueries: studyImageHasQueries !== "" ? studyImageHasQueries : undefined,
          isUnassigned: studyImageIsUnassigned !== "" ? studyImageIsUnassigned : undefined
        })
      );
    }
    return function cleanup(): void {
      // Single action that both the images and cases reducers respond to,
      // clearing all filters when the component is unmounted.
      // It's tempting to store these filters in component state, but it needs
      // to be in Redux state so that the cases/images can be refreshed without
      // losing the current filters.
      store.dispatch(clearFilters());
    };
  }, [id]);

  const canSeeHold = "resource" in user && userCanViewStudyHold(user.resource.role);

  const createCase = (studyView: StudyView) => () =>
    store.dispatch(openCreateCaseDialog(studyView.study.id));
  const createCaseButton =
    tab === Routes.Cases &&
    "resource" in user &&
    (user.resource.role === UserRole.Admin || user.resource.role === UserRole.ISC) &&
    "resource" in studyView ? (
      <Box>
        <Button
          iconBefore="plus"
          onClick={createCase(studyView.resource)}
          appearance="prominent"
          intent="primary"
        >
          Create case
        </Button>
        <CaseDialog />
      </Box>
    ) : null;
  const uploadImages = (studyView: StudyView) => () =>
    store.dispatch(openUploadDialog(studyView.study.id));
  const uploadImageButton =
    tab === Routes.Images &&
    "resource" in user &&
    user.resource.role !== null &&
    userCanUpload(user.resource.role) &&
    "resource" in studyView ? (
      <Box>
        <Button
          iconBefore="upload"
          onClick={uploadImages(studyView.resource)}
          appearance="prominent"
          intent="primary"
        >
          Upload image
        </Button>
        <UploadDialog />
      </Box>
    ) : null;

  const setImageSearchTerms = (searchTerms: string) => {
    if (searchTerms) {
      setStudyImageName(searchTerms);
    } else {
      clearSessionStorage(STUDY_IMAGE_NAME);
    }
    store.dispatch(
      setImageFilters(id, {
        ...images.filters,
        name: searchTerms
      })
    );
  };
  const setHasQueriesFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setStudyImageHasQueries(e.target.checked);
    } else {
      clearSessionStorage(STUDY_IMAGE_HAS_QUERIES);
    }
    store.dispatch(
      setImageFilters(id, {
        ...images.filters,
        hasQueries: e.target.checked || undefined
      })
    );
  };
  const setIsUnassignedFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setStudyImageIsUnassigned(e.target.checked);
    } else {
      clearSessionStorage(STUDY_IMAGE_IS_UNASSIGNED);
    }
    store.dispatch(
      setImageFilters(id, {
        ...images.filters,
        isUnassigned: e.target.checked || undefined
      })
    );
  };

  const setCasesSearchTerms = (searchTerms: string) => {
    if (searchTerms) {
      setStudyCaseProcId(searchTerms);
    } else {
      clearSessionStorage(STUDY_CASE_PROCID);
    }
    store.dispatch(
      setCaseFilters(id, {
        ...cases.filters,
        procId: searchTerms || undefined
      })
    );
  };
  const onSelectStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseStatusResult = caseStatusDecoder.decode(e.target.value);
    if (caseStatusResult instanceof Ok) {
      setStudyCaseStatus(caseStatusResult.value);
    } else {
      clearSessionStorage(STUDY_CASE_STATUS);
    }
    store.dispatch(
      setCaseFilters(id, {
        ...cases.filters,
        status: caseStatusResult instanceof Ok ? caseStatusResult.value : undefined
      })
    );
  };
  const onSelectAssignee = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStudyCaseAssignee(e.target.value);
    } else {
      clearSessionStorage(STUDY_CASE_ASSIGNEE);
    }
    store.dispatch(
      setCaseFilters(id, {
        ...cases.filters,
        assignee: e.target.value || undefined
      })
    );
  };
  const qcStatusSelect =
    "resource" in user && user.resource.role !== UserRole.Reader ? (
      <Select onChange={onSelectStatus} defaultValue={studyCaseStatus}>
        <option key="" value="">
          All statuses
        </option>
        {Object.values(CaseStatus)
          .filter(status => status !== CaseStatus.Invalid)
          .map(status => {
            return (
              <option key={status} value={status}>
                {formatCaseStatus(status)}
              </option>
            );
          })}
      </Select>
    ) : null;
  const assigneeSelect =
    "resource" in studyView && "readers" in studyView.resource ? (
      <Select onChange={onSelectAssignee} defaultValue={studyCaseAssignee}>
        <option key="" value="">
          Any assignee
        </option>
        {studyView.resource.readers.map(reader => {
          return (
            <option key={reader.id} value={reader.id}>
              Assigned to {userLabel(reader)}
            </option>
          );
        })}
        <option key="UNASSIGNED" value="UNASSIGNED">
          Unassigned
        </option>
      </Select>
    ) : null;

  const redirect =
    "resource" in user ? (
      (tab === undefined && userCanSeeCases(user.resource.role)) ||
      (tab === Routes.Images && !userCanSeeImages(user.resource.role)) ? (
        <Navigate to={`/studies/${id}/cases`} />
      ) : (tab === undefined || tab === Routes.Cases) && !userCanSeeCases(user.resource.role) ? (
        <Navigate to={`/studies/${id}/images`} />
      ) : tab !== Routes.Cases && tab !== Routes.Images ? (
        <Navigate to="/" />
      ) : null
    ) : null;
  const table =
    "resource" in user ? (
      tab === Routes.Cases && userCanSeeCases(user.resource.role) ? (
        <CasesTable studyId={id} user={user} />
      ) : (
        <ImagesTable studyId={id} user={user} />
      )
    ) : null;
  // NOTE: Save as (possibly) narrower type here since TS can't narrow the type
  // successfully using nested properties (eg. `studyView.resource.readers`)
  const studyWithUsers: StudyAdminView | null =
    "resource" in studyView && "readers" in studyView.resource ? studyView.resource : null;
  return redirect ? (
    redirect
  ) : (
    <Page>
      <Content isLoading={"isPending" in studyView || "isPending" in user || !!redirect}>
        {"resource" in studyView && "resource" in user ? (
          <Box style={{ padding: "0 2rem 4rem" }}>
            {studyView.resource.study.onHold && canSeeHold ? (
              <PageBanner>
                <PageBannerHeading>
                  On hold {studyView.resource.study.onHoldReason}
                </PageBannerHeading>
              </PageBanner>
            ) : null}
            <PageHeader>
              <PageHeading>{studyView.resource.study.name}</PageHeading>
              <PageActions>
                {studyWithUsers && user.resource.role === UserRole.Admin && (
                  <Link to={`/studies/${id}/configure/`} style={{ textDecoration: "none" }}>
                    <Button iconBefore="edit">Edit Study</Button>
                  </Link>
                )}
              </PageActions>
            </PageHeader>
            <PageTabs
              links={[
                ...(userCanSeeCases(user.resource.role)
                  ? [
                      {
                        to: `/studies/${id}/${Routes.Cases}`,
                        label: "Cases"
                      }
                    ]
                  : []),
                ...(userCanSeeImages(user.resource.role)
                  ? [
                      {
                        to: `/studies/${id}/${Routes.Images}`,
                        label: "Images"
                      }
                    ]
                  : [])
              ]}
            />
            <TableContainer>
              <TableFilters>
                <Box display="flex" width="100%">
                  {tab === Routes.Images ? (
                    <>
                      <DebouncedTextInput
                        key="images-search"
                        width="auto"
                        defaultValue={images.filters.name || ""}
                        placeholder={"Search by image name"}
                        onValueChange={setImageSearchTerms}
                      />
                      {userCanSeeCases(user.resource.role) ? (
                        <Box paddingLeft="10px">
                          <Label>
                            <Checkbox
                              checked={images.filters.isUnassigned || false}
                              onChange={setIsUnassignedFilter}
                              ml={1}
                              mr={1}
                            />
                            <Text
                              color="gray600"
                              style={{ top: "2px", position: "relative", fontWeight: 400 }}
                            >
                              Only unassigned
                            </Text>
                          </Label>
                        </Box>
                      ) : null}
                      <Box paddingLeft="10px">
                        <Label>
                          <Checkbox
                            checked={images.filters.hasQueries || false}
                            onChange={setHasQueriesFilter}
                            ml={1}
                            mr={1}
                          />
                          <Text
                            color="gray600"
                            style={{ top: "2px", position: "relative", fontWeight: 400 }}
                          >
                            Only images with queries
                          </Text>
                        </Label>
                      </Box>
                      <Box ml="auto">{uploadImageButton}</Box>
                    </>
                  ) : (
                    <>
                      <DebouncedTextInput
                        key="cases-search"
                        width="auto"
                        defaultValue={cases.filters.procId || studyCaseProcId || ""}
                        placeholder={"Search by Procedure ID"}
                        onValueChange={setCasesSearchTerms}
                      />
                      <Box paddingLeft="10px">{qcStatusSelect}</Box>
                      <Box paddingLeft="10px">{assigneeSelect}</Box>
                      <Box ml="auto">{createCaseButton}</Box>
                    </>
                  )}
                </Box>
              </TableFilters>
              {table}
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Callout intent={Intent.WARNING}>Study not found</Callout>
          </Box>
        )}
      </Content>
    </Page>
  );
};

function mapStateToProps(state: State): StudyProps {
  return {
    studyView: state.studies.study,
    user: state.auth,
    images: state.images,
    cases: state.cases
  };
}

export default connect(mapStateToProps)(Study);
