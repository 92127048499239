import {
  AddReasonForChange,
  AdminCaseWithImagesAndReaders,
  Image,
  Images,
  NestValues,
  ReadersStudyStats,
  ReaderStudyStats,
  UUID
} from "../models";

export enum CaseDialogMode {
  OpenForCreate = "OPEN_FOR_CREATE",
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

type Form = NestValues<{
  readonly procId: string;
  readonly subjectId: string;
  readonly visitId: string;
  readonly images: Images;
  readonly readers: ReadersStudyStats;
}>;

export type UpdateCaseForm = AddReasonForChange<Form>;

export type CreateCaseForm = Form;

export type CaseForm<M extends CaseDialogMode> = M extends CaseDialogMode.OpenForEdit
  ? UpdateCaseForm
  : CreateCaseForm;

export enum ActionTypes {
  OPEN_CREATE_CASE_DIALOG = "OPEN_CREATE_CASE_DIALOG",
  OPEN_EDIT_CASE_DIALOG = "OPEN_EDIT_CASE_DIALOG",
  CLOSE_CASE_DIALOG = "CLOSE_CASE_DIALOG",
  CHANGE_CASE = "CHANGE_CASE",
  FETCH_CASE_REQUEST = "FETCH_CASE_REQUEST",
  FETCH_CASE_REQUEST_SUCCESS = "FETCH_CASE_REQUEST_SUCCESS",
  FETCH_CASE_REQUEST_FAILURE = "FETCH_CASE_REQUEST_FAILURE",
  CREATE_CASE_REQUEST = "CREATE_CASE_REQUEST",
  EDIT_CASE_REQUEST = "EDIT_CASE_REQUEST",
  CASE_REQUEST_SUCCESS = "CASE_REQUEST_SUCCESS",
  CASE_REQUEST_FAILURE = "CASE_REQUEST_FAILURE",
  SEARCH_READERS_REQUEST = "CASE_SEARCH_READERS_REQUEST",
  SEARCH_READERS_REQUEST_SUCCESS = "CASE_SEARCH_READERS_REQUEST_SUCCESS",
  SEARCH_READERS_REQUEST_FAILURE = "CASE_SEARCH_READERS_REQUEST_FAILURE",
  SUGGEST_READERS_REQUEST = "CASE_SUGGEST_READERS_REQUEST",
  SUGGEST_READERS_REQUEST_SUCCESS = "CASE_SUGGEST_READERS_REQUEST_SUCCESS",
  SUGGEST_READERS_REQUEST_FAILURE = "CASE_SUGGEST_READERS_REQUEST_FAILURE",
  DROP_READER = "DROP_READER",
  ADD_READER = "ADD_READER",
  IMAGES_SEARCH = "IMAGES_SEARCH",
  IMAGES_SEARCH_SUCCESS = "IMAGES_SEARCH_SUCCESS",
  IMAGES_SEARCH_FAILURE = "IMAGES_SEARCH_FAILURE",
  ASSIGN_IMAGE = "ASSIGN_IMAGE",
  UNASSIGN_IMAGE = "UNASSIGN_IMAGE"
}

export type CaseDialogAction =
  | {
      readonly type: ActionTypes.OPEN_CREATE_CASE_DIALOG;
      readonly studyId: UUID;
    }
  | {
      readonly type: ActionTypes.OPEN_EDIT_CASE_DIALOG;
      readonly id: UUID;
      readonly studyId: UUID;
      readonly refreshSingleCaseOnSuccess: boolean;
    }
  | {
      readonly type: ActionTypes.CLOSE_CASE_DIALOG;
    }
  | { readonly type: ActionTypes.CHANGE_CASE; readonly histoCase: CaseForm<CaseDialogMode> }
  | { readonly type: ActionTypes.FETCH_CASE_REQUEST; readonly id: UUID }
  | {
      readonly type: ActionTypes.FETCH_CASE_REQUEST_SUCCESS;
      readonly caseWithImagesAndReaders: AdminCaseWithImagesAndReaders;
    }
  | { readonly type: ActionTypes.FETCH_CASE_REQUEST_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.CREATE_CASE_REQUEST }
  | { readonly type: ActionTypes.EDIT_CASE_REQUEST }
  | {
      readonly type: ActionTypes.CASE_REQUEST_SUCCESS;
      readonly caseWithImagesAndReaders: AdminCaseWithImagesAndReaders;
    }
  | { readonly type: ActionTypes.CASE_REQUEST_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.SEARCH_READERS_REQUEST;
      readonly name: string;
      readonly studyId: string | null;
    }
  | {
      readonly type: ActionTypes.SEARCH_READERS_REQUEST_SUCCESS;
      readonly readersStudyStats: ReadersStudyStats;
    }
  | { readonly type: ActionTypes.SEARCH_READERS_REQUEST_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.SUGGEST_READERS_REQUEST;
      readonly subjectId: string;
    }
  | {
      readonly type: ActionTypes.SUGGEST_READERS_REQUEST_SUCCESS;
      readonly readersStudyStats: ReadersStudyStats;
    }
  | { readonly type: ActionTypes.SUGGEST_READERS_REQUEST_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.DROP_READER; readonly readerStudyStats: ReaderStudyStats }
  | { readonly type: ActionTypes.ADD_READER; readonly readerStudyStats: ReaderStudyStats }
  | { readonly type: ActionTypes.ASSIGN_IMAGE; readonly image: Image }
  | { readonly type: ActionTypes.UNASSIGN_IMAGE; readonly image: Image }
  | { readonly type: ActionTypes.IMAGES_SEARCH; readonly name: string }
  | { readonly type: ActionTypes.IMAGES_SEARCH_SUCCESS; readonly images: Images }
  | { readonly type: ActionTypes.IMAGES_SEARCH_FAILURE; readonly errorMsg: string };

export function openCreateCaseDialog(studyId: UUID): CaseDialogAction {
  return {
    type: ActionTypes.OPEN_CREATE_CASE_DIALOG,
    studyId
  };
}

export function openEditCaseDialog(
  id: UUID,
  studyId: UUID,
  refreshSingleCaseOnSuccess: boolean
): CaseDialogAction {
  return {
    type: ActionTypes.OPEN_EDIT_CASE_DIALOG,
    id,
    studyId,
    refreshSingleCaseOnSuccess
  };
}

export function closeCaseDialog(): CaseDialogAction {
  return {
    type: ActionTypes.CLOSE_CASE_DIALOG
  };
}

export function changeCase(histoCase: CaseForm<CaseDialogMode>): CaseDialogAction {
  return {
    type: ActionTypes.CHANGE_CASE,
    histoCase
  };
}

export function fetchCaseRequest(id: UUID): CaseDialogAction {
  return {
    type: ActionTypes.FETCH_CASE_REQUEST,
    id
  };
}

export function fetchCaseRequestSuccess(
  caseWithImagesAndReaders: AdminCaseWithImagesAndReaders
): CaseDialogAction {
  return {
    type: ActionTypes.FETCH_CASE_REQUEST_SUCCESS,
    caseWithImagesAndReaders
  };
}

export function fetchCaseRequestFailure(errorMsg: string): CaseDialogAction {
  return {
    type: ActionTypes.FETCH_CASE_REQUEST_FAILURE,
    errorMsg
  };
}

export function createCaseRequest(): CaseDialogAction {
  return {
    type: ActionTypes.CREATE_CASE_REQUEST
  };
}

export function editCaseRequest(): CaseDialogAction {
  return {
    type: ActionTypes.EDIT_CASE_REQUEST
  };
}

export function caseRequestSuccess(
  caseWithImagesAndReaders: AdminCaseWithImagesAndReaders
): CaseDialogAction {
  return {
    type: ActionTypes.CASE_REQUEST_SUCCESS,
    caseWithImagesAndReaders
  };
}

export function caseRequestFailure(errorMsg: string): CaseDialogAction {
  return {
    type: ActionTypes.CASE_REQUEST_FAILURE,
    errorMsg
  };
}

export function caseSearchReadersRequest(name: string, studyId: string | null): CaseDialogAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST,
    name,
    studyId
  };
}

export function caseSearchReadersSuccess(readersStudyStats: ReadersStudyStats): CaseDialogAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST_SUCCESS,
    readersStudyStats
  };
}

export function caseSuggestReadersFailure(errorMsg: string): CaseDialogAction {
  return {
    type: ActionTypes.SUGGEST_READERS_REQUEST_FAILURE,
    errorMsg
  };
}

export function caseSuggestReadersRequest(subjectId: string): CaseDialogAction {
  return {
    type: ActionTypes.SUGGEST_READERS_REQUEST,
    subjectId
  };
}

export function caseSuggestReadersSuccess(readersStudyStats: ReadersStudyStats): CaseDialogAction {
  return {
    type: ActionTypes.SUGGEST_READERS_REQUEST_SUCCESS,
    readersStudyStats
  };
}

export function caseSearchReadersFailure(errorMsg: string): CaseDialogAction {
  return {
    type: ActionTypes.SEARCH_READERS_REQUEST_FAILURE,
    errorMsg
  };
}

export function dropReader(readerStudyStats: ReaderStudyStats): CaseDialogAction {
  return {
    type: ActionTypes.DROP_READER,
    readerStudyStats
  };
}

export function addReader(readerStudyStats: ReaderStudyStats): CaseDialogAction {
  return {
    type: ActionTypes.ADD_READER,
    readerStudyStats
  };
}

export function unassignImage(image: Image): CaseDialogAction {
  return {
    type: ActionTypes.UNASSIGN_IMAGE,
    image
  };
}

export function assignImage(image: Image): CaseDialogAction {
  return {
    type: ActionTypes.ASSIGN_IMAGE,
    image
  };
}

export function imagesSearch(name: string): CaseDialogAction {
  return {
    type: ActionTypes.IMAGES_SEARCH,
    name
  };
}

export function imagesSearchSuccess(images: Images): CaseDialogAction {
  return {
    type: ActionTypes.IMAGES_SEARCH_SUCCESS,
    images
  };
}

export function imagesSearchFailure(errorMsg: string): CaseDialogAction {
  return {
    type: ActionTypes.IMAGES_SEARCH_FAILURE,
    errorMsg
  };
}
