import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Appearance, Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import { formatRole, User } from "../models";

import { toggleDialog } from "../actions/userDialog";
import { setUsersFilter, usersFetch } from "../actions/users";
import Page, { PageHeader, PageHeading } from "../components/Page";
import { UsersState } from "../reducers/users";
import store from "../store";
import UserDialog from "../components/UserDialog";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import TooltipText from "../components/TooltipText";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";

type StateProps = UsersState;

const Users = ({ users, usersFilter }: StateProps) => {
  useEffect(() => {
    store.dispatch(usersFetch());
    return () => {
      store.dispatch(setUsersFilter(null));
    };
  }, []);
  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  const editButtonOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const userToEdit =
      "resource" in users ? users.resource.find(u => u.id === e.currentTarget.value) : undefined;
    store.dispatch(toggleDialog(true, userToEdit));
  };
  const error =
    "errorMessage" in users ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{users.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const columns: ReadonlyArray<object> = [
    {
      name: "Username",
      selector: (user: User) => user.username,
      sortable: true
    },
    {
      name: "Role",
      selector: (user: User) => user.role,
      sortable: true,
      format: (user: User) => formatRole(user.role)
    },
    {
      name: "First Name",
      selector: (user: User) => user.firstName,
      sortable: true
    },
    {
      name: "Last Name",
      selector: (user: User) => user.lastName,
      sortable: true
    },
    {
      name: "Last Login",
      selector: (user: User) => user.lastLoginDt,
      sortable: true,
      format: (user: User) => <Timestamp date={user.lastLoginDt} />
    },
    {
      name: "Registered",
      selector: (user: User) => user.registered,
      sortable: true,
      grow: 0,
      cell: (user: User) => (
        <TooltipText text={user.registered ? "Registered" : "Not Registered"}>
          {user.registered ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="cross" color="red" />
          )}
        </TooltipText>
      )
    },
    {
      right: true,
      grow: 0,
      cell: (user: User) => (
        <Button value={user.id} onClick={editButtonOnClick} appearance={Appearance.MINIMAL}>
          <Icon name="edit" />
        </Button>
      )
    }
  ];
  const content = error ? (
    error
  ) : (
    <TableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={usersFilter || ""}
            placeholder={"Search by name"}
            onValueChange={name => store.dispatch(setUsersFilter(name))}
          />
        </Box>
      </TableFilters>
      {"resource" in users ? (
        <StyledDataTable
          columns={columns}
          data={users.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
    </TableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Users</PageHeading>
        </PageHeader>
        {content}
        <UserDialog />
      </Box>
    </Page>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    users: state.users.users,
    usersFilter: state.users.usersFilter
  };
}

export default connect(mapStateToProps)(Users);
