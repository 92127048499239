import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Button, Callout, Dialog, Intent, Select, Text } from "@blasterjs/core";

import { StudyListViews } from "../models";
import { Resource } from "../types";

import { closeMoveImageDialog, moveImage, setMoveImageForm } from "../actions/moveImageDialog";
import { studiesFetch } from "../actions/studies";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { MoveImageDialogState } from "../reducers/moveImageDialog";
import store from "../store";

interface Props {
  readonly studies: Resource<StudyListViews>;
  readonly moveImageDialog: MoveImageDialogState;
}

const MoveImageDialog = ({ studies, moveImageDialog }: Props) => {
  useEffect(() => {
    store.dispatch(studiesFetch());
    // eslint-disable-next-line
  }, []);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const closeDialog = () => {
    store.dispatch(closeMoveImageDialog());
  };
  const onSelectStudy = (e: React.ChangeEvent<HTMLInputElement>) => {
    moveImageDialog.isOpen &&
      store.dispatch(
        setMoveImageForm({
          ...moveImageDialog.image.data,
          studyId: e.target.value
        })
      );
  };

  const onChangeReasonForChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    moveImageDialog.isOpen &&
      store.dispatch(
        setMoveImageForm({
          ...moveImageDialog.image.data,
          reasonForChange: e.target.value
        })
      );
  };
  const onSave = () => {
    setConfirmationDialogOpen(true);
  };

  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    store.dispatch(moveImage());
  };

  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const errorText =
    moveImageDialog.isOpen && "errorMessage" in moveImageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{moveImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const studySelect =
    "resource" in studies && moveImageDialog.isOpen ? (
      <Select onChange={onSelectStudy}>
        <option key="" value="" />
        {studies.resource.map(study => {
          const isCurrentStudy =
            moveImageDialog.savedImage &&
            moveImageDialog.savedImage.studyId === study.studyView.study.id;
          return (
            <option
              key={study.studyView.study.id}
              value={study.studyView.study.id}
              disabled={isCurrentStudy}
            >
              {study.studyView.study.name}
            </option>
          );
        })}
      </Select>
    ) : null;
  const confirmDialog = (
    <ConfirmationDialog
      title="Confirm Image Move"
      message="Are you sure you want to move this image?"
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
  return (
    <>
      <Dialog
        isOpen={moveImageDialog.isOpen && !isConfirmationDialogOpen}
        onRequestClose={closeDialog}
        appElementId="root"
      >
        <DialogHeader title="Move Image to Study" closeDialog={closeDialog} />
        <DialogBody>
          {errorText}
          <Box>{studySelect}</Box>
          <Box style={{ marginTop: 12 }}>
            <ReasonForChange
              rkey="moveImage_reason"
              onChange={onChangeReasonForChange}
              value={(moveImageDialog.isOpen && moveImageDialog.image.data.reasonForChange) || ""}
            />
          </Box>
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button
              onClick={onSave}
              appearance="prominent"
              intent="primary"
              isLoading={
                moveImageDialog.isOpen &&
                "isPending" in moveImageDialog.image &&
                moveImageDialog.image.isPending
              }
            >
              Move image
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </Dialog>
      {confirmDialog}
    </>
  );
};

function mapStateToProps(state: State): Props {
  return {
    studies: state.studies.studies,
    moveImageDialog: state.moveImageDialog
  };
}

export default connect(mapStateToProps)(MoveImageDialog);
