import React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";

import { Box, Button, Dialog, Label, Radio, TextInput } from "@blasterjs/core";

import { changeUserForm, toggleDialog, userUpdate } from "../actions/userDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { formatRole, UserRole } from "../models";
import { UserDialogState } from "../reducers/userDialog";
import store from "../store";

interface StateProps {
  readonly userDialog: UserDialogState;
}

type Props = StateProps;

const UserDialog = ({ userDialog }: Props) => {
  const closeDialog = () => {
    store.dispatch(toggleDialog(false, undefined));
  };
  const firstNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.dispatch(
      changeUserForm({
        ...userDialog.userForm,
        firstName: e.currentTarget.value
      })
    );
  };

  const lastNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.dispatch(
      changeUserForm({
        ...userDialog.userForm,
        lastName: e.currentTarget.value
      })
    );
  };

  const roleOnChange = (role: UserRole | null) => () => {
    store.dispatch(
      changeUserForm({
        ...userDialog.userForm,
        role
      })
    );
  };
  const saveOnClick = () => {
    store.dispatch(userUpdate());
  };
  const adminRole = (
    <>
      <Label>
        <Radio
          name="role"
          value={UserRole.Admin}
          mr={2}
          checked={userDialog.userForm.role === UserRole.Admin}
          disabled={true}
        />
        {formatRole(UserRole.Admin)}
      </Label>
      <br />
    </>
  );
  const notoRole = (
    <>
      <Label>
        <Radio
          name="role"
          value={UserRole.Noto}
          mr={2}
          checked={userDialog.userForm.role === UserRole.Noto}
          disabled={true}
        />
        {formatRole(UserRole.Noto)}
      </Label>
      <br />
    </>
  );

  const regularRoles = [UserRole.Reader, UserRole.Lab, UserRole.ISC, UserRole.Readonly].map(
    role => (
      <div key={role}>
        <Label>
          <Radio
            name="role"
            value={role}
            mr={2}
            checked={userDialog.userForm.role === role}
            onChange={roleOnChange(role)}
          />
          {formatRole(role)}
        </Label>
      </div>
    )
  );
  const rolesRadio = (
    <Label>
      Role
      <br />
      {userDialog.user && userDialog.userForm.role === UserRole.Admin
        ? adminRole
        : userDialog.userForm.role === UserRole.Noto
        ? notoRole
        : regularRoles}
    </Label>
  );
  return (
    <Dialog isOpen={userDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader title="Edit User" closeDialog={closeDialog} />
      <DialogBody>
        <Box display="flex" justifyContent="space-between">
          <Box style={{ width: "48%" }}>
            <Label>
              First Name
              <TextInput
                placeholder="First Name"
                mb={2}
                value={userDialog.userForm.firstName}
                onChange={firstNameOnChange}
              />
            </Label>
          </Box>
          <Box style={{ width: "48%" }}>
            <Label>
              Last Name
              <TextInput
                placeholder="Last Name"
                mb={2}
                value={userDialog.userForm.lastName}
                onChange={lastNameOnChange}
              />
            </Label>
          </Box>
        </Box>

        <Label>
          Username
          <TextInput mb={2} value={userDialog.userForm.email} disabled={true} />
        </Label>

        {rolesRadio}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            disabled={userDialog.acceptButtonDisabled}
            onClick={saveOnClick}
            appearance="prominent"
            intent="primary"
          >
            Save
          </Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    userDialog: state.userDialog
  };
}

export default connect(mapStateToProps)(UserDialog);
