import { ApiResponse, Case, CaseWithImages, QueryObjectType, UUID } from "../models";

export enum ActionTypes {
  CASE_FETCH = "CASE_FETCH",
  CASE_FETCH_SUCCESS = "CASE_FETCH_SUCCESS",
  CASE_FETCH_FAILURE = "CASE_FETCH_FAILURE",
  REFRESH_CASE = "REFRESH_CASE",
  REFRESH_CASE_SUCCESS = "REFRESH_CASE_SUCCESS",
  REFRESH_CASE_FAILURE = "REFRESH_CASE_FAILURE",
  TRANSITION_CASE_STATUS = "TRANSITION_CASE_STATUS",
  TRANSITION_CASE_STATUS_SUCCESS = "TRANSITION_CASE_STATUS_SUCCESS",
  TRANSITION_CASE_STATUS_FAILURE = "TRANSITION_CASE_STATUS_FAILURE",

  UPDATE_CASE_COMMENT = "UPDATE_CASE_COMMENT",
  UPDATE_CASE_COMMENT_SUCCESS = "UPDATE_CASE_COMMENT_SUCCESS",
  UPDATE_CASE_COMMENT_FAILURE = "UPDATE_CASE_COMMENT_FAILURE",
  UPDATE_IMAGE_COMMENT = "UPDATE_IMAGE_COMMENT",
  UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS",
  UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE",

  // query
  OPEN_QUERY = "OPEN_QUERY",
  OPEN_QUERY_SUCCESS = "OPEN_QUERY_SUCCESS",
  OPEN_QUERY_FAILURE = "OPEN_QUERY_FAILURE",

  CLOSE_QUERY = "CLOSE_QUERY",
  CLOSE_QUERY_SUCCESS = "CLOSE_QUERY_SUCCESS",
  CLOSE_QUERY_FAILURE = "CLOSE_QUERY_FAILURE",

  UNRESOLVABLE_QUERY = "UNRESOLVABLE_QUERY",
  UNRESOLVABLE_QUERY_SUCCESS = "UNRESOLVABLE_QUERY_SUCCESS",
  UNRESOLVABLE_QUERY_FAILURE = "UNRESOLVABLE_QUERY_FAILURE",

  NEW_QUERY_REMINDER = "NEW_QUERY_REMINDER",
  NEW_QUERY_REMINDER_SUCCESS = "NEW_QUERY_REMINDER_SUCCESS",
  NEW_QUERY_REMINDER_FAILURE = "NEW_QUERY_REMINDER_FAILURE"
}

export type CaseViewerAction =
  | {
      readonly type: ActionTypes.CASE_FETCH;
      readonly caseId: UUID;
    }
  | { readonly type: ActionTypes.CASE_FETCH_SUCCESS; readonly histoCase: CaseWithImages }
  | { readonly type: ActionTypes.CASE_FETCH_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.REFRESH_CASE;
    }
  | {
      readonly type: ActionTypes.REFRESH_CASE_SUCCESS;
      readonly histoCase: CaseWithImages;
    }
  | { readonly type: ActionTypes.REFRESH_CASE_FAILURE; readonly errorMsg: string }
  | { readonly type: ActionTypes.REFRESH_CASE }
  | {
      readonly type: ActionTypes.TRANSITION_CASE_STATUS;
      readonly histoCase: Case;
    }
  | {
      readonly type: ActionTypes.TRANSITION_CASE_STATUS_SUCCESS;
      readonly histoCase: CaseWithImages;
    }
  | { readonly type: ActionTypes.TRANSITION_CASE_STATUS_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.UPDATE_CASE_COMMENT;
      readonly caseId: UUID;
      readonly comment: string;
    }
  | { readonly type: ActionTypes.UPDATE_CASE_COMMENT_SUCCESS; readonly response: ApiResponse }
  | { readonly type: ActionTypes.UPDATE_CASE_COMMENT_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.UPDATE_IMAGE_COMMENT;
      readonly caseId: UUID;
      readonly imageId: UUID;
      readonly comment: string;
    }
  | { readonly type: ActionTypes.UPDATE_IMAGE_COMMENT_SUCCESS; readonly response: ApiResponse }
  | { readonly type: ActionTypes.UPDATE_IMAGE_COMMENT_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.OPEN_QUERY;
      readonly queryObjectType: QueryObjectType;
      readonly objectId: UUID;
      readonly studyId: UUID;
      readonly caseId: UUID;
      readonly category: string;
      readonly categoryOtherText: string | null;
      readonly followUpInDays: number;
    }
  | { readonly type: ActionTypes.OPEN_QUERY_SUCCESS; readonly response: ApiResponse }
  | { readonly type: ActionTypes.OPEN_QUERY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.CLOSE_QUERY;
      readonly queryId: UUID;
      readonly detailedReasonText: string;
    }
  | { readonly type: ActionTypes.CLOSE_QUERY_SUCCESS; readonly response: ApiResponse }
  | { readonly type: ActionTypes.CLOSE_QUERY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.UNRESOLVABLE_QUERY;
      readonly queryId: UUID;
      readonly unresolvableReasonText: string;
      readonly refreshImageOnSuccess: UUID | null;
    }
  | {
      readonly type: ActionTypes.UNRESOLVABLE_QUERY_SUCCESS;
      readonly response: ApiResponse;
      readonly refreshImageOnSuccess: UUID | null;
    }
  | { readonly type: ActionTypes.UNRESOLVABLE_QUERY_FAILURE; readonly errorMsg: string }
  | {
      readonly type: ActionTypes.NEW_QUERY_REMINDER;
      readonly queryId: UUID;
      readonly queryReminderId: UUID;
      readonly followUpInDays: number;
    }
  | { readonly type: ActionTypes.NEW_QUERY_REMINDER_SUCCESS; readonly response: ApiResponse }
  | { readonly type: ActionTypes.NEW_QUERY_REMINDER_FAILURE; readonly errorMsg: string };

export function caseFetch(caseId: UUID): CaseViewerAction {
  return {
    type: ActionTypes.CASE_FETCH,
    caseId
  };
}

export function caseFetchSuccess(histoCase: CaseWithImages): CaseViewerAction {
  return {
    type: ActionTypes.CASE_FETCH_SUCCESS,
    histoCase
  };
}

export function caseFetchFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.CASE_FETCH_FAILURE,
    errorMsg
  };
}

export function refreshCase(): CaseViewerAction {
  return {
    type: ActionTypes.REFRESH_CASE
  };
}

export function refreshCaseSuccess(histoCase: CaseWithImages): CaseViewerAction {
  return {
    type: ActionTypes.REFRESH_CASE_SUCCESS,
    histoCase
  };
}

export function refreshCaseFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.REFRESH_CASE_FAILURE,
    errorMsg
  };
}

export function transitionCaseStatus(histoCase: Case): CaseViewerAction {
  return {
    type: ActionTypes.TRANSITION_CASE_STATUS,
    histoCase
  };
}

export function transitionCaseStatusSuccess(histoCase: CaseWithImages): CaseViewerAction {
  return {
    type: ActionTypes.TRANSITION_CASE_STATUS_SUCCESS,
    histoCase
  };
}

export function transitionCaseStatusFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.TRANSITION_CASE_STATUS_FAILURE,
    errorMsg
  };
}

export function updateCaseComment(caseId: UUID, comment: string): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_CASE_COMMENT,
    caseId,
    comment
  };
}

export function updateCaseCommentSuccess(response: ApiResponse): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_CASE_COMMENT_SUCCESS,
    response
  };
}

export function updateCaseCommentFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_CASE_COMMENT_FAILURE,
    errorMsg
  };
}

export function updateImageComment(caseId: UUID, imageId: UUID, comment: string): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_IMAGE_COMMENT,
    caseId,
    imageId,
    comment
  };
}

export function updateImageCommentSuccess(response: ApiResponse): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_IMAGE_COMMENT_SUCCESS,
    response
  };
}

export function updateImageCommentFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.UPDATE_IMAGE_COMMENT_FAILURE,
    errorMsg
  };
}

export function openQuery(
  queryObjectType: QueryObjectType,
  objectId: UUID,
  studyId: UUID,
  caseId: UUID,
  category: string,
  categoryOtherText: string | null,
  followUpInDays: number
): CaseViewerAction {
  return {
    type: ActionTypes.OPEN_QUERY,
    queryObjectType,
    objectId,
    studyId,
    caseId,
    category,
    categoryOtherText,
    followUpInDays
  };
}

export function openQuerySuccess(response: ApiResponse): CaseViewerAction {
  return {
    type: ActionTypes.OPEN_QUERY_SUCCESS,
    response
  };
}

export function openQueryFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.OPEN_QUERY_FAILURE,
    errorMsg
  };
}

export function closeQuery(queryId: UUID, detailedReasonText: string): CaseViewerAction {
  return {
    type: ActionTypes.CLOSE_QUERY,
    queryId,
    detailedReasonText
  };
}

export function closeQuerySuccess(response: ApiResponse): CaseViewerAction {
  return {
    type: ActionTypes.CLOSE_QUERY_SUCCESS,
    response
  };
}

export function closeQueryFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.CLOSE_QUERY_FAILURE,
    errorMsg
  };
}

export function unresolvableQuery(
  queryId: UUID,
  unresolvableReasonText: string,
  refreshImageOnSuccess: UUID | null
): CaseViewerAction {
  return {
    type: ActionTypes.UNRESOLVABLE_QUERY,
    queryId,
    unresolvableReasonText,
    refreshImageOnSuccess
  };
}

export const unresolvableQuerySuccess = (refreshImageOnSuccess: UUID | null) => (
  response: ApiResponse
): CaseViewerAction => ({
  type: ActionTypes.UNRESOLVABLE_QUERY_SUCCESS,
  response,
  refreshImageOnSuccess
});

export function unresolvableQueryFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.UNRESOLVABLE_QUERY_FAILURE,
    errorMsg
  };
}

export function newQueryReminder(
  queryId: UUID,
  queryReminderId: UUID,
  followUpInDays: number
): CaseViewerAction {
  return {
    type: ActionTypes.NEW_QUERY_REMINDER,
    queryId,
    queryReminderId,
    followUpInDays
  };
}

export function newQueryReminderSuccess(response: ApiResponse): CaseViewerAction {
  return {
    type: ActionTypes.NEW_QUERY_REMINDER_SUCCESS,
    response
  };
}

export function newQueryReminderFailure(errorMsg: string): CaseViewerAction {
  return {
    type: ActionTypes.NEW_QUERY_REMINDER_FAILURE,
    errorMsg
  };
}
