import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  CaseDialogMode,
  CaseForm,
  caseRequestFailure,
  caseRequestSuccess,
  caseSearchReadersFailure,
  caseSearchReadersSuccess,
  caseSuggestReadersFailure,
  caseSuggestReadersSuccess,
  fetchCaseRequest,
  fetchCaseRequestFailure,
  fetchCaseRequestSuccess,
  imagesSearchFailure,
  imagesSearchSuccess,
  UpdateCaseForm
} from "../actions/caseDialog";
import { casesFetch } from "../actions/cases";
import { refreshCase } from "../actions/caseViewer";

import {
  createCase,
  editCase,
  fetchCase,
  searchStudyImages,
  searchStudyReaders,
  suggestReaders
} from "../api";
import {
  AdminCaseWithImagesAndReaders,
  Image,
  Images,
  onlyUpdates,
  ReadersStudyStats,
  ReaderStudyStats,
  UUID
} from "../models";
import { Resource, WriteResource } from "../types";

function adminCaseToForm(adminCase: AdminCaseWithImagesAndReaders): UpdateCaseForm {
  return {
    procId: {
      value: adminCase.caseWithStatus.procId
    },
    subjectId: {
      value: adminCase.caseWithStatus.subjectId
    },
    visitId: {
      value: adminCase.caseWithStatus.visitId
    },
    images: {
      value: adminCase.images
    },
    readers: {
      value: adminCase.readers
    }
  };
}

export interface CaseDialogState {
  readonly mode: CaseDialogMode;
  readonly studyId: UUID | null;
  readonly id: UUID | null;
  readonly savedHistoCase: AdminCaseWithImagesAndReaders | null;
  readonly histoCase: WriteResource<CaseForm<CaseDialogMode>, AdminCaseWithImagesAndReaders>;
  readonly readersSearchResults: Resource<ReadersStudyStats>;
  readonly readersSearchText: string;
  readonly imagesSearchResults: Resource<Images>;
  readonly imagesSearchText: string;
  readonly readerSuggestions: Resource<ReadersStudyStats>;
  readonly refreshSingleCaseOnSuccess: boolean;
}

export const initialState: CaseDialogState = {
  mode: CaseDialogMode.Closed,
  studyId: null,
  id: null,
  savedHistoCase: null,
  histoCase: {
    data: {
      procId: { value: "" },
      subjectId: { value: "" },
      visitId: { value: "" },
      images: { value: [] },
      readers: { value: [] }
    }
  },
  readersSearchResults: {
    resource: []
  },
  readersSearchText: "",
  imagesSearchResults: {
    resource: []
  },
  imagesSearchText: "",
  readerSuggestions: {
    resource: []
  },
  refreshSingleCaseOnSuccess: false
};

export const caseDialogReducer: LoopReducer<CaseDialogState, Action> = (
  state: CaseDialogState = initialState,
  action: Action
): CaseDialogState | Loop<CaseDialogState, Action> => {
  switch (action.type) {
    case ActionTypes.OPEN_CREATE_CASE_DIALOG:
      return {
        ...initialState,
        mode: CaseDialogMode.OpenForCreate,
        studyId: action.studyId
      };
    case ActionTypes.OPEN_EDIT_CASE_DIALOG:
      return loop(
        {
          ...initialState,
          mode: CaseDialogMode.OpenForEdit,
          studyId: action.studyId,
          id: action.id,
          refreshSingleCaseOnSuccess: action.refreshSingleCaseOnSuccess
        },
        Cmd.action(fetchCaseRequest(action.id))
      );
    case ActionTypes.CLOSE_CASE_DIALOG:
      return {
        ...state,
        mode: CaseDialogMode.Closed
      };
    case ActionTypes.CHANGE_CASE:
      return {
        ...state,
        histoCase: {
          data: action.histoCase
        }
      };
    case ActionTypes.FETCH_CASE_REQUEST:
      return loop(
        {
          ...state,
          histoCase: {
            data: state.histoCase.data,
            isPending: true
          }
        },
        Cmd.run(fetchCase, {
          successActionCreator: fetchCaseRequestSuccess,
          failActionCreator: fetchCaseRequestFailure,
          args: [state.id] as Parameters<typeof fetchCase>
        })
      );
    case ActionTypes.FETCH_CASE_REQUEST_SUCCESS:
      return state.mode === CaseDialogMode.OpenForEdit
        ? {
            ...state,
            savedHistoCase: action.caseWithImagesAndReaders,
            histoCase: {
              data: adminCaseToForm(action.caseWithImagesAndReaders),
              resource: action.caseWithImagesAndReaders
            }
          }
        : state;
    case ActionTypes.FETCH_CASE_REQUEST_FAILURE:
      return {
        ...state,
        histoCase: {
          data: state.histoCase.data,
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.CREATE_CASE_REQUEST:
      return state.studyId !== null &&
        state.histoCase.data &&
        state.mode === CaseDialogMode.OpenForCreate
        ? loop(
            {
              ...state,
              histoCase: {
                data: state.histoCase.data,
                isSaving: true
              }
            },
            Cmd.run(createCase, {
              successActionCreator: caseRequestSuccess,
              failActionCreator: caseRequestFailure,
              args: [
                state.studyId,
                state.histoCase.data.procId.value,
                state.histoCase.data.subjectId.value,
                state.histoCase.data.visitId.value,
                state.histoCase.data.readers.value.map(readerStats => readerStats.reader.id),
                state.histoCase.data.images.value.map(image => image.id)
              ] as Parameters<typeof createCase>
            })
          )
        : (state as never);
    case ActionTypes.EDIT_CASE_REQUEST:
      const updates =
        state.savedHistoCase &&
        onlyUpdates(adminCaseToForm(state.savedHistoCase), state.histoCase.data);
      return state.id !== null &&
        updates &&
        state.histoCase.data &&
        state.mode === CaseDialogMode.OpenForEdit
        ? loop(
            {
              ...state,
              histoCase: {
                data: state.histoCase.data,
                isSaving: true
              }
            },
            Cmd.run(editCase, {
              successActionCreator: caseRequestSuccess,
              failActionCreator: caseRequestFailure,
              args: [
                state.id,
                updates.procId,
                updates.subjectId,
                updates.visitId,
                updates.readers
                  ? {
                      ...updates.readers,
                      value: updates.readers.value.map(
                        (readerStats: ReaderStudyStats) => readerStats.reader.id
                      )
                    }
                  : undefined,
                updates.images
                  ? {
                      ...updates.images,
                      value: updates.images.value.map((image: Image) => image.id)
                    }
                  : undefined
              ] as Parameters<typeof editCase>
            })
          )
        : (state as never);
    case ActionTypes.CASE_REQUEST_SUCCESS:
      return state.studyId !== null
        ? loop(
            {
              ...state,
              mode: CaseDialogMode.Closed,
              histoCase: {
                data: state.histoCase.data,
                resource: action.caseWithImagesAndReaders
              }
            },
            Cmd.action(state.refreshSingleCaseOnSuccess ? refreshCase() : casesFetch(state.studyId))
          )
        : (state as never);
    case ActionTypes.CASE_REQUEST_FAILURE:
      return {
        ...state,
        histoCase: {
          data: state.histoCase.data,
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.SEARCH_READERS_REQUEST:
      return state.studyId !== null
        ? loop(
            {
              ...state,
              readersSearchText: action.name,
              readersSearchResults: { isPending: true }
            },
            Cmd.run(searchStudyReaders, {
              successActionCreator: caseSearchReadersSuccess,
              failActionCreator: caseSearchReadersFailure,
              args: [state.studyId, action.name] as Parameters<typeof searchStudyReaders>
            })
          )
        : (state as never);
    case ActionTypes.SEARCH_READERS_REQUEST_SUCCESS:
      return {
        ...state,
        readersSearchResults: { resource: action.readersStudyStats }
      };
    case ActionTypes.SEARCH_READERS_REQUEST_FAILURE:
      return {
        ...state,
        readersSearchResults: { errorMessage: action.errorMsg }
      };
    case ActionTypes.SUGGEST_READERS_REQUEST:
      return state.studyId !== null
        ? loop(
            state,
            Cmd.run(suggestReaders, {
              successActionCreator: caseSuggestReadersSuccess,
              failActionCreator: caseSuggestReadersFailure,
              args: [state.studyId, action.subjectId] as Parameters<typeof suggestReaders>
            })
          )
        : (state as never);
    case ActionTypes.SUGGEST_READERS_REQUEST_SUCCESS:
      return {
        ...state,
        readerSuggestions: { resource: action.readersStudyStats }
      };
    case ActionTypes.SUGGEST_READERS_REQUEST_FAILURE:
      return {
        ...state,
        readerSuggestions: { errorMessage: action.errorMsg }
      };
    case ActionTypes.DROP_READER:
      return state.histoCase.data
        ? {
            ...state,
            histoCase: {
              data: {
                ...state.histoCase.data,
                readers: {
                  value: state.histoCase.data.readers.value.filter(
                    readerStudyStats =>
                      readerStudyStats.reader.id !== action.readerStudyStats.reader.id
                  )
                }
              }
            }
          }
        : state;
    case ActionTypes.ADD_READER:
      return state.histoCase.data
        ? {
            ...state,
            histoCase: {
              data: {
                ...state.histoCase.data,
                readers: {
                  value: !state.histoCase.data.readers.value
                    .map(readerStats => readerStats.reader.id)
                    .includes(action.readerStudyStats.reader.id)
                    ? [...state.histoCase.data.readers.value, action.readerStudyStats]
                    : state.histoCase.data.readers.value
                }
              }
            }
          }
        : state;
    case ActionTypes.UNASSIGN_IMAGE:
      return state.histoCase.data
        ? {
            ...state,
            histoCase: {
              data: {
                ...state.histoCase.data,
                images: {
                  value: state.histoCase.data.images.value.filter(
                    imageWithAnnotations => imageWithAnnotations.id !== action.image.id
                  )
                }
              }
            }
          }
        : state;
    case ActionTypes.ASSIGN_IMAGE:
      return state.histoCase.data
        ? {
            ...state,
            histoCase: {
              data: {
                ...state.histoCase.data,
                images: {
                  value: [...state.histoCase.data.images.value, action.image]
                }
              }
            }
          }
        : state;
    case ActionTypes.IMAGES_SEARCH:
      return state.histoCase.data
        ? loop(
            {
              ...state,
              imagesSearchText: action.name,
              imagesSearchResults: {
                isPending: true
              }
            },
            Cmd.run(searchStudyImages, {
              successActionCreator: imagesSearchSuccess,
              failActionCreator: imagesSearchFailure,
              args: [
                state.studyId,
                state.id,
                action.name,
                state.histoCase.data.images.value.map(image => image.id)
              ] as Parameters<typeof searchStudyImages>
            })
          )
        : state;
    case ActionTypes.IMAGES_SEARCH_SUCCESS:
      return {
        ...state,
        imagesSearchResults: {
          resource: action.images
        }
      };
    case ActionTypes.IMAGES_SEARCH_FAILURE:
      return {
        ...state,
        imagesSearchResults: {
          errorMessage: action.errorMsg
        }
      };
    default:
      return state as never;
  }
};

export default caseDialogReducer;
