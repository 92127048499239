import { combineReducers } from "redux-loop";
import { Config, User } from "./models";
import { Resource } from "./types";

import {
  auditTrailReducer,
  AuditTrailState,
  initialState as initialAuditTrailState
} from "./reducers/auditTrail";
import authReducer from "./reducers/auth";
import caseDialogReducer, {
  CaseDialogState,
  initialState as initialCaseDialogState
} from "./reducers/caseDialog";
import caseImageViewerReducer, {
  CaseImageViewerState,
  initialState as initialCaseImageViewerState
} from "./reducers/caseImageViewer";
import casesReducer, { CasesState, initialState as initialCasesState } from "./reducers/cases";
import {
  caseStatusDialogReducer,
  CaseStatusDialogState,
  initialState as initialCaseStatusDialogState
} from "./reducers/caseStatusDialog";
import configReducer from "./reducers/config";
import {
  copyImageDialogReducer,
  CopyImageDialogState,
  initialState as initialCopyImageDialogState
} from "./reducers/copyImageDialog";
import {
  deleteImageDialogReducer,
  DeleteImageDialogState,
  initialState as initialDeleteImageDialogState
} from "./reducers/deleteImageDialog";
import {
  imageDialogReducer,
  ImageDialogState,
  initialState as initialImageDialogState
} from "./reducers/imageDialog";
import imagesReducer, { ImagesState, initialState as initialImagesState } from "./reducers/images";
import mapReducer, { initialState as initialMapState, MapState } from "./reducers/map";
import {
  initialState as initialLocatorMapState,
  locatorMapReducer,
  LocatorMapState
} from "./reducers/locationMap";
import {
  initialState as initialMetadataImagesState,
  metadataImagesReducer,
  MetadataImagesState
} from "./reducers/metadataImages";
import {
  initialState as initialAnnotationNestState,
  annotationNestReducer,
  AnnotationNestState
} from "./reducers/annotationNest";
import {
  initialState as initialMoveImageDialogState,
  moveImageDialogReducer,
  MoveImageDialogState
} from "./reducers/moveImageDialog";
import reportsReducer, {
  initialState as initialReportState,
  ReportState
} from "./reducers/reports";
import studiesReducer, {
  initialState as initialStudiesState,
  StudiesState
} from "./reducers/studies";
import studyConfigurationReducer, {
  initialState as initialStudyConfigurationState,
  StudyConfigurationState
} from "./reducers/studyConfiguration";
import {
  initialState as initialUploadDialogState,
  uploadDialogReducer,
  UploadDialogState
} from "./reducers/uploadDialog";
import { userDialogReducer, UserDialogState } from "./reducers/userDialog";
import usersReducer from "./reducers/users";

import { initialState as initialUsersState, UsersState } from "./reducers/users";
import queriesReducer, { QueryState, initialState as initialQueryState } from "./reducers/queries";

export interface State {
  readonly auth: Resource<User>;
  readonly config: Resource<Config>;
  readonly users: UsersState;
  readonly studies: StudiesState;
  readonly userDialog: UserDialogState;
  readonly studyConfiguration: StudyConfigurationState;
  readonly uploadDialog: UploadDialogState;
  readonly deleteImageDialog: DeleteImageDialogState;
  readonly moveImageDialog: MoveImageDialogState;
  readonly copyImageDialog: CopyImageDialogState;
  readonly imageDialog: ImageDialogState;
  readonly images: ImagesState;
  readonly cases: CasesState;
  readonly caseDialog: CaseDialogState;
  readonly caseStatusDialog: CaseStatusDialogState;
  readonly auditTrail: AuditTrailState;
  readonly map: MapState;
  readonly caseImageViewer: CaseImageViewerState;
  readonly locatorMap: LocatorMapState;
  readonly metadataImages: MetadataImagesState;
  readonly annotationNest: AnnotationNestState;
  readonly reports: ReportState;
  readonly queries: QueryState;
}

export const initialState: State = {
  auth: {
    isPending: false
  },
  config: {
    isPending: false
  },
  users: initialUsersState,
  studies: initialStudiesState,
  userDialog: {
    isOpen: false,
    user: undefined,
    userForm: {
      firstName: "",
      lastName: "",
      email: "",
      role: null
    },
    acceptButtonDisabled: true,
    returnedUser: null,
    errorMessage: null
  },
  studyConfiguration: initialStudyConfigurationState,
  uploadDialog: initialUploadDialogState,
  deleteImageDialog: initialDeleteImageDialogState,
  moveImageDialog: initialMoveImageDialogState,
  copyImageDialog: initialCopyImageDialogState,
  imageDialog: initialImageDialogState,
  images: initialImagesState,
  cases: initialCasesState,
  caseDialog: initialCaseDialogState,
  caseStatusDialog: initialCaseStatusDialogState,
  auditTrail: initialAuditTrailState,
  map: initialMapState,
  caseImageViewer: initialCaseImageViewerState,
  locatorMap: initialLocatorMapState,
  metadataImages: initialMetadataImagesState,
  annotationNest: initialAnnotationNestState,
  reports: initialReportState,
  queries: initialQueryState
};

const createRootReducer = () =>
  combineReducers({
    auth: authReducer,
    config: configReducer,
    users: usersReducer,
    studies: studiesReducer,
    userDialog: userDialogReducer,
    studyConfiguration: studyConfigurationReducer,
    uploadDialog: uploadDialogReducer,
    deleteImageDialog: deleteImageDialogReducer,
    moveImageDialog: moveImageDialogReducer,
    copyImageDialog: copyImageDialogReducer,
    imageDialog: imageDialogReducer,
    images: imagesReducer,
    cases: casesReducer,
    caseDialog: caseDialogReducer,
    caseStatusDialog: caseStatusDialogReducer,
    auditTrail: auditTrailReducer,
    map: mapReducer,
    caseImageViewer: caseImageViewerReducer,
    locatorMap: locatorMapReducer,
    metadataImages: metadataImagesReducer,
    annotationNest: annotationNestReducer,
    reports: reportsReducer,
    queries: queriesReducer
  });

export default createRootReducer;
