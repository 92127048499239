import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { equals } from "ramda";
import { State } from "../reducers";

import {
  Box,
  Button,
  Callout,
  Grouper,
  Intent,
  Label,
  Select as BlasterSelect,
  TextInput,
  TextArea,
  Checkbox,
  Text
} from "@blasterjs/core";

import {
  AssignableUserRoles,
  assignUserToStudy,
  changeStudy,
  EditableAnnotationClassFields,
  searchReadersRequest,
  searchReadonlyUsersRequest,
  searchUploadersRequest,
  studyFetchRequest,
  StudyForm,
  StudyFormFields,
  studyRequest,
  studyReset,
  unassignUserFromStudy,
  UserFromRole
} from "../actions/studyConfiguration";
import ColorPicker from "../components/ColorPicker";
import Page, { PageBody, PageHeader, PageHeading } from "../components/Page";
import Select, { SelectOfType } from "../components/Select";
import Content from "../components/Content";
import { AnnotationClassForm } from "../actions/studyConfiguration";
import {
  AnnotationClassType,
  AnnotationClassWithCount,
  formatIndication,
  formatModality,
  Indication,
  Modality,
  Reader,
  ReadonlyUser,
  UpdateValueWithMaybeReasonForChange,
  Uploader,
  userLabel,
  UserRole
} from "../models";
import {
  initialAnnotationClassState,
  StudyConfigurationState
} from "../reducers/studyConfiguration";
import store from "../store";
import ReasonForChange from "../components/ReasonForChange";
import { areDifferent, idsOnly } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp, faTrashCan, faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowDown);
library.add(faArrowUp);
library.add(faTrashCan);
library.add(faLockOpen);
library.add(faLock);

const SelectReaders = Select as SelectOfType<Reader>;
const SelectUploaders = Select as SelectOfType<Uploader>;
const SelectReadonlyUsers = Select as SelectOfType<ReadonlyUser>;

const NowrapGrouper = styled(Grouper)`
  & > div {
    flex-wrap: nowrap;
    width: auto;
  }
`;

interface ConfigValidations {
  readonly nameRequired: string | null;
  readonly indicationRequired: string | null;
  readonly locationsRequired: string | null;
  readonly modalityRequired: string | null;
  readonly freehandMessage: string | null;
  readonly freehandErrorsExist: boolean;
  readonly pointMessage: string | null;
  readonly pointErrorsExist: boolean;
  readonly hpfMessage: string | null;
  readonly hpfErrorsExist: boolean;
  readonly onHoldReasonRequired: string | null;
  readonly errorsExist: boolean;
}

interface StateProps {
  readonly configValidations: ConfigValidations;
  readonly studyConfiguration: StudyConfigurationState;
}

type Props = StateProps;

type AnnotationClassFields = keyof Pick<
  StudyFormFields,
  "hpfAnnotationClasses" | "pointAnnotationClasses" | "freehandAnnotationClasses"
>;
type UserFields = keyof Pick<StudyFormFields, "uploaders" | "readers" | "readonlyUsers">;
type StudyFields = keyof Omit<StudyFormFields, UserFields | AnnotationClassFields>;

export const convAnno = (a: AnnotationClassForm<AnnotationClassType>) => {
  return {
    id: a.id || "no-id",
    name: a.name || "undef",
    color: a.color,
    sortOrder: a.sortOrder,
    type: a.type,
    enabled: a.enabled,
    deleted: a.deleted
  };
};

export const convAnnoOld = (a: AnnotationClassWithCount<AnnotationClassType>) => {
  return {
    id: a.annotationClass.id || "no-id",
    name: a.annotationClass.name || "undef",
    color: a.annotationClass.color,
    sortOrder: a.annotationClass.sortOrder,
    type: a.annotationClass.type,
    enabled: a.annotationClass.enabled,
    deleted: false
  };
};

const StudyConfiguration = ({ configValidations, studyConfiguration }: Props) => {
  const params = useParams();
  const id: string = params.id || "no-id";

  useEffect(() => {
    store.dispatch(!id || id === "new" ? studyReset() : studyFetchRequest(id));
  }, [id]);

  // Keep track of form submit state for the purposes of avoiding flicker while submitting the form.
  // When the initial study loads, we want to obscure the whole form with a spinner, but then when
  // we submit the form, we just want to show a spinner on the submit button.
  const [isSubmitting, setSubmitting] = useState(false);
  const hasErrorMessage = "errorMessage" in studyConfiguration.study;
  useEffect(() => {
    setSubmitting(false);
  }, [hasErrorMessage]);

  const updateStudyField = <
    Key extends keyof StudyForm,
    PartialUpdate extends Partial<StudyForm[Key]>
  >(
    studyKey: Key,
    value: PartialUpdate
  ) => {
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [studyKey]: {
          ...studyConfiguration.study.data[studyKey],
          ...value
        }
      })
    );
  };
  const updateStudyFieldWithValue = (key: keyof StudyForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateStudyField(key, { value: e.currentTarget.value });
  const updateStudyFieldWithReasonForChange = (key: keyof StudyForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateStudyField(key, { reasonForChange: e.currentTarget.value });

  const onIndicationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const indicationString = e.target.value;
    updateStudyField("indication", {
      value: Object.values(Indication).find(indication => indication === indicationString) || null
    });
  };
  const onOnHoldChange = () => {
    const currentOnHoldValue = studyConfiguration.study.data.onHold.value;
    updateStudyField("onHold", { value: !currentOnHoldValue });
  };
  const onSegmentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfSegments = parseInt(e.target.value, 10);
    updateStudyField("segments", {
      value: isNaN(numberOfSegments) ? null : numberOfSegments
    });
  };
  const onModalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModalities = Array.from(e.target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    updateStudyField("modality", {
      value: Object.values(Modality).filter(modality => selectedModalities.includes(modality))
    });
  };
  const onReaderNameChange = (value: string) => {
    store.dispatch(searchReadersRequest(value));
  };
  const onUploaderNameChange = (value: string) => {
    store.dispatch(searchUploadersRequest(value));
  };
  const onReadonlyUserNameChange = (value: string) => {
    store.dispatch(searchReadonlyUsersRequest(value));
  };
  const onSelectUser = <R extends AssignableUserRoles>(role: R) => (user: UserFromRole<R>) => {
    store.dispatch(assignUserToStudy(role, user));
  };
  const onDeselectUser = <R extends AssignableUserRoles>(role: R) => (user: UserFromRole<R>) => {
    store.dispatch(unassignUserFromStudy(role, user));
  };
  const onAnnotationClassFieldChange = <K extends AnnotationClassFields>(
    annotationClassFormKey: K
  ) => (index: number, field: EditableAnnotationClassFields, value: string) => {
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: [
            ...studyConfiguration.study.data[annotationClassFormKey].value.slice(0, index),
            {
              // Mix in any existing values
              ...studyConfiguration.study.data[annotationClassFormKey].value[index],
              // Update specific field with value
              [field]: value
            },
            ...studyConfiguration.study.data[annotationClassFormKey].value.slice(index + 1)
          ]
        }
      })
    );
  };
  const onHpfAnnotationClassFieldChange = onAnnotationClassFieldChange("hpfAnnotationClasses");
  const onPointAnnotationClassFieldChange = onAnnotationClassFieldChange("pointAnnotationClasses");
  const onFreehandAnnotationClassFieldChange = onAnnotationClassFieldChange(
    "freehandAnnotationClasses"
  );
  const onAddAnnotationClass = (annotationClassFormKey: AnnotationClassFields) => () => {
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: [
            ...studyConfiguration.study.data[annotationClassFormKey].value,
            initialAnnotationClassState
          ]
        }
      })
    );
  };
  const onMoveAnnotationClassUp = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => () => {
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 1 ? oldValues.slice(0, index - 1) : []),
      oldValues[index],
      oldValues[index - 1],
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };
  const onMoveAnnotationClassDown = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => () => {
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      oldValues[index + 1],
      oldValues[index],
      ...(index < oldValues.length - 1 ? oldValues.slice(index + 2, oldValues.length) : [])
    ];
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onDeleteAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      { ...oldValues[index], deleted: true },
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onDisableAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      { ...oldValues[index], enabled: false },
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onEnableAnnotationClass = (
    annotationClassFormKey: AnnotationClassFields,
    index: number
  ) => (e: React.MouseEvent) => {
    e.preventDefault();
    const oldValues = studyConfiguration.study.data[annotationClassFormKey].value;
    const newValues = [
      ...(index > 0 ? oldValues.slice(0, index) : []),
      { ...oldValues[index], enabled: true },
      ...(index < oldValues.length ? oldValues.slice(index + 1, oldValues.length) : [])
    ];
    store.dispatch(
      changeStudy({
        ...studyConfiguration.study.data,
        [annotationClassFormKey]: {
          ...studyConfiguration.study.data[annotationClassFormKey],
          value: newValues
        }
      })
    );
  };

  const onAddHpfAnnotationClass = onAddAnnotationClass("hpfAnnotationClasses");
  const onAddPointAnnotationClass = onAddAnnotationClass("pointAnnotationClasses");
  const onAddFreehandAnnotationClass = onAddAnnotationClass("freehandAnnotationClasses");
  const saveOnClick = () => {
    setSubmitting(true);
    store.dispatch(studyRequest());
  };
  const navigate = useNavigate();
  const cancelOnClick = () => {
    setSubmitting(false);
    navigate(-1);
  };
  const error =
    "errorMessage" in studyConfiguration.study ? (
      <Box>
        <Callout title="Error" intent={Intent.DANGER}>
          {studyConfiguration.study.errorMessage}
        </Callout>
      </Box>
    ) : null;
  const reasonForChangeBox = (
    field: keyof StudyForm,
    update: UpdateValueWithMaybeReasonForChange<any>
  ) => (
    <Box key={"rfcb_" + field.toString()}>
      <ReasonForChange
        rkey={"rfc_" + field.toString()}
        mb={2}
        value={"reasonForChange" in update ? update.reasonForChange : ""}
        onChange={updateStudyFieldWithReasonForChange(field)}
      />
    </Box>
  );
  const reasonForChangeStudyField = (field: StudyFields) => {
    const update = studyConfiguration.study.data[field];
    return studyConfiguration.savedStudy &&
      !equals(studyConfiguration.savedStudy.study[field], update.value)
      ? reasonForChangeBox(field, update)
      : null;
  };
  const noChangeStudyField = (field: StudyFields) => {
    const update = studyConfiguration.study.data[field];
    if (studyConfiguration.savedStudy) {
      const isDiff = studyConfiguration.savedStudy
        ? !equals(studyConfiguration.savedStudy.study[field], update.value)
        : update.value !== null;
      const rfc: string = (update as any).reasonForChange || "";
      return !isDiff || rfc.length == 0;
    } else {
      return false;
    }
  };
  const reasonForChangeStudyAnnotationField = (field: AnnotationClassFields) => {
    const update = studyConfiguration.study.data[field];
    const newVal = update.value.map(convAnno);
    const oldVal =
      studyConfiguration.savedStudy && studyConfiguration.savedStudy[field].map(convAnnoOld);
    const diff = oldVal && !equals(oldVal, newVal);
    return diff ? reasonForChangeBox(field, update) : null;
  };
  const noChangeStudyAnnotationField = (field: AnnotationClassFields) => {
    const update = studyConfiguration.study.data[field];
    const newVal = update.value.map(convAnno);
    const oldVal =
      studyConfiguration.savedStudy && studyConfiguration.savedStudy[field].map(convAnnoOld);
    const diff = oldVal && !equals(oldVal, newVal);
    const rfc: string = (update as any).reasonForChange || "";
    return !(diff && rfc.length > 0);
  };
  const reasonForChangeStudyUsersField = (field: UserFields) => {
    const update = studyConfiguration.study.data[field];
    return studyConfiguration.savedStudy &&
      areDifferent(idsOnly(studyConfiguration.savedStudy[field]), idsOnly(update.value))
      ? reasonForChangeBox(field, update)
      : null;
  };
  const noChangeStudyUsersField = (field: UserFields) => {
    const update = studyConfiguration.study.data[field];
    const diff =
      studyConfiguration.savedStudy &&
      areDifferent(idsOnly(studyConfiguration.savedStudy[field]), idsOnly(update.value));
    const rfc: string = (update as any).reasonForChange || "";
    return !(diff && rfc.length > 0);
  };

  function nothingIsDirty(): boolean {
    return (
      noChangeStudyField("name") &&
      noChangeStudyField("indication") &&
      noChangeStudyField("segments") &&
      noChangeStudyField("modality") &&
      noChangeStudyField("onHold") &&
      noChangeStudyField("onHoldReason") &&
      noChangeStudyAnnotationField("hpfAnnotationClasses") &&
      noChangeStudyAnnotationField("pointAnnotationClasses") &&
      noChangeStudyAnnotationField("freehandAnnotationClasses") &&
      noChangeStudyUsersField("uploaders") &&
      noChangeStudyUsersField("readers") &&
      noChangeStudyUsersField("readonlyUsers")
    );
  }

  return (
    <Page>
      {studyConfiguration ? (
        <Box style={{ padding: "0 2rem 4rem" }}>
          <PageHeader>
            <PageHeading>{!id || id === "new" ? "Create Study" : "Edit Study"}</PageHeading>
          </PageHeader>
          <Content
            isLoading={
              !isSubmitting &&
              "isPending" in studyConfiguration.study &&
              studyConfiguration.study.isPending
            }
          >
            <PageBody>
              {error}
              <Box>
                <Label>
                  Name
                  <Box
                    style={{
                      display: configValidations.errorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.nameRequired}
                  </Box>
                  <TextInput
                    placeholder="Study Name"
                    mb={2}
                    value={studyConfiguration.study.data.name.value || ""}
                    onChange={updateStudyFieldWithValue("name")}
                  />
                </Label>
              </Box>
              {reasonForChangeStudyField("name")}
              <NowrapGrouper mb={2}>
                <Box width="66.666%">
                  <Box mb={1}>
                    <Label>
                      Indication
                      <Box
                        style={{
                          display: configValidations.errorsExist ? "block" : "none",
                          color: "red"
                        }}
                      >
                        {configValidations.indicationRequired}
                      </Box>
                      <BlasterSelect
                        placeholder="Indication"
                        onChange={onIndicationChange}
                        value={studyConfiguration.study.data.indication.value || ""}
                      >
                        <option key="" value="" />
                        {Object.values(Indication).map(indication => (
                          <option key={indication} value={indication}>
                            {formatIndication(indication)}
                          </option>
                        ))}
                      </BlasterSelect>
                    </Label>
                  </Box>
                  {reasonForChangeStudyField("indication")}
                </Box>
                <Box width="33.333%">
                  <Box mb={1}>
                    <Label>
                      Locations
                      <Box
                        style={{
                          display: configValidations.errorsExist ? "block" : "none",
                          color: "red"
                        }}
                      >
                        {configValidations.locationsRequired}
                      </Box>
                      <TextInput
                        type="number"
                        min="1"
                        step="1"
                        value={studyConfiguration.study.data.segments.value || ""}
                        onChange={onSegmentsChange}
                      />
                    </Label>
                  </Box>
                  {reasonForChangeStudyField("segments")}
                </Box>
              </NowrapGrouper>
              <Box mb={2}>
                <Label>
                  Modality
                  <br />
                  <Box
                    style={{
                      display: configValidations.errorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.modalityRequired}
                  </Box>
                  {/* NOTE: Blaster multiple select does not work properly so regular <select> is used here */}
                  <select
                    multiple={true}
                    value={studyConfiguration.study.data.modality.value}
                    onChange={onModalityChange}
                    style={{ fontSize: "16px", padding: "5px" }}
                  >
                    {Object.values(Modality).map(modality => (
                      <option key={modality} value={modality}>
                        {formatModality(modality)}
                      </option>
                    ))}
                  </select>
                </Label>
              </Box>
              {reasonForChangeStudyField("modality")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  HPF Annotation Classes
                  <Box
                    style={{
                      display: configValidations.hpfErrorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.hpfMessage}
                  </Box>
                  <Box>
                    {studyConfiguration.study.data.hpfAnnotationClasses.value.map(
                      (hpfAnnotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_${index.toString()}"}
                            color={hpfAnnotationClass.color}
                            onChange={(hexCode: string) => {
                              onHpfAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(hpfAnnotationClass.deleted === undefined ||
                              hpfAnnotationClass.deleted === false) &&
                            hpfAnnotationClass.enabled === true ? (
                              <TextInput
                                placeholder="Name"
                                value={hpfAnnotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onHpfAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (hpfAnnotationClass.deleted === undefined ||
                                    hpfAnnotationClass.deleted === false) &&
                                  hpfAnnotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{hpfAnnotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.hpfAnnotationClasses.value.length - 1 ? (
                              <Button
                                onClick={onMoveAnnotationClassDown("hpfAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon icon={faArrowDown} />
                              </Button>
                            ) : (
                              <Box style={{ width: "74px" }}></Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <Button
                                onClick={onMoveAnnotationClassUp("hpfAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon icon={faArrowUp} />
                              </Button>
                            ) : (
                              <Box style={{ width: "74px" }}></Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {hpfAnnotationClass.count === undefined ||
                            hpfAnnotationClass.count === 0 ? (
                              <Button
                                disabled={hpfAnnotationClass.deleted}
                                onClick={onDeleteAnnotationClass("hpfAnnotationClasses", index)}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {hpfAnnotationClass.enabled ? (
                              <Button
                                onClick={onDisableAnnotationClass("hpfAnnotationClasses", index)}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon icon={faLockOpen} />
                              </Button>
                            ) : (
                              <></>
                            )}
                            {!hpfAnnotationClass.enabled ? (
                              <Button
                                onClick={onEnableAnnotationClass("hpfAnnotationClasses", index)}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon icon={faLock} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddHpfAnnotationClass()}>Add Class</Button>
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("hpfAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  Point Annotation Classes
                  <Box
                    style={{
                      display: configValidations.pointErrorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.pointMessage}
                  </Box>
                  <Box>
                    {studyConfiguration.study.data.pointAnnotationClasses.value.map(
                      (pointAnnotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_p_${index.toString()}"}
                            color={pointAnnotationClass.color}
                            onChange={(hexCode: string) => {
                              onPointAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(pointAnnotationClass.deleted === undefined ||
                              pointAnnotationClass.deleted === false) &&
                            pointAnnotationClass.enabled === true ? (
                              <TextInput
                                placeholder="Name"
                                style={{ marginRight: "15px" }}
                                value={pointAnnotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onPointAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (pointAnnotationClass.deleted === undefined ||
                                    pointAnnotationClass.deleted === false) &&
                                  pointAnnotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{pointAnnotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.pointAnnotationClasses.value.length -
                              1 ? (
                              <Button
                                onClick={onMoveAnnotationClassDown("pointAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon icon={faArrowDown} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <Button
                                onClick={onMoveAnnotationClassUp("pointAnnotationClasses", index)}
                              >
                                <FontAwesomeIcon icon={faArrowUp} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {pointAnnotationClass.count === undefined ||
                            pointAnnotationClass.count === 0 ? (
                              <Button
                                onClick={onDeleteAnnotationClass("pointAnnotationClasses", index)}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {pointAnnotationClass.enabled ? (
                              <Button
                                onClick={onDisableAnnotationClass("pointAnnotationClasses", index)}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon icon={faLockOpen} />
                              </Button>
                            ) : (
                              <></>
                            )}
                            {!pointAnnotationClass.enabled ? (
                              <Button
                                onClick={onEnableAnnotationClass("pointAnnotationClasses", index)}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon icon={faLock} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddPointAnnotationClass()}>Add Class</Button>
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("pointAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box display="flex" mb={1}>
                <Label>
                  Freehand Annotation Classes
                  <Box
                    style={{
                      display: configValidations.errorsExist ? "block" : "none",
                      color: "red"
                    }}
                  >
                    {configValidations.freehandMessage}
                  </Box>
                  <Box>
                    {studyConfiguration.study.data.freehandAnnotationClasses.value.map(
                      (annotationClass, index) => (
                        <Box display="flex" key={index} style={{ marginBottom: "4px" }}>
                          <ColorPicker
                            rkey={"cp_f_${index.toString()}"}
                            color={annotationClass.color}
                            onChange={(hexCode: string) => {
                              onFreehandAnnotationClassFieldChange(index, "color", hexCode);
                            }}
                          />
                          <Box mb={1} className="configTextInput">
                            {(annotationClass.deleted === undefined ||
                              annotationClass.deleted === false) &&
                            annotationClass.enabled === true ? (
                              <TextInput
                                placeholder="Name"
                                value={annotationClass.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onFreehandAnnotationClassFieldChange(
                                    index,
                                    "name",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            ) : (
                              <Box
                                editable={false}
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
                                className={
                                  (annotationClass.deleted === undefined ||
                                    annotationClass.deleted === false) &&
                                  annotationClass.enabled === true
                                    ? "deletedTextBox"
                                    : "disabledTextBox"
                                }
                              >
                                <Text>{annotationClass.name}</Text>
                              </Box>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index <
                            studyConfiguration.study.data.freehandAnnotationClasses.value.length -
                              1 ? (
                              <Button
                                onClick={onMoveAnnotationClassDown(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon icon={faArrowDown} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {index > 0 ? (
                              <Button
                                onClick={onMoveAnnotationClassUp(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                              >
                                <FontAwesomeIcon icon={faArrowUp} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.count === undefined || annotationClass.count === 0 ? (
                              <Button
                                onClick={onDeleteAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="delete annotation class from study"
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box className="toolButton">
                            {annotationClass.enabled ? (
                              <Button
                                onClick={onDisableAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="remove annotation class from use"
                              >
                                <FontAwesomeIcon icon={faLockOpen} />
                              </Button>
                            ) : (
                              <></>
                            )}
                            {!annotationClass.enabled ? (
                              <Button
                                onClick={onEnableAnnotationClass(
                                  "freehandAnnotationClasses",
                                  index
                                )}
                                title="add annotation class back to use"
                              >
                                <FontAwesomeIcon icon={faLock} />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                    <Box mb={1}></Box>
                    <Button onClick={() => onAddFreehandAnnotationClass()}>Add Class</Button>
                  </Box>
                </Label>
              </Box>
              {reasonForChangeStudyAnnotationField("freehandAnnotationClasses")}
              <Box mb={2}>
                <hr />
              </Box>
              <Box>
                <Box>
                  <Label>
                    Assign Uploaders
                    <SelectUploaders
                      placeholder={"Search uploaders"}
                      searchText={studyConfiguration.uploadersSearchText}
                      searchResults={
                        "resource" in studyConfiguration.uploaderSearchResults
                          ? studyConfiguration.uploaderSearchResults.resource
                          : []
                      }
                      onSelect={onSelectUser(UserRole.Lab)}
                      onChangeSearchText={onUploaderNameChange}
                      onDeselect={onDeselectUser(UserRole.Lab)}
                      selectedItems={
                        ("data" in studyConfiguration.study &&
                          "uploaders" in studyConfiguration.study.data &&
                          studyConfiguration.study.data.uploaders.value) ||
                        []
                      }
                      isLoading={
                        "isPending" in studyConfiguration.uploaderSearchResults &&
                        studyConfiguration.uploaderSearchResults.isPending
                      }
                      format={userLabel}
                    />
                  </Label>
                </Box>
                {reasonForChangeStudyUsersField("uploaders")}
              </Box>
              <Box>
                <Box>
                  <Label>
                    Assign Readers
                    <SelectReaders
                      placeholder={"Search readers"}
                      searchText={studyConfiguration.readersSearchText}
                      searchResults={
                        "resource" in studyConfiguration.readerSearchResults
                          ? studyConfiguration.readerSearchResults.resource
                          : []
                      }
                      onSelect={onSelectUser(UserRole.Reader)}
                      onChangeSearchText={onReaderNameChange}
                      onDeselect={onDeselectUser(UserRole.Reader)}
                      selectedItems={
                        ("data" in studyConfiguration.study &&
                          "readers" in studyConfiguration.study.data &&
                          studyConfiguration.study.data.readers.value) ||
                        []
                      }
                      isLoading={
                        "isPending" in studyConfiguration.readerSearchResults &&
                        studyConfiguration.readerSearchResults.isPending
                      }
                      format={userLabel}
                    />
                  </Label>
                </Box>
                {reasonForChangeStudyUsersField("readers")}
              </Box>
              <Box>
                <Box>
                  <Label>
                    Assign Read-only Users
                    <SelectReadonlyUsers
                      placeholder={"Search read-only users"}
                      searchText={studyConfiguration.readonlyUsersSearchText}
                      searchResults={
                        "resource" in studyConfiguration.readonlyUserSearchResults
                          ? studyConfiguration.readonlyUserSearchResults.resource
                          : []
                      }
                      onSelect={onSelectUser(UserRole.Readonly)}
                      onChangeSearchText={onReadonlyUserNameChange}
                      onDeselect={onDeselectUser(UserRole.Readonly)}
                      selectedItems={
                        ("data" in studyConfiguration.study &&
                          "readonlyUsers" in studyConfiguration.study.data &&
                          studyConfiguration.study.data.readonlyUsers.value) ||
                        []
                      }
                      isLoading={
                        "isPending" in studyConfiguration.readonlyUserSearchResults &&
                        studyConfiguration.readonlyUserSearchResults.isPending
                      }
                      format={userLabel}
                    />
                  </Label>
                </Box>
                {reasonForChangeStudyUsersField("readonlyUsers")}
              </Box>
              <Box>
                <Label>
                  <Checkbox
                    placeholder="On Hold"
                    checked={studyConfiguration.study.data.onHold.value || false}
                    onChange={onOnHoldChange}
                  />
                  <Text>On Hold</Text>
                </Label>
              </Box>
              {reasonForChangeStudyField("onHold")}
              {studyConfiguration.study.data.onHold.value || false ? (
                <>
                  <Box>
                    <Label>
                      On Hold Reason
                      <Box
                        style={{
                          display: configValidations.errorsExist ? "block" : "none",
                          color: "red"
                        }}
                      >
                        {configValidations.onHoldReasonRequired}
                      </Box>
                      <TextArea
                        placeholder="On Hold Reason"
                        mb={2}
                        maxLength={178}
                        value={studyConfiguration.study.data.onHoldReason.value || ""}
                        onChange={updateStudyFieldWithValue("onHoldReason")}
                      />
                    </Label>
                  </Box>
                  {reasonForChangeStudyField("onHoldReason")}
                </>
              ) : null}
              <Box
                style={{
                  margin: "1.6rem -1.2rem 0",
                  padding: "1.6rem 1.2rem 0",
                  borderTop: "1px solid rgb(221, 224, 230)"
                }}
              >
                <Box>
                  <Button
                    isLoading={isSubmitting}
                    intent="primary"
                    appearance="prominent"
                    disabled={configValidations.errorsExist || nothingIsDirty()}
                    onClick={saveOnClick}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      marginLeft: "15px"
                    }}
                    isLoading={isSubmitting}
                    onClick={cancelOnClick}
                  >
                    Discard Changes
                  </Button>
                </Box>
              </Box>
            </PageBody>
          </Content>
        </Box>
      ) : (
        <Box>
          <Callout intent={Intent.WARNING}>Study not found</Callout>
        </Box>
      )}
    </Page>
  );
};

function validateConfig(studyConfiguration: StudyConfigurationState): ConfigValidations {
  const nameMissing = studyConfiguration.study.data.name.value ? false : true;
  const indicationMissing = studyConfiguration.study.data.indication.value === null;
  const locationsMissing = studyConfiguration.study.data.segments.value === null;
  const modalityMissing =
    studyConfiguration.study.data.modality.value === null ||
    studyConfiguration.study.data.modality.value.length === 0;
  const fhClasses = studyConfiguration.study.data.freehandAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  function isNonUnique(classes: readonly string[]): boolean {
    return classes.length !== Array.from(new Set(classes)).length || classes.includes("");
  }
  const fhNonUnique = isNonUnique(fhClasses);
  const pointClasses = studyConfiguration.study.data.pointAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  const hpfClasses = studyConfiguration.study.data.hpfAnnotationClasses.value.map(ac => {
    return ac.name || "";
  });
  const pointNonUnique = isNonUnique(pointClasses);
  const hpfNonUnique = isNonUnique(hpfClasses);
  const onHoldReasonMissing = studyConfiguration.study.data.onHold.value
    ? studyConfiguration.study.data.onHoldReason.value
      ? false
      : true
    : false;
  return {
    nameRequired: nameMissing ? "Name is required" : "",
    indicationRequired: indicationMissing ? "Indication is required" : "",
    locationsRequired: locationsMissing ? "Location is required" : "",
    modalityRequired: modalityMissing ? "Modality is required" : "",
    freehandMessage: fhNonUnique ? "name is blank or not unique" : "",
    freehandErrorsExist: fhNonUnique,
    pointMessage: pointNonUnique ? "name is blank or not unique" : "",
    pointErrorsExist: pointNonUnique,
    hpfMessage: hpfNonUnique ? "name is blank or not unique" : "",
    hpfErrorsExist: hpfNonUnique,
    onHoldReasonRequired: onHoldReasonMissing ? "On Hold Reason is required" : "",
    errorsExist:
      nameMissing ||
      indicationMissing ||
      locationsMissing ||
      modalityMissing ||
      fhNonUnique ||
      pointNonUnique ||
      hpfNonUnique ||
      onHoldReasonMissing
  };
}

function mapStateToProps(state: State): StateProps {
  return {
    configValidations: validateConfig(state.studyConfiguration),
    studyConfiguration: state.studyConfiguration
  };
}

export default connect(mapStateToProps)(StudyConfiguration);
