/* Pure functions to help with e.g. data conversion */
import { Feature, LineString, Point, Polygon as GeoJSONPolygon } from "geojson";
import L from "leaflet";
import { latLng, latLngBounds, LatLngBounds, LatLngLiteral } from "leaflet";
import { difference } from "lodash";
import {
  HasId,
  Indication,
  Polygon,
  UpdateValueWithMaybeReasonForChange,
  UUID,
  ZoomDefinition
} from "./models";

import { isThisYear, isToday } from "date-fns";
import format from "date-fns/format";
import { extname } from "path";

export const LOCALE = "en-CA";

export function pointToGeoJSON(latLng: LatLngLiteral): Feature<Point> {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [latLng.lat, latLng.lng]
    },
    properties: {}
  };
}

export function lineToGeoJSON(latLngs: ReadonlyArray<LatLngLiteral>): Feature<LineString> {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: latLngs.map(l => [l.lat, l.lng])
    },
    properties: {}
  };
}

export function polygonToGeoJSON(latLngs: ReadonlyArray<LatLngLiteral>): Feature<GeoJSONPolygon> {
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [latLngs.map(l => [l.lat, l.lng])]
    },
    properties: {}
  };
}

export function toLatLngBounds(poly: Polygon): LatLngBounds | undefined {
  const coords = poly.coordinates[0]?.map(arr => latLng(arr[1], arr[0]));
  return coords && latLngBounds(coords);
}

export function getMaxZoom(zooms: ReadonlyArray<ZoomDefinition>): number {
  return zooms.map(obj => obj.zoom).reduce((x, y) => Math.max(x, y), 0);
}

export function getMinZoom(zooms: ReadonlyArray<ZoomDefinition>): number {
  return zooms.map(obj => obj.zoom).reduce((x, y) => Math.min(x, y), 30);
}

export function formatDate(date: Date | null): string {
  return date instanceof Date
    ? isToday(date)
      ? format(date, "h:mma")
      : isThisYear(date)
      ? format(date, "MMM d")
      : format(date, "MMM d yyyy")
    : "—";
}

function distanceToMeters(distance: number, pixelSize: number, resolutionInMeters: number): number {
  return (distance * resolutionInMeters) / pixelSize;
}

export function metersToMicrometers(meters: number): number {
  return meters * 10 ** 6;
}

export function toMicrometers(
  distance: number,
  pixelSize: number,
  resolutionInMeters: number
): number {
  const meters = distanceToMeters(distance, pixelSize, resolutionInMeters);
  return metersToMicrometers(meters);
}

export function formatMicrometers(micrometers: number): string {
  return `${Math.floor(micrometers)} μm`;
}

/**
 * Calculate the number of micrometers (μm) corresponding to a given distance on current map.
 */
export function mapDistanceToMicrometers(
  map: L.Map,
  distance: number,
  pixelSize: number,
  resolutionInMeters: number
): number {
  // NOTE: Borrowed logic from built in Leaflet scale control
  // See: https://github.com/Leaflet/Leaflet/blob/37d2fd15ad6518c254fae3e033177e96c48b5012/src/control/Control.Scale.js#L68-L73
  const y = map.getSize().y / 2;
  const width = map.distance(
    map.containerPointToLatLng([0, y]),
    map.containerPointToLatLng([distance, y])
  );
  return metersToMicrometers(distanceToMeters(width, pixelSize, resolutionInMeters));
}

export function getHpfRadiusInMeters(
  pixelSize: number,
  resolutionInMeters: number,
  indication: Indication | null
): number {
  // Most studies use a standard zoom of 40x which is 0.238mm² known as 1 HPF
  // NASH studies use a 20x zoom
  const hpfFactor = indication && indication === Indication.NonalcoholicSteatohepatitis ? 2 : 1;
  const hpfAreaInSquareMeters = 0.238 * 10 ** -6;
  const hpfRadiusInMeters = Math.sqrt(hpfAreaInSquareMeters / Math.PI); // radius = sqrt(Area / PI)
  return (hpfFactor * (hpfRadiusInMeters * pixelSize)) / resolutionInMeters;
}

/*
 * Test whether sets of ids are different to detect changes in form input.
 */
export function areDifferent<T extends string | number>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<T>
): boolean {
  return a.length !== b.length
    ? true
    : difference(Array.from(a).sort(), Array.from(b).sort()).length > 0;
}

/*
 * Creates a name for a duplicate image
 */
export function duplicateImageName(imageName: string, suffix: string = "_d"): string {
  return imageName.replace(extname(imageName), `${suffix}${extname(imageName)}`);
}

export function idsOnly(haveId: readonly HasId[]): UUID[] {
  return haveId.map(({ id }) => id);
}

export function updateWithIdsOnly({
  value,
  reasonForChange
}: UpdateValueWithMaybeReasonForChange<readonly HasId[]>): UpdateValueWithMaybeReasonForChange<
  UUID[]
> {
  return {
    value: idsOnly(value),
    ...(reasonForChange ? { reasonForChange } : {})
  };
}
