import { Image } from "../models";

export interface DeleteImageForm {
  readonly image: Image;
  readonly reasonForChange: string | null;
}

export enum ActionTypes {
  OPEN_DELETE_IMAGE_DIALOG = "OPEN_DELETE_IMAGE_DIALOG",
  CLOSE_DELETE_IMAGE_DIALOG = "CLOSE_DELETE_IMAGE_DIALOG",
  SET_DELETE_IMAGE_FORM = "SET_DELETE_IMAGE_FORM",
  DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE",
  IMAGE_DELETE = "IMAGE_DELETE"
}

export type DeleteImageDialogAction =
  | {
      readonly type: ActionTypes.OPEN_DELETE_IMAGE_DIALOG;
      readonly image: Image;
    }
  | { readonly type: ActionTypes.CLOSE_DELETE_IMAGE_DIALOG }
  | { readonly type: ActionTypes.SET_DELETE_IMAGE_FORM; readonly form: DeleteImageForm }
  | { readonly type: ActionTypes.IMAGE_DELETE }
  | { readonly type: ActionTypes.DELETE_IMAGE_SUCCESS }
  | { readonly type: ActionTypes.DELETE_IMAGE_FAILURE; readonly errorMessage: string };

export function openDeleteImageDialog(image: Image): DeleteImageDialogAction {
  return {
    type: ActionTypes.OPEN_DELETE_IMAGE_DIALOG,
    image
  };
}

export function closeDeleteImageDialog(): DeleteImageDialogAction {
  return {
    type: ActionTypes.CLOSE_DELETE_IMAGE_DIALOG
  };
}

export function imageDelete(): DeleteImageDialogAction {
  return {
    type: ActionTypes.IMAGE_DELETE
  };
}

export function setDeleteImageForm(form: DeleteImageForm): DeleteImageDialogAction {
  return {
    type: ActionTypes.SET_DELETE_IMAGE_FORM,
    form
  };
}

export function deleteImageSuccess(): DeleteImageDialogAction {
  return {
    type: ActionTypes.DELETE_IMAGE_SUCCESS
  };
}

export function deleteImageFailure(errorMessage: string): DeleteImageDialogAction {
  return {
    type: ActionTypes.DELETE_IMAGE_FAILURE,
    errorMessage
  };
}
