import { Appearance, Button, Icon } from "@blasterjs/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { openDeleteImageDialog } from "../actions/deleteImageDialog";
import { openImageDialog } from "../actions/imageDialog";
import { imagesFetch } from "../actions/images";
import { openMoveImageDialog } from "../actions/moveImageDialog";
import { openUploadDialog } from "../actions/uploadDialog";
import {
  Image,
  ImageListView,
  ImageListViews,
  ImageWithCase,
  User,
  UserRole,
  UUID
} from "../models";
import { State } from "../reducers";
import store from "../store";
import { Resource } from "../types";

import { openCopyImageDialog } from "../actions/copyImageDialog";
import QueryIcon from "../components/QueryIcon";
import { StyledDataTable } from "../components/Table";
import Timestamp from "../components/Timestamp";
import TooltipFlyout, { TooltipLink, TooltipLinkDanger } from "../components/Tooltip";
import TooltipText from "../components/TooltipText";
import Content from "./Content";
import CopyImageDialog from "./CopyImageDialog";
import DeleteImageDialog from "./DeleteImageDialog";
import ImageDialog from "./ImageDialog";
import MoveImageDialog from "./MoveImageDialog";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";

interface Props {
  readonly studyId: UUID;
  readonly user: Resource<User>;
}

interface StateProps {
  readonly images: Resource<ImageListViews>;
}

const editImage = (image: Image) => () => store.dispatch(openImageDialog(image, false));
const moveImage = (image: Image) => () => store.dispatch(openMoveImageDialog(image));

const copyImage = (image: Image) => () => store.dispatch(openCopyImageDialog(image));
const replaceImage = (image: Image) => () => store.dispatch(openUploadDialog(image.studyId, image));
const deleteImage = (image: Image) => () => store.dispatch(openDeleteImageDialog(image));

const imageIsProcessed = (image: Image): boolean => "cogKey" in image;
const imageProcessingFailed = (image: Image): boolean =>
  !("cogKey" in image) && image.processingFailed === true;

const ImagesTable = ({ studyId, images, user }: Props & StateProps) => {
  useEffect(() => {
    store.dispatch(imagesFetch(studyId));
  }, [studyId]);
  const navigate = useNavigate();
  const [imageRowsPerPage, setImageRowsPerPage] = useLocalIntStorage("imageRowsPerPage", 20);
  const viewImage = ({ imageAndQuery }: ImageListView) =>
    navigate(`/studies/${imageAndQuery.image.studyId}/images/${imageAndQuery.image.id}`);
  const userCanEditImages =
    "resource" in user &&
    (user.resource.role === UserRole.ISC ||
      user.resource.role === UserRole.Admin ||
      user.resource.role === UserRole.Reader);
  const userCanDeleteImages = "resource" in user && user.resource.role === UserRole.Admin;
  const userCanUploadImages =
    "resource" in user &&
    user.resource.role !== null &&
    [UserRole.Admin, UserRole.ISC, UserRole.Lab].includes(user.resource.role);
  const columns: ReadonlyArray<object> = [
    {
      name: "Name",
      grow: 2,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.name,
      sortable: true
    },
    {
      name: "Uploaded",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.uploadedAt,
      sortable: true,
      format: ({ imageAndQuery }: ImageListView) => (
        <Timestamp date={imageAndQuery.image.uploadedAt} />
      )
    },
    {
      name: "Accession #",
      grow: 1,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.accessionNumber,
      sortable: true
    },
    {
      name: "Biopsy Location",
      grow: 1,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.biopsyLocation,
      sortable: true
    },
    {
      name: "Processed",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.processingFailed,
      sortable: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) =>
        imageIsProcessed(imageAndQuery.image) ? (
          <TooltipText text="Succeeded">
            <Icon name="processSuccess" color="green" />
          </TooltipText>
        ) : imageProcessingFailed(imageAndQuery.image) ? (
          <TooltipText text="Failed">
            <Icon name="processFail" color="red" />
          </TooltipText>
        ) : null
    },
    {
      name: "Query",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.query,
      sortable: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) => <QueryIcon imageAndQuery={imageAndQuery} />
    },
    ...("resource" in user && user.resource.role !== UserRole.Lab
      ? [
          {
            name: "Case",
            selector: (imageListView: ImageWithCase) => imageListView.simpleCase?.procId,
            sortable: true,
            grow: 1,
            cell: (imageListView: ImageWithCase) =>
              imageListView.simpleCase ? (
                <Link
                  to={`/studies/${imageListView.imageAndQuery.image.studyId}/cases/${imageListView.simpleCase.id}?tab=images`}
                  style={{ textDecoration: "none" }}
                >
                  <Button>{imageListView.simpleCase.procId}</Button>
                </Link>
              ) : null
          }
        ]
      : []),
    {
      right: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) =>
        userCanEditImages || userCanUploadImages || userCanDeleteImages ? (
          <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
            <Icon name="menu" />
            <TooltipFlyout placement={"left-start"}>
              {userCanEditImages ? (
                <>
                  <TooltipLink onClick={editImage(imageAndQuery.image)}>Edit</TooltipLink>
                  <TooltipLink onClick={copyImage(imageAndQuery.image)}>Copy</TooltipLink>
                  <TooltipLink onClick={moveImage(imageAndQuery.image)}>Move</TooltipLink>
                </>
              ) : null}
              {userCanUploadImages && imageAndQuery.query ? (
                <>
                  <TooltipLink onClick={replaceImage(imageAndQuery.image)}>Replace</TooltipLink>
                </>
              ) : null}
              {userCanDeleteImages ? (
                <TooltipLinkDanger onClick={deleteImage(imageAndQuery.image)}>
                  Archive
                </TooltipLinkDanger>
              ) : null}
            </TooltipFlyout>
          </Button>
        ) : null
    }
  ];
  const moveImageDialog = userCanEditImages ? <MoveImageDialog /> : null;
  const editImageDialog = userCanEditImages ? <ImageDialog /> : null;
  const deleteImageDialog = userCanDeleteImages ? <DeleteImageDialog /> : null;
  const copyImageDialog = userCanEditImages ? <CopyImageDialog /> : null;

  return (
    <>
      <Content isLoading={"isPending" in images}>
        {"resource" in images ? (
          <StyledDataTable
            columns={columns}
            data={images.resource}
            highlightOnHover={true}
            pointerOnHover={true}
            defaultSortField="imageAndQuery.image.uploadedAt"
            defaultSortAsc={false}
            sortIcon={<Icon name="caretUp" />}
            className="data-table"
            onRowClicked={viewImage}
            noHeader={true}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationPerPage={imageRowsPerPage}
            onChangeRowsPerPage={setImageRowsPerPage}
            customTheme={dataTableTheme}
          />
        ) : null}
      </Content>
      {moveImageDialog}
      {copyImageDialog}
      {editImageDialog}
      {deleteImageDialog}
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    images: state.images.images
  };
}

export default connect(mapStateToProps)(ImagesTable);
