import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { Action } from "../actions";
import {
  ActionTypes,
  studiesFetch,
  studiesFetchFailure,
  studiesFetchSuccess,
  studyFetchFailure,
  studyFetchSuccess
} from "../actions/studies";

import { fetchStudies, fetchStudy } from "../api";
import { StudyListViews, StudyView } from "../models";
import { Resource } from "../types";

export interface StudiesState {
  readonly studies: Resource<StudyListViews>;
  readonly studiesFilter: string | null;
  readonly study: Resource<StudyView>;
}

export const initialState: StudiesState = {
  studies: {
    isPending: false
  },
  studiesFilter: null,
  study: {
    isPending: false
  }
};

const studiesReducer: LoopReducer<StudiesState, Action> = (
  state: StudiesState = initialState,
  action: Action
): StudiesState | Loop<StudiesState, Action> => {
  switch (action.type) {
    case ActionTypes.SET_STUDIES_FILTER:
      return loop(
        {
          ...state,
          studiesFilter: action.name
        },
        Cmd.action(studiesFetch())
      );
    case ActionTypes.STUDIES_FETCH:
      return loop(
        {
          ...state,
          studies: {
            isPending: true
          }
        },
        Cmd.run(fetchStudies, {
          successActionCreator: studiesFetchSuccess,
          failActionCreator: studiesFetchFailure,
          args: [state.studiesFilter] as Parameters<typeof fetchStudies>
        })
      );
    case ActionTypes.STUDIES_FETCH_SUCCESS:
      return {
        ...state,
        studies: {
          resource: action.studies
        }
      };
    case ActionTypes.STUDIES_FETCH_FAILURE:
      return {
        ...state,
        studies: {
          errorMessage: action.errorMsg
        }
      };
    case ActionTypes.STUDY_FETCH:
      return loop(
        {
          ...state,
          study: {
            isPending: true
          }
        },
        Cmd.run(fetchStudy, {
          successActionCreator: studyFetchSuccess,
          failActionCreator: studyFetchFailure,
          args: [action.studyId] as Parameters<typeof fetchStudy>
        })
      );
    case ActionTypes.STUDY_FETCH_SUCCESS:
      return {
        ...state,
        study: {
          resource: action.study
        }
      };
    case ActionTypes.STUDY_FETCH_FAILURE:
      return {
        ...state,
        study: {
          errorMessage: action.errorMsg
        }
      };
    default:
      return state;
  }
};

export default studiesReducer;
