import { useEventHandlers } from "@react-leaflet/core";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useMap, Rectangle, TileLayer, useMapEvent, MapContainer } from "react-leaflet";
import styled from "styled-components";
import L, { LatLngBounds } from "leaflet";
import React from "react";
import { toggleLocatorMapPanelExpanded } from "../actions/locationMap";
import { LocatorMapState } from "../reducers/locationMap";
import { connect } from "react-redux";
import { State } from "../reducers";
import store from "../store";
import Expandable, { CollapseIcon } from "./Expandable";

type StateProps = Pick<LocatorMapState, "isLocatorMapPanelExpanded">;

const Locator = styled.div`
  position: absolute;
  z-index: 20000;
  padding: 0;
  left: 0px;
  bottom: 50px;
  display: flex;
  background: grey;
  width: 180px;
  height: 180px;
`;

const LocatorCollapsed = styled.div`
  position: absolute;
  z-index: 20000;
  padding: 0;
  left: -54px;
  bottom: 50px;
  display: flex;
  width: 180px;
  height: 26px;
`;

const ExpandableContainer = styled.div`
  position: absolute;
  z-index: 20001;
  padding: 0;
  top: 4px;
  right: 4px;
  bottom: ;
  display: flex;
`;

const CollapseIconContainer = styled.div`
  background: #fff;
  transform: scaleX(-1);
`;

interface LocatorBoxProps {
  readonly parentMap: L.Map;
  readonly mapBounds: LatLngBounds | undefined;
  readonly setMapBounds: (bounds: LatLngBounds) => void;
  readonly zoom: number;
}

interface LocatorMapProps {
  readonly tileUrl: string | null;
  readonly imageBounds: LatLngBounds | undefined;
  readonly minZoom: number;
  readonly isLocatorMapPanelExpanded: boolean;
}

const LocatorBox = ({ parentMap, mapBounds, setMapBounds, zoom }: LocatorBoxProps) => {
  const locatorMap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    e => {
      parentMap.setView(e.latlng, parentMap.getZoom());
      setMapBounds(parentMap.getBounds());
    },
    [parentMap, mapBounds]
  );

  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const onChange = useCallback(() => {
    setMapBounds(parentMap.getBounds());
  }, [locatorMap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [
    locatorMap,
    parentMap,
    zoom
  ]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  useEventHandlers({ instance: parentMap }, handlers);

  return mapBounds ? (
    <Rectangle bounds={mapBounds} color={"red"} fillOpacity={0} weight={1} />
  ) : null;
};

const LocatorMap = ({
  parentMap,
  mapBounds,
  setMapBounds,
  zoom,
  isLocatorMapPanelExpanded,
  tileUrl,
  minZoom,
  imageBounds
}: LocatorBoxProps & LocatorMapProps) => {
  const tileUrlRef = useRef(null);
  const LocatorContainer = isLocatorMapPanelExpanded ? Locator : LocatorCollapsed;
  useEffect(() => {
    if (tileUrlRef.current) {
      (tileUrlRef.current as any).setUrl(tileUrl);
    }
  }, [tileUrl]);
  return imageBounds && tileUrl ? (
    <LocatorContainer>
      <ExpandableContainer>
        <Expandable
          label={"Mini-map"}
          isExpanded={isLocatorMapPanelExpanded}
          onToggle={() => store.dispatch(toggleLocatorMapPanelExpanded())}
        >
          <CollapseIconContainer>
            <CollapseIcon
              onToggle={(e: React.MouseEvent) => {
                e.stopPropagation();
                store.dispatch(toggleLocatorMapPanelExpanded());
              }}
            />
          </CollapseIconContainer>
        </Expandable>
      </ExpandableContainer>

      {isLocatorMapPanelExpanded && (
        <MapContainer
          key={"minimap"}
          bounds={imageBounds}
          // disable all zooming and panning
          zoomControl={false}
          fadeAnimation={false}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          dragging={false}
          boxZoom={false}
          touchZoom={false}
          style={{
            position: "absolute",
            top: 1,
            bottom: 1,
            left: 1,
            right: 1
          }}
        >
          <TileLayer
            ref={tileUrlRef}
            url={tileUrl}
            zIndex={3000}
            noWrap={true}
            minNativeZoom={minZoom}
          />
          {parentMap && (
            <LocatorBox
              parentMap={parentMap}
              mapBounds={mapBounds}
              setMapBounds={setMapBounds}
              zoom={zoom}
            />
          )}
        </MapContainer>
      )}
    </LocatorContainer>
  ) : null;
};

function mapStateToProps(state: State): StateProps {
  return {
    isLocatorMapPanelExpanded: state.locatorMap.isLocatorMapPanelExpanded
  };
}

export default connect(mapStateToProps)(LocatorMap);
