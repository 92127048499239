import { AdminCase, CaseStatus } from "../models";

export interface CaseStatusForm {
  readonly status: CaseStatus | null;
  readonly reasonForChange: string | null;
}

export enum ActionTypes {
  OPEN_CASE_STATUS_DIALOG = "OPEN_CASE_STATUS_DIALOG",
  CLOSE_CASE_STATUS_DIALOG = "CLOSE_CASE_STATUS_DIALOG",
  SET_CASE_STATUS_FORM = "SET_NEW_CASE_STATUS",
  REVERT_CASE_STATUS = "REVERT_CASE_STATUS",
  REVERT_CASE_STATUS_SUCCESS = "REVERT_CASE_STATUS_SUCCESS",
  REVERT_CASE_STATUS_FAILURE = "REVERT_CASE_STATUS_FAILURE"
}

export type CaseStatusDialogAction =
  | {
      readonly type: ActionTypes.OPEN_CASE_STATUS_DIALOG;
      readonly histoCase: AdminCase;
    }
  | { readonly type: ActionTypes.CLOSE_CASE_STATUS_DIALOG }
  | { readonly type: ActionTypes.SET_CASE_STATUS_FORM; readonly form: CaseStatusForm }
  | { readonly type: ActionTypes.REVERT_CASE_STATUS }
  | { readonly type: ActionTypes.REVERT_CASE_STATUS_SUCCESS }
  | { readonly type: ActionTypes.REVERT_CASE_STATUS_FAILURE; readonly errorMsg: string };

export function openCaseStatusDialog(histoCase: AdminCase): CaseStatusDialogAction {
  return {
    type: ActionTypes.OPEN_CASE_STATUS_DIALOG,
    histoCase
  };
}

export function closeCaseStatusDialog(): CaseStatusDialogAction {
  return {
    type: ActionTypes.CLOSE_CASE_STATUS_DIALOG
  };
}

export function setCaseStatusForm(form: CaseStatusForm): CaseStatusDialogAction {
  return {
    type: ActionTypes.SET_CASE_STATUS_FORM,
    form
  };
}

export function revertCaseStatus(): CaseStatusDialogAction {
  return {
    type: ActionTypes.REVERT_CASE_STATUS
  };
}

export function revertCaseStatusSuccess(): CaseStatusDialogAction {
  return {
    type: ActionTypes.REVERT_CASE_STATUS_SUCCESS
  };
}

export function revertCaseStatusFailure(errorMsg: string): CaseStatusDialogAction {
  return {
    type: ActionTypes.REVERT_CASE_STATUS_FAILURE,
    errorMsg
  };
}
